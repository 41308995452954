import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {translateKey} from "../../../_redux/actions/index";
import {IconsMap} from "../../../_helpers/index";

class ContractPCMPlanningComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }

    onOpenShow() {
        this.setState(prevState => {
                return {
                    ...prevState,
                    isOpen: prevState.isOpen ? false : true
                }
            }
        );
    }

    shouldWeShowIt() {
        const {element: {pcmPlanningItem}} = this.props;
        return (pcmPlanningItem.name != null || pcmPlanningItem.email !== null)

    }

    render() {
        const {translateKey, element: {pcmPlanningItem}} = this.props;
        const {isOpen} = this.state;
        if (this.shouldWeShowIt()) {
            let pcmData;
            if (isOpen) {
                pcmData = (
                    <div className="lead-details-client-info">
                        <div className="col-sm-12 col-lg-5">
                            {pcmPlanningItem.name !== null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label
                                        className="col-sm-4 col-form-label form-label-basic">{translateKey("contract_pcm_planning_name")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {pcmPlanningItem.name}
                                        </span>
                                    </div>
                                </div>
                            }
                            {pcmPlanningItem.email !== null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">
                                        <a href={"mailto:" + pcmPlanningItem.email} target="_top">
                                            <img src={IconsMap.svg.email} alt="Email" className="user-name-icon"/>
                                        </a>
                                        {translateKey("contract_pcm_planning_email")}
                                    </label>
                                    <div className="col-sm-8">
                                        {pcmPlanningItem.email}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
            }
            return (
                <div className="lead-client-row">
                    <div className="lead-details-header">
                        <div className="filter-modal-option-text">{translateKey("contract_pcm_planning")}</div>
                        <div className="filter-modal-option-text cursor-pointer" onClick={() => this.onOpenShow()}>
                            {isOpen ? translateKey("show_less") : translateKey("show_more")}
                        </div>
                    </div>
                    {pcmData}
                </div>
            );
        } else {
            return '';
        }
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ContractPCMPlanningComponent));