import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";

class ClientsOffertsAndContractors extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      dashboardData: this.props.dashboardData,
      loadingDashBoard: true,
      month_now: {
        TotalInquiries: 0,
        TotalSignedContracts: 0,
        TotalNewContracts: 0,
        TotalEndingContracts: 0
      },
      month_previous: {
        TotalInquiries: 0,
        TotalSignedContracts: 0,
        TotalNewContracts: 0,
        TotalEndingContracts: 0
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    if(this.props.dashboardData!== null && this.props.dashboardData !== undefined ){
      if(this.props.dashboardData.ClientContractsStatistics !== null && this.props.dashboardData.ClientContractsStatistics !== undefined){
        if(this.props.dashboardData.ClientContractsStatistics.Months.length>0){
          if(this.props.dashboardData.ClientContractsStatistics.Months.length===1){
            const now = this.props.dashboardData.ClientContractsStatistics.Months[0];
            this.setState({
              loadingDashBoard: false,
              month_now: {
                TotalInquiries: now.TotalInquiries,
                TotalSignedContracts: now.TotalSignedContracts,
                TotalNewContracts: now.TotalNewContracts,
                TotalEndingContracts: now.TotalEndingContracts,
              }
            });
          }else{
            const now = this.props.dashboardData.ClientContractsStatistics.Months[1];
            const previous = this.props.dashboardData.ClientContractsStatistics.Months[0];
            this.setState({
              loadingDashBoard: false,
              month_now: {
                TotalInquiries: now.TotalInquiries,
                TotalSignedContracts: now.TotalSignedContracts,
                TotalNewContracts: now.TotalNewContracts,
                TotalEndingContracts: now.TotalEndingContracts,
              },
              month_previous:{
                TotalInquiries: previous.TotalInquiries,
                TotalSignedContracts: previous.TotalSignedContracts,
                TotalNewContracts: previous.TotalNewContracts,
                TotalEndingContracts: previous.TotalEndingContracts,
              }
            });
          }
        }else{
          this.setState({loadingDashBoard: false})
        }
      }
    }
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashBoard } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashBoard){
      Content = (
          <table className="table table-dashboard">
            <thead>
              <tr>
                <th className="dashboard-table-head"></th>
                <th className="dashboard-table-head text-right">{translateKey("month_now")}</th>
                <th className="dashboard-table-head text-right">{translateKey("month_previous")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("create_a_request_for_quotation")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_now.TotalInquiries}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_previous.TotalInquiries}</td>
              </tr>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("clients_signed_contracts")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_now.TotalSignedContracts}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_previous.TotalSignedContracts}</td>
              </tr>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("clients_soon_live")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_now.TotalNewContracts}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_previous.TotalNewContracts}</td>
              </tr>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("clients_going_to_end")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_now.TotalEndingContracts}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.month_previous.TotalEndingContracts}</td>
              </tr>
            </tbody>
          </table>
      )
    }
    return (
      <React.Fragment>
        <div className="table-container-wrapper no_padding">
          <div className="table-name-class">{translateKey("clients_offers_amount")}</div>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(null, mapDispatchToProps)(ClientsOffertsAndContractors));