import * as FieldTypes from "../fieldTypes";
import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as Sections from "./values/sections";
import * as IsContactPersonDataSameAs from "./values/isContactPersonDataSameAs";
import * as Entity from "./values/entity";
import * as Relationship from "./values/relationship";
import * as SkillRange from "./values/skillRange";
import * as SpecialAgree from "./values/specialAgree";
import * as LocationRange from "./values/locationRange";
import * as HouseType from "./values/houseType";
import * as MarketingID from "./values/marketingId";
const importantListForSection17 = [
  { key: "is_cooking", type: "BOOLEAN" },
  { key: "is_purchases", type: "BOOLEAN" },
  { key: "is_laundry", type: "BOOLEAN" },
  { key: "is_poted_flower_care", type: "BOOLEAN" },
  { key: "is_ironing", type: "BOOLEAN" },
  { key: "is_accompanying_patient_while_out", type: "BOOLEAN" },
  { key: "is_cleaning_the_house", type: "BOOLEAN" },
  { key: "is_company_during_medical_visits", type: "BOOLEAN" },
  { key: "is_common_trips", type: "BOOLEAN" },
  { key: "is_organization_of_free_time", type: "BOOLEAN" },
  { key: "is_driving", type: "BOOLEAN" },
  { key: "is_driving_automat_gearbox", type: "BOOLEAN" },
]

const importantListForSection4 = [
  { key: "is_allergies_list", type: "BOOLEAN" },
  { key: "is_difficulty_in_walking_determined_by_age", type: "BOOLEAN" },
  { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
  { key: "is_asthma_list", type: "BOOLEAN" },
  { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
  { key: "is_bedsores_list", type: "BOOLEAN" },
  { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
  { key: "is_dementia_list", type: "BOOLEAN" },
  { key: "is_atopic_dermatitis", type: "BOOLEAN" },
  { key: "is_diabetes", type: "BOOLEAN" },
  { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
  { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
  { key: "is_heart_failure_list", type: "BOOLEAN" },
  { key: "is_arrhythmias_list", type: "BOOLEAN" },
  { key: "is_hypertension_list", type: "BOOLEAN" },
  { key: "is_incontinence_list", type: "BOOLEAN" },
  { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
  { key: "is_osteoporosis_list", type: "BOOLEAN" },
  { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
  { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
  { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
  { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
  { key: "is_stoma_list", type: "BOOLEAN" },
  { key: "is_cancer_list", type: "BOOLEAN" },
  { key: "is_other_description_de", type: "STRING" }
]

const importantListForSection7 = [
  { key: "is_fearful", type: "BOOLEAN" },
  { key: "is_apathetic", type: "BOOLEAN" },
  { key: "is_demanding", type: "BOOLEAN" },
  { key: "is_cheerful", type: "BOOLEAN" },
  { key: "is_nervous", type: "BOOLEAN" },
  { key: "is_open", type: "BOOLEAN" },
  { key: "is_nice", type: "BOOLEAN" },
  { key: "is_moody", type: "BOOLEAN" },
  { key: "is_euphoric", type: "BOOLEAN" },
  { key: "is_decisive", type: "BOOLEAN" },
  { key: "is_sensitive", type: "BOOLEAN" },
  { key: "is_positive", type: "BOOLEAN" },
  { key: "is_unstable", type: "BOOLEAN" },
  { key: "is_withdrawn", type: "BOOLEAN" },
  { key: "is_critical", type: "BOOLEAN" },
  { key: "is_negative", type: "BOOLEAN" },
  { key: "is_emotional_condition_others_de", type: "STRING" }
]
const skipList = ["is_kein_pflegegrad"];
const skipListPatient = [
  "is_patient_last_name",
  "is_patient_first_name",
  "is_service_address_street",
  "is_service_address_street_number",
  "is_service_address_house_number",
  "is_service_address_postal_code",
  "is_service_address_city",
  "is_patient_cellphone"
];
const flattenFields = fieldsList =>
  fieldsList.reduce(
    (previous, field) =>
      Object.assign(previous, {
        [field.key]: field.value
      }),
    {}
  );
//const flattenFieldsArray = fieldsList => fieldsList.map(field => field.key);
const flattenFieldsArraywithSkiped = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key);

const flattenFieldsArraywithSkipedPatient = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipListPatient.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key);
const birthdayField = new FormField({
  defaultValue: "",
  key: "is_birthday",
  entity: Entity.PATIENT,
  isRequired: true
});
const weightField = new FormField({
  defaultValue: "",
  key: "is_weight",
  entity: Entity.PATIENT,
  isRequired: true
});
const heightField = new FormField({
  defaultValue: "",
  key: "is_height",
  entity: Entity.PATIENT,
  isRequired: true
});
const homeDoctorField = new FormField({
  defaultValue: "",
  key: "is_home_doctor",
  entity: Entity.PATIENT,
  isRequired: true
});

export default class BBForm extends Form {
  constructor() {
    super({
      sections: [
        // Section 0
        new FormSection({
          key: "section-0",
          sectionName: "bb_client_data_p_0",
          fields: [
            new FormField({
              key: "lastname",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "firstname",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_degree_relationship_with_patient_carer",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_street",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_street_number",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_zip_postal_code",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_registered_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_registered_city",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_email",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              }
            }),
            new FormField({
              key: "is_address_type",
              entity: Entity.CONTACT,
              defaultValue: " 717700000"
            }),
            new FormField({
              key: "is_correspondence_name",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isLastName = form.getFieldByKey("lastname");
                const isFirstName = form.getFieldByKey("firstname");
                return isLastName.value + isFirstName.value;
              }
            }),
            new FormField({
              key: "is_correspondence_city",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey(
                  "is_registered_city"
                );
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_zip_postal_code",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey(
                  "is_registered_zip_postal_code"
                );
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_house_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey(
                  "is_registered_house_number"
                );
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey(
                  "is_registered_street"
                );
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey(
                  "is_registered_street_number"
                );
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_contact_person_data_same_as",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              defaultValue: IsContactPersonDataSameAs.YES
            }),
            birthdayField,
            weightField,
            heightField,
            homeDoctorField
          ],
          nextSectionSelector: form => {
            const isContactPersonDataSameAs = form.getFieldByKey(
              "is_contact_person_data_same_as"
            );
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey(
              "is_degree_relationship_with_patient_carer"
            );
            if (
              isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES
            ) {
              if (
                isDegreeRelationShipWithPatientCarer.value ===
                Relationship.TYPE_8
              ) {
                return Sections.PFLEGEGRAD;
              } else {
                return Sections.PATIENT;
              }
            } else {
              return Sections.CONTACT_PERSON;
            }
          },
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey(
              "is_degree_relationship_with_patient_carer"
            );
            if (
              isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey(
              "is_degree_relationship_with_patient_carer"
            );
            if (
              isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          }
        }),

        // Section 1
        new FormSection({
          key: "section-1",
          sectionName: "bb_contact_data_p_1",
          fields: [
            new FormField({
              key: "is_contact_person_lastname",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("lastname").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_firstname",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("firstname").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_mobile_phone").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_email",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_email").value;
                }
              }
            }),
            new FormField({
              key: "is_relationship_with_patient_op",
              entity: Entity.CONTACT,
              isRequired: true,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey(
                    "is_degree_relationship_with_patient_carer"
                  ).value;
                }
              }
            })
          ]
        }),

        // Section 2
        new FormSection({
          key: "section-2",
          sectionName: "bb_lead_data_p_2",
          fields: [
            birthdayField,
            weightField,
            heightField,
            homeDoctorField,
            new FormField({
              key: "is_patient_last_name",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_patient_first_name",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_street",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_street_number",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_service_address_postal_code",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_service_address_city",
              entity: Entity.CONTACT,
              isRequired: true
            }),
            new FormField({
              key: "is_first_name_and_last_name_of_patient",
              entity: Entity.PATIENT,
              valueMapper: form => {
                const isPatientLastName = form.getFieldByKey(
                  "is_patient_last_name"
                );
                const isPatientFirstName = form.getFieldByKey(
                  "is_patient_first_name"
                );
                return isPatientFirstName.value + " " + isPatientLastName.value;
              }
            }),
            new FormField({
              key: "is_patient_cellphone",
              entity: Entity.CONTACT,
              isRequired: true,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            })
          ],
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey(
              "is_degree_relationship_with_patient_carer"
            );
            if (
              isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey(
              "is_degree_relationship_with_patient_carer"
            );
            if (
              isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          }
        }),

        // Section 3
        new FormSection({
          key: "section-3",
          sectionName: "bb_pflegegrad_data_p_3",
          fields: [
            new FormField({
              key: "is_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isKeinPflegegrad = form.getFieldByKey(
                  "is_kein_pflegegrad"
                );
                const isAppliedPflegegrad = form.getFieldByKey(
                  "is_applied_pflegegrad"
                );
                if (
                  isKeinPflegegrad.value === true ||
                  isAppliedPflegegrad.value === "717700007"
                ) {
                  return false;
                } else {
                  if (
                    isAppliedPflegegrad.value !== null &&
                    isAppliedPflegegrad.value !== ""
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            }),
            new FormField({
              key: "is_applied_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isKeinPflegegrad = form.getFieldByKey(
                  "is_kein_pflegegrad"
                );
                const isAppliedPflegegrad = form.getFieldByKey(
                  "is_applied_pflegegrad"
                );
                if (
                  isKeinPflegegrad.value === true ||
                  isAppliedPflegegrad.value === "717700007"
                ) {
                  return false;
                } else {
                  const isPflegegrad = form.getFieldByKey("is_pflegegrad");
                  if (
                    isPflegegrad.value !== null &&
                    isPflegegrad.value !== ""
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                }
              }
            }),
            new FormField({
              key: "is_krankenkasse",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isKeinPflegegrad = form.getFieldByKey(
                  "is_kein_pflegegrad"
                );
                const isAppliedPflegegrad = form.getFieldByKey(
                  "is_applied_pflegegrad"
                );
                if (
                  isKeinPflegegrad.value === true ||
                  isAppliedPflegegrad.value === "717700007"
                ) {
                  return false;
                }
                return true;
              }
            }),
            new FormField({
              key: "is_pflegegrad_posting_date",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_insurance_type",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_kein_pflegegrad",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
              /*valueMapper: (form) => {
                                const isPflegegrad = form.getFieldByKey('is_pflegegrad');
                                const isAppliedPflegegrad = form.getFieldByKey('is_applied_pflegegrad');
                                const isKrankenkasse = form.getFieldByKey('is_krankenkasse');
                                if(
                                    (isPflegegrad.value!==null && isPflegegrad.value.toString().trim() !=='') ||
                                    (isAppliedPflegegrad.value!==null && isAppliedPflegegrad.value.toString().trim() !=='') ||
                                    (isKrankenkasse.value!==null && isKrankenkasse.value.toString().trim() !=='')
                                ){
                                    return false
                                }else{
                                    return true
                                }
                            }*/
            })
          ],
          payloadExceptions: () => {
            return ["is_kein_pflegegrad"];
          }
        }),

        // Section 4   //    2.1
        new FormSection({
          key: "section-4",
          sectionName: "bb_ills_data_p_4",
          fields: [
            new FormField({
              key: "is_allergies_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: null
            }),
            new FormField({
              key: "is_difficulty_in_walking_determined_by_age",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_infectious_diseases",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false,
              fieldImportantList: [{ key: "is_infectious_diseases", type: "BOOLEAN" }],
            }),
            new FormField({
              key: "is_parkinsons_disease_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_stoma_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_cancer_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_family_suffer_infectionus_diseases_des_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isIndectiousDiseases = form.getFieldByKey("is_infectious_diseases");
                if (isIndectiousDiseases.value === true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_osteoporosis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_incontinence_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_bedsores_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_hypertension_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_other_description_de",
              entity: Entity.PATIENT,
              isRequired: false,
              fieldImportantList: importantListForSection4,
            }),
            new FormField({
              key: "is_asthma_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_chronic_diarrhea_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_dementia_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_patient_smokes",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
            }),
            new FormField({
              key: "is_alzheimers_disease_the_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_family_suffer_infectionus_diseases",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false,
              fieldImportantList: [{ key: "is_family_suffer_infectionus_diseases", type: "BOOLEAN" }]
            }),
            new FormField({
              key: "is_infectious_diseases_what_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isFamilySufferInfectionusDiseases = form.getFieldByKey(
                  "is_family_suffer_infectionus_diseases"
                );
                if (isFamilySufferInfectionusDiseases.value === true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_diabetes",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_diabetes_mellitus_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_atopic_dermatitis",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_heart_failure_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_initial_stages_of_dementia_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_rheumatic_disease_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_multiple_sclerosis_the_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_left_sided_paresis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_right_sided_paresis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_myocardial_infarction_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            }),
            new FormField({
              key: "is_arrhythmias_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              fieldImportantList: [
                { key: "is_allergies_list", type: "BOOLEAN" },
                {
                  key: "is_difficulty_in_walking_determined_by_age",
                  type: "BOOLEAN"
                },
                { key: "is_alzheimers_disease_the_list", type: "BOOLEAN" },
                { key: "is_asthma_list", type: "BOOLEAN" },
                { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
                { key: "is_bedsores_list", type: "BOOLEAN" },
                { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
                { key: "is_dementia_list", type: "BOOLEAN" },
                { key: "is_atopic_dermatitis", type: "BOOLEAN" },
                { key: "is_diabetes", type: "BOOLEAN" },
                { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
                { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
                { key: "is_heart_failure_list", type: "BOOLEAN" },
                { key: "is_arrhythmias_list", type: "BOOLEAN" },
                { key: "is_hypertension_list", type: "BOOLEAN" },
                { key: "is_incontinence_list", type: "BOOLEAN" },
                { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
                { key: "is_osteoporosis_list", type: "BOOLEAN" },
                { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
                { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
                { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
                { key: "is_stoma_list", type: "BOOLEAN" },
                { key: "is_cancer_list", type: "BOOLEAN" },
                { key: "is_other_description_de", type: "STRING" }
              ],
              defaultValue: false
            })
          ]
        }),

        // Section 5 // 2.2
        new FormSection({
          key: "section-5",
          sectionName: "bb_comnunication_data_p_5",
          fields: [
            new FormField({
              key: "is_communication_hearing",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_communication_vision",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_communication_speech",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_hearing_aid_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false
            }),
            new FormField({
              key: "is_glasses_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false
            }),
            new FormField({
              key: "is_hearing_aid_self_contained_use",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: form =>
                form.getFieldByKey("is_hearing_aid_list").value
            }),
            new FormField({
              key: "is_glasses_self_use",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: form => form.getFieldByKey("is_glasses_list").value
            })
          ]
        }),

        // Section 6 2.3
        new FormSection({
          key: "section-6",
          sectionName: "bb_orientation_data_p_6",
          fields: [
            new FormField({
              key: "is_orientation_temporal",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_orientation_locational",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_orientation_personal",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // Section 7 2.4
        new FormSection({
          key: "section-7",
          sectionName: "bb_characteristic_data_p_7",
          fields: [
            new FormField({
              key: "is_fearful",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_apathetic",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_demanding",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_cheerful",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_nervous",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_open",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_nice",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_moody",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_euphoric",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_decisive",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_sensitive",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_positive",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_unstable",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_withdrawn",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_critical",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_negative",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection7
            }),
            new FormField({
              key: "is_emotional_condition_others_de",
              entity: Entity.PATIENT,
              fieldImportantList: importantListForSection7
            })
          ]
        }),

        // Section 8 2.5
        new FormSection({
          key: "section-8", 
          sectionName: "bb_movement_data_p_8",
          fields: [
            new FormField({
              key: "is_patient_need_help_with_transfer",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_rollator_or_walker_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_lying_patient",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_regular_change_of_position",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_walking_stick",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_specialist_bed_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_chair_lift",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_transfer_lifter",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_wheelchair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_getting_up",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_patient_is_active_during_transfer",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_carrying_bed_wheelchair",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_move",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_antibedsore_mattress_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_walking_up_stairs",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_moving_with_rollator_alone",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_moving_with_rollator_in_flat_alone",
              entity: Entity.PATIENT,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              isRequired: form => {
                const isMovingWRA = form.getFieldByKey("is_moving_with_rollator_alone" );
                if (isMovingWRA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_moving_rollator_outside_flat",
              entity: Entity.PATIENT,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              isRequired: form => {
                const isMovingWRA = form.getFieldByKey("is_moving_with_rollator_alone" );
                if (isMovingWRA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_moving_on_wheelchair_alone",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_moving_wheelchair_in_apartment_alone",
              entity: Entity.PATIENT,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              isRequired: form => {
                const isMovingOWA = form.getFieldByKey("is_moving_on_wheelchair_alone" );
                if (isMovingOWA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_moving_wheelchair_outside_alone",
              entity: Entity.PATIENT,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              isRequired: form => {
                const isMovingOWA = form.getFieldByKey("is_moving_on_wheelchair_alone" );
                if (isMovingOWA.value === IsContactPersonDataSameAs.YES) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_patient_help_change_position",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            })
          ]
        }),

        // Section 9 2.6
        new FormSection({
          key: "section-9",
          sectionName: "bb_defecation_data_p_9",
          fields: [
            new FormField({
              key: "is_inserts_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_nappies_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_urination_control",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_catheterized_through_the_abdominal_wall",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_bedpan_a_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_catheter_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_control_of_defecation",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_use_toilet_or_toilet_chair_alone",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_if_limited_how_often",
              entity: Entity.PATIENT,
              isRequired: form => {
                const useToiletOrChair = form.getFieldByKey(
                  "is_use_toilet_or_toilet_chair_alone"
                );
                if (useToiletOrChair.value === SkillRange.LIMITED) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_aids_other_de",
              entity: Entity.PATIENT
            })
          ]
        }),

        // Section 10 2.7
        new FormSection({
          key: "section-10",
          sectionName: "bb_hugiene_data_p_10",
          fields: [
            new FormField({
              key: "is_shower_chair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_toilet_chair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_shaving",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_oral_care_and_dentures",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_hair_care",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_bed_care",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_intimate_hygiene",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_skin_care",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_lift_by_the_bath",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_bathing_shower",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_toilet_seat",
              entity: Entity.PATIENT,
              isFalseable: true
            })
          ]
        }),

        // Section 11 2.8
        new FormSection({
          key: "section-11",
          sectionName: "bb_foods_data_p_11",
          fields: [
            new FormField({
              key: "is_diet_diabetes",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_special",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_vegetarian",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_dehydration",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_balanced",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_diet_normal",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_sonda_peg",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_chewing_and_swallowing",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_drinking",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_eating",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_nutritional_status",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_taking_drink",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_favorite_food_drink_de",
              entity: Entity.PATIENT
            })
          ]
        }),

        // Section 12 2.9
        new FormSection({
          key: "section-12",
          sectionName: "bb_clothes_data_p_12",
          fields: [
            new FormField({
              key: "is_dressing_upper_part_of_body",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_dressing_underwear",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_dressing_pants_skirt",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_dressing_shoes",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_upper_part_of_body",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_underwear",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_pants_skirt",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_undress_shoes",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // Section 13 2.10
        new FormSection({
          key: "section-13",
          sectionName: "bb_sleep_data_p_13",
          fields: [
            new FormField({
              key: "is_falling_asleep",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_sleep_over",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_remarks_sleeps_de",
              entity: Entity.PATIENT,
              isRequired: true,
            }),
            new FormField({
              key: "is_activities_performed_by_carer_at_night_de",
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: "is_patient_medicines_hypnotics_de",
              entity: Entity.PATIENT,
              fieldImportantList: [{ key: "is_patient_medicines_hypnotics_de", type: "STRING" }]
            }),
            new FormField({
              key: "is_how_often_carer_must_get_up_at_night_de",
              entity: Entity.PATIENT,
              isRequired: true,
            })
          ]
        }),

        // Section 14 3
        new FormSection({
          key: "section-14",
          sectionName: "bb_pflegedienst_data_p_14",
          fields: [
            new FormField({
              key: "is_outpatient_services_list_de",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_outpatient_help",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false,
              fieldImportantList: [{ key: "is_outpatient_help", type: "BOOLEAN", basedOnField: 'is_contract_pflegedienst_services_signed', skipCondition: true }]

            }),
            new FormField({
              key: "is_contract_pflegedienst_services_signed",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false,
              fieldImportantList: [{ key: "is_contract_pflegedienst_services_signed", type: "BOOLEAN", basedOnField: 'is_outpatient_help', skipCondition: true }]
            }),
            new FormField({
              key: "is_medical_care_sgb_v",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_benefits_kind_for_sgb_care",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_anicillary_services_45b",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            }),
            new FormField({
              key: "is_emergency_call_system",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: [{ key: "is_emergency_call_system", type: "BOOLEAN" }]
            }),
            new FormField({
              key: "is_how_often_outpatient_help_de",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_day_care_home",
              entity: Entity.PATIENT,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_home_care_day_frequency_de",
              entity: Entity.PATIENT
            })
          ],
          validationExceptions: form => {
            const isDayCareHome = form.getFieldByKey("is_day_care_home");
            if (
              isDayCareHome.value === "False" ||
              isDayCareHome.value === false
            ) {
              return ["is_home_care_day_frequency_de"];
            }
          }
        }),

        // Section 15 4
        new FormSection({
          key: "section-15",
          sectionName: "bb_therapy_data_p_15",
          fields: [
            new FormField({
              key: "is_therapeutic_care_how_often_in_week_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isTherapeuticCAH = form.getFieldByKey("is_therapeutic_care_at_home" );
                const isTherapeuticCOH = form.getFieldByKey("is_therapeutic_care_outside_home" );
                if (isTherapeuticCAH.value === true && isTherapeuticCOH.value===true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_physiotherapy",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_speech_therapy",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_therapeutic_care_outside_home",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_therapeutic_care_at_home",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_occupational_theraphy",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_ergotherapy",
              entity: Entity.PATIENT,
              isFalseable: true
            })
          ]
        }),

        // Section 16 5
        new FormSection({
          key: "section-16",
          sectionName: "bb_expectations_data_p_16",
          fields: [
            new FormField({
              key: "is_most_important_taking_care_patient",
              entity: Entity.PATIENT,
              isRequired: true,
            }),
            new FormField({
              key: "is_candidate_gender",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // Section 17 5.1
        new FormSection({
          key: "section-17",
          sectionName: "bb_actions_data_p_17",
          fields: [
            new FormField({
              key: "is_cooking",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_purchases",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_laundry",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_poted_flower_care",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_ironing",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_accompanying_patient_while_out",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_cleaning_the_house",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_company_during_medical_visits",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_common_trips",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_organization_of_free_time",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_driving",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_driving_automat_gearbox",
              entity: Entity.PATIENT,
              isFalseable: true,
              fieldImportantList: importantListForSection17
            })
          ]
        }),

        // Section 18 5.2
        new FormSection({
          key: "section-18",
          sectionName: "bb_place_data_p_18",
          fields: [
            new FormField({
              key: "is_internet_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_how_often_carer_free_de",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_pc_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_who_take_care_when_carer_free_de",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_location",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: LocationRange.LIST
              }
            }),
            new FormField({
              key: "is_bed_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_taking_care_of_animals",
              entity: Entity.PATIENT,
              isFalseable: false,
              validationRules: {
                possibleValues: SpecialAgree.LIST
              }
            }),
            new FormField({
              key: "is_radio_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_shopping_on_foot",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_table_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_television_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_housing_conditions",
              entity: Entity.PATIENT,
              isRequired: true,
              validationRules: {
                possibleValues: HouseType.LIST
              }
            }),
            new FormField({
              key: "is_separate_bathroom_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_shopping_are_made_by_family",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_pets",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_patient_lives",
              entity: Entity.PATIENT,
              isRequired: true
            }),
            new FormField({
              key: "is_garden",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_bike",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: true,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              }
            }),
            new FormField({
              key: "is_taking_care_of_animals_which_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isActivities = form.getFieldByKey("is_pets");
                if (isActivities.value === true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_wardrobe_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_activities_additional_people",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_activities_regarding_additional_people_de",
              entity: Entity.PATIENT,
              isRequired: form => {
                const isActivities = form.getFieldByKey("is_activities_additional_people");
                if (isActivities.value === true) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              key: "is_marketingid",
              entity: Entity.CONTACT,
              possibleValues: MarketingID.getValues()
            })
          ]
        }),

        // Section 19 5.3
        new FormSection({
          key: "section-19",
          sectionName: "form-text-notiz",
          fields: [
            new FormField({
              key: "is_additional_fp_comments",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                maxLength: 5000
              }
            })
          ]
        }),

        // Section 20 6
        new FormSection({
          key: "section-20",
          sectionName: "consents",
          fields: [
            new FormField({
              defaultValue: null,
              key: "is_zg_de_2",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_3",
              entity: Entity.CONTACT,
              isFalseable: false,
              isRequired: form => {
                const isDegreeRelationShipWithPatientCarer = form.getFieldByKey(
                  "is_degree_relationship_with_patient_carer"
                );
                if (
                  isDegreeRelationShipWithPatientCarer.value ===
                  Relationship.TYPE_8
                ) {
                  return true;
                }
                return false;
              }
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_4",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_5",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_6",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_7",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_8",
              entity: Entity.CONTACT,
              isFalseable: false
            })
          ]
        }),

        // Hidden section (for fields that have constant values)
        new FormSection({
          key: "section-hidden",
          fields: [
            new FormField({
              key: "is_contact_source",
              entity: Entity.CONTACT,
              defaultValue: "717700001"
            })
          ]
        })
      ]
    });
  }

  get payload() {
    const patientFields = this.getFieldsByEntity(Entity.PATIENT);
    const contactFields = this.getFieldsByEntity(Entity.CONTACT);

    return {
      patient: flattenFields(patientFields),
      contact: flattenFields(contactFields)
    };
  }

  getRetiveDataALl(lkId, patietList) {
    let retrieveData = [];
    let patietListWithOutZero = patietList.filter(element => element !== 0);
    if (patietList.length !== "0") {
      const patientFields = this.getFieldsByEntity(Entity.PATIENT);
      const flattenFields = flattenFieldsArraywithSkiped(patientFields);
      patietListWithOutZero.forEach(element => {
        retrieveData.push({
          id: element,
          entity: Entity.PATIENT,
          fields: flattenFields
        });
      });
    }
    if (lkId !== "0") {
      const contactFields = this.getFieldsByEntity(Entity.CONTACT);
      if (patietList.length !== "0") {
        if (patietListWithOutZero.length > 0) {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraywithSkiped(contactFields)
          });
        } else {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraywithSkipedPatient(contactFields)
          });
        }
      } else {
        retrieveData.push({
          id: lkId,
          entity: Entity.CONTACT,
          fields: flattenFieldsArraywithSkipedPatient(contactFields)
        });
      }
    }
    return retrieveData;
  }

  getPayloadForSection(sectionKey, lkId, patientId) {
    const patientFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.PATIENT,
      true
    );
    const contactFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CONTACT,
      true
    );
    let patientDataToSave = flattenFields(patientFields);
    if (patientId === "0") {
      if (Object.keys(patientDataToSave).length > 0) {
        patientDataToSave["is_contact_id"] = lkId;
      }
    }
    let returnArray = [];
    if (Object.keys(patientDataToSave).length > 0) {
      if (patientId === "0" || patientId === 0) {
        patientDataToSave["is_contact_id"] = lkId;
      }
      returnArray.push({
        Id: patientId,
        Entity: Entity.PATIENT,
        Fields: patientDataToSave
      });
    }
    const contactFieldsToSave = flattenFields(contactFields);
    if (Object.keys(contactFieldsToSave).length > 0) {
      returnArray.push({
        Id: lkId,
        Entity: Entity.CONTACT,
        Fields: contactFieldsToSave
      });
    }
    return returnArray;
  }
}
