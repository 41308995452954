const Validate =(val, rules) =>{
    let isValid = true;
    for(let rule in rules){
        switch(rule){
            case    'isEmail':
                isValid = isValid && emailValidator(val);
                break
            case    'minLength':
                isValid = isValid && minLengthValidator(val, rules[rule]);
                break
            case    'maxLength':
                isValid = isValid && maxLenghtValidator(val, rules[rule]);
                break
            case    'containsSpecialCharacters':
                isValid = isValid && containsSpecialCharacters(val);
                break
            default:
                isValid = true;
        }
    }
    return isValid;
}
const emailValidator = val =>{
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(val);
}

const minLengthValidator = (val, minLength) =>{
    if(val==null || val.trim().length===0){
        return false;
    }else{
        return val.trim().length>=minLength;
    }
}

const maxLenghtValidator = (val, maxLength) =>{
    if(val===null || val===undefined){
        return false;
    }else{
        return val.trim().length<=maxLength;
    }
}

const containsSpecialCharacters = (val)=>{
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,32}$/.test(val);
}

export default Validate;