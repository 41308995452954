import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import {RouteButtonComponent} from "../../../route-button-compontent/RouteButtonComponent";
import { IconsMap } from "../../../../_helpers/index";

class ClientPatientDataAddressesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }
    
    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    render() {
        const { element, translateKey } = this.props;
        let patientAddressData;
        if(this.state.isOpen){
            patientAddressData = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        {element.street!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("street")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.street}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.buildingNumber!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("building_number")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.buildingNumber}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.apartmentNumber!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("apartment_number")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.apartmentNumber}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.postalCode!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("postal_code")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.postalCode}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.phone!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">
                                    {translateKey("phone")}
                                    <a className="mail-icon-in-details" href={"tel:"+element.phone} target="_top">
                                        <img src={IconsMap.svg.phone} alt="Email" className="user-name-icon"/>
                                    </a>
                                </label>
                                <div className="col-sm-8">
                                    {element.phone}
                                </div>
                            </div>
                        }
                        {element.cellPhone!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">
                                    {translateKey("mobile_phone")}
                                    <a className="mail-icon-in-details" href={"tel:"+element.cellPhone} target="_top">
                                        <img src={IconsMap.svg.phone} alt="Email" className="user-name-icon"/>
                                    </a>
                                </label>
                                <div className="col-sm-8">
                                    {element.cellPhone}
                                </div>
                            </div>
                        }
                        {element.phoneOtherRemarks!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("phone_other_remarks")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.phoneOtherRemarks}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-sm-12 col-lg-5">
                        {element.city!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("city")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.city}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.community!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("community")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.community}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.stateprovince!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("province")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.stateprovince}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.countryregion!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("geo_region")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.countryregion}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-lg-2 text-right lead-route-container">
                        <RouteButtonComponent element={element} text={translateKey("route")}/>
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{translateKey("address")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                    </div>
                </div>
                {patientAddressData}
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ClientPatientDataAddressesComponent));