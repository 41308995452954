import * as React from "react";
import { baseData } from '../../_constance/base_data';
export const CustomizedLabel = (props) => {
    const { x, y, width, value,height, external, index, history } = props;
    return (
      <text x={x + width+10} y={y + (height/1.5)} onClick={()=>handleClickSecond(external[index], history)} fill="black">
        {value}
      </text>
    );
  };
  
const handleClickSecond = (data, history) =>{
    if(data.amount>0){
        history.push(`${baseData.subFolders}leads/?type=status&param=${data.status}`);
    }
}