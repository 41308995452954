import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { InputTypeTextAreaComponent } from "../../form-bb-components/input-type-textarea-component/input-type-textarea-component";
import produce from "immer";


class Section19 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-19'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey } = this.props;
        const { section } = this.state;
        return (
          <div className="form-section-main">
            <div className="form-section-version">
              {translateKey("version")}: {baseData.bbFormVersion}
            </div>
            <div className="form-section-main-number">
              <span>{translateKey("mainFormName")}</span>
              <span className="form-section-main-number-number">
                S.{this.props.sectionNumber}
              </span>
            </div>
            <div className="form-section-subsection-number">
              {this.props.subsectionNumber}. {this.props.subsectionName}
            </div>
            <form className="section-form-content form_serializer">
              <fieldset disabled={disabled}>
                <div className="row col-sm-12 col-lg-12">
                  <InputTypeTextAreaComponent
                    showValidation={this.state.showValidation}
                    className="form-margin-bottom-fix top-too"
                    setTypeChangeToParent={this.setTypeChange}
                    field={section.getFieldByKey("is_additional_fp_comments")}
                    text={null}
                    transKey="form-text-notiz"
                    keyName="is_additional_fp_comments"
                  />
                </div>
              </fieldset>
            </form>
          </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section19));