export const MockPatientAddress = {
    apartmentNumber: "",
    buildingNumber: '',
    cellPhone: '',
    city: '',
    community: '',
    countryregion:'',
    county: '',
    latitude: 0,
    location: '',
    longitude: 0,
    others: '',
    phone: '',
    postalCode: '',
    stateprovince: '',
    street: '',
}