export default class SortingClientsElement {
    type;
    column; //field to search
    active;
    format;
    name; // column name to translation
    keyName; // firebase individual key
    enabled;
    blocked;
    languageRestrictions;

    constructor(type, column,active,format,name,keyName,enabled, blocked, languageRestrictions) {
        this.type = type;
        this.column = column;
        this.active = active;
        this.format = format;
        this.name = name;
        this.keyName = keyName;
        this.enabled = enabled;
        this.blocked = blocked;
        this.languageRestrictions = languageRestrictions;
    }
}