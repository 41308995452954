import noop from '../_helpers/noop';

export default class FormSection {
    form;
    key;
    fields = [];
    order = 0;
    sectionName;
    nextSectionSelector = noop;
    validationExceptions = noop;
    payloadExceptions = noop;

    constructor({ key, sectionName, fields = [], nextSectionSelector = noop, validationExceptions = noop, payloadExceptions = noop } = {}) {
        this.key = key;
        this.fields = fields;
        this.sectionName = sectionName;
        this.nextSectionSelector = nextSectionSelector;
        this.validationExceptions = validationExceptions;
        this.payloadExceptions = payloadExceptions;
    }

    assignToForm(form, order) {
        this.form = form;
        this.order = order;
        this.fields.forEach(field => field.assignToForm(this.form));
    }

    clearFields(){
        this.fields.forEach(field => field.value=null);
    }

    getFieldByKey(key) {
        return this.fields.find(field => field.key === key);
    }

    get nextSection() {
        const nextSection = this.nextSectionSelector(this.form);
        if (nextSection) {
            return nextSection;
        }
        return this.order + 1;
    }

    get isValid() {
        const exceptions = this.validationExceptions(this.form) || [];
        const fieldsWithoutExceptions = this.fields.filter(field => exceptions.findIndex(exception => field.key === exception) === -1);
        return fieldsWithoutExceptions.reduce((previous, field) => field.isValid && previous, true);
    }

    get allImportantsFilled() {
        const fieldsWithImportant = this.fields.filter(field => {return field.checkImportant });
        if(fieldsWithImportant.length>0){
            return false;
        }
        return true;
    }

    get payloadFields() {
        const exceptions = this.payloadExceptions(this.form) || [];
        return this.fields.filter(field => exceptions.findIndex(exception => field.key === exception) === -1);
    }
}
