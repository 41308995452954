import * as Env from './env';

export const ENVIRONMENT = Env.PROD; // It might be good idea, to use process.env here in future

const Config = {
    COMMON: {
        base_url: 'http://localhost:3000/',
        subFolders: '/',
        //base_api: 'http://crm-vp.promedica24.pl:5557/',
        //prod
        base_api: 'https://mobileapi.promedica24.pl',
        //test 
        //base_api: 'http://crm-vp1.promedica24.pl:2180/',
        public_key: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDV4NyfCnT4KLud4RySvdOJECNgF6NYgpe8qul7Q+F4RsTO4BR0Ghm2CKYKQV1Iqrb+4MBkoz9h2fw6Tty8bvOn8kW2LjBmjKdKt0dltsC1XMdlbrTVvSW+Kt88Fh8UGOoBgXyejxL0PF+az9pCW8PkkRirPQYdo/BHeEiJHQHX2QIDBLAB",
        releaseDate: "18.01.2024, 12:58 v.1.0.18.000",
        bbFormVersion: "2020/01",
        hash: "5f049b4ef0058a1d652f13d612375a5b",
        //new version new hash
        deepLinkStart: "https://pmcrm.promedica24.pl/main.aspx",
        fireBaseURL: "https://us-central1-promedica24-96610.cloudfunctions.net/",
        fireBaseAuthToken: "QFjf9Sh5RcUDJXGufkIIn1C1MHH6eMqnqgdNDSlh"
    },
    TEST: {
        currencyID: '68D4B06C-A56E-E611-80E0-00155D00E22E',
        multiToLeadSelection: "F2557264-4978-E811-80D7-00155D00F809",
        enumClient:"formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumMultiplier: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumContract: "formid=0b87917a-9cc7-4022-bda7-5af1c95b283b",
        enumInvoice: "formid=c40cd045-c809-4f18-a6dc-bdc0f8ef3559",
        enumTaskTypeEvent: "formid=549a8aa3-c349-4ab9-8bac-4873fa9a7a5c",
        enumTaskTypeTask: "formid=16654191-19ad-48ca-9e46-c4b4fbdad76f",
        enumTaskTypeCall: "formid=a91390a5-99bb-4d10-9eee-3a5c87f841f1",
        enumPatient: "formid=c1784d20-94ca-4575-8694-557ab28855d7",
        enumCarer: "formid=ea978e6a-2681-4e0a-a45c-ee84ac6d768f"
    },
    PROD: {
        currencyID: '7F52070D-FE9A-E811-80CE-00155D13F317',
        multiToLeadSelection: "E33DD1F7-B99C-E911-80D1-00155D2D0E3C",
        enumClient:"formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumMultiplier: "formid=8e6c630a-0698-4eb8-a0bc-2a2d8117d98b",
        enumContract: "formid=0b87917a-9cc7-4022-bda7-5af1c95b283b",
        enumInvoice: "formid=c40cd045-c809-4f18-a6dc-bdc0f8ef3559",
        enumTaskTypeEvent: "formid=549a8aa3-c349-4ab9-8bac-4873fa9a7a5c",
        enumTaskTypeTask: "formid=16654191-19ad-48ca-9e46-c4b4fbdad76f",
        enumTaskTypeCall: "formid=a91390a5-99bb-4d10-9eee-3a5c87f841f1",
        enumPatient: "formid=c1784d20-94ca-4575-8694-557ab28855d7",
        enumCarer: "formid=ea978e6a-2681-4e0a-a45c-ee84ac6d768f"
    },
};
// Create config object, that includes env-specific options
export const baseData = Object.assign({}, Config.COMMON, Config[ENVIRONMENT]);

export const refreshTokenTime = {
    expirationTime: 604800000,
    actionExpirationTime:  900000,
    refreshBasicTime: 300000,
};
