import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";
import * as DashBoardClientPeriod from "../../../../_constance/values/dashBoardClientPeriod";

class ClientsRejectReasons extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      dashboardData: this.props.dashboardData,
      loadingDashBoard: true,
      periodType: DashBoardClientPeriod.SIX_MONTHS,
      terminationReasons: [],
      terminationReasonsList: [],
      dates: [],
      total: 0,
      churnRate: 0
      
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  getMePercentageValue (item, total){
    return ((100 * item) / total).toFixed(2) + '%';
  }

  prepareMeData = (type) =>{
    const { translateKey, dashboardData} = this.props;
    let churnRate = 0;
    let dates = [];
    let terminationReasonsTemp = [];
    if(dashboardData!== null && dashboardData !== undefined ){
      if(dashboardData.TerminationReasonsStatistics !== null && dashboardData.TerminationReasonsStatistics !== undefined){
        let periodType = type?type:this.state.periodType;
        switch(periodType){
          case DashBoardClientPeriod.ONE_MONTH:
            const item = dashboardData.TerminationReasonsStatistics.Months[dashboardData.TerminationReasonsStatistics.Months.length-1];
            churnRate = this.getMePercentageValue(item.TotalTerminatedContracts, item.TotalActiveContracts);
            dates = [this.prepareMeMonthData(item.Month) + '.' + item.Year];
            terminationReasonsTemp =  item.TerminationReasons.map(element =>{
              if(element.Label===null){
                return {
                  date: this.prepareMeMonthData(item.Month) + '.' + item.Year,
                  reasonName: translateKey("other"),
                  reasonKey: "0",
                  amount: element.Count,
                }
              }else{
                return {
                  date: this.prepareMeMonthData(item.Month) + '.' + item.Year,
                  reasonName: element.Label,
                  reasonKey: element.Value,
                  amount: element.Count,
                }
              }
            })
            break;
          case DashBoardClientPeriod.THREE_MONTHS:
            let totalActive = 0;
            let totalTerminated = 0;
            const months = dashboardData.TerminationReasonsStatistics.Months;
            let lastThree = months.slice(Math.max(months.length - 3, 1));
            lastThree.forEach(element => {
              totalActive += element.TotalActiveContracts;
              totalTerminated += element.TotalTerminatedContracts
              dates.push(this.prepareMeMonthData(element.Month) + '.' + element.Year)
              element.TerminationReasons.forEach(item => {
                if(item.Label===null){
                  terminationReasonsTemp.push({
                    date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
                    reasonName: translateKey("other"),
                    reasonKey: "0",
                    amount: item.Count,
                  })
                }else{
                  terminationReasonsTemp.push({
                    date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
                    reasonName: item.Label,
                    reasonKey: item.Value,
                    amount: item.Count,
                  })                  
                }
              });
            });
            churnRate = this.getMePercentageValue(totalTerminated, totalActive);
            break;
          case DashBoardClientPeriod.SIX_MONTHS:
              let totalActiveSix = 0;
              let totalTerminatedSix = 0;
              const monthsSix = dashboardData.TerminationReasonsStatistics.Months;
              let lastSix = monthsSix.slice(Math.max(monthsSix.length - 6, 1));
              lastSix.forEach(element => {
                totalActiveSix += element.TotalActiveContracts;
                totalTerminatedSix += element.TotalTerminatedContracts
                dates.push(this.prepareMeMonthData(element.Month) + '.' + element.Year)
                element.TerminationReasons.forEach(item => {
                  if(item.Label===null){
                    terminationReasonsTemp.push({
                      date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
                      reasonName: translateKey("other"),
                      reasonKey: "0",
                      amount: item.Count,
                    })
                  }else{
                    terminationReasonsTemp.push({
                      date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
                      reasonName: item.Label,
                      reasonKey: item.Value,
                      amount: item.Count,
                    })
                  }
                });
              });
              churnRate = this.getMePercentageValue(totalTerminatedSix, totalActiveSix);
            break;
          default: 
        }
      }
    }
    let total = terminationReasonsTemp.reduce((acc, item) => acc = acc+item.amount, 0);
    this.setState({
      loadingDashBoard: false,
      terminationReasons: this.prepareMeFinalTerminationLeasons(terminationReasonsTemp),
      terminationReasonsList: terminationReasonsTemp,
      dates: dates,
      total: total,
      churnRate: churnRate
    })
  }

  prepareMeMonthData = ( element ) =>{
    if(element<10){
      return "0"+element
    }else{
      return element
    }
  }

  prepareMeFinalTerminationLeasons (temp){
    if(temp.length>0){
      let finalTempReasons = [];
      temp.forEach(element => {
        if(!this.checkIfExistsInArray(element.reasonKey, finalTempReasons)){
          finalTempReasons.push(element);
        }
      });
      return finalTempReasons;
    }
    return [];
  }

  checkIfExistsInArray(name, items){
    if(items.length>0){
      let exists = items.filter(element=>{
        return element.reasonKey === name
      })
      if(exists.length>0){
        return true
      }else{
        return false;
      }
    }
    return false
  }

  changePeriod(type){
    this.setState({periodType: type})
    this.prepareMeData(type);
  }

  getMeCountBasedOnDateAndReason(date, reasonKey){
    let gotItem = this.state.terminationReasonsList.filter(element=>element.reasonKey===reasonKey && element.date ===date);
    if(gotItem.length>0){
      return gotItem[0].amount
    }else{
      return "---";
    }
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashBoard, dates, terminationReasons } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashBoard){
      Content = (
        <React.Fragment>
          <table className="table table-dashboard">
            <thead>
              <tr>
                <th className="dashboard-table-head"></th>
                {dates.map((element)=>(
                  <th className="dashboard-table-head text-right" key={element}>{element}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {terminationReasons.map((element)=>(
                <tr className="table-row-wrapper" key={Math.random().toString(36).substr(2, 9)}>
                  <td className="table-cell-wrapper-dashboard">{element.reasonName}</td>
                  {dates.map((item)=>(
                    <td className="table-cell-wrapper-dashboard bold text-right" key={Math.random().toString(36).substr(2, 9)}>{this.getMeCountBasedOnDateAndReason(item, element.reasonKey)}</td>
                  ))}
                </tr>
              ))}
              <tr className="table-row-wrapper dashboard-green-background">
                <td className="table-cell-wrapper-dashboard">{translateKey("dashboard_total")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right" colSpan={dates.length}>{this.state.total}</td>
              </tr>
              <tr className="table-row-wrapper dashboard-green-background">
                <td className="table-cell-wrapper-dashboard">{translateKey("dashboard_churn_rate")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right" colSpan={dates.length}>{this.state.churnRate}</td>
              </tr>
            </tbody>
          </table>
          <div className="flexCenterItems">
            <button className={"btn period-button "+ (this.state.periodType===DashBoardClientPeriod.ONE_MONTH?"btn-primary":'btn-secondary')} onClick={()=>this.changePeriod(DashBoardClientPeriod.ONE_MONTH)}>{translateKey("month_now")}</button>
            <button className={"btn period-button "+ (this.state.periodType===DashBoardClientPeriod.THREE_MONTHS?"btn-primary":'btn-secondary')} onClick={()=>this.changePeriod(DashBoardClientPeriod.THREE_MONTHS)}>{translateKey("dashboard_period_last_3")}</button>
            <button className={"btn period-button "+ (this.state.periodType===DashBoardClientPeriod.SIX_MONTHS?"btn-primary":'btn-secondary')}  onClick={()=>this.changePeriod(DashBoardClientPeriod.SIX_MONTHS)}>{translateKey("dashboard_period_last_6")}</button>
          </div>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <div className="table-container-wrapper no_padding">
          <div className="table-name-class">{translateKey("dashboard_reject_reasons")}</div>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(null, mapDispatchToProps)(ClientsRejectReasons));