import axios from 'axios';
import ReqHelper from '../../_helpers/request_helper';
import {baseData} from '../../_constance/base_data';

const request = axios.create({
    baseURL: baseData.fireBaseURL
});

request.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(ReqHelper.responseErrorHelper(error));
});

request.interceptors.request.use(config => {
    config.headers = { 
      'X-Auth-Token': baseData.fireBaseAuthToken,
      'Access-Control-Allow-Origin': '*'
    };
    return config;
  }, err => {
    return Promise.reject(ReqHelper.responseErrorHelper(err));
  });

export default request;