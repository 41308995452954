export const Diseases = [
    {Name: "is_allergies_list", Checked: false},
    {Name: "is_difficulty_in_walking_determined_by_age", Checked: false},
    {Name: "is_alzheimers_list_disease_the_list", Checked: false},
    {Name: "is_asthma_list", Checked: false},
    {Name: "is_chronic_diarrhea_list", Checked: false},
    {Name: "is_initial_stages_of_dementia_list", Checked: false},
    {Name: "is_dementia_list", Checked: false},
    {Name: "is_bedsores_list", Checked: false},
    {Name: "is_deppression_list", Checked: false},
    {Name: "is_diabetes", Checked: false},
    {Name: "is_diabetes_mellitus_list", Checked: false},
    {Name: "is_myocardial_infraction_list", Checked: false},
    {Name: "is_heart_failure_list", Checked: false},
    {Name: "is_arrhythmias_list", Checked: false},
    {Name: "is_hypertension", Checked: false},
    {Name: "is_incontinence", Checked: false},
    {Name: "is_multiple_sclerosis_the_list", Checked: false},
    {Name: "is_osteoporosis_list", Checked: false},
    {Name: "is_parkinsons_disease_list", Checked: false},
    {Name: "is_rheumatism_disease_list", Checked: false},
    {Name: "is_right_sided_paresis_list", Checked: false},
    {Name: "is_left_sided_paresis_list", Checked: false},
    {Name: "is_stomia_list", Checked: false},
    {Name: "is_cancer_list", Checked: false}
];
