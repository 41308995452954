export const ClientStatus = [
    {
        Key: 'client-status-1',
        Value: "1"
    },
    {
        Key: 'client-status-2',
        Value: "2"
    },
    {
        Key: 'client-status-3',
        Value: "3"
    },
    {
        Key: 'client-status-4',
        Value: "4"
    },
    {
        Key: 'client-status-5',
        Value: "5"
    },
    {
        Key: 'client-status-6',
        Value: "6"
    },
]