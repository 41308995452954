export const PatientContactPersonAddress = {
    apartmentNumber: '',
    buildingNumber: '',
    cellPhone: '',
    city: '',
    community: '',
    countryregion: '',
    county: '',
    firstName: '',
    lastName: '',
    latitude: 0,
    location: '',
    longitude: 0,
    others: '',
    phone: '',
    postalCode: '',
    relationshipId: '',
    stateprovince: '',
    street: ''
}