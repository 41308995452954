import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ComposedChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer, Tooltip, Legend, Line} from 'recharts';
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";
import { Colors } from "../../../../_constance/enums/colors";

class LeadsPerConversion extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      dashboardData: this.props.dashboardData,
      loadingDashBoard: true,
      leadsPerConversion: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeConversionData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeConversionData = () =>{
    let data = [];
    if(this.props.dashboardData!== null && this.props.dashboardData !== undefined ){
      if(this.props.dashboardData.LeadConversionStatistics !== null && this.props.dashboardData.LeadConversionStatistics !== undefined){
        data =  this.props.dashboardData.LeadConversionStatistics.Months.map(element =>{
          return {
            date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
            leads: element.NumberOfLeads,
            signed: element.NumberOfLeadsWhoSignedContract,
            conversion: parseInt(((element.NumberOfLeadsWhoSignedContract/element.NumberOfLeads) * 100).toFixed(0))
          }
        })
      }
    }
    //`${((element.NumberOfLeadsWhoSignedContract/element.NumberOfLeads) * 100).toFixed(0)}%`
    this.setState({
      loadingDashBoard: false,
      leadsPerConversion: data
    })
  }

  prepareMeMonthData = ( element ) =>{
    if(element<10){
      return "0"+element
    }else{
      return element
    }
  }


  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashBoard} = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashBoard){
      Content = (
        <ResponsiveContainer width="80%" height={400}>
          <ComposedChart data={this.state.leadsPerConversion} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <XAxis name={translateKey("date")} dataKey="date" type="category" />
            <YAxis />
            <YAxis yAxisId="right" orientation="right" />
            <Tooltip />
            <Bar name={translateKey("number_of_leads")} dataKey="leads" fill={Colors.DARKBLUE}>
              <LabelList dataKey="leads" position="top" offset={5}/>
            </Bar>
            <Bar name={translateKey("number_of_leads_who_signed_contract")} dataKey="signed" fill={Colors.DARKGREEN}>
              <LabelList dataKey="signed" position="top" offset={5}/>
            </Bar>
            <Line
              yAxisId="right"
              name={translateKey("dashboard_conversion")}
              type="monotone"
              dataKey="conversion"
              stroke={Colors.LIGHTGREEN}
            />
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              width={200}
              height={400}
              wrapperStyle={{
                overflowY: "auto",
                overflowX: "hidden",
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                fontSize: "14px"
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
    return (
      <React.Fragment>
        <div className="charts-pie-container-wrapper">
          <h5 className="text-center">{translateKey("dashboard_lead_conversion")}</h5>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(null, mapDispatchToProps)(LeadsPerConversion));