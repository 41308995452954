import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as Entity from '../BBForm/values/entity';
import * as isContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import { baseData } from "../../_constance";
const skipList = [];
const nullPointerExceptionFields = ["is_invoice_delivery_method_letter", "is_invoice_delivery_method_mail"];
const flattenFields = fieldsList => fieldsList.reduce((previous, field) => Object.assign(previous, {
    [field.key]: field.value,
}), {});

const flattenFieldswithSkiped = fieldsList => fieldsList.map(element=>{
    if(nullPointerExceptionFields.indexOf(element.key) !== -1){
        if(element.value===null){
            element.value = "717700001";
        }
    }
    return element;
})
.filter(element => {
    if (element.value!==null && element.value.toString().trim()!=='') {return true;}return false;
})
.reduce((previous, field) => Object.assign(previous, {
    [field.key]: field.value,
}), {})
//const flattenFieldsArray = fieldsList => fieldsList.map(field => field.key);
const flattenFieldsArraywithSkiped = fieldsList => fieldsList.filter(element => {
    if (skipList.indexOf(element.key) !== -1) {return false;}return true;
}).map(field => field.key);
export default class ContractForm extends Form {
    constructor() {
        super({
            sections: [
                new FormSection({
                    key: 'contract-section',
                    fields: [
                        new FormField({
                            key: 'is_start_date',
                            isRequired: true,
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_end_date',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isPermanentContract = form.getFieldByKey('is_permanent_contract');
                                return isPermanentContract.value !== '717700000';
                            },
                            // valueMapper: form => {
                            //     const isPermanentContract = form.getFieldByKey('is_permanent_contract');
                            //     if (isPermanentContract.value) {
                            //         return '2100-01-31';
                            //     }
                            // }
                        }),
                        new FormField({
                            key: 'is_permanent_contract',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                            validationRules: {
                                possibleValues: ['717700000', '717700001'],
                            },
                        }),
                        new FormField({
                            key: 'is_product_id',
                            isRequired: true,
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_rate',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                        }),
                        new FormField({
                            key: 'is_contract_date',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                        }),
                        new FormField({
                            key: 'is_franchise_regionid',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_other_region_franchiseid',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_frequency_of_invoicing',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                        }),
                        new FormField({
                            key: 'is_invoice_delivery_method_letter',
                            entity: Entity.CONTRACT,
                            validationRules: form => {
                                return {
                                    atLeastOneYes: [
                                        {
                                            value: form.getFieldByKey('is_invoice_delivery_method_mail').value,
                                            neededValue: isContactPersonDataSameAs.YES
                                        }
                                    ]
                                }
                            },
                            isRequired: form => {
                                const isInvoiceDeliveryMethodMail = form.getFieldByKey('is_invoice_delivery_method_mail');
                                const isInvoiceDeliveryMethodLetter = form.getFieldByKey('is_invoice_delivery_method_letter');
                                if (isInvoiceDeliveryMethodMail.value === isContactPersonDataSameAs.YES) {
                                    return false;
                                }else{
                                    if (isInvoiceDeliveryMethodLetter.value === isContactPersonDataSameAs.YES) {
                                        return false;
                                    }else{
                                        return true;
                                    }
                                }
                            },
                        }),
                        new FormField({
                            key: 'is_invoice_delivery_method_mail',
                            entity: Entity.CONTRACT,
                            validationRules: form => {
                                return {
                                    atLeastOneYes: [
                                        {
                                            value: form.getFieldByKey('is_invoice_delivery_method_letter').value,
                                            neededValue: isContactPersonDataSameAs.YES
                                        }
                                    ]
                                }
                            },
                            isRequired: form => {
                                const isInvoiceDeliveryMethodMail = form.getFieldByKey('is_invoice_delivery_method_mail');
                                const isInvoiceDeliveryMethodLetter = form.getFieldByKey('is_invoice_delivery_method_letter');
                                if (isInvoiceDeliveryMethodMail.value === isContactPersonDataSameAs.YES) {
                                    return false;
                                }else{
                                    if (isInvoiceDeliveryMethodLetter.value === isContactPersonDataSameAs.YES) {
                                        return false;
                                    }else{
                                        return true;
                                    }
                                }
                            },
                        }),
                        new FormField({
                            key: 'is_holiday_break',
                            entity: Entity.CONTRACT,
                            defaultValue: isContactPersonDataSameAs.YES
                        }),
                        new FormField({
                            key: 'is_contract_status',
                            entity: Entity.CONTRACT,
                            defaultValue: '717700006'
                        }),
                        new FormField({
                            key: 'is_technical_source_contract',
                            entity: Entity.CONTRACT,
                            defaultValue: '717700002'
                        }),
                        new FormField({
                            key: 'is_identical_patient_data',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                            defaultValue: '717700000',
                        }),
                        new FormField({
                            key: 'is_first_name',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_last_name',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_street',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_house_number',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_appartment_number',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_zip_postal_code',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_city',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_customer_status_de',
                            entity: Entity.CONTACT,
                            defaultValue: '717700011',
                            valueMapper: () => "717700011",
                        }),
                        new FormField({
                            key: 'is_inquiry_based_contract',
                            entity: Entity.CONTRACT,
                            defaultValue: '0',
                        }),
                        new FormField({
                            key: 'is_correspondence_salutation_invoice',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_identical_payer_data',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                            defaultValue: '717700000',
                        }),
                        new FormField({
                            key: 'is_payer_last_name',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_first_name',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_registered_street',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_registered_house_number',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_registered_appartment_number',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_registered_zip_code',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_registered_city',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_registered_country',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
                                return isIdenticalPayerData.value === '717700000';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_mobile_phone',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_payer_phone',
                            entity: Entity.CONTRACT
                        }),
                        new FormField({
                            key: 'is_payer_email',
                            entity: Entity.CONTRACT
                        }),
                        new FormField({
                            key: 'is_payer_email_2',
                            entity: Entity.CONTRACT
                        }),
                        new FormField({
                            key: 'is_identical_invoice_delivery_address',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_last_name',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_first_name',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_street',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_house_number',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondance_appartment_number',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_zip_code',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_city',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_payer_correspondence_country',
                            entity: Entity.CONTRACT,
                            defaultValue: "Deutschland",
                            isRequired: form => {
                                const isIdenticalPayerData = form.getFieldByKey('is_identical_invoice_delivery_address');
                                return isIdenticalPayerData.value === '717700002';
                            },
                        }),
                        new FormField({
                            key: 'is_contact_patientID',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_primary_patientid',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_rate_settlement',
                            entity: Entity.CONTRACT
                        }),
                        new FormField({
                            key: 'is_holiday_break_value',
                            entity: Entity.CONTRACT,
                            defaultValue: '100',
                        }),
                        new FormField({
                            key: 'is_currencyid',
                            entity: Entity.CONTRACT,
                            defaultValue: baseData.currencyID,
                            valueMapper: () => baseData.currencyID,
                        }),
                        new FormField({
                            key: 'is_current_version_contract',
                            entity: Entity.CONTRACT,
                            defaultValue: '1',
                            valueMapper: () => "1",
                        }),
                        new FormField({
                            key: 'is_destination_country',
                            entity: Entity.CONTRACT,
                            defaultValue: '717700000',
                            valueMapper: () => "717700000",
                        }),
                        new FormField({
                            key: 'is_date_receipt_contract',
                            entity: Entity.CONTRACT,
                            valueMapper: () => Math.floor(new Date().getTime() / 1000),
                        }),
                        new FormField({
                            key: 'is_contract_status',
                            entity: Entity.CONTRACT,
                            valueMapper: () => "717700006",
                        }),
                        new FormField({
                            key: 'is_driving_licence',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_second_ooo',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_transfer',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_carer_with_diploma',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_additional_foreign_language',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                    ],
                }),
                new FormSection({
                    key: 'contract-hidden',
                    fields: [
                        new FormField({
                            key: 'is_cooperation_condition_id',
                            entity: Entity.CONTRACT,
                        })
                    ],
                }),
            ]
        });
    }
    get payload() {
        const patientFields = this.getFieldsByEntity(Entity.PATIENT);
        const contractFields = this.getFieldsByEntity(Entity.CONTRACT);

        return {
            patient: flattenFields(patientFields),
            contract: flattenFields(contractFields),
        };
    }

    getRetiveDataALl(lkId, contractId){
        let retrieveData = []
        if (lkId !== '0') {
            const patientFields = this.getFieldsByEntity(Entity.CONTACT);
            retrieveData.push({
                id: lkId,
                entity: Entity.CONTACT,
                fields: flattenFieldsArraywithSkiped(patientFields),
            });
        }
        if (contractId !== '0') {
            const contactFields = this.getFieldsByEntity(Entity.CONTRACT);
            retrieveData.push({
                id: contractId,
                entity: Entity.CONTRACT,
                fields: flattenFieldsArraywithSkiped(contactFields),
            });
        }
        return retrieveData;
    }

    getPayload(lkId, contractId) {
        const contractFields = this.getFieldsByEntity(Entity.CONTRACT);
        const contactFields = this.getFieldsByEntity(Entity.CONTACT);

        return [
                {
                    "Id": lkId,
                    "Entity": Entity.CONTACT,
                    "Fields": flattenFieldswithSkiped(contactFields)
                },
                {
                    "Id": contractId,
                    "Entity": Entity.CONTRACT,
                    "Fields": flattenFieldswithSkiped(contractFields)
                }
        ]

    }
}