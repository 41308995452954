import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  setMultipliersData,
  pendingMultipliersData,
  removeMultipliersData,
  authSetActionTimestamp
} from "../../_redux/actions/index";
import {
  TableColumHead,
  TableProperties,
  LoaderModal,
  LoaderTable,
  NavBar,
  SideBar,
  EmptyTableIcon,
  AlertMessage,
  MailToEmailComponent,
  MailToPhoneComponent,
  TablePagination
} from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import { TableEnums } from "../../_constance/enums";
import { Alert, MultiplierTable, Paging } from "../../_constance/classes/index";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { shouldRefresh, IconsMap, ReqHelper} from "../../_helpers/index";
import { SortingMultipliers } from "../../_constance/classes/sortingClasses/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";

//COLUMNS
import {
  setColumnsData,
  removeColumnsData,
  addColumnData,
  updateColumTypeData
} from "../../_redux/actions/active-columns";
import * as ColumnsNames from "../../_constance/values/columnsNames";
import {saveColumnsData, getColumnsData} from "../../_services/firebase_service";
import * as Entity from '../../_forms/BBForm/values/entity';
import { getDynamicFields } from '../../_services/user_service';

class MultipliersMainPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      searchQuery: null,
      showModalLoader: false,
      errorComponent: Alert,
      pagingItem: Paging,
      sorting: new SortingMultipliers(),
      filterData: [],
      rerender: Date.now(),
      breadCrumbs:[
        {
          link: "multipliers",
          key: "multipliers",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    const query = new URLSearchParams(this.props.location.search);
    const urlType = query.get('type')
    if(urlType==="status" || urlType==="quality"){
      let param = query.get('param');
      if(param!==undefined && param!==null){
        this.setState({searchQuery: param})
      }
    }
    this.getMeColumnConfiguration();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };



  getMeColumnConfiguration(){
    const lastUpdate = this.props.activeColumnsTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getColumnsDataFromApi();
    } else {
      if (this._isMounted) {
        this.prepareMeSortingBasedOnColumns()
        this.getDataBasic();
      }
    }
  }
  getColumnsDataFromApi(){
   getColumnsData(this.props.userIdColumn)
    .then(res => {
        this.props.setColumns(res);
        this.prepareMeSortingBasedOnColumns();
        this.getDataBasic();
    })
    .catch((error) => {
      this.prepareMeSortingBasedOnColumns();
      this.getDataBasic();
    });
  }
  prepareMeSortingBasedOnColumns(){
    if(this.props.activeColumns){
      this.props.activeColumns.forEach(element => {
        if(element.name === ColumnsNames.MULTIPLIERS){
          element.columns.forEach(item => {
            this.state.sorting.setColumnEnabled(item.enabled, item.name)
          });
        }
      });
    }
    this.setState({rerender: Date.now()})
  }
  prepareColumnsData = (data) =>{
    data.forEach(column => {
      this.state.sorting.setColumnEnabled(column.enabled, column.keyName)
    });
    let columnsData = this.props.activeColumns.find(element => element.name === ColumnsNames.MULTIPLIERS); 
    if(columnsData){
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.MULTIPLIERS, data, false);
      this.props.updateColumnTypeData(ColumnsNames.MULTIPLIERS, dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.MULTIPLIERS, dataToSave.columns)
    }else{
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.MULTIPLIERS, data, false);
      this.props.addNewColumnTable(dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.MULTIPLIERS, dataToSave.columns)
    }
  }

  getMultipliersList = () => {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    this.props.pendingRequest();
    axiosAuth.get("multipliers/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map(async (element)=>{
          let dataToSend = [
            {
                "Id": element.id,
                "Entity": Entity.CONTACT,
                "Fields": [
                  "jobtitle"
                ]
            }
          ];
          await getDynamicFields(dataToSend).then(res => {
            element.jobtitle = res[0].fields["jobtitle"];
          })
          .catch((error) => {
            console.log(error);
          });
          return new MultiplierTable(element);
        });
        return mapedRes
      }).then(res => {
        Promise.all(res).then(values => {
          this.props.setData(values);
            if (this._isMounted) {
              this.setState({
                data: values,
                loading: false,
              }, () => this.filterOrDataChange());
            }
        });
      })
      .catch((error) => {
        this.props.clearData();
        this.alertD.show = true;
        this.alertD.type = "danger";
        this.alertD.message = this.props.translateKey("basic_error_message");
        if (this._isMounted) {
          this.setState({
            data: [],
            dataToShow: [],
            loading: false,
            errorComponent: this.alertD
          },() => this.filterOrDataChange());
        }
        console.log(error);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  getDataBasic() {
    const lastUpdate = this.props.multipliersTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getMultipliersList();
    } else {
      if (this._isMounted) {
        this.setState({data: this.props.multipliersData}, () => this.filterOrDataChange());
      }
    }
  }

  filterOrDataChange(val){
    let finalDataToShow = this.propsFilterSearch(val);
    ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
    this.propsPagingData(finalDataToShow);
    setTimeout(() => {
      let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
      if (this._isMounted) {
        this.setState({data: finalData})
      }
    }, 50);
  }

  toggleModalLoader = () => {
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          ...prevState,
          showModalLoader: !prevState.showModalLoader
        }
      });
    }
  }

  propsSearchTable = async (val) => {
    const { history } = this.props;
    if(val.lastIndexOf(baseData.deepLinkStart, 0) === 0){
      this.toggleModalLoader();
      let url = new URL(val);
      let id = url.searchParams.get("id");
      let form = url.searchParams.get("extraqs");
      if(form!==null && id!==null){
        const { appLang } = this.props
        id = id.replace('{', '').replace('}', '').toLowerCase();
        switch(form){
            case baseData.enumClient:           axiosAuth.get("leads/list/0/" + appLang)
                                                .then(res => {
                                                    let statusObj = res.data.find((element) => {return element.leadId.toString().toLowerCase()===id.toString()})
                                                    if(statusObj){
                                                      this.toggleModalLoader();
                                                      history.push(`${baseData.subFolders}leadDetails/${id}`);
                                                    }else{
                                                        axiosAuth
                                                        .get("clients/list/0")
                                                        .then(res => {
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              this.toggleModalLoader();
                                                              history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                            }else{
                                                                axiosAuth.get("multipliers/list/0")
                                                                .then(res => {
                                                                  this.toggleModalLoader();
                                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                    if(statusObj){
                                                                      history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                    }
                                                                }).catch(()=>this.toggleModalLoader())
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    }
                                                }).catch(()=>{
                                                    axiosAuth
                                                    .get("clients/list/0")
                                                    .then(res => {
                                                        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                        if(statusObj){
                                                          this.toggleModalLoader();
                                                          history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                        }else{
                                                            axiosAuth.get("multipliers/list/0")
                                                            .then(res => {
                                                                this.toggleModalLoader();
                                                                let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                if(statusObj){
                                                                  history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                }
                                                            }).catch(()=>this.toggleModalLoader())
                                                        }
                                                    }).catch(()=>{
                                                        axiosAuth.get("multipliers/list/0")
                                                        .then(res => {
                                                            this.toggleModalLoader();
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    })
                                                })
                                                break;
            case baseData.enumPatient:          if(this.props.patientLeadData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientLeadData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/LEAD/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }
                                                if(this.props.patientClientData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientClientData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/CLIENT/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }                   
                                                break;
            case baseData.enumCarer:            axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundCarer = false;
                                                  for(let i=0; i<res.data.length && !foundCarer; i++){
                                                    foundCarer = this.getMeCarer(res.data[i].id, appLang, id, i===res.data.length-1);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumContract:         let gotHim = false
                                                await axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundContract = false;
                                                  for(let i=0; i<res.data.length && !gotHim; i++){
                                                    foundContract = this.getMeContract(res.data[i].id, appLang, id, "CLIENT");
                                                  }
                                                  if(!foundContract){
                                                    this.getMeLeads(appLang, id);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumInvoice:          this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}invoices/${id}`);
                                                break;
            case baseData.enumTaskTypeEvent:    this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeTask:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeCall:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            default: this.toggleModalLoader()
        }    
      }
    }else{
      if (this._isMounted) {
        this.setState({ searchQuery: val.trim() });
      }
      this.filterOrDataChange(val);
    }
  }

  getMeCarer = (id, lang, carerId, redirect=false) =>{
    axiosAuth.get(`/carers/list/${id}/0/${lang}`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.wzid.toString().toLowerCase()===carerId.toString())
        if(statusObj){
          this.toggleModalLoader()
          this.props.history.push(`${baseData.subFolders}clientDetails/${statusObj.clientId}/${carerId}`);
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeContract = (id, appLang, contractId, type, redirect=false) => {
    const { history } = this.props;
    axiosAuth.get(`/contracts/list/${id}/0`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===contractId.toString())
        if(statusObj){
          this.toggleModalLoader()
          if(appLang === LanguageSelect.DE){
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}bb-form-lead/${id}/${contractId}/ENQUIRY`);                            
            }else{
              history.push(`${baseData.subFolders}bb-form-client/${id}/${contractId}/ENQUIRY`);   
            }
          }else{
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}leadDetails/${id}`);                            
            }else{
              history.push(`${baseData.subFolders}contract/${id}/${contractId}`);
            }
          }
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeLeads = (appLang, contractId) =>{
    axiosAuth.get("leads/list/0/" + appLang)
      .then((res) => {
        for(let i=0; i<res.data.length; i++){
          this.getMeContract(res.data[i].leadId, appLang, contractId, "LEAD", i===res.data.length-1);
        }
      }).catch(()=>this.toggleModalLoader())
  }

  propsSortType(type){
    this.state.sorting.changeColumnByColumn(type);
    this.filterOrDataChange();
  }

  propsFilterSearch = (val) => {
    const { appLang } = this.props;
    const { sortingColumns } = this.state.sorting
    let finalDataAfter = [];
    if (this.props.multipliersData !== null && this.props.multipliersData.length > 0) {
      if(val && val!==null){
        finalDataAfter = this.props.multipliersData.filter((value) => {
          return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
        });
      }else{
        let search = this.state.searchQuery;
        if(search && search!==null && val!==''){
          // samo search bez filtrów
          finalDataAfter = this.props.multipliersData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
          });
        }else{
          finalDataAfter = this.props.multipliersData;
        }
      }
    }
    return finalDataAfter;
  }


  propsPagingData(data){
    if(data){
      if(data.length<=25){
        if (this._isMounted) {
          this.setState(prevState => {
            return {
              ...prevState,
              pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
              }
            }
          });
        }
      }else{
        if (this._isMounted) {
          this.setState(prevState => {
            return {
              ...prevState,
              pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
              }
            }
          });
        }
      }
    }
  }

  navigateToDetails(multiplierData){
    this.props.history.push(`${baseData.subFolders}multiplierDetails/${multiplierData.id}`);
  }

  render() {
    const { translateKey, appLang, history } = this.props;
    const { sorting } = this.state;
    let emptyHandler;
    let PreparedTableContent;
    if (this.state.loading) {
      emptyHandler = <LoaderTable />
    } else {
      if (this.props.multipliersData === null) {
        emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")} />
      } else {
        if (this.props.multipliersData.length === 0) {
          emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")} />
        }else{
          if(this.state.data.lengh===0){
            emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
          }else{
            PreparedTableContent = this.state.data.map(element => (
              <tr className="table-row-wrapper cursor-pointer" key={element.id} onClick={()=>{this.navigateToDetails(element)}}>
                {
                  sorting.isColumnVisibleByKey('multipliers_multiplier', appLang) &&
                    <td className="table-cell-wrapper">{element.multiplier}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_company', appLang) &&
                    <td className="table-cell-wrapper">{element.company}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_location', appLang) &&
                    <td className="table-cell-wrapper">{element.address.city}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_telephone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.telephone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_email', appLang) &&
                    <td className="table-cell-wrapper"><MailToEmailComponent emailAddress={element.email}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_profession', appLang) &&
                    <td className="table-cell-wrapper">{element.profession}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_phone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.phone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_cellPhone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.cellPhone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_businessPhone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.businessPhone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_phoneOtherRemarks', appLang) &&
                    <td className="table-cell-wrapper">{element.phoneOtherRemarks}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_city', appLang) &&
                    <td className="table-cell-wrapper">{element.address.city}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_postal_code', appLang) &&
                    <td className="table-cell-wrapper">{element.address.postalCode}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_street', appLang) &&
                    <td className="table-cell-wrapper">{element.address.street}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_care_ambassador', appLang) &&
                    <td className="table-cell-wrapper">{element.careAmbasador?'Yes':'No'}</td>
                }
                {
                  sorting.isColumnVisibleByKey('multipliers_jobtitle', appLang) &&
                    <td className="table-cell-wrapper">{element.jobtitle}</td>
                }
              </tr>
            ))
          }
        }
      }
    }
    let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
    return (
      <div className="mainContentWrapper" onClick={()=>this.props.tookAction()}>
        <NavBar breadCrumbs={this.state.breadCrumbs}/>
        <TableProperties addClick={()=>history.push(`${baseData.subFolders}newmultiplier`)} prepareColumnsData={this.prepareColumnsData} onShowMeData={()=>this.filterOrDataChange()} onSearch={(val) => this.propsSearchTable(val)} options={[TableEnums.SEARCH, TableEnums.ADD, TableEnums.ACTIVECOLUMNS]} filterData={this.state.filterData} sortData={sorting.sortingColumns}/>
        <div className="mainContent">
          <SideBar />
          <LoaderModal show={this.state.showModalLoader}/>
          <div className="listDataWrapper">
            <div className="listData flipped">
              <div className="flipped">
                <table className="table">
                  <thead>
                    <tr>
                      {
                          sorting.sortingColumns.map(column => {
                            if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                              return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                            }else{
                              return null
                            }
                          }).filter(element => element !==null )
                      }
                    </tr>
                  </thead>
                  <tbody>{PreparedTableContent}</tbody>
                </table>
                {emptyHandler}
                {alertComponent}
                <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
              </div>
            </div>
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    userIdColumn: state.auth.userId,
    multipliersData: state.database.multipliers,
    multipliersTimeStamp: state.database.multipliersTimeStamp,
    patientLeadData: state.database.patientLead,
    patientClientData: state.database.patientClient,
    activeColumns: state.activeColumns.data,
    activeColumnsTimeStamp: state.activeColumns.columnsTimeStamp
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) =>dispatch(translateKey(firstLvl, secondLvl)),
  setData: (data) => dispatch(setMultipliersData(data)),
  pendingRequest: () => dispatch(pendingMultipliersData()),
  clearData: () => dispatch(removeMultipliersData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setColumns: (data) => dispatch(setColumnsData(data)),
  addNewColumnTable: (data) => dispatch(addColumnData(data)),
  updateColumnTypeData: (type,data) => dispatch(updateColumTypeData(type, data)),
  removeColumns: () => dispatch(removeColumnsData()),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MultipliersMainPage));