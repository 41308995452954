import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { ClientAddressesComponent, ClientCorespondenceAddressesComponent, ClientDetailsBasicComponent } from "./index";
import { ConsentListComponent } from "../../../_components/index";

class ClientClientData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            clientData: this.props.clientData,
        };
    }

    componentDidMount() {document.body.classList.remove('modal-open');}

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    render() {
        let Basics =(
            <ClientDetailsBasicComponent key="clientDetailsComponent03" element={this.state.clientData}/>
        )
        let Address =(
            <ClientAddressesComponent key="clientAddressesComponent03" element={this.state.clientData.address}/>
        )
        let CorespondenceAddress =(
            <ClientCorespondenceAddressesComponent key="clientCoespondenceComponent03" same={this.props.clientData.correspondenceSameAsMain} element={this.state.clientData.correspondenceAddress}/>
        )
        let Consents =(
            <ConsentListComponent isEditable={false}  key="consents-client-new-03" id={this.props.clientData.id}/>
        )
        return (
            <div className="client-data-wrapper-tab">
                {Basics}
                {Address}
                {CorespondenceAddress}
                {Consents}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ClientClientData));