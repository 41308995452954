import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  translateKey,
  getMarketingData,
  getRejectReasonData,
  setMultipliersData,
  pendingMultipliersData,
  removeMultipliersData,
  getClients
} from "../../_redux/actions/index";
import DatePickerComponent from "../../_components/date-picker-component/DatePickerComponent";
import SelectOptionsComponent from "../../_components/select-options-component/SelectOptionsComponent";
import GroupedSelect from '../../_components/select2/GroupedSelect';
import { Rating } from "../../_constance/enums/ratingLead"
import { LeadType } from "../../_constance/enums/leadType"
import { MultiplierTable } from "../../_constance/classes/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import { LeadSourceExceptionsList } from "../../_constance/values/LeadSourceExceptions";
import * as LeadStatusExceptions from "../../_constance/values/LeadStatusExceptions";
import SwitchComponent from "../../_components/form-bb-components/switch-component/switch-component"; 
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { shouldRefresh } from "../../_helpers/index";
import axiosAuth from "../../_services/config/axios-auth";
import { Toggle } from "react-toggle-component"

class LeadDetailsBasicComponent extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          element: this.props.element,
          edited: false
      };
  }

  componentDidMount() {
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getMultiDataBasic();
    this.props.getClients();
  }

  handleChangeSelect = (key, value) =>{
    this.props.element[key] = value;
    this.setState(prevState => {
        let elem = {...prevState.element}
        elem[key] = value;
        return {
            ...prevState,
            element: elem
        }
    });
    this.props.editedData();
  }

  getMultipliersList = () => {
    this.props.pendingRequest();
    axiosAuth.get("multipliers/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new MultiplierTable(element);});
        this.props.setData(mapedRes);
      })
      .catch((error) => {
        this.props.clearData();
      });
  }

  getMultiDataBasic() {
    const lastUpdate = this.props.multipliersTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getMultipliersList();
    }
  }

  handleChangeSelect = (key, value) =>{
    this.props.element[key] = value;
    this.setState(prevState => {
        let elem = {...prevState.element}
        elem[key] = value;
        return {
            ...prevState,
            element: elem
        }
    });
    this.props.editedData();
  }

  handleChangeMulti = (value) =>{
    this.props.editedMulti(value);
  }

  handleDateChange(key, event) {
    let timestamp = new Date(event).getTime();
    const hoursLeftFromMidnight = (timestamp/1000)%86400;
    if (hoursLeftFromMidnight > 79200) {
      console.log('timestamp: ', timestamp, hoursLeftFromMidnight, timestamp/1000 + 72000);
      timestamp = timestamp/1000 + 72000;
    } else {
      console.log('timestamp: ', timestamp, hoursLeftFromMidnight, timestamp/1000 + 72000 - hoursLeftFromMidnight);
      timestamp = timestamp/1000 + 72000 - hoursLeftFromMidnight;
    }
    this.props.element[key] = timestamp;
    this.setState(prevState => {
      let elem = {...prevState.element}
      elem[key] = timestamp;
      return {
        ...prevState,
        element: elem
      }
    });
    this.props.editedData();
  }

  handleDateChangeForENFields(key, event) {
    let timestamp = new Date(event).getTime();
    const hoursLeftFromMidnight = (timestamp/1000)%86400;
    if (hoursLeftFromMidnight > 79200) {
      console.log('timestamp2: ', timestamp, hoursLeftFromMidnight, timestamp/1000 + 72000);
      timestamp = timestamp/1000 + 72000;
    } else {
      console.log('timestamp2: ', timestamp, hoursLeftFromMidnight, timestamp/1000 + 72000 - hoursLeftFromMidnight);
      timestamp = timestamp/1000 + 72000 - hoursLeftFromMidnight;
    }
    this.props.assasmentDates[key] = timestamp;
    this.setState(prevState => {
      let assasment = {...prevState.assasmentDates}
      assasment[key] = timestamp;
      return {
        ...prevState,
        assasmentDates: assasment
      }
    });
    this.props.editedData();
  }

  handleChangeValueKeyNotArrow(key, value){
    this.props.element[key] = value;
    this.setState(prevState => {
      let elem = {...prevState.element}
      elem[key] = value;
      return {
        ...prevState,
        element: elem
      }
    });
    this.props.editedData();
  }
  
  createStatusSelect(){
      const {translateKey, disabled} = this.props;
      return (
          <React.Fragment>
            <select disabled={disabled} className={"custom-select col-sm-10 lead-input-wrapper "+(this.props.element.status.length<=2 && this.props.showValidation?"is-invalid": '')} onChange={(e)=>this.handleChangeSelect('status', e.target.value)} value={this.props.element.status}>
              <option hidden>{translateKey("choose_select")}</option>
              {this.props.leadsStatus.map((value) => {
                  if(this.props.appLang===LanguageSelect.DE){
                    let existsInException = LeadStatusExceptions.DE_LIST.filter(element=>element===value.Value)
                    if(value.Value === this.props.element.status){
                      return <option defaultValue key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                    }else{
                      return <option key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                    }
                  }else{
                    let existsInException = LeadStatusExceptions.EN_LIST.filter(element=>element===value.Value)
                    if(value.Value === this.props.element.status){
                      return <option defaultValue key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                    }else{
                      return <option key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                    }
                  }
              })}
            </select>
            <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
          </React.Fragment>
      )
  } 

  createReferralSelectOptions() {
    const {leadsData, clientsData, multipliersData, match: { params }} = this.props;
    const leadValues = leadsData.filter(item => item.leadId != params.leadId).map(item => ({value: item.leadId, label: item.lead}));
    const clientValues = clientsData.map(item => ({ value: item.id, label: item.client}));
    const multiplierValues =multipliersData.map(item => ({ value: item.id, label: item.multiplier}));

    return [{label: 'Leads', options: leadValues}, {label: 'Clients', options: clientValues}, {label: 'Multipliers', options: multiplierValues}];
  }

  createMultiSelect(){
    const {translateKey, disabled} = this.props;
    return (
      <React.Fragment>
        <select disabled={disabled} className={"custom-select col-sm-10 lead-input-wrapper "+(this.props.multiId===0 && this.props.showValidation ?"is-invalid": '')} onChange={(e)=>this.handleChangeMulti(e.target.value)} value={this.props.multiId}>
          <option hidden>{translateKey("choose_select")}</option>
          {this.props.multipliersData.sort((a, b) => a.multiplier.localeCompare(b.multiplier)).map((value) => {
              return <option defaultValue={this.props.multiId===value.id} key={value.id} value={value.id}>{value.multiplier}</option>
          })}
        </select>
        <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
      </React.Fragment>
    )
  }

  prepareMeMarketingBasedOnLanguage(){
    const { marketingsList, appLang } = this.props;
    return marketingsList.filter(element=> {
      if(appLang==="EN"){
        return element.Market==="UK" || element.Market===appLang
      }else{
        return element.Market===appLang
      }
    }).sort(function (a, b) {
      const firstLabel = a.Label.toLowerCase();
      const secondLabel = b.Label.toLowerCase();

      if (firstLabel < secondLabel) {
        return -1;
      }
      if (firstLabel > secondLabel) {
        return 1;
      }
      return 0;
    });
  }

  toggleThisElement = () =>{
    this.props.element.leadExclusiveForPm24 = !this.props.element.leadExclusiveForPm24
    this.setState(prevState => {
      return {
        ...prevState,
        element: {
          ...prevState.element,
          leadExclusiveForPm24: !this.props.element.leadExclusiveForPm24
        }
      }
    });
    this.props.editedData();
  }

  getMeComment(){
    let comment = this.props.dynamics.is_lead_description;
    let result = comment.split("##");
    if(result.length===0){
      return '';
    }else{
      return result[result.length-1];
    }
  }

  prepareMeLeadSource(){
    const srcL = this.props.leadsSourceStatus.map(element=>{
      if(element.Value!==null){
        if(LeadSourceExceptionsList.indexOf(element.Value.toString().toLowerCase())>-1){
          element.Hidden = true;
          return element;
        }else{
          element.Hidden = false;
          return element;
        }
      }else{
        element.Hidden = true;
        return element;
      }
    })
    return srcL;
  }

  prepareRejectReasonsList(rejectionReasons) {
    const mapedReasons = rejectionReasons.filter(reason => reason.Value != '717700002' && reason.Value != '717700005' && reason.Value != '717700008' && reason.Value != '717700011' && reason.Value != '717700024' && reason.Value != '717700028' && reason.Value != '717700033' && reason.Value != '717700034' && reason.Value != '717700038' && reason.Value != '717700039').sort((a, b) => a.Label.localeCompare(b.Label));

    return mapedReasons;
  }

  render() {
    const {translateKey, appLang, leadsSourceStatus, marketingsList, rejectReasonsList, element, disabled, dynamics, assasmentDates, handleDinamicDateChange, handleDinamicChange,  match: { params }} = this.props;
    const leadSourceList = this.prepareMeLeadSource();
    const referralsList = this.createReferralSelectOptions();
    const preparedRejectReasonsList = this.prepareRejectReasonsList(rejectReasonsList);
    return (
      <div className="lead-first-row">
        <div className="col-sm-12 col-lg-5">
          <fieldset disabled={disabled}>
            <div className="form-group row flexAlignCenter no-margin-row">
              <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_entry")}</label>
              <div className="col-sm-8 lead-input-wrapper">
                <DatePickerComponent readOnly = {disabled} key="date_of_entry_01" timestamp={element.dateOfEntry!==null?element.dateOfEntry:new Date()} withTime={false} onDateChange={(e)=>this.handleDateChange("dateOfEntry", e)}/>
              </div>
            </div>
            {LeadType.length>0 &&
              <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList={LeadType.filter(function(r){ return r.Hidden === false })} keyName="leadType" value={element.leadType!==null?element.leadType:''} mainText={translateKey("lead_type")}/>
            } 
            {(element.leadType == '717700001' || element.leadType == '717700002') &&
              <>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("referral_contact_id")}</label>
                  <div className="col-sm-8">
                    <div className={"custom-select2 lead-input-wrapper "+(this.props.element.referralContactId===null && this.props.showValidation ?"is-invalid": '')}>
                      <GroupedSelect
                        disabled={disabled}
                        name="referralContactId"
                        value={element.referralContactId ? element.referralContactId : referralsList[0].options[0].value}
                        defaultValue={element.referralContactId ? referralsList.filter(item => item.value == element.referralContactId) : referralsList[0].options[0].value}
                        onChange={(val)=>this.handleChangeSelect('referralContactId', val.value)} 
                        options={referralsList}
                        searchable={false}
                      />
                    </div>
                    <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
                  </div>
                </div>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("referral_other")}</label>
                  <div className="col-sm-8">
                    <Toggle 
                      name={"is_referral_other"} 
                      checked={dynamics.is_referral_other} 
                      onToggle={()=>handleDinamicChange('is_referral_other', !dynamics.is_referral_other)}
                    />
                  </div>
                </div> 
                {dynamics.is_referral_other &&
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("referral_desc")}</label>
                    <div className="col-sm-8">
                      <input 
                        type="text"
                        className={"form-control"}
                        onChange={(e)=>handleDinamicChange('is_other_source_description', e.target.value)}
                        value={dynamics.is_other_source_description?dynamics.is_other_source_description:''}
                      />
                    </div>
                  </div> 
                }
              </>
            }
            {this.props.leadsStatus.length>0 && 
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("status")}</label>
                <div className="col-sm-8">
                  {this.createStatusSelect()}
                </div>
              </div>
            }
            {leadsSourceStatus.length>0 &&
              <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList={leadSourceList} keyName="source" value={element.source!==null?element.source.toUpperCase():''} mainText={translateKey("source_of_acquisition")}/>
            }            
            {element.source!==null && element.source === baseData.multiToLeadSelection &&
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("multiplier")}</label>
                <div className="col-sm-8">
                  {this.createMultiSelect()}
                </div>
              </div>
            }
            {appLang === LanguageSelect.EN && Rating.length>0 &&
              <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList = {Rating.filter(function(r){ return r.Hidden === false })} keyName="rating" value={element.rating!==null?element.rating:''} mainText={translateKey("rating")}/>
            }       
            {appLang === LanguageSelect.EN && marketingsList.length>0 &&
              <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList = {this.prepareMeMarketingBasedOnLanguage()} keyName="marketingId" value={element.marketingId!==null?element.marketingId.toUpperCase():''} mainText={translateKey("marketing_type")}/>
            }  
            {rejectReasonsList.length>0 &&
              <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList = {preparedRejectReasonsList} keyName="reasonForRejection" value={element.reasonForRejection!==null?element.reasonForRejection:''} mainText={translateKey("rejection_reason")}/>
            }  
            {appLang === LanguageSelect.DE &&
              <React.Fragment>
                <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("rejection_details")}</label>
                    <div className="col-sm-8">
                      <input disabled = {disabled} type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("reasonsForRejectionDetails", e.target.value)} value={element.reasonsForRejectionDetails?element.reasonsForRejectionDetails:''}/>
                    </div>
                </div>
                {params.leadId && params.leadId!==0 &&
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("lead_exclusive")}</label>
                    <SwitchComponent
                      hideLabel={true}
                      disabled={true}
                      setTypeChangeToParent={()=>{}}
                      showValidation={false}
                      className="col-sm-8 text-center no-pointer-events"
                      transKey=""
                      key="is_contact_person_data_same_as"
                      keyName="is_contact_person_data_same_as"
                      value={element.leadExclusiveForPm24}
                      validationErrors={[]}
                      valueYes = {true}
                      valueNo = {false}
                    />
                  </div>
                }
              </React.Fragment>
            }  
            {appLang === LanguageSelect.EN &&
              <React.Fragment>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_first_interview")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent readOnly = {disabled} key="date_of_entry_01" timestamp={element.dateFirstInterview!==null?element.dateFirstInterview:''} withTime={false} onDateChange={(e)=>this.handleDateChange('dateFirstInterview', e)}/>
                  </div>
                </div>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_start_initial_assassment")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent readOnly = {disabled} key="birthdate" timestamp={assasmentDates.birthdate!==null? assasmentDates.birthdate : ''} withTime={false} onDateChange={(e)=>this.handleDateChangeForENFields('birthdate', e)}/>
                  </div>
                </div>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_completioon_care_assassment")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent readOnly = {disabled} key="anniversary" timestamp={assasmentDates.anniversary!==null? assasmentDates.anniversary : ''} withTime={false} onDateChange={(e)=>this.handleDateChangeForENFields('anniversary', e)}/>
                  </div>
                </div>
              </React.Fragment>
            }
            {dynamics.is_lead_description &&
              <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("comments_from_internet_form")}</label>
                  <div className="col-sm-8">
                    <textarea rows="4" disabled = {true} type="text" className="form-control lead-input-wrapper" value={this.getMeComment()} style={{resize: 'none'}}/>
                  </div>
              </div>
            }
            { dynamics.is_last_note ?
              <>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_note")}</label>
                  <div className="col-sm-8">
                    <input 
                      disabled={true} 
                      type="text" 
                      className="form-control lead-input-wrapper" 
                      onChange={()=>{}}
                      value={dynamics.is_last_note?dynamics.is_last_note:''}
                    />
                  </div>
                </div>
              </>
              : null
            }
            { dynamics.is_last_call ?
              <>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_last_call")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent 
                      readOnly = {disabled} 
                      key="is_last_call" 
                      timestamp={dynamics.is_last_call!==null ? dynamics.is_last_call : ''} 
                      withTime={false} 
                      onDateChange={(e)=>handleDinamicDateChange('is_last_call', e)}
                      disabled={true}
                    />
                  </div>
                </div>
              </> 
              : null
            }
            { dynamics.is_last_meeting ?
              <>
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_last_meeting")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent 
                      readOnly = {disabled} 
                      key="is_last_meeting" 
                      timestamp={dynamics.is_last_meeting!==null?dynamics.is_last_meeting:''} 
                      withTime={false} 
                      onDateChange={(e)=>handleDinamicDateChange('is_last_meeting', e)}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
              : null
            }
          </fieldset>
        </div>
        <div className="col-sm-12 col-lg-5">
          <div className="form-group row flexAlignCenter no-margin-row">
            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("client_id")}</label>
            <div className="col-sm-8">
              <input 
                disabled={true} 
                type="text" 
                className="form-control lead-input-wrapper" 
                onChange={()=>{}}
                value={dynamics.is_sid?dynamics.is_sid:''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        leadsStatus: state.enums.leadStatus,
        leadsSourceStatus: state.enums.leadSource,
        marketingsList: state.enums.marketingsList,
        marketingsListStatus: state.enums.marketingsListStatus,
        rejectReasonsList: state.enums.rejectReasonsList,
        rejectReasonsListStatus: state.enums.rejectReasonsListStatus,
        multipliersData: state.database.multipliers,
        multipliersTimeStamp: state.database.multipliersTimeStamp,
        clientsData: state.database.clients,
        leadsData: state.database.leads,
    };
  }
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getMarketingData: () => dispatch(getMarketingData()),
  getRejectReasonData: (lang) => dispatch(getRejectReasonData(lang)),
  setData: (data) => dispatch(setMultipliersData(data)),
  pendingRequest: () => dispatch(pendingMultipliersData()),
  clearData: () => dispatch(removeMultipliersData()),
  getClients: () => dispatch(getClients()),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadDetailsBasicComponent));