import { SET_COLUMNS_DATA, REMOVE_COLUMNS_DATA, ADD_COLUMN_DATA, UPDATE_COLUMN_DATA } from './action-types';

export const setColumnsData = (data) => {
  return {
      type: SET_COLUMNS_DATA,
      data: data
  };
};

export const removeColumnsData = () => {
  return {
      type: REMOVE_COLUMNS_DATA,
  };
};

export const addColumnData = (data) => {
  return {
      type: ADD_COLUMN_DATA,
      data: data
  };
};

export const updateColumTypeData = (type, data) => {
  return {
      type: UPDATE_COLUMN_DATA,
      data: data,
      rowType: type
  };
};