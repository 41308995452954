import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey, getPatientLead, getPatientClient} from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import produce from "immer";
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import ListComponent from "../../form-bb-components/list-component/list-component";
import BBFormService from '../../../_services/bbform';
import OfferEnquiryForm from "../../../_forms/OfferEnquiryForm/OfferEnquiryForm";
import { ResponseStatus } from '../../../_helpers/response_status'
import SelectComponent from "../select-component/SelectComponent";
import { ReqHelper } from "../../../_helpers/index";
import { MDBModal, MDBModalBody, MDBModalHeader} from 'mdbreact';
import * as FormTypeToShow from '../../../_forms/BBForm/values/formTypeToShow';
import * as Entity from '../../../_forms/BBForm/values/entity';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import axiosAuth from "../../../_services/config/axios-auth";
import { ContractTable }  from "../../../_constance/classes/index";
import FormContractPCMComponent from "../form-contract-pcm-component/FormContractPCMComponent";
const RejectStatusLead = "717700009";
const RejectedEnquiryStatus = "717700004";

class formComponent extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            sectionList: props.sectionList,
            activeSection: props.activeSection,
            products: props.products,
            regionsFP: props.regionsFP,
            selectedProduct: {},
            testValue: '',
            showValidation: props.showValidationErrors,
            form: new OfferEnquiryForm(),
            edit: true,
            closeIt: false,
            loadingPatients: true,
            rejectModal: false,
            offerId: 0,
            newOne: props.newOne,
            showValidationRejected: false,
            patientIdList: [],
            wholeResponse: props.wholeResponse,
            is_driving_licence: null,
            is_second_ooo: null,
            is_transfer: null,
            is_carer_with_diploma: null,
            is_additional_foreign_language: null,
            contractData: null
        };
    }

    componentDidMount() {  
        const { match: { params }, newOne } = this.props;
        const { form } = this.state;  
        let type = params.type;
        let offerId = 0;
        if(!newOne){
            if(type===FormTypeToShow.ENQUIRY){
                this.setState({offerId: params.patientId})
                offerId = params.patientId;
                BBFormService.retrievePatient(form.getRetiveDataALl(params.id, offerId)).then(response => {
                    const { data } = response;
                    data.forEach(entity => form.setFields(entity.fields));
                    this.setMeInitValuesAddition();
                });
            }
        }
        this.getInitialPatient();
        this.getBasicContractsData();
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0); 
    }

    componentDidUpdate(prevProps) {
        if(prevProps.newOne !== this.props.newOne){
            this.setState({
                form: new OfferEnquiryForm(),
                offerId: 0
            })
        }
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    getBasicContractsData(){
        const { match: { params }, newOne } = this.props;
        let type = params.type;
        if(type===FormTypeToShow.ENQUIRY){
            axiosAuth.get("/contracts/list/"+params.id+"/0")
                .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
                .then(json => { return json.data })
                .then(res => {
                    if(!newOne){
                        for(let i=0; i<res.length; i++){
                            if(res[i].id === params.patientId){
                                this.setState({
                                    contractData: new ContractTable(res[i])
                                })
                            break;
                        }
                    }
                }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        
      }

    setMeInitValuesAddition = () =>{
        const { form } = this.state;
        this.setState({
            is_driving_licence: form.getFieldByKey("is_driving_licence").value,
            is_second_ooo: form.getFieldByKey("is_second_ooo").value,
            is_transfer: form.getFieldByKey("is_transfer").value,
            is_carer_with_diploma: form.getFieldByKey("is_carer_with_diploma").value,
            is_additional_foreign_language: form.getFieldByKey("is_additional_foreign_language").value
        })   
    }

    prepareMeAddition = () => {
        const { form, is_driving_licence, is_second_ooo, is_transfer, is_carer_with_diploma, is_additional_foreign_language } = this.state;
        let isDrivingLicenceForm = form.getFieldByKey("is_driving_licence");
        if(isDrivingLicenceForm.value === '717700001' || isDrivingLicenceForm.value === null){
            if(is_driving_licence !== '717700000'){
                isDrivingLicenceForm.value = null;
            }
        }

        let isSecondOoo = form.getFieldByKey("is_second_ooo");
        if(isSecondOoo.value === '717700001' || isSecondOoo.value === null){
            if(is_second_ooo !== '717700000'){
                isSecondOoo.value = null;
            }
        }

        let isTransfer = form.getFieldByKey("is_transfer")
        if(isTransfer.value === '717700001' || isTransfer.value === null){
            if(is_transfer !== '717700000'){
                isTransfer.value = null;
            }
        }

        let isCarerWithDiploma= form.getFieldByKey("is_carer_with_diploma");
        if(isCarerWithDiploma.value === '717700001' || isCarerWithDiploma.value === null){
            if(is_carer_with_diploma !== '717700000'){
                isCarerWithDiploma.value = null;
            }
        }

        let isAdditionalForeignLanguage = form.getFieldByKey("is_additional_foreign_language");
        if(isAdditionalForeignLanguage.value === '717700001' || isAdditionalForeignLanguage.value === null){
            if(is_additional_foreign_language !== '717700000'){
                isAdditionalForeignLanguage.value = null;
            }
        }
    }

    getInitialPatient(){
        if(this.props.mainType==="CLIENT"){
            if(this.props.patientClientStatus === ResponseStatus.READY){
                if(this.props.wholeResponse===null){
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }else{
                    clearTimeout(this.myTimeout);
                    const { match: { params } } = this.props;
                    let finalPatientList = this.props.patientClientData.filter(element => {
                        if(element.clientId === params.id){
                            return true;
                        }else{
                            return false;
                        }
                    })
                    let patientList = [];
                    if(params.patientId !=='0' && params.patientId !==0){
                        patientList = finalPatientList.filter(element =>{
                            if(element.contractId === params.patientId){
                                return this.checkIfPatientIsValid(element.id);
                            }else{
                                return false
                            }
                        }).map(element => element.id);
                        if(patientList.length===0){
                            patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                        }
                    }else{
                        patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                    }
                    this.setState({
                        loadingPatients: false,
                        patientIdList: patientList
                    })
                    if(this.state.patientIdList.length>0){
                        setTimeout(() => {
                            this.checkIfPatientIsValid(this.state.patientIdList[0])
                        }, 500);
                    }
                }
            }else{
                if(this.props.patientClientStatus === ResponseStatus.FREE){
                    this.props.getPatientClientData(this.props.appLang);
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }else{
                    if(this.props.patientClientStatus === ResponseStatus.ERROR){
                    clearTimeout(this.myTimeout);
                    this.setState({ loadingPatients: false })
                    }else{
                        this.myTimeout = setTimeout(() => {
                            this.getInitialPatient()
                        }, 500);
                    }
                }
            }
        }else{
            if(this.props.patientLeadStatus === ResponseStatus.READY){
                if(this.props.wholeResponse===null){
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }else{
                    clearTimeout(this.myTimeout);
                    const { match: { params } } = this.props;
                    let finalPatientList = this.props.patientLeadData.filter(element => {
                        if(element.leadId === params.id){
                            return true;
                        }else{
                            return false;
                        }
                    })
                    let patientList = [];
                    if(params.patientId !=='0' && params.patientId !==0){
                        patientList = finalPatientList.filter(element =>{
                            if(element.contractId === params.patientId){
                                return this.checkIfPatientIsValid(element.id);
                            }else{
                                return false
                            }
                        }).map(element => element.id);
                        if(patientList.length===0){
                            patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                        }
                    }else{
                        patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                    }
                    this.setState({
                        loadingPatients: false,
                        patientIdList: patientList
                    })
                    if(this.state.patientIdList.length>0){
                        setTimeout(() => {
                            this.checkIfPatientIsValid(this.state.patientIdList[0])
                        }, 500);
                    }
                }
            }else{
                if(this.props.patientLeadStatus === ResponseStatus.FREE){
                    this.props.getPatientLeadData(this.props.appLang);
                    this.myTimeout = setTimeout(() => {
                    this.getInitialPatient()
                    }, 500);
                }else{
                    if(this.props.patientLeadStatus === ResponseStatus.ERROR){
                    clearTimeout(this.myTimeout);
                    this.setState({ loadingPatients: false })
                    }else{
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                    }
                }
            }
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setTypeSpecialChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value===true?'717700000':'717700001'
            }
        });
        this.setState(nextState);
    };

    setTypeChangeWithKey(key, value){
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setTypeChangeProduct= (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
        let selectedProductData = this.props.products.filter(element => {
            if(element.ProductId === value){
                return true;
            }else{
                return false;
            }
        })
        this.setState({selectedProduct: selectedProductData[0]})
    };

    setTypeIfNumber(key, value){
        if(ReqHelper.isNumber(parseInt(value))){
            const nextState = produce(this.state, draftState => {
                const { form } = draftState;
                const field = form.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
        }
    }

    checkIfPatientIsValid = (id) => {
        let patientWIthId = this.props.wholeResponse.filter(entity => entity.entity===Entity.PATIENT && entity.id === id);
        if(patientWIthId.length>0){
            this.props.bbform.setFields(patientWIthId[0].fields);
            return this.props.bbform.isValid;
        }
        return false
    }

    changePatientIdList = (id) =>{
        if(this.state.patientIdList.indexOf(id) !== -1){
            if(this.state.patientIdList.length===1){
                this.props.showMessage("danger", this.props.translateKey("form-at-least-one-patient"));
            }else{
                this.setState(prevState => {
                    return {
                        ...prevState,
                            patientIdList: prevState.patientIdList.filter(item => item!==id)
                        }
                    }
                );
            }
        }else{
            if(this.checkIfPatientIsValid(id)){
                this.setState(prevState => {
                    return {
                        ...prevState,
                            patientIdList: [...prevState.patientIdList,  id]
                        }
                    }
                );
            }else{
                this.props.showMessage("danger", this.props.translateKey("form-text-not-every-section-valid"));
            }
        }
    }

    prepareMePatientList = () =>{
        const { match: { params }, translateKey } = this.props;
        if(this.props.mainType === "CLIENT"){
            let finalPatientList = this.props.patientClientData.filter(element => {
                if(element.clientId === params.id){
                    return true;
                }else{
                return false;
                }
            })
            if(finalPatientList.length>1){
                let prepareValueList = finalPatientList.map((element)=>{
                    return {value: element.id, label: (element.name!=="" && element.name!==null)?element.name:"---"};
                });
                return (
                    <div className="hilfsmittel-wrapper">
                        <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("patients")}</div>
                        {prepareValueList.map((element)=>{
                            return (
                                <div className="custom-control custom-checkbox" key={Math.random().toString(36).substr(2, 9)}>
                                    <input className="form-check-input" checked={this.state.patientIdList.indexOf(element.value) !== -1} onChange={() => this.changePatientIdList(element.value)} value={element.value} type="checkbox" id={element.value}/>
                                    <label className="form-check-label cursor-pointer break-word-all" htmlFor={element.value}>{element.label}</label>
                                </div>
                            )
                        })}
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-text-err_required')}</div>
                            </div>
                        }
                    </div>
                )
            }else{
                return (
                    <React.Fragment>
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-error-no-patient')}</div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }else{
            let finalPatientList = this.props.patientLeadData.filter(element => {
                if(element.leadId === params.id){
                    return true;
                }else{
                return false;
                }
            })
            if(finalPatientList.length>1){
                let prepareValueList = finalPatientList.map((element)=>{
                    return {value: element.id, label: (element.name!=="" && element.name!==null)?element.name:"---"};
                });
                return (
                    <div className="hilfsmittel-wrapper">
                        <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("patients")}</div>
                        {prepareValueList.map((element)=>{
                            return (
                                <div className="custom-control custom-checkbox" key={element.value}>
                                    <input className="form-check-input" checked={this.state.patientIdList.indexOf(element.value) !== -1} onChange={() => this.changePatientIdList(element.value)} value={element.value} type="checkbox" id={element.value}/>
                                    <label className="form-check-label cursor-pointer break-word-all" htmlFor={element.value}>{element.label}</label>
                                </div>
                            )
                        })}
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-text-err_required')}</div>
                            </div>
                        }
                    </div>
                )
            }else{
                return (
                    <React.Fragment>
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-error-no-patient')}</div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    }

    rejectEnquiryWithReason = () =>{
        const { form } = this.state;
        const isInquiryRejectionReason = form.getFieldByKey('is_inquiry_rejection_reason');
        const isInquiryRejectionOtherReason = form.getFieldByKey('is_inquiry_rejection_other_reason');
        if(isInquiryRejectionReason.value===null || isInquiryRejectionReason.value.toString().trim()===''){
            this.setState({showValidationRejected: true})
        }else{
            if(isInquiryRejectionReason.value === '717700008' && (isInquiryRejectionOtherReason.value===null || isInquiryRejectionOtherReason.value.toString().trim()==='')){
                this.setState({showValidationRejected: true})
            }else{
                this.saveRejectOffer();
            }
        }
    }
    
    saveRejectOffer = () =>{
        const { form } = this.state;
        const { match: { params } } = this.props;
        let Rejecttimestamp = new Date().getTime();
        Rejecttimestamp = Math.floor(Rejecttimestamp / 1000);
        form.getFieldByKey('is_customer_status_de').value = RejectStatusLead;
        form.getFieldByKey('is_inquiry_rejection_date').value = Rejecttimestamp;
        BBFormService.savePatient(form.getRejectFields(params.id, this.state.offerId)).then(response => {
            const { data } = response;
            let newofferID = 0;
            data.forEach(element => {if(element.entity === Entity.CONTRACT){newofferID = element.id}});
            this.setState({
                offerId: newofferID,
                rejectModal: false
            });
            this.props.setNewLeadStatus(RejectStatusLead);
        }).catch(error => {
            this.props.showMessage("danger", this.props.translateKey("form-error-data-saved-failed"));
            console.log(error)
        });
    }

    giveMePatientId(){
        const { match: { params } } = this.props;
        if(this.props.mainType==="CLIENT"){
            let finalPatientList = this.props.patientClientData.filter(element => {
                if(element.clientId === params.id){
                    return true;
                }else{
                  return false;
                }
            })
            if(finalPatientList.length>0){
                return finalPatientList[0].id
            }else{
                return 0;
            }
        }else{
            let finalPatientList = this.props.patientLeadData.filter(element => {
                if(element.leadId === params.id){
                    return true;
                }else{
                  return false;
                }
            })
            if(finalPatientList.length>0){
                return finalPatientList[0].id
            }else{
                return 0;
            }
        }
    }

    saveOffer = () => {
        if(this.props.mainType !== "CLIENT"){
            const { bbform, coopId, history, translateKey } = this.props;
            this.props.showSaveModal();
            const { form } = this.state;
            const { match: { params } } = this.props;
            form.getFieldByKey('is_contact_patientID').value = params.id;
            form.getFieldByKey('is_primary_patientid').value = this.giveMePatientId();
            form.getFieldByKey('is_cooperation_condition_id').value = coopId;
            const isDegreeRelationshipWithPatientCarer = bbform.getFieldByKey('is_degree_relationship_with_patient_carer');
            if (isDegreeRelationshipWithPatientCarer.value === '717700008') {
                form.getFieldByKey('is_identical_patient_data').value = '717700001';
                form.getFieldByKey('is_first_name').value = bbform.getFieldByKey('firstname').value;
                form.getFieldByKey('is_last_name').value = bbform.getFieldByKey('lastname').value;
                form.getFieldByKey('is_street').value = bbform.getFieldByKey('is_registered_street').value;
                form.getFieldByKey('is_house_number').value = bbform.getFieldByKey('is_registered_street_number').value;
                form.getFieldByKey('is_appartment_number').value = bbform.getFieldByKey('is_registered_house_number').value;
                form.getFieldByKey('is_zip_postal_code').value = bbform.getFieldByKey('is_registered_zip_postal_code').value;
                form.getFieldByKey('is_city').value = bbform.getFieldByKey('is_registered_city').value;
            }else{
                form.getFieldByKey('is_identical_patient_data').value = '717700000'
                form.getFieldByKey('is_first_name').value = bbform.getFieldByKey('is_patient_first_name').value;
                form.getFieldByKey('is_last_name').value = bbform.getFieldByKey('is_patient_last_name').value;
                form.getFieldByKey('is_street').value = bbform.getFieldByKey('is_service_address_street').value;
                form.getFieldByKey('is_house_number').value = bbform.getFieldByKey('is_service_address_street_number').value;
                form.getFieldByKey('is_appartment_number').value = bbform.getFieldByKey('is_service_address_house_number').value;
                form.getFieldByKey('is_zip_postal_code').value = bbform.getFieldByKey('is_service_address_postal_code').value;
                form.getFieldByKey('is_city').value = bbform.getFieldByKey('is_service_address_city').value;
            }

            if (form.isValid && this.state.patientIdList.length>0) {
                this.prepareMeAddition();
                BBFormService.savePatient(form.getPayload(params.id, this.state.offerId)).then(response => {
                    const { data } = response;
                    let newofferID = 0;
                    data.forEach(element => {if(element.entity === Entity.CONTRACT){
                        newofferID = element.id
                    }});
                    this.props.setNewLeadStatus(form.getFieldByKey('is_customer_status_de').value);
                    let prepareMePatientToAssign = {
                        "ContractId": newofferID,
                        "Patients": this.state.patientIdList
                    }
                    BBFormService.assignPatients(prepareMePatientToAssign).then(() => {
                        this.props.getPatientLeadData(this.props.appLang);
                        this.props.hideSaveModal();
                        this.props.showMessage("success", translateKey("form-data-saved"));
                        history.push(`${baseData.subFolders}bb-form-lead/${params.id}/${newofferID}/${FormTypeToShow.ENQUIRY}`);
                        this.setState({
                            offerId: newofferID,
                            rejectModal: false
                        });
                    }).catch(error => {
                        this.props.hideSaveModal();
                        this.props.showMessage("danger", translateKey("form-error-data-saved-failed"));
                        console.log(error);
                    });
                }).catch(error => {
                    this.props.hideSaveModal();
                    this.props.showMessage("danger", translateKey("form-error-data-saved-failed"));
                    console.log(error);
                });
            } else {
                this.setState({showValidation: true});
                this.props.hideSaveModal();
            }
        }
    };

    rejectToggle = () => {
        const { form } = this.state;
        form.getFieldByKey('is_inquiry_rejection_reason').value = null;
        form.getFieldByKey('is_inquiry_rejection_other_reason').value= null;
        this.setState({
            rejectModal: !this.state.rejectModal,
            showValidationRejected: false
        });
    }

    convertToContract = () => {
        this.props.convertToContrat(false);
    };

    render() {
        const { disabled, translateKey, showButtons, products } = this.props;
        const { form, contractData } = this.state;
        const isStartDate = form.getFieldByKey('is_start_date');
        const isPermanentContract = form.getFieldByKey('is_permanent_contract');
        const isEndDate = form.getFieldByKey('is_end_date');
        const isRate = form.getFieldByKey('is_rate');
        const isInquiryRejectionReason = form.getFieldByKey('is_inquiry_rejection_reason');
        const isInquiryRejectionOtherReason = form.getFieldByKey('is_inquiry_rejection_other_reason');
        const isInquiryRejectionDate = form.getFieldByKey('is_inquiry_rejection_date');
        const isProductId = form.getFieldByKey('is_product_id');
        const isContractStatus = form.getFieldByKey('is_contract_status');
        let isDisabled = disabled;
        if(isContractStatus.value === RejectedEnquiryStatus){
            isDisabled = true
        }
        
        const productList = products.filter(element=>{
            return !element.ProductName.startsWith("Franchise");
        })

        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{translateKey("create_a_request_for_quotation")}</span>
                </div>
                <form className="section-form-content form_serializer newUI">
                    <fieldset disabled={isDisabled} className="special-padding-for-offer">
                        <div className="row col-sm-12 col-lg-6">
                            <WrappedDatePickerComponent
                                showValidation={this.state.showValidation}
                                keyName="is_start_date"
                                value={isStartDate.value}
                                validationErrors={isStartDate.validationErrors}
                                text={translateKey("contract-text-voraussichtlicher-begin")}
                                setTypeChangeToParent={this.setTypeChange}
                                disabled={isDisabled}
                            />
                        </div>
                        <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={this.state.showValidation}
                                className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                transKey="contract-text-unbefristeter"
                                key="is_permanent_contract"
                                keyName="is_permanent_contract"
                                value={isPermanentContract.value}
                                validationErrors={isPermanentContract.validationErrors}
                                valuesList={[
                                    { value: '717700000', label: translateKey("yes")},
                                    { value: '717700001', label: translateKey("no")},
                                ]}
                        />
                        {isPermanentContract.value==='717700001' &&
                            <React.Fragment>
                                <div className="row col-sm-12 col-lg-6">
                                    <WrappedDatePickerComponent
                                        showValidation={this.state.showValidation}
                                        keyName="is_end_date"
                                        value={isEndDate.value}
                                        validationErrors={isEndDate.validationErrors}
                                        text={translateKey("contract-text-voraussichtlicher-dauer")}
                                        setTypeChangeToParent={this.setTypeChange}
                                        disabled={isDisabled}
                                    />
                                </div>
                            </React.Fragment>
                        } 

                        <SelectComponent
                            showValidation={this.state.showValidation}
                            keyName="is_product_id"
                            valuesList={productList}
                            value={isProductId.value}
                            validationErrors={isProductId.validationErrors}
                            label={translateKey("product")}
                            setTypeChangeToParent={this.setTypeChangeProduct}
                            optionValueKey="ProductId"
                            optionLabelKey="ProductName"
                        />

                        <div className="row col-sm-12 col-lg-6">
                            <div className="col-sm-12 form-margin-bottom-fix">
                                <label className="form-label-basic">
                                    <span className="quick-label-position-fix">
                                        {translateKey("additional_services")}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_driving_licence').value==='717700000'} text={translateKey("form-text-is-driving-licence")} transKey="form-text-is-driving-licence" keyName="is_driving_licence"/>
                        <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_second_ooo').value==='717700000'} text={translateKey("second_service_user")} transKey="second_service_user" keyName="is_second_ooo"/>
                        <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_transfer').value==='717700000'} text={translateKey("plus")} transKey="plus" keyName="is_transfer"/>
                        <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_carer_with_diploma').value==='717700000'} text={translateKey("diploma")} transKey="diploma" keyName="is_carer_with_diploma"/>
                        <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_additional_foreign_language').value==='717700000'} text={translateKey("additional_language")} transKey="additional_language" keyName="is_additional_foreign_language"/>

                        <div className="row col-sm-12 col-lg-6">
                            <InputTypeTextComponent
                                valueFormatter={ReqHelper.valueFormatter}
                                showValidation={this.state.showValidation}
                                className="form-margin-bottom-fix"
                                setTypeChangeToParent={this.setTypeChange}
                                field={isRate}
                                text={translateKey("rate")}
                                transKey="rate"
                                keyName="is_rate"
                            />
                            <div className="col-sm-12 form-margin-bottom-fix">
                                {!(isRate.value<=this.state.selectedProduct.DailyRateTo && isRate.value>=this.state.selectedProduct.DailyRateFrom) && isRate.value!=='0' && isRate.value && this.state.selectedProduct.ProductId &&
                                    <div className="force-gray">{`${translateKey("contract-out-of-range")} (${this.state.selectedProduct.DailyRateFrom} - ${this.state.selectedProduct.DailyRateTo})`}</div>
                                }
                            </div>
                        </div>

                        {this.prepareMePatientList()}
                        
                        {this.state.edit === false &&
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.state.showValidation}
                                    keyName="is_inquiry_rejection_date"
                                    value={isInquiryRejectionDate.value}
                                    validationErrors={isInquiryRejectionDate.validationErrors}
                                    text={translateKey("form-text-ablehnungsdatum")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isDisabled}
                                />
                            </div>
                        }

                        {isInquiryRejectionReason.value!==null && isInquiryRejectionReason.value.toString().trim() !=="" && this.state.rejectModal===false && 
                            <React.Fragment>
                                <ListComponent
                                        shouldBeEdited={this.state.edit || this.state.closeIt}
                                        setTypeChangeToParent={this.setTypeChange}
                                        showValidation={this.state.showValidation}
                                        className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                        transKey="rejection_reason"
                                        key="is_inquiry_rejection_reason"
                                        keyName="is_inquiry_rejection_reason"
                                        value={isInquiryRejectionReason.value}
                                        validationErrors={isInquiryRejectionReason.validationErrors}
                                        valuesList={[
                                            { value: '717700000', label: translateKey("form-text-rejection-reson-type-0")},
                                            { value: '717700001', label: translateKey("form-text-rejection-reson-type-1")},
                                            { value: '717700002', label: translateKey("form-text-rejection-reson-type-2")},
                                            { value: '717700003', label: translateKey("form-text-rejection-reson-type-3")},
                                            { value: '717700004', label: translateKey("form-text-rejection-reson-type-4")},
                                            { value: '717700005', label: translateKey("form-text-rejection-reson-type-5")},
                                            { value: '717700006', label: translateKey("form-text-rejection-reson-type-6")},
                                            { value: '717700007', label: translateKey("form-text-rejection-reson-type-7")},
                                            { value: '717700008', label: translateKey("form-text-rejection-reson-type-8")},
                                        ]}
                                />
                                {isInquiryRejectionReason.value==='717700008' &&
                                    <React.Fragment>
                                        <div className="row col-sm-12 col-lg-6">
                                            <InputTypeTextComponent disabled={this.state.edit || this.state.closeIt} showValidation={this.state.showValidation} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isInquiryRejectionOtherReason} text={translateKey("form-text-andre")} transKey="form-text-andre" keyName="is_inquiry_rejection_other_reason"/>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </fieldset>
                    {contractData!==null &&
                        <FormContractPCMComponent key="form-contract-data-pcm-component-098" element={contractData}/>
                    }
                </form>
                <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectToggle} fullHeight size="lg">
                    <MDBModalHeader toggle={this.rejectToggle}>{translateKey("form-text-close-offert")}</MDBModalHeader>
                    <MDBModalBody className="filter-modal-body">
                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.state.showValidation}
                            className="col-sm-12 col-lg-12 form-margin-bottom-fix"
                            transKey="rejection_reason"
                            keyFix="s_inquiry_rejection_reason_chicken"
                            key="is_inquiry_rejection_reason"
                            keyName="is_inquiry_rejection_reason"
                            value={isInquiryRejectionReason.value}
                            validationErrors={isInquiryRejectionReason.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("form-text-rejection-reson-type-0")},
                                { value: '717700001', label: translateKey("form-text-rejection-reson-type-1")},
                                { value: '717700002', label: translateKey("form-text-rejection-reson-type-2")},
                                { value: '717700003', label: translateKey("form-text-rejection-reson-type-3")},
                                { value: '717700004', label: translateKey("form-text-rejection-reson-type-4")},
                                { value: '717700005', label: translateKey("form-text-rejection-reson-type-5")},
                                { value: '717700006', label: translateKey("form-text-rejection-reson-type-6")},
                                { value: '717700007', label: translateKey("form-text-rejection-reson-type-7")},
                                { value: '717700008', label: translateKey("form-text-rejection-reson-type-8")},
                            ]}
                        />
                        {this.state.showValidationRejected===true && isInquiryRejectionReason.value!=='717700008' &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-text-err_required')}</div>
                            </div>
                        }
                        {isInquiryRejectionReason.value==='717700008' &&
                            <React.Fragment>
                                <div className="row col-sm-12 col-lg-12">
                                    <InputTypeTextComponent showValidation={this.state.showValidation} className="col-lg-12 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isInquiryRejectionOtherReason} text={translateKey("form-text-andre")} transKey="form-text-andre" keyName="is_inquiry_rejection_other_reason"/>
                                </div>
                                {this.state.showValidationRejected===true &&
                                    <div className="invalid-feedback showError custom-error-padding-fix">
                                        <div>{translateKey('form-text-err_required')}</div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </MDBModalBody>
                    <div className="filteredFloatingFooterButtonClear">
                        <button type="button" onClick={this.rejectEnquiryWithReason} className="btn btn-light show-results-filter-button btn-sm" data-dismiss="modal">{translateKey('yes')}</button>
                    </div>
                    <div className="filteredFloatingFooterButtons">
                        <button type="button" onClick={this.rejectToggle} className="btn btn-primary show-results-filter-button btn-sm" data-dismiss="modal">{translateKey('no')}</button>
                    </div>
                </MDBModal>
                <div className="btn-toolbar-fixed row">
                    {this.state.offerId!==0 && showButtons && !isDisabled &&
                        <React.Fragment>
                            <div className="previous-next-wrapper">
                                <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.convertToContract}>{translateKey("form-text-covert-to-contract")}</button>
                            </div>
                            <div className="previous-next-wrapper">
                                <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.rejectToggle}>{translateKey("form-text-close-offert")}</button>
                            </div>
                        </React.Fragment>
                    }
                    {!isDisabled && showButtons &&
                        <div className="previous-next-wrapper">
                            <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.saveOffer}>{translateKey("save")}</button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        coopId: state.auth.CooperationId,
        appLang: state.lang.appLanguage,
        patientLeadData: state.database.patientLead,
        patientLeadStatus: state.database.patientLeadState,
        patientClientData: state.database.patientClient,
        patientClientStatus: state.database.patientClientState,
    };
}
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
    getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(formComponent));