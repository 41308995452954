import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  authSetActionTimestamp,
  setInvoicesData,
  pendingInvoicesData,
  removeInvoicesData
} from "../../_redux/actions/index";
import {
  InvoiceTableDetails,
  TableColumHead,
  TableProperties,
  LoaderTable,
  LoaderModal,
  NavBar,
  SideBar,
  EmptyTableIcon,
  AlertMessage,
  TableDateFormat,
  TablePagination,
  InvoiceApprovalHandler,
  InvoicePaymentHandler
} from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import { TableEnums } from "../../_constance/enums";
import { Alert, InvoiceTable, Paging } from "../../_constance/classes/index";
import { SortingInvoices } from "../../_constance/classes/sortingClasses/index";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { shouldRefresh, ReqHelper, IconsMap } from "../../_helpers/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import {setFiltersData} from "../../_redux/actions";

//COLUMNS
import {
  setColumnsData,
  removeColumnsData,
  addColumnData,
  updateColumTypeData
} from "../../_redux/actions/active-columns";
import * as ColumnsNames from "../../_constance/values/columnsNames";
import {saveColumnsData, getColumnsData} from "../../_services/firebase_service";

class InvoicesMainPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      acceptanceData: [],
      loading: true,
      sorting: new SortingInvoices(),
      filterData: [],
      rerender: Date.now(),
      searchQuery: null,
      showModalLoader: false,
      errorComponent: Alert,
      pagingItem: Paging,
      pagingItemAcceptance: Paging,
      selectedKeyToShow: 0,
      activeTab: "0",
      breadCrumbs:[
        {
          link: "invoices",
          key: "invoices",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getMeColumnConfiguration();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };



  getMeColumnConfiguration(){
    const lastUpdate = this.props.activeColumnsTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getColumnsDataFromApi();
    } else {
      if (this._isMounted) {
        this.prepareMeSortingBasedOnColumns()
        this.myTimeout = setTimeout(() => {
          this.getInitialDataTime()
        }, 100);
      }
    }
  }
  getColumnsDataFromApi(){
   getColumnsData(this.props.userIdColumn)
    .then(res => {
        this.props.setColumns(res);
        this.prepareMeSortingBasedOnColumns();
        this.myTimeout = setTimeout(() => {
          this.getInitialDataTime()
        }, 100);
    })
    .catch((error) => {
      this.prepareMeSortingBasedOnColumns();
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 100);
    });
  }
  prepareMeSortingBasedOnColumns(){
    if(this.props.activeColumns){
      this.props.activeColumns.forEach(element => {
        if(element.name === ColumnsNames.INVOICES){
          element.columns.forEach(item => {
            this.state.sorting.setColumnEnabled(item.enabled, item.name)
          });
        }
      });
    }
    this.setState({rerender: Date.now()})
  }
  prepareColumnsData = (data) =>{
    data.forEach(column => {
      this.state.sorting.setColumnEnabled(column.enabled, column.keyName)
    });
    let columnsData = this.props.activeColumns.find(element => element.name === ColumnsNames.INVOICES); 
    if(columnsData){
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.INVOICES, data, false);
      this.props.updateColumnTypeData(ColumnsNames.INVOICES, dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.INVOICES, dataToSave.columns)
    }else{
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.INVOICES, data, false);
      this.props.addNewColumnTable(dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.INVOICES, dataToSave.columns)
    }
  }

  getInitialDataTime(){
    if(this.props.enumsInvoiceApprovalStatus.length>0 && this.props.enumsInvoicePaymentStatus.length>0){
      clearTimeout(this.myTimeout);
      this.getDataBasic();
    }else{
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 100);
    }
  }

  _prepareFilterObj = () => {
    let filterData = [];
    let Options = [];
    for (let i = 0; i < this.props.enumsInvoiceApprovalStatus.length; i++) {
      let filtering = {
        column: this.props.enumsInvoiceApprovalStatus[i].Label,
        value: this.props.enumsInvoiceApprovalStatus[i].Value,
        active: false,
        type: "text"
      }
      Options.push(filtering);
    }
    filterData.push({
      filterName: "approval_status",
      filterOptions: Options
    });
    Options=[];
    for (let i = 0; i < this.props.enumsInvoicePaymentStatus.length; i++) {
      let filtering = {
        column: this.props.enumsInvoicePaymentStatus[i].Label,
        value: this.props.enumsInvoicePaymentStatus[i].Value,
        active: false,
        type: "text"
      }
      Options.push(filtering);
    }
    filterData.push({
      filterName: "payment_status",
      filterOptions: Options
    });
    let saved = this.props.savedFilters;
    if(saved !== null) {
        return saved;
    } else {
        return filterData
    }
  }

  getStatusApprovalText(status){
    for(let i=0; i<this.props.enumsInvoiceApprovalStatus.length; i++){
        if(this.props.enumsInvoiceApprovalStatus[i].Value === status){
            return this.props.enumsInvoiceApprovalStatus[i].Label;
        }
    }
  }

  getStatusPaymentText(status){
    for(let i=0; i<this.props.enumsInvoicePaymentStatus.length; i++){
      if(this.props.enumsInvoicePaymentStatus[i].Value === status){
          return this.props.enumsInvoicePaymentStatus[i].Label;
      }
    }
  }

  getInvoicesList = () => {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    this.props.pendingRequest();
    axiosAuth.get("invoices/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          element.approvalText = this.getStatusApprovalText(element.approval.Status);
          element.paymentText = this.getStatusPaymentText(element.invoice.status);
          return new InvoiceTable(element);
        });
        const { match: { params } } = this.props;
        this.props.setData(mapedRes);
        if (this._isMounted) {
          this.setState({
            loading:false,
            filterData: this._prepareFilterObj(),
            selectedKeyToShow: params.id?params.id:0,
            activeTab: params.id?"1":"0",
          }, () => this.filterOrDataChange());
        }
      })
      .catch((error) => {
        this.props.clearData();
        this.alertD.show = true;
        this.alertD.type = "danger";
        this.alertD.message = this.props.translateKey("basic_error_message");
        if (this._isMounted) {
          this.setState({
            data: [],
            acceptanceData: [],
            loading: false,
            errorComponent: this.alertD
          })
        }
        console.log(error);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  getDataBasic() {
    const lastUpdate = this.props.invoicesTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getInvoicesList();
    } else {    
      const { match: { params } } = this.props;
      if (this._isMounted) {
        this.setState({
          loading:false,
          filterData: this._prepareFilterObj(),
          selectedKeyToShow: params.id?params.id:0,
          activeTab: params.id?"1":"0",
        }, () => this.filterOrDataChange());
      }
    }
  }

  filterOrDataChange(val){
    let finaldata = this.propsFilterSearch(val);
    ReqHelper.sortMeData(finaldata, this.state.sorting.sortingColumns);
    this.propsPagingData(finaldata);
    setTimeout(() => {
      let finalData = finaldata.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
      let acceptanceData = this.propsPrepareAccpetanceData(finaldata);
      this.propsPagingDataAcceptance(acceptanceData);
      setTimeout(() => {
        let finalDataAcceptance = acceptanceData.slice((this.state.pagingItemAcceptance.currentPage-1)*this.state.pagingItemAcceptance.itemsPerPage, this.state.pagingItemAcceptance.currentPage*this.state.pagingItemAcceptance.itemsPerPage);
        if (this._isMounted) {
          this.setState({
            data: finalData,
            acceptanceData: finalDataAcceptance
          })
        }
      }, 50);
    }, 50);
    this.props.setFilters('invoices', this.state.filterData);
  }

  propsPrepareAccpetanceData(data){
    let acceptanceD = data.filter((value,index)=>{
      return value.approval.Status === '717700000';
    })
    return acceptanceD;
  }

  toggleModalLoader = () => {
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          ...prevState,
          showModalLoader: !prevState.showModalLoader
        }
      });
    }
  }

  propsSearchTable = async (val) => {
    const { history } = this.props;
    if(val.lastIndexOf(baseData.deepLinkStart, 0) === 0){
      this.toggleModalLoader();
      let url = new URL(val);
      let id = url.searchParams.get("id");
      let form = url.searchParams.get("extraqs");
      if(form!==null && id!==null){
        const { appLang } = this.props
        id = id.replace('{', '').replace('}', '').toLowerCase();
        switch(form){
            case baseData.enumClient:           axiosAuth.get("leads/list/0/" + appLang)
                                                .then(res => {
                                                    let statusObj = res.data.find((element) => {return element.leadId.toString().toLowerCase()===id.toString()})
                                                    if(statusObj){
                                                      this.toggleModalLoader();
                                                      history.push(`${baseData.subFolders}leadDetails/${id}`);
                                                    }else{
                                                        axiosAuth
                                                        .get("clients/list/0")
                                                        .then(res => {
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              this.toggleModalLoader();
                                                              history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                            }else{
                                                                axiosAuth.get("multipliers/list/0")
                                                                .then(res => {
                                                                  this.toggleModalLoader();
                                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                    if(statusObj){
                                                                      history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                    }
                                                                }).catch(()=>this.toggleModalLoader())
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    }
                                                }).catch(()=>{
                                                    axiosAuth
                                                    .get("clients/list/0")
                                                    .then(res => {
                                                        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                        if(statusObj){
                                                          this.toggleModalLoader();
                                                          history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                        }else{
                                                            axiosAuth.get("multipliers/list/0")
                                                            .then(res => {
                                                                this.toggleModalLoader();
                                                                let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                if(statusObj){
                                                                  history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                }
                                                            }).catch(()=>this.toggleModalLoader())
                                                        }
                                                    }).catch(()=>{
                                                        axiosAuth.get("multipliers/list/0")
                                                        .then(res => {
                                                            this.toggleModalLoader();
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    })
                                                })
                                                break;
            case baseData.enumPatient:          if(this.props.patientLeadData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientLeadData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/LEAD/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }
                                                if(this.props.patientClientData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientClientData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/CLIENT/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }                   
                                                break;
            case baseData.enumCarer:            axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundCarer = false;
                                                  for(let i=0; i<res.data.length && !foundCarer; i++){
                                                    foundCarer = this.getMeCarer(res.data[i].id, appLang, id, i===res.data.length-1);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumContract:         let gotHim = false
                                                await axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundContract = false;
                                                  for(let i=0; i<res.data.length && !gotHim; i++){
                                                    foundContract = this.getMeContract(res.data[i].id, appLang, id, "CLIENT");
                                                  }
                                                  if(!foundContract){
                                                    this.getMeLeads(appLang, id);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumInvoice:          this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}invoices/${id}`);
                                                break;
            case baseData.enumTaskTypeEvent:    this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeTask:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeCall:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            default: this.toggleModalLoader()
        }    
      }
    }else{
      if (this._isMounted) {
        this.setState({ searchQuery: val.trim() });
      }
      this.filterOrDataChange(val);
    }
  }

  getMeCarer = (id, lang, carerId, redirect=false) =>{
    axiosAuth.get(`/carers/list/${id}/0/${lang}`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.wzid.toString().toLowerCase()===carerId.toString())
        if(statusObj){
          this.toggleModalLoader()
          this.props.history.push(`${baseData.subFolders}clientDetails/${statusObj.clientId}/${carerId}`);
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeContract = (id, appLang, contractId, type, redirect=false) => {
    const { history } = this.props;
    axiosAuth.get(`/contracts/list/${id}/0`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===contractId.toString())
        if(statusObj){
          this.toggleModalLoader()
          if(appLang === LanguageSelect.DE){
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}bb-form-lead/${id}/${contractId}/ENQUIRY`);                            
            }else{
              history.push(`${baseData.subFolders}bb-form-client/${id}/${contractId}/ENQUIRY`);   
            }
          }else{
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}leadDetails/${id}`);                            
            }else{
              history.push(`${baseData.subFolders}contract/${id}/${contractId}`);
            }
          }
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeLeads = (appLang, contractId) =>{
    axiosAuth.get("leads/list/0/" + appLang)
      .then((res) => {
        for(let i=0; i<res.data.length; i++){
          this.getMeContract(res.data[i].leadId, appLang, contractId, "LEAD", i===res.data.length-1);
        }
      }).catch(()=>this.toggleModalLoader())
  }

  propsSortType(type){
    this.state.sorting.changeColumnByColumn(type);
    this.filterOrDataChange();
  }

  propsFilterSearch = (val) => {
    const { appLang } = this.props;
    const { sortingColumns } = this.state.sorting
    let finalDataAfter = [];
    if(this.props.invoicesData !== null && this.props.invoicesData.length > 0) {
      if(val && val!==null){
        // searching
        let filters = ReqHelper.getMeFilersObj(this.state.filterData);
        if(filters.length>0){
          // mamy search i filtry
          // search filter
          let afterSearchData = this.props.invoicesData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
          });
          finalDataAfter = afterSearchData.filter((value) => {
            let gotAllFilters = true;
            for(let i=0; i<filters.length; i++){ 
              if(gotAllFilters){
                if(filters[i].name==='approval_status'){
                  let gotApproval = false
                  for(let v=0; v<filters[i].values.length; v++){
                    if(value.approval.Status === filters[i].values[v].value){
                      gotApproval=true;
                      break;
                    }
                  }
                  gotAllFilters = gotApproval;
                }else{
                  if(filters[i].name==='payment_status'){
                    let gotPayment = false;
                    for(let v=0; v<filters[i].values.length; v++){
                      if(value.invoice.status === filters[i].values[v].value){
                        gotPayment=true;
                        break;
                      }
                    }
                    gotAllFilters = gotPayment;
                  }
                }
              }
            }
            return gotAllFilters;
          });
        }else{
          // samo search bez filtrów
          finalDataAfter = this.props.invoicesData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
          });
        }
      }else{
        let search = this.state.searchQuery;
        if(search && search!==null && val!==''){
          // filtrowanie z wpisanym search
          let filters = ReqHelper.getMeFilersObj(this.state.filterData);
          if(filters.length>0){
            // mamy search i filtry
            let afterSearchData = this.props.invoicesData.filter((value) => {
              return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
            });
            finalDataAfter = afterSearchData.filter((value) => {
              let gotAllFilters = true;
              for(let i=0; i<filters.length; i++){ 
                if(gotAllFilters){
                  if(filters[i].name==='approval_status'){
                    let gotApproval = false
                    for(let v=0; v<filters[i].values.length; v++){
                      if(value.approval.Status === filters[i].values[v].value){
                        gotApproval=true;
                        break;
                      }
                    }
                    gotAllFilters = gotApproval;
                  }else{
                    if(filters[i].name==='payment_status'){
                      let gotPayment = false;
                      for(let v=0; v<filters[i].values.length; v++){
                        if(value.invoice.status === filters[i].values[v].value){
                          gotPayment=true;
                          break;
                        }
                      }
                      gotAllFilters = gotPayment;
                    }
                  }
                }
              }
              return gotAllFilters;
            });
          }else{
            // samo search bez filtrów
            finalDataAfter = this.props.invoicesData.filter((value) => {
              return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
            });
          }
        }else{
          //samo filtrowanie
          let filters = ReqHelper.getMeFilersObj(this.state.filterData);
          if(filters.length>0){
            finalDataAfter = this.props.invoicesData.filter((value) => {
              let gotAllFilters = true;
              for(let i=0; i<filters.length; i++){ 
                if(gotAllFilters){
                  if(filters[i].name==='approval_status'){
                    let gotApproval = false
                    for(let v=0; v<filters[i].values.length; v++){
                      if(value.approval.Status === filters[i].values[v].value){
                        gotApproval=true;
                        break;
                      }
                    }
                    gotAllFilters = gotApproval;
                  }else{
                    if(filters[i].name==='payment_status'){
                      let gotPayment = false;
                      for(let v=0; v<filters[i].values.length; v++){
                        if(value.invoice.status === filters[i].values[v].value){
                          gotPayment=true;
                          break;
                        }
                      }
                      gotAllFilters = gotPayment;
                    }
                  }
                }
              }
              return gotAllFilters;
            });
            return finalDataAfter;
          }else{
            //brak search, brak filtrowania
            finalDataAfter = this.props.invoicesData;
          }
        }
      }
    }
    return finalDataAfter;
  }

  propsPagingData(data){
    if(data.length<=25){
      if(this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              totalItems: data.length,
              itemsPerPage: prevState.pagingItem.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              ...prevState.pagingItem,
              totalItems: data.length,
              pageFrom: prevState.pagingItem.pageFrom,
              pageTo: prevState.pagingItem.pageTo,
              currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }
  }

  propsPagingDataAcceptance(data){
    if(data.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItemAcceptance: {
              totalItems: data.length,
              itemsPerPage: prevState.pagingItemAcceptance.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(data.length/prevState.pagingItemAcceptance.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItemAcceptance: {
              ...prevState.pagingItemAcceptance,
              totalItems: data.length,
              pageFrom: prevState.pagingItemAcceptance.pageFrom,
              pageTo: prevState.pagingItemAcceptance.pageTo,
              currentPage: prevState.pagingItemAcceptance.currentPage>Math.ceil(data.length/prevState.pagingItemAcceptance.itemsPerPage)? Math.ceil(data.length/prevState.pagingItemAcceptance.itemsPerPage) : prevState.pagingItemAcceptance.currentPage,
              totalPages: Math.ceil(data.length/prevState.pagingItemAcceptance.itemsPerPage)
            }
          }
        });
      }
    }
  }

  showDetails(key){
    if(key!== this.state.selectedKeyToShow){
      if (this._isMounted) {
        this.setState({selectedKeyToShow: key})
      }
    }else{
      if (this._isMounted) {
        this.setState({selectedKeyToShow: 0})
      }
    }
  }

  previousNextClickAcceptance(key, type){
    let previous = 0;
    let next = 0;
    for(let j=0; j<this.state.acceptanceData.length; j++){
      if(j>0){
        previous = this.state.acceptanceData[j-1].Id;
      }else{
        previous = 0
      }
      if(j+1<=this.state.acceptanceData.length-1){
        next = this.state.acceptanceData[j+1].Id
      }else{
        next = 0
      }
      if(this.state.acceptanceData[j].Id === key){
        if(type==="next"){
          this.showDetails(next);
          break;
        }else{
          this.showDetails(previous);
          break;
        }
      }
    }
  }

  previousNextClick(key, type){
    let previous = 0;
    let next = 0;
    for(let j=0; j<this.state.data.length; j++){
      if(j>0){
        previous = this.state.data[j-1].Id;
      }else{
        previous = 0
      }
      if(j+1<=this.state.data.length-1){
        next = this.state.data[j+1].Id
      }else{
        next = 0
      }
      if(this.state.data[j].Id === key){
        if(type==="next"){
          this.showDetails(next);
          break;
        }else{
          this.showDetails(previous);
          break;
        }
      }
    }
  }
  
  tabClicked(tabIndex){
    if (this._isMounted) {
      this.setState({activeTab: tabIndex,selectedKeyToShow:0})
    }
  }

  render() {
    const { translateKey, appLang } = this.props;
    const { sorting, loading, selectedKeyToShow } = this.state;
    let emptyHandler;
    let emptyAcceptanceHandler;
    let PreparedTableContent=[];
    let PreparedTableContentAcceptance = [];
    if (loading) {
      emptyHandler = <LoaderTable />
      emptyAcceptanceHandler = <LoaderTable />
    } else {
      if (this.props.invoicesData===null) {
        emptyHandler = <EmptyTableIcon RefreshMe={this.getInvoicesList} text={translateKey("no_data")} />
      } else {
        if (this.props.invoicesData.length === 0) {
          emptyHandler = <EmptyTableIcon RefreshMe={this.getInvoicesList} text={translateKey("no_data_to_show")} />
        }else{
          if(this.state.data.lengh===0){
            emptyHandler = <EmptyTableIcon RefreshMe={this.getInvoicesList} text={translateKey("no_data_to_show")} />
          }else{
            let next= 0;
            let previous= 0;
            for(let j=0; j<this.state.data.length; j++){
              if(j>0){
                previous = true;
              }else{
                previous = 0
              }
              if(j+1<=this.state.data.length-1){
                next = true
              }else{
                next = 0
              }    
              PreparedTableContent.push(
                <tr className={"table-row-wrapper cursor-pointer"+(selectedKeyToShow===this.state.data[j].Id? " active-row-invoice": "")} key={this.state.data[j].Id} onClick={()=> this.showDetails(this.state.data[j].Id)}>
                  {
                  sorting.isColumnVisibleByKey('invoices_clientName', appLang) &&
                    <td className="table-cell-wrapper">{this.state.data[j].ClientName}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_amount', appLang) &&
                    <td className="table-cell-wrapper">{ReqHelper.numberWithSpaces(this.state.data[j].invoice.amount) + ' ' + this.state.data[j].invoice.currency}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_approval', appLang) &&
                    <td className="table-cell-wrapper"><InvoiceApprovalHandler status={this.state.data[j].approval.Status} statusData={this.props.enumsInvoiceApprovalStatus}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_payment', appLang) &&
                    <td className="table-cell-wrapper"><InvoicePaymentHandler status={this.state.data[j].invoiceStatus} statusData={this.props.enumsInvoicePaymentStatus}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_number', appLang) &&
                    <td className="table-cell-wrapper">{this.state.data[j].invoiceNumber}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_periodForm', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={this.state.data[j].periodFrom}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_periodTo', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={this.state.data[j].periodTo}/></td>
                  }
                </tr>
              )
              if(selectedKeyToShow===this.state.data[j].Id){
                PreparedTableContent.push(
                  <tr key={Math.random().toString(36).substr(2, 9)}>
                    <td colSpan="7" className="no_padding invoice-details-border-wrapper">
                      <InvoiceTableDetails previousNextClick={(key, type) => this.previousNextClick(key, type)}  next={next} previous={previous} element={this.state.data[j]} paymentStatus={this.props.enumsInvoiceApprovalStatus} invoiceStatus={this.props.enumsInvoicePaymentStatus}/>
                    </td>
                  </tr>
                )
              }
            }
          }
        }
      }


      if (this.props.invoicesData===null) {
        emptyAcceptanceHandler = <EmptyTableIcon RefreshMe={this.getInvoicesList} text={translateKey("no_data")} />
      } else {
        if (this.props.invoicesData.length === 0) {
          emptyAcceptanceHandler = <EmptyTableIcon RefreshMe={this.getInvoicesList} text={translateKey("no_data_to_show")} />
        }else{
          if(this.state.acceptanceData.length===0){
            emptyAcceptanceHandler = <EmptyTableIcon RefreshMe={this.getInvoicesList} text={translateKey("no_data_to_show")} />
          }else{
            let nextA= 0;
            let previousA= 0;
            for(let i=0; i<this.state.acceptanceData.length; i++){
              if(i>0){
                previousA = true;
              }else{
                previousA = 0
              }
              if(i+1<=this.state.acceptanceData.length-1){
                nextA = true
              }else{
                nextA = 0
              }
              PreparedTableContentAcceptance.push(
                <tr className={"table-row-wrapper cursor-pointer"+(selectedKeyToShow===this.state.acceptanceData[i].Id? " active-row-invoice": "")} key={this.state.acceptanceData[i].Id} onClick={()=> this.showDetails(this.state.acceptanceData[i].Id)}>
                  {
                  sorting.isColumnVisibleByKey('invoices_clientName', appLang) &&
                    <td className="table-cell-wrapper">{this.state.acceptanceData[i].ClientName}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_amount', appLang) &&
                    <td className="table-cell-wrapper">{ReqHelper.numberWithSpaces(this.state.acceptanceData[i].invoice.amount) + ' ' + this.state.acceptanceData[i].invoice.currency}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_approval', appLang) &&
                    <td className="table-cell-wrapper"><InvoiceApprovalHandler status={this.state.acceptanceData[i].approval.Status} statusData={this.props.enumsInvoiceApprovalStatus}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_number', appLang) &&
                    <td className="table-cell-wrapper">{this.state.acceptanceData[i].invoiceNumber}</td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_periodForm', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={this.state.acceptanceData[i].periodFrom}/></td>
                  }
                  {
                  sorting.isColumnVisibleByKey('invoices_periodTo', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={this.state.acceptanceData[i].periodTo}/></td>
                  }
                </tr>
              )
              if(selectedKeyToShow===this.state.acceptanceData[i].Id){
                PreparedTableContentAcceptance.push(
                  <tr key={Math.random().toString(36).substr(2, 9)}>
                    <td colSpan="7" className="no_padding invoice-details-border-wrapper">
                      <InvoiceTableDetails previousNextClick={(key, type) => this.previousNextClickAcceptance(key, type)}  next={nextA} previous={previousA} rejectInvoiceStatus={()=>this.filterOrDataChange()} acceptInvoiceStatus={()=>this.filterOrDataChange()} element={this.state.acceptanceData[i]} paymentStatus={this.props.enumsInvoiceApprovalStatus} invoiceStatus={this.props.enumsInvoicePaymentStatus}/>
                    </td>
                  </tr>
                )
              }
            }
          }
        }
      }
    }
    let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
    return (
      <div className="mainContentWrapper" onClick={() => this.props.tookAction()}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties onShowMeData={()=>this.filterOrDataChange()} prepareColumnsData={this.prepareColumnsData} onSearch={(val) => this.propsSearchTable(val)} options={[ TableEnums.SEARCH, TableEnums.FILTER, TableEnums.ACTIVECOLUMNS]} filterData={this.state.filterData} sortData={sorting.sortingColumns} />
        <div className="mainContent">
          <SideBar />
          <LoaderModal show={this.state.showModalLoader}/>
          <div className="listDataWrapper transparentClass position-relative">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className={"nav-item nav-link nav-link-invoices "+(this.state.activeTab==="0"?"active":"")} id="nav-acceptance-tab" data-toggle="tab" href="#nav-acceptance" role="tab" aria-controls="nav-acceptance" aria-selected={this.state.activeTab==="0"?"true":"false"} onClick={()=> this.tabClicked("0")}>{translateKey("invoices_for_acceptance")}</a>
                <a className={"nav-item nav-link nav-link-invoices "+(this.state.activeTab==="1"?"active":"")} id="nav-all-tab" data-toggle="tab" href="#nav-all" role="tab" aria-controls="nav-all" aria-selected={this.state.activeTab==="1"?"true":"false"} onClick={()=> this.tabClicked("1")}>{translateKey("all_invoices")}</a>
              </div>
            </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className={"tab-pane fade "+(this.state.activeTab==="0"?"show active":"")} id="nav-acceptance" role="tabpanel" aria-labelledby="nav-acceptance-tab">
                <div className="listData tab-list-data">
                  <table className="table">
                    <thead>
                      <tr>
                        {
                          sorting.sortingColumns.map(column => {
                            if(sorting.isColumnVisibleByKey(column.keyName, appLang) && column.keyName!=='invoices_number'){
                              return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                            }else{
                              return null
                            }
                          }).filter(element => element !==null )
                        }
                      </tr>
                    </thead>
                    <tbody>{PreparedTableContentAcceptance}</tbody>
                  </table>
                  {emptyAcceptanceHandler}
                  {alertComponent}
                  <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={loading} paginationObj={this.state.pagingItemAcceptance} totalItems={this.state.pagingItemAcceptance.totalItems}/>
                </div>
              </div>
              <div className={"tab-pane fade "+(this.state.activeTab==="1"?"show active":"")} id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
                <div className="listData tab-list-data">
                  <table className="table">
                    <thead>
                      <tr>
                        {
                          sorting.sortingColumns.map(column => {
                            if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                              return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                            }else{
                              return null
                            }
                          }).filter(element => element !==null )
                        }
                      </tr>
                    </thead>
                    <tbody>{PreparedTableContent}</tbody>
                  </table>
                  {emptyHandler}
                  {alertComponent}
                  <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    userIdColumn: state.auth.userId,
    invoicesData: state.database.invoices,
    invoicesTimestamp: state.database.invoicesTimeStamp,
    enumsInvoicePaymentStatus: state.enums.invoicePayment,
    enumsInvoiceApprovalStatus: state.enums.invoiceApproval,
    patientLeadData: state.database.patientLead,
    patientClientData: state.database.patientClient,
    activeColumns: state.activeColumns.data,
    activeColumnsTimeStamp: state.activeColumns.columnsTimeStamp,
    savedFilters: state.filters.data.invoices
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setData: (data) => dispatch(setInvoicesData(data)),
  pendingRequest: () => dispatch(pendingInvoicesData()),
  clearData: () => dispatch(removeInvoicesData()),
  setColumns: (data) => dispatch(setColumnsData(data)),
  addNewColumnTable: (data) => dispatch(addColumnData(data)),
  updateColumnTypeData: (type,data) => dispatch(updateColumTypeData(type, data)),
  removeColumns: () => dispatch(removeColumnsData()),
  setFilters: (type, data) => dispatch(setFiltersData(type, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesMainPage));