export const LeadSourceExceptionsList = [
    'b23f29a0-c87f-ea11-80d5-00155d140169',
    'bc4d40a2-107f-ea11-80d5-00155d140169',
    '54a65610-af57-ea11-80d6-00155d2d0e4b',
    '96a0eb5a-d136-ea11-80d5-00155d2d0c68',
    '3453c0d8-f505-ea11-80d8-00155d13f32d',
    '685d2b4a-91ee-e911-80d4-00155d140169',
    '2694bd41-91ee-e911-80d4-00155d140169',
    '8eac3936-91ee-e911-80d4-00155d140169',
    '5136c32b-91ee-e911-80d4-00155d140169',
    'ab197820-91ee-e911-80d4-00155d140169',
    '6ddfcc99-1cad-e911-80d2-00155d2d0e3e',
    'fee75b33-a898-e911-80d1-00155d140163',
    '3e8c0e17-a898-e911-80d1-00155d140163',
    'b2f2730b-a898-e911-80d1-00155d140163',
    '0ae96e05-a898-e911-80d1-00155d140163',
    '25e86bfd-a798-e911-80d1-00155d140163',
    'd5a952f7-a798-e911-80d1-00155d140163',
    '34c009e9-a798-e911-80d1-00155d140163',
    'e11d14dd-a798-e911-80d1-00155d140163',
    'e01d14dd-a798-e911-80d1-00155d140163',
    '2769acce-a798-e911-80d1-00155d140163',
    'e080e8c5-a798-e911-80d1-00155d140163',
    'ff4b50a4-a798-e911-80d1-00155d140163',
    '0f696795-a798-e911-80d1-00155d140163',
    'f9e0d487-a798-e911-80d1-00155d140163',
    'ccd4c374-a798-e911-80d1-00155d140163',
    'b716ed6d-a798-e911-80d1-00155d140163',
    '522f5161-a798-e911-80d1-00155d140163',
    '512f5161-a798-e911-80d1-00155d140163',
    '67d43453-a798-e911-80d1-00155d140163',
    'f1226248-a798-e911-80d1-00155d140163',
    'b7021442-a798-e911-80d1-00155d140163',
    'a01c3e38-a798-e911-80d1-00155d140163',
    'ec506f28-a798-e911-80d1-00155d140163',
    'cd3c5d65-a598-e911-80d1-00155d140163',
    '402030e9-a298-e911-80d1-00155d140163'
]
