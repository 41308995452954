import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";

class TableSortingComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            sortData: props.sortData,
            rerender: Date.now()
        };
    }

    updateElement(element){
        if(element.enabled===false){
            element.enabled = true;
            this.setState({
                rerender: Date.now()
            })
        }else{
            if(this.atLeastOneActive()){
                element.enabled = false;
                this.setState({
                    rerender: Date.now()
                })
            }
        }
    }

    atLeastOneActive(){
        let atLeast = this.props.sortData.filter((element) =>{
            return element.enabled===true;
        })
        if(atLeast.length>1){
            return true;
        }else{
            return false;
        }
    }

    prepareMeFinalData (){
        return this.props.sortData.filter(element=>{
            if(element.blocked!==true){
                if(element.languageRestrictions!==null){
                    if(element.languageRestrictions===this.props.appLang){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return true;
                }
            }else{
                return false;
            }
        });
    }

    render() {
        const { translateKey } = this.props;
        let content = '';
        if(this.props.sortData.length>0){
            let finalSortData = this.prepareMeFinalData();
            if(finalSortData.length>0){
                content = (
                    <div className="filer-category-wrapper">
                        <div className="filter-modal-option">
                            <div className="filter-modal-option-text">{translateKey("select-columns-to-show")}</div>
                        </div>
                        <div className="filter-modal-option-container">
                            {
                                finalSortData.map((element, iter) =>{
                                    return <button key={'column-item'+iter} type="button" onClick={()=>this.updateElement(element)} className={"btn btn-light btn-sm option" + (element.enabled===true? ' active-filter': '')}>{translateKey(element.name)}</button>
                                })
                            }
                        </div>
                    </div>
                )
            }
        }
        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage};};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableSortingComponent));