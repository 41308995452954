import * as React from "react";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import { translateKey, authSetActionTimestamp, setClientsData, pendingClientsData, removeClientsData, setPatientClientData, 
  pendingPatientClientData, removePatientClientData, getPatientClient } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, LoaderModal } from "../../_components/index";
import { ClientTable, Alert } from "../../_constance/classes/index";
import { IconsMap } from "../../_helpers/index";
import { Section0, Section1, Section2, Section3, Section4, Section5, Section6, Section7, Section8, Section9, Section10, Section11, 
  Section12, Section13, Section14, Section15, Section16, Section17, Section18, Section19} from "../../_components/form-bb-sections/index";
import ContractComponent from "../../_components/form-bb-components/contract-component/ContractComponent";
import OfferEnquiryComponent from "../../_components/form-bb-components/offer-enquiry-component/OfferEnquiryComponent";
import axiosAuth from "../../_services/config/axios-auth";
import { ResponseStatus } from '../../_helpers/response_status'
import { getUserProducts, getRegionsFP } from "../../_redux/actions/bb-form-services";
import BBFormService from '../../_services/bbform';
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';
import BBForm from '../../_forms/BBForm/BBForm';

class MainClientBBWrapper extends React.Component {
  _isMounted = false;
  myTimout;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      editedForm: false,
      editedContract: false,
      editedQuatation: false,
      showContract: false,
      showOfferEnquiry: false,
      currentSection: 0,
      loader: null,
      modalLoader: true,
      savingData: false,
      errorsMessage: null,
      errorsType: "danger",
      LKId: 0,
      patientId: 0,
      products: [],
      regionsFP: [],
      formbbStatus: "0",
      errorComponent: Alert,
      type: FormTypeToShow.FORM,
      confirmConsents: false,
      confirmNewEnquiry: false,
      toPage: null,
      isConsentsEditable: true,
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ],
      clientRetries: 0,
      showValidationErrors: false,
      form: new BBForm(),
      rerender: Date.now(),
      newOne: false,
      leadPatients: [],
      wholeResponse: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { match: { params } } = this.props;
    let type = params.type;
    if(type!==FormTypeToShow.CONTRACT && type!==FormTypeToShow.ENQUIRY){
      type=FormTypeToShow.FORM
    }
    this.setState({LKId: params.id,patientId: params.patientId, type: type})
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {this.getDataBasicClient();}, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
    clearTimeout(this.patientTimeout);
  };

  giveMeSpecialToGoSection(){
    const { currentSection, form } = this.state;
    let toPageNumber = parseInt(currentSection) - 1;
    if(toPageNumber === Sections.PATIENT){
      const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
      if(isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8){
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }else{
          toPageNumber =  Sections.CONTACT_PERSON
        }
      }
    }else{
      if(toPageNumber === Sections.CONTACT_PERSON){
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }
      }
    }
    return toPageNumber;
  }

  changePage = async number => {
    if(this._isMounted){
      this.setState({
        currentSection: number,
        savingData: false,
        showValidationErrors: false,
        confirmConsents:false
      });
    }
  }

  previousPageClick = async () => {
    if(this._isMounted){
      this.setState({currentSection: this.giveMeSpecialToGoSection()});
    }
  }

  nextPageClick = async () => {
    const section = this.state.form.getSectionByOrder(this.state.currentSection);
    if(this._isMounted){
      this.setState({currentSection: section.nextSection});
    }
  }

  gotBasicDataPrepareWhichPageToShow(){
    switch(this.state.type){
      case FormTypeToShow.ENQUIRY:  this.prepareMeEnquiryOrContract(FormTypeToShow.ENQUIRY);
                                    break;
      case FormTypeToShow.CONTRACT: this.prepareMeEnquiryOrContract(FormTypeToShow.CONTRACT);
                                    break;
      default:  this.prepareMeForm();
    }
  }

  prepareMeEnquiryOrContract(type){
    const { match: { params } } = this.props;
    const { form } = this.state;
    const listPatient = this.getMeInitLeadPatientsList();
    if(listPatient.length>0){
      if(this._isMounted){
        this.setState({
          showValidationErrors: false,
          loader: false,
          modalLoader: false,
          savingData: false,
          showContract: false,
          showOfferEnquiry: false
        })
      }
      BBFormService.retrievePatient(form.getRetiveDataALl(params.id, listPatient)).then(response => {
        const { data } = response;
        data.forEach(entity => form.setFields(entity.fields));
        if(this._isMounted){
          this.setState({wholeResponse: data});
        }
      }).then(()=>{
        if(this._isMounted){
          this.setState({rerender: Date.now()});
        }
      })
      if(type === FormTypeToShow.ENQUIRY){
        this.showMeQuatation(false);
      }else{
        this.showMeContract(false);
      }
    }else{
      if(this._isMounted){
        this.setState({
          showValidationErrors: false,
          loader: false,
          modalLoader: false,
          savingData: false,
          showContract: false,
          showOfferEnquiry: false
        })
      }
      BBFormService.retrievePatient(form.getRetiveDataALl(params.id, [])).then(response => {
        const { data } = response;
        data.forEach(entity => form.setFields(entity.fields));
      }).then(()=>{
        if(this._isMounted){
          this.setState({rerender: Date.now()});
        }
      })
    }
  }

  prepareMeForm(){
    const { match: { params } } = this.props;
    const { form } = this.state;
    let patientIdList = [];
    if(params.patientId!==0 && params.patientId!=="0"){
      patientIdList.push(params.patientId);
    }
    BBFormService.retrievePatient(form.getRetiveDataALl(params.id, patientIdList)).then(response => {
      const { data } = response;
      data.forEach(entity => form.setFields(entity.fields));
      if(params.patientId==="0" || params.patientId===0){
        if(this._isMounted){
          this.setState({
            showValidationErrors: false,
            currentSection: 0,
            savingData: false,
            modalLoader: false,
            showContract: false,
            showOfferEnquiry: false
          })
        }
      }else{
        if(this._isMounted){
          this.setState({
            showValidationErrors: false,
            currentSection: 0,
            savingData: false,
            modalLoader: false,
            showContract: false,
            showOfferEnquiry: false
          })
        }
      }
    }).then(()=>{
      if(this._isMounted){
        this.setState({rerender: Date.now()});
      }
    })
  }

  getClientDataBasic() {
    const { match: { params } } = this.props;
    for(let i=0;i<this.props.clientsData.length; i++){
      if(this.props.clientsData[i].id===params.id){
        if(this._isMounted){
          this.setState({
            LKId: params.id,
            breadCrumbs:[...this.state.breadCrumbs, {
              link: "clientDetails/"+params.id,
              key: "",
              name: this.props.clientsData[i].client
            },
            {
              link: "",
              key: "",
              name: "BB"
            }],
          })
        }
        this.gotBasicDataPrepareWhichPageToShow();
        break;
      }
    }
  }

  getInitialDataTime(){
    if(this.props.clientsData.length>0){
      clearTimeout(this.myTimeout);
      this.getClientDataBasic();
    }else{
      if(this.state.clientRetries<10){
        this.getClientsList();
        if(this._isMounted){
          this.setState({clientRetries: this.state.clientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTime()}, 500);
      }else{
        if(this._isMounted){
          this.setState({clientRetries: 0})
        }
      }
    }
  }

  getClientsList = () => {
    if(this.props.clientsState !== ResponseStatus.PENDING){
      this.props.pendingRequestClient();
      axiosAuth.get("clients/list/0")
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{
            for(let i=0; i<this.props.clientsPatientData.length; i++){
              if(this.props.clientsPatientData[i].id===element.id){
                element.locationP = this.props.clientsPatientData[i].patientAddress ? this.props.clientsPatientData[i].patientAddress.city:'';
                element.patientName = this.props.clientsPatientData[i].name;
                element.patient = this.props.clientsPatientData[i]
              }
            }
            return new ClientTable(element);}
          );
          this.props.setClientData(mapedRes);
        })
        .catch((error) => {this.props.clearDataClient();});
    }
  }

  getPatientList(){
    this.props.pendingPatientData();
    axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getInitialDataTime();
      })
      .catch((error) => {
        this.props.clearPatientData();
        this.getInitialDataTime();
      });
  }

  getDataBasicClient() {
    if(this._isMounted){
      this.setState({loadingBasics: true});
    }
    if (this.props.clientsPatientData.length === 0) {
      if (this.props.clientsPatientData.length !== 0 && this.props.clientsPatientData !== undefined) {
        this.getInitialDataTime();
      } else {
        this.getPatientList();
      }
    } else {
      this.getInitialDataTime();
    }
  }

  getDataBasic(isFormDisabled){
    const { translateKey } = this.props;
    const { form, rerender, showValidationErrors } = this.state;
    let content = (
      <LoaderTable />
    );

    if(!this.state.loader){
      switch(this.state.currentSection){
        case 0:   content = (
                    <div className="section-class-container">
                      <Section0 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_client_data_p_0")} subsectionNumber="1" sectionNumber="1" form={form} rerender={rerender} disabled={isFormDisabled}></Section0>
                    </div>
                  );
                  break;
        case 1:   content = (
                    <div className="section-class-container">
                      <Section1 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_contact_data_p_1")} subsectionNumber="1.1" sectionNumber="1" form={form} rerender={rerender} disabled={isFormDisabled}></Section1>
                    </div>
                  );
                  break;
        case 2:   content = (
                    <div className="section-class-container">
                      <Section2 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_lead_data_p_2")} subsectionNumber="1.2" sectionNumber="1" form={form} rerender={rerender} disabled={isFormDisabled}></Section2>
                    </div>
                  );
                  break;
        case 3:   content = (
                    <div className="section-class-container">
                      <Section3 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_pflegegrad_data_p_3")} subsectionNumber="2" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section3>
                    </div>
                  );
                  break;
        case 4:   content = (
                    <div className="section-class-container">
                      <Section4 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_ills_data_p_4")} subsectionNumber="2.1" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section4>
                    </div>
                  );
                  break;
        case 5:   content = (
                    <div className="section-class-container">
                      <Section5 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_comnunication_data_p_5")} subsectionNumber="2.2" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section5>
                    </div>
                  );
                  break;
        case 6:   content = (
                    <div className="section-class-container">
                      <Section6 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_orientation_data_p_6")} subsectionNumber="2.3" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section6>
                    </div>
                  );
                  break;
        case 7:   content = (
                    <div className="section-class-container">
                      <Section7 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_characteristic_data_p_7")} subsectionNumber="2.4" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section7>
                    </div>
                  );
                  break;
        case 8:   content = (
                    <div className="section-class-container">
                      <Section8 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_movement_data_p_8")} subsectionNumber="2.5" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section8>
                    </div>
                  );
                  break;
        case 9:   content = (
                    <div className="section-class-container">
                      <Section9 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_defecation_data_p_9")} subsectionNumber="2.6" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section9>
                    </div>
                  );
                  break;
        case 10:   content = (
                    <div className="section-class-container">
                        <Section10 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_hugiene_data_p_10")} subsectionNumber="2.7" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section10>
                    </div>
                  );
                  break;
        case 11:   content = (
                    <div className="section-class-container">
                      <Section11 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_foods_data_p_11")} subsectionNumber="2.8" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section11>
                    </div>
                  );
                  break;
        case 12:   content = (
                    <div className="section-class-container">
                      <Section12 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_clothes_data_p_12")} subsectionNumber="2.9" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section12>
                    </div>
                  );
                  break;
        case 13:   content = (
                    <div className="section-class-container">
                      <Section13 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_sleep_data_p_13")} subsectionNumber="2.10" sectionNumber="2" form={form} rerender={rerender} disabled={isFormDisabled}></Section13>
                    </div>
                  );
                  break;
        case 14:   content = (
                    <div className="section-class-container">
                      <Section14 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_pflegedienst_data_p_14")} subsectionNumber="3" sectionNumber="3" form={form} rerender={rerender} disabled={isFormDisabled}></Section14>
                    </div>
                  );
                  break;
        case 15:   content = (
                    <div className="section-class-container">
                      <Section15 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_therapy_data_p_15")} subsectionNumber="4" sectionNumber="4" form={form} rerender={rerender} disabled={isFormDisabled}></Section15>
                    </div>
                  );
                  break;
        case 16:   content = (
                    <div className="section-class-container">
                      <Section16 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_expectations_data_p_16")} subsectionNumber="5" sectionNumber="5" form={form} rerender={rerender} disabled={isFormDisabled}></Section16>
                    </div>
                  );
                  break;
        case 17:   content = (
                    <div className="section-class-container">
                      <Section17 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_actions_data_p_17")} subsectionNumber="5.1" sectionNumber="5" form={form} rerender={rerender} disabled={isFormDisabled}></Section17>
                    </div>
                  );
                  break;
        case 18:   content = (
                    <div className="section-class-container">
                      <Section18 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_place_data_p_18")} subsectionNumber="5.2" sectionNumber="5" form={form} rerender={rerender} disabled={isFormDisabled}></Section18>
                    </div>
                  );
                  break;
        case 19:   content = (
                    <div className="section-class-container">
                      <Section19 showValidationErrors={showValidationErrors} subsectionName={translateKey("consents")} subsectionNumber="6" sectionNumber="6" form={form} rerender={rerender} disabled={isFormDisabled || !this.state.isConsentsEditable}></Section19>
                    </div>
                  );
                  break;
        default:  content = (
                    <div className="section-class-container">
                      <Section0 showValidationErrors={showValidationErrors} subsectionName={translateKey("bb_client_data_p_0")} subsectionNumber="1" sectionNumber="1" form={form} rerender={rerender} disabled={isFormDisabled}></Section0>
                    </div>
                  );
      }
    }
    return content;
  }

  getMeInitLeadPatientsList(){
    const { match: { params }, clientsPatientData } = this.props;
    let pushedElements = [];
    let leadPatientsList = clientsPatientData.filter(element => {
        if(element.clientId === params.id){
            if(pushedElements.indexOf(element.id) > -1){
                return false;
            }else{
                pushedElements.push(element.id);
                return element
            }
        }else{
          return false;
        }
    })
    if(this._isMounted){
      this.setState({leadPatients: leadPatientsList})
    }
    return pushedElements;
  }

  showMeQuatation =(isNewOne = true) => {
    if(this._isMounted){
      this.setState({modalLoader: true});
    }
    this.props.getProducts().then((data)=>{
      if(this._isMounted){
        if(data.length>1){
          data.sort((a,b)=>{
            const nameA = a.ProductName;
            const nameB = b.ProductName;
            if (nameA === null || nameA === undefined ) {return -1;}
            else if (nameB === null || nameB === undefined ) {return 1;}
            else if (nameA === nameB) {return 0;}
            if (nameA.localeCompare(nameB)===-1){ return -1; }
            else{ return 1; }
          })
        }
        this.setState({
          modalLoader: false,
          products: data,
        });
      }
    })
    .catch((error)=>{
      console.log(error);
    })
    this.props.getRegions().then((data)=>{
      if(this._isMounted){
        this.setState({
          modalLoader: false,
          regionsFP: data
        });
      }
    })
    .catch((error)=>{
      console.log(error);
    })
    if(this._isMounted){
      this.setState({
        showContract: false,
        showOfferEnquiry: true,
        newOne: false
      })
    }
  }

  showMeContract =(isNewOne = false) => {
    if(this._isMounted){
      this.setState({modalLoader: true})
    }
    this.props.getProducts().then((data)=>{
      if(this._isMounted){
        if(data.length>1){
          data.sort((a,b)=>{
            const nameA = a.ProductName;
            const nameB = b.ProductName;
            if (nameA === null || nameA === undefined ) {return -1;}
            else if (nameB === null || nameB === undefined ) {return 1;}
            else if (nameA === nameB) {return 0;}
            if (nameA.localeCompare(nameB)===-1){ return -1; }
            else{ return 1; }
          })
        }
        this.setState({
          modalLoader: false,
          products: data
        });
      }
    })
    .catch((error)=>{
      console.log(error);
    })
    this.props.getRegions().then((data)=>{
      if(this._isMounted){
        this.setState({
          modalLoader: false,
          regionsFP: data
        });
      }
    })
    .catch((error)=>{
      console.log(error);
    })
    if(this._isMounted){
      this.setState({
        showContract: true,
        showOfferEnquiry: false,
        newOne: false
      })
    }
  }

  showAlertMessage = (type, message) =>{
    if(this._isMounted){
      this.setState(prevState => {
        return {
            ...prevState,
            errorComponent: {
              ...prevState.errorComponent,
              show: true,
              type: type,
              message: message
            }
        }
      })
    }
    this.clearAlertComponent(true);
  }

  showSaveModal = () =>{
    if(this._isMounted){
      this.setState({savingData: !this.state.savingData})
    }
  }

  hideSaveModal = () =>{
    if(this._isMounted){
      this.setState({savingData: false})
    }
  }

  render() {
    const { form, showContract, showOfferEnquiry, currentSection } = this.state;
    const { translateKey } = this.props;

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar showSectionValidation = {false} changeSection={(number)=>this.changePage(number)} form={showContract===true || showOfferEnquiry===true?'':form} activeSectionNumber={currentSection}/>
          <LoaderModal show={this.state.savingData || this.state.modalLoader} text={this.state.savingData?translateKey("saving_with_dots"):''}/>
          <div className="listDataWrapper">
            {showContract === true &&
              <ContractComponent wholeResponse={this.state.wholeResponse} mainType={"CLIENT"} showMessage={this.showAlertMessage} hideSaveModal={this.hideSaveModal} showSaveModal={this.showSaveModal} showButtons = {false} setNewLeadStatus = {()=>{}} bbStatus={0} newOne={false} products={this.state.products} regionsFP={this.state.regionsFP} disabled={true} bbform={form}/>
            }
            {showOfferEnquiry === true &&
              <OfferEnquiryComponent wholeResponse={this.state.wholeResponse} mainType={"CLIENT"} showMessage={this.showAlertMessage} hideSaveModal={this.hideSaveModal} showSaveModal={this.showSaveModal} showButtons = {false} setNewLeadStatus = {()=>{}} bbStatus={0} newOne={false} type={this.state.type} convertToContrat={this.showMeContract} products={this.state.products} regionsFP={this.state.regionsFP} disabled={true} bbform={form} />
            }
            {showContract === false && showOfferEnquiry === false &&
              <React.Fragment>
                {this.getDataBasic(true)}
                <div className={"btn-toolbar-fixed"+(currentSection===0 || currentSection===19 ?' btn-toolbar-fixed-onlyone':'')}>
                  <div className="previous-next-wrapper">
                    {currentSection!==0 &&
                      <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.previousPageClick}>{translateKey("previous")}</button>
                    }
                    {currentSection!==19 &&
                      <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.nextPageClick}>{translateKey("next")}</button>
                    }
                  </div>
                </div>
              </React.Fragment>
            }
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    clientsData: state.database.clients,
    clientsState: state.database.clientsState,
    clientsPatientData: state.database.patientClient,
    clientsPatientDataStatus: state.database.patientClientState,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  setClientData: (data) => dispatch(setClientsData(data)),
  pendingRequestClient: () => dispatch(pendingClientsData()),
  clearDataClient: () => dispatch(removeClientsData()),
  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainClientBBWrapper));