import { ReqHelper } from "../../_helpers/index";
export class LeadTable {
    constructor(element){
        let lead = '';
        if(element.client){
            if(element.client.fullname){
                lead = element.client.fullname;
            }else{
                if(element.client.lastName){
                    if(element.client.firstName){
                        lead = element.client.lastName + ' ' + element.client.firstName
                    }else{
                        lead = element.client.lastName
                    }
                }else{
                    if(element.client.firstName){
                        lead = element.client.firstName;
                    }
                }
            }
        }
        let patient = element.patient !== null ? element.patient.fullname===""? element.patient.fullName: element.patient.fullname: '';
        let location = element.client !== null ? element.client.address !== null ? element.client.address.city : '' : '';
        let phone = element.client !== null ? element.client.cellPhone != null ? element.client.cellPhone : element.client.phone != null ? element.client.phone : '' : '';
        let patientPhone = element.patient !== null ? element.patient.cellPhone != null ? element.patient.cellPhone : element.patient.phone != null ? element.patient.phone : '' : '';
        let email = element.client !== null ? element.client.email : '';
        let converted_date = ReqHelper.dateConversion(element.dateOfEntry);
        this.lead = lead;
        this.leadId = element.leadId;
        this.client = element.client;
        this.dateOfEntry = element.dateOfEntry;
        this.source = element.source !== null ? element.source.toUpperCase() : '';
        this.sourceCountry = element.sourceCountry;
        this.status = element.status;
        this.patient = patient;
        this.patientPhone = patientPhone;
        this.patientObj = element.patient;
        this.converted_date = converted_date;
        this.location = location;
        this.phone = phone;
        this.email = email;
        this.noteFp = element.noteFp;
        this.payer = element.payer;
        this.typeText = element.typeText;
        this.dateFirstInterview = element.dateFirstInterview;
        this.ViewedInApplication = element.ViewedInApplication;
        this.leadExclusiveForPm24 = element.leadExclusiveForPm24;
        this.marketingId = element.marketingId;
        this.rating = element.rating;
        this.reasonForRejection = element.reasonForRejection;
        this.reasonsForRejectionDetails = element.reasonsForRejectionDetails;
        this.leadType = element.leadType;
        this.referralContactId = element.referralContactId;
    }
};