import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp, addNewMultiplierData, needsSaveData, doesNotNeedsSave} from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, AlertMessage, MultiplierComponent , LeadCancelSaveComponent, ConsentListComponent } from "../../_components/index";
import { Alert} from "../../_constance/classes/index";
import { IconsMap } from "../../_helpers/index";
import { multiplierSave } from "../../_redux/actions/auth";
import { MockMultiplier } from "../../_constance/classes/mockMultiplier/mockMultiplier";
import { saveDynamicFields } from '../../_services/user_service';
import { baseData } from '../../_constance/base_data';
import * as Entity from '../../_forms/BBForm/values/entity';

class NewMultiplierPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  autoSaveTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      multiplierData: {...MockMultiplier},
      savingMultiplier: false,
      loadingMultiplier: true,
      consentsDe8: false,
      errorsMessage: null,
      errorsType: "danger",
      errorComponent: Alert,
      breadCrumbs:[
        {
          link: "multipliers",
          key: "multipliers",
          name: null
        },
        {
          link: "newmultiplier",
          key: "new_multiplier",
          name: null
        }
      ]
    };
  }

  componentDidUpdate() {
    if (this.props.needsSave) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }
  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState({
        loadingMultiplier: false,
        multiplierData: JSON.parse(JSON.stringify(MockMultiplier))
      })
    }
    this.autoSaveTimeOut = setInterval(() => {
      this.checkIfShouldWeSave();
    }, 15000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              multiplierData: {...MockMultiplier}
          }
        });
      }
    }
    clearInterval(this.autoSaveTimeOut);
    clearTimeout(this.myTimeout);
  };

  checkIfShouldWeSave = () =>{
    const currentTime = new Date().getTime();
    if(this.props.lastActionTime - currentTime <= 30000){
      this.saveChange(true);
    }
  }

  cancelChange(){
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            multiplierData: MockMultiplier
        }
      });
    }
  }

  tryToSave(id){
    const {consentsDe8} = this.state;
    let fields = {};
    fields = {"is_zg_de_8": consentsDe8};
    let dataToSend = [
      {
          "Id": id,
          "Entity": Entity.CONTACT,
          "Fields": fields
      }
    ];
    saveDynamicFields(dataToSend).then(response => {
      this.props.doesNotNeedsSave();
    })
    .catch((err) => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              savingMultiplier: false,
              errorsMessage: err.message,
              errorsType: "danger"
          }
        });
      }
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            errorsMessage: null,
            errorsType: "danger"
          }) 
        }
      }, 3000);
    });
  }

  saveChange(shouldLogout=false){
    const { history } = this.props;
    this.props.tookAction();
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState({savingMultiplier: true})
      }
      this.props.saveMultiplierData(this.state.multiplierData, this.props.appLang)
      .then((res)=>{
        this.props.doesNotNeedsSave();
        this.tryToSave(res);
        if(shouldLogout){
          history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState({
              savingMultiplier: false,
              errorsMessage: "multiplier_successfully_saved",
              errorsType: "success",
            })      
          }
          let dataToSend = [
            {
                "Id": res,
                "Entity": Entity.CONTACT,
                "Fields": {
                  'jobtitle': this.state.multiplierData.jobtitle
                }
            }
          ];
          saveDynamicFields(dataToSend);
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({
                errorsMessage: null,
                multiplierData: MockMultiplier,
                errorsType: "danger"
              })
            }
            history.push(`${baseData.subFolders}multipliers`);
          }, 3000);
          this.props.addMultiplierData(res,this.state.multiplierData);
        }
      })
      .catch((err)=>{
        if(shouldLogout){
          history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                  ...prevState,
                  savingMultiplier: false,
                  errorsMessage: err.message,
                  errorsType: "danger"
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({
                errorsMessage: null,
                errorsType: "danger"
              }) 
            }
          }, 3000);
        }
      })
    }
  }

  basicEdit = () =>{this.props.needsSaveData();}

  setConsent = (value) =>{
    if(value !== null ){
      if (this._isMounted) {
        this.setState({consentsDe8: value})
      }
    }else{
      if (this._isMounted) {
        this.setState({consentsDe8: false})
      }
    }
  }

  render() {
    const {translateKey, needsSave} = this.props;
    const { match: { params } } = this.props;
    let multiplierEmptyHandler
    if (this.state.loadingMultiplier) {
      multiplierEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      let MultiplierRow =(
        <MultiplierComponent new={true} editedData={this.basicEdit} edited={needsSave} key="newMultiplierComponenet01" element={this.state.multiplierData}/>
      );
      let Consents = (
        <ConsentListComponent 
          isEditable={false}  
          editedData={this.basicEdit}
          key="consents-lead-03" 
          id={params.id} 
          setConsents={this.setConsent}
          multiplierConsent={true}
        />
      );
      let CancelSave = (
        <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="cancelSaveNewMultiplier01" edited={needsSave} savingData={this.state.savingMultiplier}/>
      );
      let SaveAlert;
      if(this.state.errorsMessage!==null){
        SaveAlert = (
          <AlertMessage centeredAlert={true} show={this.state.errorsMessage!==null} key="alertNewMultiplier01" type={this.state.errorsType} message={translateKey(this.state.errorsMessage)}/>
        )
      }
      multiplierEmptyHandler = [
        MultiplierRow,
        Consents,
        SaveAlert,
        CancelSave
      ]
    }

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active nav-link-invoices" id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                {translateKey("new_multiplier")}
              </a>
            </div>
          </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {multiplierEmptyHandler}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={needsSave}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    needsSave: state.refreshTokenStatus.needsDataSave,
    lastActionTime: state.auth.actionTimestamp
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  saveMultiplierData: (data, lang) => dispatch(multiplierSave(data, lang)),
  addMultiplierData: (multiId, data) => dispatch(addNewMultiplierData(multiId, data)),
  needsSaveData: () => dispatch(needsSaveData()),
  doesNotNeedsSave: () => dispatch(doesNotNeedsSave())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewMultiplierPage));