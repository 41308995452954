import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import {Toggle} from "react-toggle-component"

class LeadPatientInformationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            informationHealth: []
        };
    }

    componentDidMount() {this.prepareHealthSort()} 

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    toggleThisElement(element){
        this.props.element.HealthStateListResource[element.Schema]? this.props.element.HealthStateListResource[element.Schema]=false: this.props.element.HealthStateListResource[element.Schema]=true;
        element.Checked? element.Checked = false: element.Checked = true;
        this.props.editedData();
    }

    prepareMeToggleData(){
        return this.state.informationHealth.map(element => (
            <div className="form-group row flexAlignCenter no-margin-row" key={element.Schema}>
                <label className="col-sm-4 col-form-label form-label-basic break-word">{element.Name}</label>
                <div className="col-sm-8 text-center">
                    <Toggle name={"toggle-"+element.Schema} checked={element.Checked} onToggle={()=> this.toggleThisElement(element)}/>
                </div>
            </div>
        ));
    }

    prepareHealthSort(){
        let newArray = Object.keys(this.props.element.HealthStateListResource).map(e => {
            return {
                Schema: e,
                Name: this.getMeHealthName(e),
                Checked: this.props.element.HealthStateListResource[e]
            }
        });
        newArray.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
        this.setState({informationHealth: newArray});
    }

    getMeHealthName(key){
        for(let i=0; i < this.props.healthState.length; i++){
            if(this.props.healthState[i].SchemaName === key){
                return this.props.healthState[i].Name
            }
        }
        return key;
    }

    render() {
        let leadPatientHealth;
        if(this.state.isOpen){
            leadPatientHealth = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        {this.prepareMeToggleData()}
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{this.props.translateKey("information")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                    </div>
                </div>
                {leadPatientHealth}
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    i18: state.lang.i18data,
    healthState: state.enums.healthState
};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientInformationComponent));