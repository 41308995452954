import { SET_APP_LANGUAGE, REMOVE_APP_LANGUAGE } from './action-types';
export const setAppLang = (lang) => {
  return {
      type: SET_APP_LANGUAGE,
      appLanguage: lang
  };
};

export const setStoreLang = (lang) => {
  return dispatch => {
    dispatch(setAppLang(lang));
    localStorage.setItem('ap:appLang', lang);
  };
};

export const setRemoveLang = (lang) => {
  return {
      type: REMOVE_APP_LANGUAGE
  };
};

export const setRemoveLangStorage = () => {
  return dispatch => {
    dispatch(setRemoveLang());
    return localStorage.removeItem("ap:appLang");
  };
};

export const translateKey = (firstLevel,secondLevel=null) => {
  return (dispatch, getState) => {
    let data = getState().lang.i18data;
    if (secondLevel === null) {
      if (data.hasOwnProperty(firstLevel)) {
        return data[firstLevel];
      } else {
        return firstLevel;
      }
    } else {
      if (data.hasOwnProperty(firstLevel)) {
        if (data[firstLevel].hasOwnProperty(secondLevel)) {
          return data[firstLevel][secondLevel];
        } else {
          return secondLevel;
        }
      } else {
        return firstLevel;
      }
    }
  };
};

export const getBasicSelectedLang = () => {
  return (dispatch) => {
    let selectedLang = localStorage.getItem("ap:appLang");
    if(selectedLang){
      if(selectedLang==="EN" || selectedLang==="DE"){
        dispatch(setStoreLang(selectedLang));
      }
    }
  };
}