export const NEW_LEAD = "717700000"
export const FIRST_CONTACT_MADE = "717700001"
export const MEETING_PLANNED = "717700002"
export const MEETING_HELD = "717700003"
export const CONTRACT_SIGNED = "717700004"
export const REJECTED = "717700005"
export const ON_HOLD = "717700006"
export const METTING_PLANNED = "717700008"
export const CONTACT_ATTEMPTED = "717700009"
export const RENEWED_CONTACT = "717700012"
export const INTERESTS_NOT_ACHIVED = "717700013"

export const DE_LIST = [NEW_LEAD,FIRST_CONTACT_MADE, MEETING_PLANNED, MEETING_HELD, CONTRACT_SIGNED, REJECTED, METTING_PLANNED, RENEWED_CONTACT, INTERESTS_NOT_ACHIVED ]
export const EN_LIST = [NEW_LEAD,FIRST_CONTACT_MADE, MEETING_PLANNED, MEETING_HELD, REJECTED, ON_HOLD, CONTACT_ATTEMPTED, METTING_PLANNED]