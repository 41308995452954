import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import * as DebtRestrained from "../../../_constance/enums/debtRestrained";
import { NotificationLevel } from "../../../_constance/enums/notificationLevel";
import { DebtStatus } from "../../../_constance/enums/debtStatus";
import ReqHelper from "../../../_helpers/request_helper";

class InvoiceCollectionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }
    
    
    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    findInNotificationLevel = (status) =>{
        if(status){
            let statusObj = NotificationLevel.find((element) => element.Value===status.toString())
            if(statusObj){
                return this.props.translateKey(statusObj.Key);
            }
        }
    }

    findInDebtStatus = (status) =>{
        if(status){
            let statusObj = DebtStatus.find((element) => element.Value===status.toString())
            if(statusObj){
                return this.props.translateKey(statusObj.Key);
            }
        }
    }

    shouldWeShowThisSection(){
        const { invoice } = this.props.element;
        let show = false;
        if(invoice.collections!==null && invoice.collections!==undefined){
            for ( const [key] of Object.entries( invoice.collections ) ) {
                if(ReqHelper.notNullAndUndefinied(invoice.collections[key])){
                    show = true;
                }
            }
        }
        return show
    }

    render() {
        const { translateKey } = this.props;
        const { invoice } = this.props.element;
        let InvoiceData;
        if(this.state.isOpen){
            InvoiceData = (
                <div className="lead-details-client-info">
                        <div className="col-sm-12 col-lg-6">
                            {
                                invoice.collections!==null && 
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("debt_status")}</label>
                                        <div className="col-sm-8">
                                            <span className="lead-input-wrapper">
                                                {this.findInDebtStatus(invoice.collections.debtStatus)}
                                            </span>
                                        </div>
                                    </div>
                            }
                            {
                                invoice.collections!==null && 
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("prompt_level")}</label>
                                        <div className="col-sm-8">
                                            <span className="lead-input-wrapper">
                                                {this.findInNotificationLevel(invoice.collections.promptLevel)}
                                            </span>
                                        </div>
                                    </div>
                            }
                            {
                                invoice.collections!==null && 
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("note")}</label>
                                        <div className="col-sm-8">
                                            <span className="lead-input-wrapper">
                                                {invoice.collections.noteDebt}
                                            </span>
                                        </div>
                                    </div>
                            }
                            {
                                invoice.collections!==null && invoice.collections.debtRestrained!==null && invoice.collections.debtRestrained.toString() === DebtRestrained.YES &&
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("debt_collection_restrained")}</label>
                                        <div className="col-sm-8">
                                            <span className="lead-input-wrapper">
                                                {translateKey("yes")}
                                            </span>
                                        </div>
                                    </div>
                            }
                    </div>
                </div>
            )
        }
        return (
            <React.Fragment>
                { this.shouldWeShowThisSection() && 
                    <div className="lead-client-row">
                        <div className="lead-details-header">
                            <div className="filter-modal-option-text">
                                {this.props.translateKey("collections")}
                            </div>
                            <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                                {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                            </div>
                        </div>
                        {InvoiceData}
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(InvoiceCollectionComponent));