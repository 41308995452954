export const DebtStatus = [
    {
        Key: 'request_for_clarification',
        Value: "717700000"
    },
    {
        Key: 'dunned',
        Value: "717700001"
    },
    {
        Key: 'not_dunned',
        Value: "717700002"
    },
]