import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InvoiceApprovalHandler } from "../../index";

class FpApprovalComponenet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            paymentStatus: this.props.paymentStatus
        };
    }

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    render() {
        let FpApprovalData;
        if(this.state.isOpen){
            FpApprovalData = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-6">
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("status")}</label>
                            <div className="col-sm-8">
                                <InvoiceApprovalHandler status={this.props.element.approval.Status} statusData={this.props.paymentStatus}/>
                            </div>
                        </div>
                    </div>
                        {
                            this.props.element.approval.Comment!==null?
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group row flexAlignCenter no-margin-row">
                                        <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("additional_comment")}</label>
                                        <div className="col-sm-8">
                                            <span className="lead-input-wrapper">
                                                {this.props.element.approval.Comment}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''

                        }
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{this.props.translateKey("fp_approval")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                    </div>
                </div>
                {FpApprovalData}
            </div>
        );
    }
}
  
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(FpApprovalComponenet));