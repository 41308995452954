import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LoginPage from './_pages/login/login_page';
import LeadsMainPage from './_pages/leads/leads';
import LeadsDetailPage from './_pages/leads/leadsDetails';
import LeadPatientPage from './_pages/leadPatient/leadPatient';
import NewLeadPage from './_pages/leads/newLead';
import ClientsMainPage from './_pages/clients/clients';
import ClientDetails from './_pages/clients/clientDetails';
import ClientPatientPage from './_pages/clientPatient/clientPatient';
import ContractPage from './_pages/contract/contractDetails';
import MultipliersMainPage from './_pages/multipliers/multipliers';
import EditMultiplierPage from './_pages/multipliers/editMultiplier';
import NewMultiplierPage from './_pages/multipliers/newMultiplier';
import InvoicesMainPage from './_pages/invoices/invoices';
import TasksMainPage from './_pages/tasks/tasks';
import EditTaskPage from './_pages/tasks/editTask';
import NewTaskPage from './_pages/tasks/newTask';
import DashboardPage from './_pages/dashboard/dashboard';
import InformationPage from './_pages/information/information';
import LogoutPage from './_pages/logoutPage/logoutPage';
import MainPatientWrapper from './_pages/patientBB/mainPatientWrapper';
import MainClientBBWrapper from './_pages/clientBB/mainClientBBWrapper';
import { baseData } from './_constance/base_data';
//Redux
import { authCheckState } from './_redux/actions/auth';
import { getBasicSelectedLang, loadBasicDataToRedux, loadBasicEnumsToRedux } from './_redux/actions/index';
import { connect } from 'react-redux';
require('es6-promise/auto');
require("jspolyfill-array.prototype.findIndex")
require('ie-array-find-polyfill');

class Root extends Component {

    componentDidMount() {
        this.props.tryAutoLogin();
        this.props.getBasicSelectedLang();
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && this.props.langCode) {
            this.props.loadBasicEnumsToRedux(this.props.langCode);
            this.props.loadBasicDataToRedux(this.props.langCode);
        }
    }

    render() {
        let routes = null;
        if (!this.props.isAuthenticated && !this.props.basicDataSaved) {
            routes = (
                <Switch>
                    <Route path={baseData.subFolders} component={LoginPage} />
                </Switch>
            );
        } else {
            routes = (
                <Switch>
                    <Route path={`${baseData.subFolders}logout`} exact component={LogoutPage} />
                    <Route path={`${baseData.subFolders}leads`} exact component={LeadsMainPage} />
                    <Route path={`${baseData.subFolders}leadDetails/:leadId`} exact component={LeadsDetailPage} />
                    <Route path={`${baseData.subFolders}leadPatient/:leadId/:patientId?`} exact component={LeadPatientPage} />
                    <Route path={`${baseData.subFolders}newlead`} exact component={NewLeadPage} />
                    <Route path={`${baseData.subFolders}clients`} exact component={ClientsMainPage} />
                    <Route path={`${baseData.subFolders}clientDetails/:id/:contractId?`} exact component={ClientDetails} />
                    <Route path={`${baseData.subFolders}clientPatient/:id/:patientId?`} exact component={ClientPatientPage} />
                    <Route path={`${baseData.subFolders}contract/:id/:contractId`} exact component={ContractPage} />
                    <Route path={`${baseData.subFolders}multipliers`} exact component={MultipliersMainPage} />
                    <Route path={`${baseData.subFolders}multiplierDetails/:id`} exact component={EditMultiplierPage} />
                    <Route path={`${baseData.subFolders}newmultiplier`} exact component={NewMultiplierPage} />
                    <Route path={`${baseData.subFolders}invoices/:id?`} exact component={InvoicesMainPage} />
                    <Route path={`${baseData.subFolders}tasks`} exact component={TasksMainPage} />
                    <Route path={`${baseData.subFolders}taskDetails/:id`} exact component={EditTaskPage} />
                    <Route path={`${baseData.subFolders}newtask/:assignTo?/:type?`} exact component={NewTaskPage} />
                    <Route path={`${baseData.subFolders}dashboard`} exact component={DashboardPage} />
                    <Route path={`${baseData.subFolders}contact`} exact component={InformationPage} />
                    <Route path={`${baseData.subFolders}bb-form-lead/:id/:patientId?/:type?`} exact component={MainPatientWrapper} />
                    <Route path={`${baseData.subFolders}bb-form-client/:id/:patientId?/:type?`} exact component={MainClientBBWrapper} />
                    <Redirect to={`${baseData.subFolders}leads`} />
                </Switch>
            );
        }

        return (
            <Router>
                {routes}
            </Router>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        tryAutoLogin: () => dispatch(authCheckState()),
        getBasicSelectedLang: () => dispatch(getBasicSelectedLang()),
        loadBasicDataToRedux: (lang) => dispatch(loadBasicDataToRedux(lang)),
        loadBasicEnumsToRedux: (lang) => dispatch(loadBasicEnumsToRedux(lang))
    };
};
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.appToken !== null && state.auth.appToken !== undefined,
        basicDataSaved: state.enums.finishSave,
        langCode: state.lang.appLanguage
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);