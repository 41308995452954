import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import produce from 'immer';
import { translateKey } from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import ListComponent from "../../form-bb-components/list-component/list-component";
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import SelectComponent from "../../form-bb-components/select-component/SelectComponent";
import WrappedAutoSuggestComponent from "../../../_components/wrapped-autosuggest-component/WrappedAutoSuggestComponent";
import ReqHelper from '../../../_helpers/request_helper';

class Section0 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-0'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setTypeChangePostalCode = (key, suggestion) => {
        if(typeof suggestion === 'object' && suggestion !== null){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion.value;
                }
                const city = section.getFieldByKey('is_registered_city');
                if (city) {
                    city.value = suggestion.city;
                }
            });
            this.setState(nextState);
        }else{
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChangeNumber = (key, value) => {
        if(value.trim()===''){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
        }else{
            if(ReqHelper.isNumber(parseInt(value.trim()))){
                const nextState = produce(this.state, draftState => {
                    const { section } = draftState;
                    const field = section.getFieldByKey(key);
                    if (field) {
                        field.value = value;
                    }
                });
                this.setState(nextState);
            }
        }
    };

    prepareMeCitySelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }


    render() {
        const { disabled, translateKey } = this.props;
        const { section } = this.state;
        const isBirthday = section.getFieldByKey('is_birthday');
        const isDegreeRelationshipWithPatientCarer = section.getFieldByKey('is_degree_relationship_with_patient_carer');
        const isRegisteredZipPostalCode =  section.getFieldByKey('is_registered_zip_postal_code')
        const preparedSelectGroup = this.prepareMeCitySelect();

        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">
                    {this.props.subsectionNumber}. {this.props.subsectionName}
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                    <div className="row col-sm-12 col-lg-6">
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('lastname')} text={translateKey("last_name")} transKey="last_name" keyName="lastname"/>
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('firstname')} text={translateKey("first_name")} transKey="first_name" keyName="firstname"/>
                    </div>

                    <div className="row col-sm-12 col-lg-6">
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_street')} text={translateKey("street")} transKey="street" keyName="is_registered_street"/>
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_street_number')} text={translateKey("form-text-section-0-2")} transKey="form-text-section-0-2" keyName="is_registered_street_number"/>
                    </div>

                    <div className="row col-sm-12 col-lg-6">
                        <WrappedAutoSuggestComponent 
                            value={isRegisteredZipPostalCode.value} 
                            suggestions={preparedSelectGroup}
                            showValidation={this.state.showValidation}
                            keyName="is_registered_zip_postal_code"
                            validationErrors={isRegisteredZipPostalCode.validationErrors}
                            text={translateKey("postal_code")}
                            setTypeChangeToParent={this.setTypeChangePostalCode}
                            disabled={disabled}
                            className="col-lg-6 form-margin-bottom-fix"
                        /> 
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_house_number')} text={translateKey("apartment_number")} transKey="apartment_number" keyName="is_registered_house_number"/>
                    </div>

                    <div className="row col-sm-12 col-lg-6">
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_mobile_phone')} text={translateKey("form-text-section-0-3")} transKey="form-text-section-0-3" keyName="is_mobile_phone"/>
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_registered_city')} text={translateKey("city")} transKey="city" keyName="is_registered_city"/>
                    </div>

                    <div className="row col-sm-12 col-lg-6">
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_email')} text={translateKey("email")} transKey="email" keyName="is_email"/>
                    </div>

                    <SelectComponent
                        showValidation={this.state.showValidation}
                        keyName="is_degree_relationship_with_patient_carer"
                        valuesList={[
                            { value: '717700000', label: translateKey('form-text-relationship-0') },
                            { value: '717700001', label: translateKey('form-text-relationship-1') },
                            { value: '717700002', label: translateKey('form-text-relationship-2') },
                            { value: '717700003', label: translateKey('form-text-relationship-3') },
                            { value: '717700004', label: translateKey('form-text-relationship-4') },
                            { value: '717700005', label: translateKey('form-text-relationship-5') },
                            { value: '717700006', label: translateKey('form-text-relationship-6') },
                            { value: '717700007', label: translateKey('form-text-relationship-7') },
                            { value: '717700008', label: translateKey('form-text-relationship-8') },
                            { value: '717700010', label: translateKey('form-text-relationship-10') }
                        ]}
                        value={isDegreeRelationshipWithPatientCarer.value}
                        validationErrors={isDegreeRelationshipWithPatientCarer.validationErrors}
                        label={translateKey("form-text-section-0-1")}
                        setTypeChangeToParent={this.setTypeChange}
                    />

                    { isDegreeRelationshipWithPatientCarer.value === '717700008' && (
                        <React.Fragment>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_birthday"
                                    value={isBirthday.value}
                                    validationErrors={isBirthday.validationErrors}
                                    text={translateKey("born")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} valueFormatter={ReqHelper.simpleValueFormatter} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChangeNumber} field={section.getFieldByKey('is_height')} text={translateKey("form-text-height")} transKey="form-text-height" keyName="is_height"/>
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} valueFormatter={ReqHelper.simpleValueFormatter} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChangeNumber} field={section.getFieldByKey('is_weight')} text={translateKey("weight")} transKey="weight" keyName="is_weight"/>
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_home_doctor')} text={translateKey("form-text-home-doctor")} transKey="form-text-home-doctor" keyName="is_home_doctor"/>
                            </div>
                        </React.Fragment>
                    )}

                    <ListComponent
                        setTypeChangeToParent={this.setTypeChange}
                        showValidation={this.props.showValidationErrors}
                        className="form-margin-bottom-fix"
                        transKey="form-text-contact-person-same-data"
                        key="is_contact_person_data_same_as"
                        keyName="is_contact_person_data_same_as"
                        value={section.getFieldByKey('is_contact_person_data_same_as').value}
                        validationErrors={section.getFieldByKey('is_contact_person_data_same_as').validationErrors}
                        valuesList={[
                            { value: '717700000', label: translateKey("yes") },
                            { value: '717700001', label: translateKey("no") },
                        ]}
                    />
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section0));