import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { translateKey, closeTaskPopup } from "../../_redux/actions/index";
import { IconsMap } from '../../_helpers/icons_set'
import { baseData } from '../../_constance/base_data';
import TaskModal from "../../_components/task-modal/task-modal";
import { ResponseStatus } from '../../_helpers/response_status';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRoute: '',
            breadCrumbs: this.props.breadCrumbs
        };
    }

    logMeOutWithCleaning = () =>{
        this.props.history.push(`${baseData.subFolders}logout`);
    }

    navigateToDetailsTask(url){
        this.props.history.push(url);
        this.props.closePopup();
    }

    prepareMeBreadCrumbs = () =>{
        let breadCrumbs = this.props.breadCrumbs.map((element, i, arr) => {
            if(i===arr.length-1){
                return <li key={Math.random().toString(36).substr(2, 9)} className="nav-item">
                    <span className="nav-link not-active-break-crumbs">{element.key?this.props.translateKey(element.key): element.name}</span>
                </li>
            }else{
                return <li key={Math.random().toString(36).substr(2, 9)} className="nav-item">
                <Link className="nav-link" to={baseData.subFolders+element.link}>{element.key?this.props.translateKey(element.key): element.name} <span className="bread-crumbs-selector">/</span></Link>
                </li>
            }
        });
        return breadCrumbs;
    }

    render() {
        const filterdTasks = this.props.tasksData.filter(item => item.status === 'Open' && item.dueDay < new Date());
        return (
            <>
                <nav className="navbar navbar-expand-lg fixed-top">
                    <Link to={`${baseData}leads`}><img className="navbar-brand-icon" src={IconsMap.rest.white_logo} alt={"Icon"}/></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">{this.prepareMeBreadCrumbs()}</ul>
                        <div className="form-inline my-2 my-lg-0 rest-items-nav-wrapper">
                            <div className="loginWrapper">
                                <i className="fas fa-user fa-1x user-name-icon"></i>
                                <span>{this.props.login? this.props.login : "undefinied"}</span>
                            </div>
                            <div className="logOutIconWrapper">
                                <button className="btn btn-primary logout-button" onClick={this.logMeOutWithCleaning}>{this.props.translateKey('logout')}</button>
                            </div>
                        </div>
                    </div>
                </nav>
                <TaskModal 
                    content={filterdTasks} 
                    show={(this.props.tasksStatus === ResponseStatus.READY && this.props.isPopupOpen && filterdTasks.length > 0)} 
                    onClose={() => this.props.closePopup()} 
                    translateKey={this.props.translateKey}
                    navigateToDetailsTask={(url) => this.navigateToDetailsTask(url)}
                />
            </>
        );
    }
}
const mapStateToProps = state => {return {
    login: state.auth.appLogin,
    tasksData: state.database.tasks,
    tasksStatus: state.database.tasksState,
    isPopupOpen: state.auth.isPopupOpen,
}}  
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    closePopup: () => dispatch(closeTaskPopup()),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));