import * as React from 'react';
import { IconsMap } from "../../_helpers/index";

export class InvoicePaymentHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: props.status,
            statusData: props.statusData
        }
    }

    getPaymentStatusColor(status){
        switch(status){
            case '717700000': //not paid
                return IconsMap.svg.red_status_icon;
            case '717700001': //partly paid
                return IconsMap.svg.orange_status_icon;
            case '717700002': //paid
                return IconsMap.svg.green_status_icon;
            case '717700003': //excess payment
                return IconsMap.svg.blue_status_icon;
            default :
                return IconsMap.svg.white_status_icon;
        }
    }

    getStatusText(status){
        if(this.props.statusData){
            for(let i=0; i<this.props.statusData.length; i++){
                if(this.props.statusData[i].Value === status){
                    return this.props.statusData[i].Label;
                }
            }
        }
    }

    render() {
        return (
            <div className="flexAlignCenter">
                <span><img className="invoiceIconStatus" src={this.getPaymentStatusColor(this.props.status)} alt="Icon"/></span>
                <span>{this.getStatusText(this.props.status)}</span>
            </div>
        );
    }
}