import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import * as SkillRange from "../../../_forms/BBForm/values/skillRange";
import ListComponent from "../../form-bb-components/list-component/list-component";
import produce from "immer";


class Section16 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-16'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey } = this.props;
        const { section } = this.state;
        const isCandidateGender = section.getFieldByKey('is_candidate_gender');
        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                            <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={this.state.showValidation}
                                className="form-margin-bottom-fix"
                                transKey="form-text-section-17-01"
                                key="is_candidate_gender"
                                keyName="is_candidate_gender"
                                value={isCandidateGender.value}
                                validationErrors={isCandidateGender.validationErrors}
                                valuesList={[
                                    { value: SkillRange.PARTIAL, label: translateKey("form-text-frau") },
                                    { value: SkillRange.UNLIMITED, label: translateKey("form-text-mann") },
                                    { value: SkillRange.LIMITED, label: translateKey("form-text-irrelevant") }
                                ]}
                            />
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.state.showValidation} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_most_important_taking_care_patient')} text={translateKey("form-text-wichtigsten")} transKey="form-text-wichtigsten" keyName="is_most_important_taking_care_patient"/>
                            </div>
                        </fieldset>
                    </form>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section16));