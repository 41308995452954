import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";

class ClientPatientHealthDataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }

    onOpenShow(){
      this.setState(prevState => {
          return {
              ...prevState,
              isOpen: prevState.isOpen? false: true
              }
          }
      );
    }

    getMepflegegrad(element){
      let finder = this.props.pflegegrad.filter(x => x.Value.toString() === element.toString());
      if(finder){
          return finder[0].Label
      }else{
          return '---'
      }
    }

    getMepflegegradApplication(element){
      let finder = this.props.pflegegradApplication.filter(x => x.Value.toString() === element.toString());
      if(finder){
          return finder[0].Label
      }else{
          return '---'
      }
    }


    render() {
      const {translateKey, element } = this.props
      let patientHealthData;
      if(this.state.isOpen){
        patientHealthData = (
          <div className="lead-first-row" key='clientDetailsBasicInformationLongKey1'>
            <div className="col-sm-12 col-lg-5">
              {
                element.disabilityLevel!==null && element.disabilityLevel!==0?
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("is_degree_of_disability")}</label>
                      <div className="col-sm-8">
                          <span className="lead-input-wrapper">
                              {this.getMepflegegrad(element.disabilityLevel)}
                          </span>
                      </div>
                    </div>
                    :
                    ''
              }
              {
                element.patientHealth.pflegegrad!==null && element.patientHealth.pflegegrad!==0?
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("pflegegrad")}</label>
                      <div className="col-sm-8">
                          <span className="lead-input-wrapper">
                            {this.getMepflegegrad(element.patientHealth.pflegegrad)}
                          </span>
                      </div>
                    </div>
                    :
                    ''
              }
              {
                element.patientHealth.pflegegradApplication!==null && element.patientHealth.pflegegradApplication!==0?
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("pflegegrad_application")}</label>
                      <div className="col-sm-8">
                          <span className="lead-input-wrapper">
                            {this.getMepflegegradApplication(element.patientHealth.pflegegradApplication)}
                          </span>
                      </div>
                    </div>
                    :
                    ''
              }
              {
                element.HealthStateListResource.is_lying_patient!==null &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("is_lying")}</label>
                      <div className="col-sm-8">
                          <span className="lead-input-wrapper">
                              {element.HealthStateListResource.is_lying_patient?
                                translateKey("yes")
                                :
                                translateKey("no")
                              }
                          </span>
                      </div>
                    </div>
              }
              {
                element.hardCase!==null && element.hardCase!==false?
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("is_hard_case")}</label>
                      <div className="col-sm-8">
                          <span className="lead-input-wrapper">
                              {element.hardCase?
                                translateKey("yes")
                                :
                                translateKey("no")
                              }
                          </span>
                      </div>
                    </div>
                    :
                    ''
              }
            </div>
          </div>
        );
      }
      return (
        <div className="lead-client-row">
            <div className="lead-details-header">
                <div className="filter-modal-option-text">{translateKey("health_status_data")}</div>
                <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                    {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                </div>
            </div>
            {patientHealthData}
        </div>
      );
    }
}
const mapStateToProps = state => {return {
  pflegegrad: state.enums.careDegree,
  pflegegradApplication: state.enums.careDegreeApplication
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPatientHealthDataComponent));