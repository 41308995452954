import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  setLeadsData,
  pendingLeadsData,
  removeLeadsData,
  authSetActionTimestamp,
  setLeadStatusData,
  removeLeadStatusData,
} from "../../../_redux/actions/index";
import { AlertModalInfo, LoaderTable} from "../../index";
import axiosAuth from "../../../_services/config/axios-auth";
import { refreshTokenTime } from '../../../_constance/base_data';
import { shouldRefresh, ReqHelper } from "../../../_helpers/index";
import { Alert, LeadTable} from "../../../_constance/classes/index";
import { LeadsPerStatusChar, LeadsPerStatusTable, LeadsPerSource, LeadsPerConversion, LeadsPerMeetings } from "../../../_components/Dashboard/dashboard-leads/charts-and-table/index";

class DashboardLeadsPage extends React.Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab,
      tabName: this.props.tabName,
      dashboardData: this.props.dashboardData,
      dashboardLoading: this.props.dashboardLoading,
      loading: true,
      errorComponent: Alert
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.getDataBasic();
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  getDataBasic() {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    if (this.props.leadsStatus.length === 0) {
      if (this.props.leadsStatus.length !== 0 && this.props.leadStatus !== undefined) {
        this.getLeadsDataByTimestamp();
      } else {
        this.getLeadsStatus();
      }
    }else{
      this.getLeadsDataByTimestamp();
    }
  }

  getLeadsDataByTimestamp(){
    const lastUpdate = this.props.leadsTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getLeadsList();
    }else{
      if(this._isMounted) {
        this.setState({loading: false})
      }
    }
  }

  getLeadsList = () => {
    this.props.pendingRequest();
    axiosAuth.get("leads/list/0/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          element.typeText = ReqHelper.getMeLeadStatusText(element.status, this.props.leadsStatus);
          return new LeadTable(element);
        });
        this.props.setData(mapedRes);
        if (this._isMounted) {
          this.setState({loading: false})
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.clearData();
        if (this._isMounted) {
          this.setState({
            loading: false,
            errorComponent: {
              show:true,
              type: "danger",
              message: this.props.translateKey("basic_error_message")
            }
          })
        }
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  getLeadsStatus = () => {
    axiosAuth.get("lists/leadStatus/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setLeadsStatus(res);
        this.getLeadsDataByTimestamp();
      })
      .catch((error) => {
        this.props.removeLeadsStatus();
        this.getLeadsDataByTimestamp();
      });
  }

  render() {
    const { errorComponent, loading } = this.state;
    const { dashboardLoading, dashboardData } = this.props;
    let ModalAlert = (
      <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
    )
    let Content = (
      <LoaderTable />
    );
    if(!loading && !dashboardLoading){
      Content = (
        <React.Fragment>
          <LeadsPerStatusChar loading={loading}/>
          <LeadsPerStatusTable loading={loading}/>
          <LeadsPerSource loading={loading}/>
          <LeadsPerConversion loading={dashboardLoading}  dashboardData={dashboardData}/>
          <LeadsPerMeetings loading={dashboardLoading}  dashboardData={dashboardData}/>
        </React.Fragment>
      )
    }
    return (
      <div className={"tab-pane fade "+(this.props.activeTab===this.props.tabName?"show active":"")} id={this.props.tabName} role="tabpanel" aria-labelledby={this.props.tabName}>
        <div className="listData tab-list-data">
          {Content}
          {ModalAlert}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsStatus: state.enums.leadStatus,
    leadsData: state.database.leads,
    leadsTimestamp: state.database.leadsTimeStamp
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setData: (data) => dispatch(setLeadsData(data)),
  pendingRequest: () => dispatch(pendingLeadsData()),
  clearData: () => dispatch(removeLeadsData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setLeadsStatus: (data) => dispatch(setLeadStatusData(data)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLeadsPage));