export default class Form {
    sections = [];
    flatFieldsList = [];

    constructor({ sections = [] } = {}) {
        this.sections = sections;
        this.sections.forEach((section, index) => {
            section.assignToForm(this, index);
            this.flatFieldsList = [...this.flatFieldsList, ...section.fields];
        });
    }

    getSectionByKey(key) {
        return this.sections.find(section => section.key === key);
    }

    getSectionByOrder(order) {
        return this.sections.find(section => section.order === order);
    }

    getFieldByKey(key) {
        return this.flatFieldsList.find(field => field.key === key);
    }

    getFieldsByEntity(entity) {
        return this.uniqueFlatFieldsList.filter(field => field.entity === entity);
    }

    get uniqueFlatFieldsList() {
        return this.flatFieldsList.filter((field, index, self) => self.indexOf(field) === index);
    }

    setFields(data) {
        Object.keys(data).forEach(key => {
            const field = this.getFieldByKey(key);
            if(field){
                field.value = data[key];
            }
        });
    }

    getFieldsBySectionAndEntity(key, entity, payload = false){
        const section = this.getSectionByKey(key);
        const fields = payload ? section.payloadFields : section.fields;
        return fields.filter(field => {
            if(field && field.entity === entity){
                if(field.value!==null){
                    if(field.value.toString().trim()!==""){
                        return true
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            }else{
                return false;
            }
        });
    }

    get isValid() {
        return this.sections.reduce((previous, section) => section.isValid && previous, true);
    }
}
