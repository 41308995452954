import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PieChart, Pie, Cell, Legend} from 'recharts';
import { translateKey } from "../../../../_redux/actions/index";
import { Colors } from "../../../../_constance/enums/colors";
import { ColorsByStatus } from "../../../../_constance/enums/index";
import { LoaderTable } from "../../../index";
import { baseData } from '../../../../_constance/base_data';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, value}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  )
};


class LeadsPerSource extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      leadsPerSource: [],
      loadingSource: true,
      colorsClass: new ColorsByStatus()
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeLeadPerStatus();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeLeadPerStatus = () =>{
    const {colorsClass} = this.state
    let data = [];
    let emptyObj = {
        amount: 0,
        name: this.props.translateKey("no_information"),
        status: "00000000-0000-0000-0000-000000000000",
        color: Colors.GRAY
    }
    this.props.leadSource.forEach(status=>{
      let total = this.props.leadsData.reduce((acc, item) => {
        if(item.source.toString().toUpperCase() === status.Value.toUpperCase()){
          return acc = acc+1
        }else{
          return acc;
        }
      }, 0);
      if(total>0){
        let itemColor = colorsClass.getColorByStatus(status.Value.toUpperCase());
        if(!itemColor){ 
          itemColor = colorsClass.giveMeRandomColor();
        }else{
          itemColor = itemColor.color
        }
        data.push({
          amount: total,
          name: status.Label,
          status: status.Value,
          color: itemColor
        })
      }
    })
    let total = this.props.leadsData.reduce((acc, item) => {
      if(item.source===null || item.source=== undefined || item.source==="00000000-0000-0000-0000-000000000000"){
        return acc = acc+1
      }else{
        return acc;
      }
    }, 0);
    emptyObj.amount =total;
    if(emptyObj.amount>0){
      data = [emptyObj, ...data]
      this.setState({
        loadingSource: false,
        leadsPerSource: data
      })
    }else{
      this.setState({
        loadingSource: false,
        leadsPerSource: data
      })
    }
  }
  
  handleClick = (data) => {
    this.props.history.push(`${baseData.subFolders}leads/?type=source&param=${data.status}`);
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingSource, leadsPerSource } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingSource){
      Content = (
        <PieChart width={700} height={420}>
          <Pie
            data={leadsPerSource}
            cx={200}
            cy={200}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            dataKey="amount"
            onClick={this.handleClick}
            className="cursor-pointer"
            nameKey="amount"
          >
            {leadsPerSource.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="middle"
            align="right"
            layout="vertical"
            width={200}
            height={420}
            wrapperStyle={{
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              fontSize: "14px"
            }}
          />
        </PieChart>
      );
    }
    return (
      <React.Fragment>
        <div className="charts-pie-container-wrapper">
          <h5 className="text-center">{translateKey("dashboard_lead_source")}</h5>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsData: state.database.leads,
    leadSource: state.enums.leadSource,
    leadSourceStatus: state.enums.leadSourceStatus
  };
};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadsPerSource));
