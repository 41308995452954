import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  removeTasksData,
  authSetActionTimestamp,
  getTasks
} from "../../../_redux/actions/index";
import { AlertModalInfo, LoaderTable} from "../../index";
import { ResponseStatus, shouldRefresh } from "../../../_helpers/index";
import { Alert } from "../../../_constance/classes/index";
import { refreshTokenTime } from '../../../_constance/base_data';
import TasksStatusTable from './charts-and-table/tasks-status-table';

class DashboardTasksPage extends React.Component {
  _isMounted = false;
  taskTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab,
      tabName: this.props.tabName,
      dashboardData: this.props.dashboardData,
      dashboardLoading: this.props.dashboardLoading,
      loading: true,
      errorComponent: Alert,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.getDataBasic();
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  getBasicTasksData(){
    if(this.props.tasksStatus === ResponseStatus.READY){
      clearTimeout(this.taskTimeOut);
      if (this._isMounted) {
        this.setState({loading: false})
      }
    }else{
      if(this.props.tasksStatus === ResponseStatus.FREE){
        this.props.getGlobalTasksData();
        this.taskTimeOut = setTimeout(() => {
          this.getBasicTasksData()
        }, 500);
      }else{
        if(this.props.tasksStatus === ResponseStatus.ERROR){
          clearTimeout(this.taskTimeOut);
          if (this._isMounted) {
            this.setState({ loading: false })
          }
        }else{
          this.taskTimeOut = setTimeout(() => {
            this.getBasicTasksData()
          }, 500);
        }
      }
    }
  }

  getDataBasic() {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    const lastUpdate = this.props.tasksTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.props.removeTasksData();
      this.getBasicTasksData();
    } else {
      if (this._isMounted) {
        this.setState({loading: false})
      }
    }
  }


  render() {
    const { errorComponent } = this.state;
    let ModalAlert = (
      <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
    )
    let Content = (
      <LoaderTable />
    );
    if(!this.state.loading){
      Content = (
        <TasksStatusTable loading={this.state.loading}/>
      )
    }
    return (
      <div className={"tab-pane fade "+(this.props.activeTab===this.props.tabName?"show active":"")} id={this.props.tabName} role="tabpanel" aria-labelledby={this.props.tabName}>
        <div className="listData tab-list-data">
          {Content}
          {ModalAlert}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tasksTimestamp: state.database.tasksTimeStamp,
    tasksStatus: state.database.tasksState
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getGlobalTasksData: () => dispatch(getTasks()),
  removeTasksData: (data) => dispatch(removeTasksData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardTasksPage));