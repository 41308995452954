export const LeadType = [
    {
        Label: 'Lead',
        Value: "717700000",
        Hidden: false
    },
    {
        Label: 'Referral',
        Value: "717700001",
        Hidden: false
    },
    {
        Label: 'Referral Parter',
        Value: "717700002",
        Hidden: false
    },
]