import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import ValidationErrorsList from "../validation-errors-list/validation-errors-list";
const getSuggestionValue = suggestion => suggestion.value;
const renderSuggestion = suggestion => (<span>{suggestion.value} - {suggestion.city}</span>);

class WrappedAutoSuggestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            suggestions:this.props.suggestions
        };
    }

    prepareMeCitySelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }

    onChange = (event, { newValue }) => {
        const { keyName } = this.props;
        this.props.setTypeChangeToParent(keyName, newValue);
        this.setState({value: newValue});
    };

    onSuggestionSelected = (event, { suggestion }) => {
        const { keyName } = this.props;
        this.props.setTypeChangeToParent(keyName, suggestion);
        this.setState({value: suggestion.value});
    };

    getSuggestions = value => {      
        if(value.length<4){
            return [];
        }else{
            let NewArray = [];
            if(this.props.cities.length>0){
                this.props.cities.map(x => {
                    if(x.PostalCode.indexOf(value) !== -1){
                        NewArray.push({
                            value: x.PostalCode!==null?x.PostalCode:'', 
                            city:  x.City!==null?x.City:'',
                            label:  x.City!==null?x.PostalCode+"-"+x.City:''
                        });
                    }
                    return true;
                });
                return NewArray;
            }else{
                return [];
            }
        }
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({suggestions: this.getSuggestions(value)});
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({suggestions: []});
    };

    render() {
        const { value, validationErrors, showValidation, keyName, text, disabled, className } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;
        const inputProps = {
          value: value?value:"",
          onChange: this.onChange
        };
        return (
            <div className={`col-sm-12 ${className} ${showErrors ? 'fixed-class-for-autosuggest-validation' : ''}`}>
                <label className="form-label-basic"><span className="quick-label-position-fix">{text}</span></label>
                {!disabled?
                    <Autosuggest
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        onSuggestionSelected={this.onSuggestionSelected}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                    :
                    <input type="text" className="form-control lead-input-wrapper" value={value} readOnly/>
                }
                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        cities: state.enums.citiesList,
        citiesStatus: state.enums.citiesListStatus,
    };
}
export default withRouter(connect(mapStateToProps, null)(WrappedAutoSuggestComponent));