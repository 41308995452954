import { ReqHelper } from "../../_helpers/index";
export class InvoiceTable {
    constructor(element){
        let converted_date = ReqHelper.dateConversion(element.dateOfEntry);
        let amount = element.invoice !== null ? element.invoice.amount: '';
        let periodFrom = element.invoice !== null ? element.invoice.periodFrom : '';
        let periodTo = element.invoice !== null ? element.invoice.periodTo : '';
        let status = element.approval !== null ? element.approval.Status : '';
        let invoiceStatus = element.invoice !== null ? element.invoice.status : '';
        let invoiceNumber = element.invoice !== null ? element.invoice.invoiceNumber : '';
        this.BankName = element.BankName;
        this.ClientId = element.ClientId;
        this.ClientIdInvoiceNumber = element.ClientIdInvoiceNumber;
        this.ClientIdbg = element.ClientIdbg;
        this.ClientName = element.ClientName;
        this.Company = element.Company;
        this.ContractId = element.ContractId;
        this.Id = element.Id;
        this.InvoiceDate = element.InvoiceDate;
        this.InvoiceLines = element.InvoiceLines;
        this.Patient = element.Patient;
        this.Payer = element.Payer;
        this.PayerCoor = element.PayerCoor;
        this.approval = element.approval;
        this.invoice = element.invoice;
        this.date = converted_date; 
        this.amount = amount;
        this.periodFrom = periodFrom;
        this.periodTo = periodTo;
        this.status = status;
        this.invoiceStatus = invoiceStatus; 
        this.invoiceNumber = invoiceNumber;
        this.approvalText = element.approvalText;
        this.paymentText = element.paymentText;
    }
};