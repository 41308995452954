import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { IconsMap } from "../../../_helpers/index";

class FormContractPCMComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    shouldWeShowIt(){
        const { element: {pcmItem} } = this.props;
        if(
            pcmItem.name!=null ||
            pcmItem.cellphone!==null || 
            pcmItem.phone!==null || 
            pcmItem.email!==null ||
            pcmItem.team!==null ||
            pcmItem.teamLead!==null ||
            pcmItem.teamLeadCell!==null ||
            pcmItem.teamLeadPhone!==null ||
            pcmItem.teamLeadMail!==null
        ){
            return true
        }
        return false;
    }

    getMeOrderByLang(){
        const { element: {pcmItem}, translateKey } = this.props;
        let myComponents = (
            <React.Fragment>
                {pcmItem.team!==null &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("pcm_team")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {pcmItem.team}
                            </span>
                        </div>
                    </div>
                }
                {pcmItem.teamLeadMail!==null &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">
                            <a href={"mailto:"+pcmItem.email} target="_top">
                                <img src={IconsMap.svg.email} alt="Email" className="user-name-icon"/>
                            </a>
                            {translateKey("pcm_team_email")}
                        </label>
                        <div className="col-sm-8">
                            {pcmItem.teamLeadMail}
                        </div>
                    </div>
                }
                {pcmItem.teamLead!==null &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("pcm_team_leader")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {pcmItem.teamLead}
                            </span>
                        </div>
                    </div>
                }
                {pcmItem.teamLeadCell!==null &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">
                            <a href={"tel:"+pcmItem.phone}>
                                <img src={IconsMap.svg.phone} alt="Phone" className="user-name-icon"/>
                            </a>
                            {translateKey("pcm_team_cellphone")}
                        </label>
                        <div className="col-sm-8">
                            {pcmItem.teamLeadCell}
                        </div>
                    </div>
                }
                {pcmItem.teamLeadPhone!==null &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">
                            <a href={"tel:"+pcmItem.phone}>
                                <img src={IconsMap.svg.phone} alt="Phone" className="user-name-icon"/>
                            </a>
                            {translateKey("pcm_team_phone")}
                        </label>
                        <div className="col-sm-8">
                            {pcmItem.teamLeadPhone}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
        return myComponents;
    }

    render() {
        const { translateKey, element: {pcmItem} } = this.props;
        const { isOpen } = this.state;
        if(this.shouldWeShowIt()){
            let pcmData;
            if(isOpen){
                pcmData = (
                    <div className="lead-offer-pcm-class">
                        <div className="col-sm-12 col-lg-6">
                            {pcmItem.name!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_and_first_name")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {pcmItem.name}
                                        </span>
                                    </div>
                                </div>
                            }
                            {pcmItem.cellphone!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">
                                        <a href={"tel:"+pcmItem.cellphone}>
                                            <img src={IconsMap.svg.phone} alt="Phone" className="user-name-icon"/>
                                        </a>
                                        {translateKey("mobile_phone")}
                                    </label>
                                    <div className="col-sm-8">
                                        {pcmItem.cellphone}
                                    </div>
                                </div>
                            }
                            {pcmItem.phone!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">
                                        <a href={"tel:"+pcmItem.phone}>
                                            <img src={IconsMap.svg.phone} alt="Phone" className="user-name-icon"/>
                                        </a>
                                        {translateKey("home_phone")}
                                    </label>
                                    <div className="col-sm-8">
                                        {pcmItem.phone}
                                    </div>
                                </div>
                            }
                            {pcmItem.email!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">
                                        <a href={"mailto:"+pcmItem.email} target="_top">
                                            <img src={IconsMap.svg.email} alt="Email" className="user-name-icon"/>
                                        </a>
                                        {translateKey("email")}
                                    </label>
                                    <div className="col-sm-8">
                                        {pcmItem.email}
                                    </div>
                                </div>
                            }
                            {this.getMeOrderByLang()}
                        </div>
                    </div>
                )
            }
            return (
                <div className="pcm-offer-wrapper">
                     <div className="lead-details-header">
                        <div className="filter-modal-option-text">{translateKey("contract_pcm")}</div>
                        <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                            {isOpen?translateKey("show_less"):translateKey("show_more")}
                        </div>
                    </div>
                    {pcmData} 
                </div>
            );
        }else{
            return '';
        }
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(FormContractPCMComponent));