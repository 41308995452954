export class ContractTable {
    constructor(element) {
        let contract = '';
        if (element.lastName) {
            contract += element.lastName;
            if (element.firstName) {
                contract += ' ' + element.firstName;
            }
        } else {
            if (element.firstName) {
                contract += ' ' + element.firstName;
            }
        }
        this.Active = element.Active ? element.Active : false;
        this.agreementDate = element.agreementDate ? element.agreementDate : 0;
        this.businessPartner = element.businessPartner ? element.businessPartner : '';
        this.careManagerName = element.careManagerName ? element.careManagerName : '';
        this.companyName = element.companyName ? element.companyName : '';
        this.currency = element.currency ? element.currency : '';
        this.dateOfBeginning = element.dateOfBeginning ? element.dateOfBeginning : 0;
        this.dateOfEnd = element.dateOfEnd ? element.dateOfEnd : 0;
        this.firstName = element.firstName ? element.firstName : 0;
        this.id = element.id ? element.id : 0;
        this.inquiryReceiptDate = element.inquiryReceiptDate ? element.inquiryReceiptDate : 0;
        this.lastName = element.lastName ? element.lastName : '';
        this.pcmItem = element.pcmItem ?
            element.pcmItem
            :
            {
                name: '',
                cellphone: '',
                phone: '',
                email: '',
            }
        this.product = element.product ? element.product : '';
        this.rate = element.rate ? element.rate : 0;
        this.rateType = element.rateType ? element.rateType : '';
        this.registerManagerName = element.registerManagerName ? element.registerManagerName : '';
        this.status = element.status ? element.status : '';
        this.contract = contract;
        this.pcmPlanningItem = {name: null, email: null}
    }

    fillWithPlanningElement(pcmPlanningElement = null) {
        this.pcmPlanningItem = pcmPlanningElement ? pcmPlanningElement : {name: null, email: null};
    }
};