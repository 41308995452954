import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { CheckBoxSmallMultipleComponent } from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import  ListMovementComponent  from '../../form-bb-components/list-movement-component/list-movement-component';
import produce from "immer";
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import ListComponent from "../../form-bb-components/list-component/list-component";


class Section8 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-8'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey, showValidationErrors } = this.props;
        const { section } = this.state;
        const isRegularChangeOfPosition = section.getFieldByKey('is_regular_change_of_position');
        const isMove = section.getFieldByKey('is_move');
        const isGettingUp = section.getFieldByKey('is_getting_up');
        const isCarryingBedWheelChar = section.getFieldByKey('is_carrying_bed_wheelchair');
        const isWalkingUpStairs = section.getFieldByKey('is_walking_up_stairs');
        const isMovingWithRollarAlone = section.getFieldByKey('is_moving_with_rollator_alone');
        const isMovingOnWheelChairAlone = section.getFieldByKey('is_moving_on_wheelchair_alone');
        const isMovingWithRollatorInFlatAlone =  section.getFieldByKey('is_moving_with_rollator_in_flat_alone');
        const isMovingRollatorOutsideFlat =  section.getFieldByKey('is_moving_rollator_outside_flat');
        const isMovingWheelCharInApartmentAlone =  section.getFieldByKey('is_moving_wheelchair_in_apartment_alone');
        const isMovingWheelChairOutsideAlone =  section.getFieldByKey('is_moving_wheelchair_outside_alone');
        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_lying_patient').value} validationErrors={section.getFieldByKey('is_lying_patient').validationErrors} text={translateKey("form-text-bettlagerig")} transKey="form-text-bettlagerig" keyName="is_lying_patient"/>
                        
                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isRegularChangeOfPosition.value} validationErrors={isRegularChangeOfPosition.validationErrors} text={translateKey("form-text-gelagert")} transKey="form-text-gelagert" keyName="is_regular_change_of_position"/>
                        {isRegularChangeOfPosition.value===true &&
                            <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6 top-too" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_patient_help_change_position').value} validationErrors={section.getFieldByKey('is_patient_help_change_position').validationErrors} text={translateKey("form-text-mithelfen")} transKey="form-text-mithelfen" keyName="is_patient_help_change_position"/>
                        }

                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6 top-too" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_patient_need_help_with_transfer').value} validationErrors={section.getFieldByKey('is_patient_need_help_with_transfer').validationErrors} text={translateKey("form-text-braucht")} transKey="form-text-braucht" keyName="is_patient_need_help_with_transfer"/>
                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6 top-too" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_patient_is_active_during_transfer').value} validationErrors={section.getFieldByKey('is_patient_is_active_during_transfer').validationErrors} text={translateKey("form-text-arbeitet")} transKey="form-text-arbeitet" keyName="is_patient_is_active_during_transfer"/>

                        <ListMovementComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-gehen" key="is_move" keyName="is_move" value={isMove.value} validationErrors={isMove.validationErrors}/>
                        <ListMovementComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-stehen" key="is_getting_up" keyName="is_getting_up" value={isGettingUp.value} validationErrors={isGettingUp.validationErrors}/>
                        <ListMovementComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-transfer" key="is_carrying_bed_wheelchair" keyName="is_carrying_bed_wheelchair" value={isCarryingBedWheelChar.value} validationErrors={isCarryingBedWheelChar.validationErrors}/>
                       
                        <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={showValidationErrors}
                                className="form-margin-bottom-fix"
                                transKey="form-text-treppen"
                                key="is_walking_up_stairs"
                                keyName="is_walking_up_stairs"
                                value={isWalkingUpStairs.value}
                                validationErrors={isWalkingUpStairs.validationErrors}
                                valuesList={[
                                    { value: SkillRange.LIMITED, label: translateKey("form-text-uneingeschrankt") },
                                    { value: SkillRange.PARTIAL, label: translateKey("form-text-teilweise") },
                                    { value: SkillRange.UNLIMITED, label: translateKey("form-text-eingeschrankt") },
                                ]}
                        />



                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                            transKey="form-text-fortbewegen-m"
                            key="is_moving_with_rollator_alone"
                            keyName="is_moving_with_rollator_alone"
                            value={isMovingWithRollarAlone.value}
                            validationErrors={isMovingWithRollarAlone.validationErrors}
                            valuesList={[
                                { value: IsContactPersonDataSameAs.YES, label: translateKey("yes")},
                                { value: IsContactPersonDataSameAs.NO, label: translateKey("no")}
                            ]}
                        />
                        {isMovingWithRollarAlone.value===IsContactPersonDataSameAs.YES &&
                            <React.Fragment>
                                <ListComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                                    transKey="form-text-innerhalb"
                                    key="is_moving_with_rollator_in_flat_alone"
                                    keyName="is_moving_with_rollator_in_flat_alone"
                                    value={isMovingWithRollatorInFlatAlone.value}
                                    validationErrors={isMovingWithRollatorInFlatAlone.validationErrors}
                                    valuesList={[
                                        { value: IsContactPersonDataSameAs.YES, label: translateKey("yes")},
                                        { value: IsContactPersonDataSameAs.NO, label: translateKey("no")}
                                    ]}
                                />
                                <ListComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                                    transKey="form-text-auberhalb"
                                    key="is_moving_rollator_outside_flat"
                                    keyName="is_moving_rollator_outside_flat"
                                    value={isMovingRollatorOutsideFlat.value}
                                    validationErrors={isMovingRollatorOutsideFlat.validationErrors}
                                    valuesList={[
                                        { value: IsContactPersonDataSameAs.YES, label: translateKey("yes")},
                                        { value: IsContactPersonDataSameAs.NO, label: translateKey("no")}
                                    ]}
                                />
                            </React.Fragment>
                        }

                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                            transKey="form-text-fortbewegen-im"
                            key="is_moving_on_wheelchair_alone"
                            keyName="is_moving_on_wheelchair_alone"
                            value={isMovingOnWheelChairAlone.value}
                            validationErrors={isMovingOnWheelChairAlone.validationErrors}
                            valuesList={[
                                { value: IsContactPersonDataSameAs.YES, label: translateKey("yes")},
                                { value: IsContactPersonDataSameAs.NO, label: translateKey("no")}
                            ]}
                        />
                        {isMovingOnWheelChairAlone.value===IsContactPersonDataSameAs.YES &&
                            <React.Fragment>
                                <ListComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                                    transKey="form-text-innerhalb"
                                    key="is_moving_wheelchair_in_apartment_alone"
                                    keyName="is_moving_wheelchair_in_apartment_alone"
                                    value={isMovingWheelCharInApartmentAlone.value}
                                    validationErrors={isMovingWheelCharInApartmentAlone.validationErrors}
                                    valuesList={[
                                        { value: IsContactPersonDataSameAs.YES, label: translateKey("yes")},
                                        { value: IsContactPersonDataSameAs.NO, label: translateKey("no")}
                                    ]}
                                />
                                <ListComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                                    transKey="form-text-auberhalb"
                                    key="is_moving_wheelchair_outside_alone"
                                    keyName="is_moving_wheelchair_outside_alone"
                                    value={isMovingWheelChairOutsideAlone.value}
                                    validationErrors={isMovingWheelChairOutsideAlone.validationErrors}
                                    valuesList={[
                                        { value: IsContactPersonDataSameAs.YES, label: translateKey("yes")},
                                        { value: IsContactPersonDataSameAs.NO, label: translateKey("no")}
                                    ]}
                                />
                            </React.Fragment>
                        }

                        <div className="hilfsmittel-wrapper">
                            <div className="form-label-basic cursor-pointer quick-label-position-fix">{translateKey("form-text-hilfsmittel")}</div>
                            <div className="checkboxes_wrapper">
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-stock"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_walking_stick')} text={translateKey("form-text-stock")} transKey="form-text-stock" keyName="is_walking_stick"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-rollstuhl"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_wheelchair')} text={translateKey("form-text-rollstuhl")} transKey="form-text-rollstuhl" keyName="is_wheelchair"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-rollator"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_rollator_or_walker_list')} text={translateKey("form-text-rollator")} transKey="form-text-rollator" keyName="is_rollator_or_walker_list"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-transferlifter"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_transfer_lifter')} text={translateKey("form-text-transferlifter")} transKey="form-text-transferlifter" keyName="is_transfer_lifter"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-treppenlifter"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_chair_lift')} text={translateKey("form-text-treppenlifter")} transKey="form-text-treppenlifter" keyName="is_chair_lift"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-pflegebett"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_specialist_bed_list')} text={translateKey("form-text-pflegebett")} transKey="form-text-pflegebett" keyName="is_specialist_bed_list"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-antidekubitusmatratze"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_antibedsore_mattress_list')} text={translateKey("form-text-antidekubitusmatratze")} transKey="form-text-antidekubitusmatratze" keyName="is_antibedsore_mattress_list"/>
                            </div>
                        </div>
                        </fieldset>
                    </form>
                </div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section8));