import * as React from 'react';

export class CarerNameTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            title: this.props.title
        };
    }

    prepareMeName(){
        if(this.props.title === "BB"){
            let realName = "";
            if(this.props.element.lastName){
                realName += this.props.element.lastName.charAt(0).toUpperCase();
                if(realName!==""){
                    realName += '.';
                }
                if(this.props.element.firstName){
                    realName += ' ' + this.props.element.firstName;
                }
            }else{
                if(this.props.element.firstName){
                    realName += ' ' + this.props.element.firstName;
                }
            }
            return realName;
        }else{
            let realName = "";
            if(this.props.element.lastName){
                realName +=this.props.element.lastName;
                if(this.props.element.firstName){
                    realName += ' ' + this.props.element.firstName;
                }
            }else{
                if(this.props.element.firstName){
                    realName += ' ' + this.props.element.firstName;
                }
            }
            return realName;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.prepareMeName()}
            </React.Fragment>
        );
    }
}