import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import SelectComponent from "../../form-bb-components/select-component/SelectComponent";
import produce from "immer";
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';

class Section1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-1'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey, form } = this.props;
        const { section } = this.state;
        let isdisabledfields = disabled;
        const isRelationshipWithPatientOp = section.getFieldByKey('is_relationship_with_patient_op');
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
            isdisabledfields = true
        }
        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={isdisabledfields}>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_contact_person_lastname')} text={translateKey("last_name")} transKey="last_name" keyName="is_contact_person_lastname"/>  
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_contact_person_firstname')} text={translateKey("first_name")} transKey="first_name" keyName="is_contact_person_firstname"/>  
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_contact_person_mobile_phone')} text={translateKey("form-text-section-0-3")} transKey="form-text-section-0-3" keyName="is_contact_person_mobile_phone"/> 
                            </div>
                            
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_contact_person_email')} text={translateKey("email")} transKey="email" keyName="is_contact_person_email"/> 
                            </div>

                            <SelectComponent
                                showValidation={this.props.showValidationErrors}
                                keyName="is_relationship_with_patient_op"
                                valuesList={[
                                    { value: '717700000', label: translateKey("form-text-relationship-0") },
                                    { value: '717700001', label: translateKey("form-text-relationship-1") },
                                    { value: '717700002', label: translateKey("form-text-relationship-2") },
                                    { value: '717700003', label: translateKey("form-text-relationship-3") },
                                    { value: '717700004', label: translateKey("form-text-relationship-4") },
                                    { value: '717700005', label: translateKey("form-text-relationship-5") },
                                    { value: '717700006', label: translateKey("form-text-relationship-6") },
                                    { value: '717700007', label: translateKey("form-text-relationship-7") },
                                    { value: '717700008', label: translateKey("form-text-relationship-9") },
                                    { value: '717700010', label: translateKey('form-text-relationship-10') }
                                ]}
                                value={isRelationshipWithPatientOp.value}
                                validationErrors={isRelationshipWithPatientOp.validationErrors}
                                label={translateKey("form-text-section-0-1")}
                                setTypeChangeToParent={this.setTypeChange}
                            />
                        </fieldset>
                    </form>
                </div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section1));