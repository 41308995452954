export const MockAddress = {
    street: "",
    buildingNumber: "",
    apartmentNumber: "",
    postalCode: "",
    city: "",
    county: "",
    community: "",
    stateprovince: "",
    countryregion: "",
    longitude: "",
    latitude: "",
    location:""
};