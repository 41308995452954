import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import DatePickerComponent from "../../../../_components/date-picker-component/DatePickerComponent"

class LeadPatientDataBasicsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
        };
    }

    getMeLiveAloneText(element){
        let finder = this.props.liveAlone.find(x => x.Value.toString() === element.toString());
        if(finder){
            return finder.Label
        }else{
            return '---'
        }
    }

    handleChangeName(event){
        this.props.element.name = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    name: event
                }
            }
        });
        this.props.editedData();
    }

    handleChangeBorn(event){
        let timestamp = Math.floor(new Date(event).getTime() / 1000);
        this.props.element.birthdate = timestamp;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    birthdate: timestamp
                }
            }
        });
        this.props.editedData();
    }

    handleChangeWeight(event){
        if(event.trim()===''){
            this.props.element.weight = event;
            this.setState(prevState => {
                return {
                    ...prevState,
                    element: {
                        ...prevState.element,
                        weight: event
                    }
                }
            });
            this.props.editedData();
        }else{
            if(/^\d+$/.test(event.trim())){
                this.props.element.weight = parseInt(event);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        element: {
                            ...prevState.element,
                            weight: parseInt(event)
                        }
                    }
                });
                this.props.editedData();
            }
        }
    }

    handleChangeLiveAlone(event){
        this.props.element.liveAlone = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    liveAlone: event
                }
            }
        });
        this.props.editedData();
    }

    createLiveAloneSelect(){
        return (
            <select className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangeLiveAlone(e.target.value)} value={this.props.element.liveAlone}>
            <option value='' hidden>{this.props.translateKey('choose_select')}</option>
            {this.props.liveAlone.map((value, index) => {
                if(value.Value === this.props.element.liveAlone){
                  return <option defaultValue key={"liveAlone-"+value.Value} value={value.Value}>{value.Label}</option>
                }else{
                  return <option  key={"liveAlone-"+value.Value} value={value.Value}>{value.Label}</option>
                }
            })}
            </select>
        )
    }
    

    render() {
        return (
            <div className="lead-details-client-info">
                <div className="col-sm-12 col-lg-5">
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("full_name")}</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeName(e.target.value)} value={this.props.element.name?this.props.element.name:''}/>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("born")}</label>
                        <div className="col-sm-8">
                            <DatePickerComponent withYear={true} key="date_of_birthday_07" timestamp={this.props.element.birthdate} withTime={false} onDateChange={(e)=>this.handleChangeBorn(e)}/>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("weight")}</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeWeight(e.target.value)} value={this.props.element.weight?this.props.element.weight:''}/>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("live_alone")}</label>
                        <div className="col-sm-8">
                            {this.createLiveAloneSelect()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {liveAlone: state.enums.liveAlone};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientDataBasicsComponent));