import {
  SET_DISEASES_ENUM,
  PENDING_DISEASES_ENUM,
  REMOVE_DISEASES_ENUM,
  SET_CAREDEGREEAPPLICATION_ENUM,
  PENDING_CAREDEGREEAPPLICATION_ENUM,
  REMOVE_CAREDEGREEAPPLICATION_ENUM,
  SET_INVOICEPAYMENT_ENUM,
  PENDING_INVOICEPAYMENT_ENUM,
  REMOVE_INVOICEPAYMENT_ENUM,
  SET_CAREOTHERREQUIREMENTS_ENUM,
  PENDING_CAREOTHERREQUIREMENTS_ENUM,
  REMOVE_CAREOTHERREQUIREMENTS_ENUM,
  SET_CONTRACTRATETYPE_ENUM,
  PENDING_CONTRACTRATETYPE_ENUM,
  REMOVE_CONTRACTRATETYPE_ENUM,
  SET_HEALTHSTATE_ENUM,
  PENDING_HEALTHSTATE_ENUM,
  REMOVE_HEALTHSTATE_ENUM,
  SET_TASKPRIORITY_ENUM,
  PENDING_TASKPRIORITY_ENUM,
  REMOVE_TASKPRIORITY_ENUM,
  SET_LEADSTATUS_ENUM,
  PENDING_LEADSTATUS_ENUM,
  REMOVE_LEADSTATUS_ENUM,
  SET_INVOICEAPPROVALSTATUS_ENUM,
  PENDING_INVOICEAPPROVALSTATUS_ENUM,
  REMOVE_INVOICEAPPROVALSTATUS_ENUM,
  SET_CONTRACTSTATUS_ENUM,
  PENDING_CONTRACTSTATUS_ENUM,
  REMOVE_CONTRACTSTATUS_ENUM,
  SET_LIVEALONE_ENUM,
  PENDING_LIVEALONE_ENUM,
  REMOVE_LIVEALONE_ENUM,
  SET_LEADSOURCE_ENUM,
  PENDING_LEADSOURCE_ENUM,
  REMOVE_LEADSOURCE_ENUM,
  SET_ACTIVITYTYPE_ENUM,
  PENDING_ACTIVITYTYPE_ENUM,
  REMOVE_ACTIVITYTYPE_ENUM,
  SET_INVOICECORRECTION_ENUM,
  PENDING_INVOICECORRECTION_ENUM,
  REMOVE_INVOICECORRECTION_ENUM,
  SET_CAREDEGREE_ENUM,
  PENDING_CAREDEGREE_ENUM,
  REMOVE_CAREDEGREE_ENUM,
  SET_SMOKINGATTITUDE_ENUM,
  PENDING_SMOKINGATTITUDE_ENUM,
  REMOVE_SMOKINGATTITUDE_ENUM,
  SET_CALLDIRECTION_ENUM,
  PENDING_CALLDIRECTION_ENUM,
  REMOVE_CALLDIRECTION_ENUM,
  FINISH_BASIC_DATA_SAVE,
  REMOVE_ALL_BASIC_DATA_SAVE,
  SET_CITIES_ENUM,
  PENDING_CITIES_ENUM,
  REMOVE_CITIES_ENUM,
  SET_MARKETING_ENUM,
  PENDING_MARKETING_ENUM,
  REMOVE_MARKETING_ENUM,
  SET_REJECT_REASON_ENUM,
  PENDING_REJECT_REASON_ENUM,
  REMOVE_REJECT_REASON_ENUM
} from "../actions/action-types";
import { ResponseStatus } from '../../_helpers/response_status'

const initialState = {
  diseases: [],
  diseasesStatus: ResponseStatus.FREE,
  careDegreeApplication: [],
  careDegreeApplicationStatus: ResponseStatus.FREE,
  invoicePayment: [],
  invoicePaymentStatus: ResponseStatus.FREE,
  carerOtherRequirements: [],
  carerOtherRequirementsStatus: ResponseStatus.FREE,
  contractRateType: [],
  contractRateTypeStatus: ResponseStatus.FREE,
  healthState: [],
  healthStateStatus: ResponseStatus.FREE,
  taskPriority: [],
  taskPriorityStatus: ResponseStatus.FREE,
  leadStatus: [],
  leadStatusStatus: ResponseStatus.FREE,
  invoiceApproval: [],
  invoiceApprovalStatus: ResponseStatus.FREE,
  contractStatus: [],
  contractStatusStatus: ResponseStatus.FREE,
  liveAlone: [],
  liveAloneStatus: ResponseStatus.FREE,
  leadSource: [],
  leadSourceStatus: ResponseStatus.FREE,
  activityType: [],
  activityTypeStatus: ResponseStatus.FREE,
  invoiceCorrection: [],
  invoiceCorrectionStatus: ResponseStatus.FREE,
  careDegree: [],
  careDegreeStatus: ResponseStatus.FREE,
  smokingAttitude: [],
  smokingAttitudeStatus: ResponseStatus.FREE,
  callDirection: [],
  callDirectionStatus: ResponseStatus.FREE,
  citiesList: [],
  citiesListStatus: ResponseStatus.FREE,
  marketingsList: [],
  marketingsListStatus: ResponseStatus.FREE,
  rejectReasonsList: [],
  rejectReasonsListStatus: ResponseStatus.FREE,
  finishSave: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISEASES_ENUM:
        return {
          ...state,
          diseases: action.data,
          diseasesStatus: ResponseStatus.READY
        };
    case PENDING_DISEASES_ENUM:
        return {
          ...state,
          diseasesStatus: ResponseStatus.PENDING
        };
    case REMOVE_DISEASES_ENUM:
        return {
          ...state,
          diseases: [],
          diseasesStatus: ResponseStatus.FREE,
        };
    case SET_CAREDEGREEAPPLICATION_ENUM:
        return {
          ...state,
          careDegreeApplication: action.data,
          careDegreeApplicationStatus: ResponseStatus.READY
        };
    case PENDING_CAREDEGREEAPPLICATION_ENUM:
        return {
          ...state,
          careDegreeApplicationStatus: ResponseStatus.PENDING
        };
    case REMOVE_CAREDEGREEAPPLICATION_ENUM:
        return {
          ...state,
          careDegreeApplication: [],
          careDegreeApplicationStatus: ResponseStatus.FREE,
        };
    case SET_INVOICEPAYMENT_ENUM:
        return {
          ...state,
          invoicePayment: action.data,
          invoicePaymentStatus: ResponseStatus.READY
        };
    case PENDING_INVOICEPAYMENT_ENUM:
        return {
          ...state,
          invoicePaymentStatus: ResponseStatus.PENDING
        };
    case REMOVE_INVOICEPAYMENT_ENUM:
        return {
          ...state,
          invoicePayment: [],
          invoicePaymentStatus: ResponseStatus.FREE,
        };
    case SET_CAREOTHERREQUIREMENTS_ENUM:
        return {
          ...state,
          carerOtherRequirements: action.data,
          carerOtherRequirementsStatus: ResponseStatus.READY
        };
    case PENDING_CAREOTHERREQUIREMENTS_ENUM:
        return {
          ...state,
          carerOtherRequirementsStatus: ResponseStatus.PENDING
        };
    case REMOVE_CAREOTHERREQUIREMENTS_ENUM:
        return {
          ...state,
          carerOtherRequirements: [],
          carerOtherRequirementsStatus: ResponseStatus.FREE,
        };
    case SET_CONTRACTRATETYPE_ENUM:
        return {
          ...state,
          contractRateType: action.data,
          contractRateTypeStatus: ResponseStatus.READY
        };
    case PENDING_CONTRACTRATETYPE_ENUM:
        return {
          ...state,
          contractRateTypeStatus: ResponseStatus.PENDING
        };
    case REMOVE_CONTRACTRATETYPE_ENUM:
        return {
          ...state,
          contractRateType: [],
          contractRateTypeStatus: ResponseStatus.FREE,
        };
    case SET_HEALTHSTATE_ENUM:
        return {
          ...state,
          healthState: action.data,
          healthStateStatus: ResponseStatus.READY
        };
    case PENDING_HEALTHSTATE_ENUM:
        return {
          ...state,
          healthStateStatus: ResponseStatus.PENDING
        };
    case REMOVE_HEALTHSTATE_ENUM:
        return {
          ...state,
          healthState: [],
          healthStateStatus: ResponseStatus.FREE,
        };
    case SET_TASKPRIORITY_ENUM:
        return {
          ...state,
          taskPriority: action.data,
          taskPriorityStatus: ResponseStatus.READY
        };
    case PENDING_TASKPRIORITY_ENUM:
        return {
          ...state,
          taskPriorityStatus: ResponseStatus.PENDING
        };
    case REMOVE_TASKPRIORITY_ENUM:
        return {
          ...state,
          taskPriority: [],
          taskPriorityStatus: ResponseStatus.FREE,
        };
    case SET_LEADSTATUS_ENUM:
        return {
          ...state,
          leadStatus: action.data,
          leadStatusStatus: ResponseStatus.READY
        };
    case PENDING_LEADSTATUS_ENUM:
        return {
          ...state,
          leadStatusStatus: ResponseStatus.PENDING
        };
    case REMOVE_LEADSTATUS_ENUM:
        return {
          ...state,
          leadStatus: [],
          leadStatusStatus: ResponseStatus.FREE,
        };
    case SET_INVOICEAPPROVALSTATUS_ENUM:
        return {
          ...state,
          invoiceApproval: action.data,
          invoiceApprovalStatus: ResponseStatus.READY
        };
    case PENDING_INVOICEAPPROVALSTATUS_ENUM:
        return {
          ...state,
          invoiceApprovalStatus: ResponseStatus.PENDING
        };
    case REMOVE_INVOICEAPPROVALSTATUS_ENUM:
        return {
          ...state,
          invoiceApproval: [],
          invoiceApprovalStatus: ResponseStatus.FREE,
        };
    case SET_CONTRACTSTATUS_ENUM:
        return {
          ...state,
          contractStatus: action.data,
          contractStatusStatus: ResponseStatus.READY
        };
    case PENDING_CONTRACTSTATUS_ENUM:
        return {
          ...state,
          contractStatusStatus: ResponseStatus.PENDING
        };
    case REMOVE_CONTRACTSTATUS_ENUM:
        return {
          ...state,
          contractStatus: [],
          contractStatusStatus: ResponseStatus.FREE,
        };
    case SET_LIVEALONE_ENUM:
        return {
          ...state,
          liveAlone: action.data,
          liveAloneStatus: ResponseStatus.READY
        };
    case PENDING_LIVEALONE_ENUM:
        return {
          ...state,
          liveAloneStatus: ResponseStatus.PENDING
        };
    case REMOVE_LIVEALONE_ENUM:
        return {
          ...state,
          liveAlone: [],
          liveAloneStatus: ResponseStatus.FREE,
        };
    case SET_LEADSOURCE_ENUM:
        return {
          ...state,
          leadSource: action.data,
          leadSourceStatus: ResponseStatus.READY
        };
    case PENDING_LEADSOURCE_ENUM:
        return {
          ...state,
          leadSourceStatus: ResponseStatus.PENDING
        };
    case REMOVE_LEADSOURCE_ENUM:
        return {
          ...state,
          leadSource: [],
          leadSourceStatus: ResponseStatus.FREE,
        };
    case SET_ACTIVITYTYPE_ENUM:
        return {
          ...state,
          activityType: action.data,
          activityTypeStatus: ResponseStatus.READY
        };
    case PENDING_ACTIVITYTYPE_ENUM:
        return {
          ...state,
          activityTypeStatus: ResponseStatus.PENDING
        };
    case REMOVE_ACTIVITYTYPE_ENUM:
        return {
          ...state,
          activityType: [],
          activityTypeStatus: ResponseStatus.FREE,
        };
    case SET_INVOICECORRECTION_ENUM:
        return {
          ...state,
          invoiceCorrection: action.data,
          invoiceCorrectionStatus: ResponseStatus.READY
        };
    case PENDING_INVOICECORRECTION_ENUM:
        return {
          ...state,
          invoiceCorrectionStatus: ResponseStatus.PENDING
        };
    case REMOVE_INVOICECORRECTION_ENUM:
        return {
          ...state,
          invoiceCorrection: [],
          invoiceCorrectionStatus: ResponseStatus.FREE,
        };
    case SET_CAREDEGREE_ENUM:
        return {
          ...state,
          careDegree: action.data,
          careDegreeStatus: ResponseStatus.READY
        };
    case PENDING_CAREDEGREE_ENUM:
        return {
          ...state,
          careDegreeStatus: ResponseStatus.PENDING
        };
    case REMOVE_CAREDEGREE_ENUM:
        return {
          ...state,
          careDegree: [],
          careDegreeStatus: ResponseStatus.FREE,
        };
    case SET_SMOKINGATTITUDE_ENUM:
        return {
          ...state,
          smokingAttitude: action.data,
          smokingAttitudeStatus: ResponseStatus.READY
        };
    case PENDING_SMOKINGATTITUDE_ENUM:
        return {
          ...state,
          smokingAttitudeStatus: ResponseStatus.PENDING
        };
    case REMOVE_SMOKINGATTITUDE_ENUM:
        return {
          ...state,
          smokingAttitude: [],
          smokingAttitudeStatus: ResponseStatus.FREE,
        };
    case SET_CALLDIRECTION_ENUM:
        return {
          ...state,
          callDirection: action.data,
          callDirectionStatus: ResponseStatus.READY
        };
    case PENDING_CALLDIRECTION_ENUM:
        return {
          ...state,
          callDirectionStatus: ResponseStatus.PENDING
        };
    case REMOVE_CALLDIRECTION_ENUM:
        return {
          ...state,
          callDirection: [],
          callDirectionStatus: ResponseStatus.FREE,
        };
    case SET_CITIES_ENUM:
        return {
          ...state,
          citiesList: action.data,
          citiesListStatus: ResponseStatus.READY
        };
    case PENDING_CITIES_ENUM:
        return {
          ...state,
          citiesListStatus: ResponseStatus.PENDING
        };
    case REMOVE_CITIES_ENUM:
        return {
          ...state,
          citiesList: [],
          citiesListStatus: ResponseStatus.FREE,
        };
    case SET_MARKETING_ENUM:
        return {
          ...state,
          marketingsList: action.data,
          marketingsListStatus: ResponseStatus.READY
        };
    case PENDING_MARKETING_ENUM:
        return {
          ...state,
          marketingsListStatus: ResponseStatus.PENDING
        };
    case REMOVE_MARKETING_ENUM:
        return {
          ...state,
          marketingsList: [],
          marketingsListStatus: ResponseStatus.FREE,
        };
    case SET_REJECT_REASON_ENUM:
        return {
          ...state,
          rejectReasonsList: action.data,
          rejectReasonsListStatus: ResponseStatus.READY
        };
    case PENDING_REJECT_REASON_ENUM:
        return {
          ...state,
          rejectReasonsListStatus: ResponseStatus.PENDING
        };
    case REMOVE_REJECT_REASON_ENUM:
        return {
          ...state,
          rejectReasonsList: [],
          rejectReasonsListStatus: ResponseStatus.FREE,
        };
    case FINISH_BASIC_DATA_SAVE:
        return {
          ...state,
          finishSave: true
        };
    case REMOVE_ALL_BASIC_DATA_SAVE:
        return {
          ...initialState
        };
    default:
      return state;
  }
};

export default reducer;