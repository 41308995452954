import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";
import * as TaskType from "../../../../_constance/values/taskType";
import * as TaskStatus from "../../../../_constance/values/taskStatus";
import { baseData } from '../../../../_constance/base_data';

class TasksStatusTable extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      typeTask: 0,
      typeCall: 0,
      typeEvent: 0,
      loadingDashboard: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    let task = 0;
    let call = 0;
    let event = 0;
    this.props.tasksData.map(element => {
      switch (element.taskType) {
        case TaskType.TASK:
          if (element.status === TaskStatus.OPEN) {
            task = task + 1;
          }
          break;
        case TaskType.CALL:
          if (element.status === TaskStatus.OPEN) {
            call = call + 1;
          }
          break;
        case TaskType.EVENT:
          if (element.status === TaskStatus.OPEN || element.status === TaskStatus.SCHEDULED) {
            event = event + 1;
          }
          break;
        default:
      }
      return element;
    });
    this.setState({
      loadingDashboard: false,
      typeTask: task,
      typeCall: call,
      typeEvent: event
    });
  }

  handleClick(type, amount){
    if(type!== null && type!==undefined && amount>0){
      this.props.history.push(`${baseData.subFolders}tasks/?type=type&param=${type}`);
    }
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingStatus } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingStatus){
      Content = (
          <table className="table table-dashboard">
            <thead>
              <tr>
                <th className="dashboard-table-head"></th>
                <th className="dashboard-table-head text-right">{translateKey("task_task")}</th>
                <th className="dashboard-table-head text-right">{translateKey("task_call")}</th>
                <th className="dashboard-table-head text-right">{translateKey("task_event")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("task_open")}</td>
                <td className={"table-cell-wrapper-dashboard text-right"+(this.state.typeTask>0? " cursor-pointer": "")} onClick={()=>this.handleClick(translateKey("task_task"),this.state.typeTask)}>{this.state.typeTask}</td>
                <td className={"table-cell-wrapper-dashboard text-right"+(this.state.typeCall>0? " cursor-pointer": "")} onClick={()=>this.handleClick(translateKey("task_call"),this.state.typeCall)}>{this.state.typeCall}</td>
                <td className={"table-cell-wrapper-dashboard text-right"+(this.state.typeEvent>0? " cursor-pointer": "")} onClick={()=>this.handleClick(translateKey("task_event"),this.state.typeEvent)}>{this.state.typeEvent}</td>
              </tr>
            </tbody>
          </table>
      )
    }
    return (
      <React.Fragment>
        <div className="table-container-wrapper no_padding">
          <div className="table-name-class">{translateKey("tasks")}</div>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {return {tasksData: state.database.tasks};};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TasksStatusTable));