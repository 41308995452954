import * as React from 'react';

import ValidationErrorsList from '../../validation-errors-list/validation-errors-list';

export class InputTypeTextComponent extends React.Component {
    static defaultProps = {
        valueFormatter: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            isIm: props.isImportant
        };
    }

    setTypeChange(key, value){
        const valueFormatted = typeof valueFormatter === 'function' ? this.props.valueFormatter(value) : value;
        this.props.setTypeChangeToParent(key, valueFormatted);
    }

    render() {
        const { field, showValidation, keyName, valueFormatter, disabled, isImportant } = this.props;
        const validationErrors = field.validationErrors;
        const showErrors = validationErrors.length > 0 && showValidation;

        const value = typeof valueFormatter === 'function' ? valueFormatter(field.value) : field.value;

        return (
            <div className={"col-sm-12 "+this.props.className}>
                <label className="form-label-basic"><span className={`quick-label-position-fix ${isImportant&&field.checkImportant ? 'input_important_class' : ''}`}>{this.props.text}</span></label>
                <input type="text" disabled={disabled} className={`form-control ${showErrors ? 'is-invalid' : ''} ${isImportant&&field.checkImportant ? 'input_important_input' : ''}`} data-field-name={this.props.keyName} onChange={e => this.setTypeChange(this.props.keyName, e.target.value)} value={value}/>
                { showErrors && (
                    <ValidationErrorsList keyName={keyName} errors={validationErrors} />
                )}
            </div>
        );
    }
}
