import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { addNoteData, removeNoteData } from "../../../_redux/actions/auth";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, LoaderModal, ConfirmModal, TableDateFormat } from "../../index";
import { SortingNotes } from "../../../_constance/classes/sortingClasses/index";
import { Alert, Paging} from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';


class ClientNotesData extends React.Component {
    _isMounted = false;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: JSON.parse(JSON.stringify(SortingNotes)),
            errorComponent: Alert,
            pagingItem: Paging,
            retries: 0,
            noteData: {Id:0, Note: ''},
            noteTemp: {Id:0, Note: ''},
            saveErrorMessage: '',
            showEdit: false, 
            loaderModal: false,
            clientId: this.props.clientId,
            type: "EDIT",
            errorModal: false,
            confirmDeleteNotes: false,
            noteToDelete: {},
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        this.getInitialDataTime();
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    getInitialDataTime(){
        if(this.props.notesData.length>0){
          clearTimeout(this.myTimeout);
          this.filterOrDataChange();
        }else{
            if(this.state.retries<10){
                if (this._isMounted) {
                    this.setState({retries: this.state.retries+1})
                }
                this.myTimeout = setTimeout(() => {
                    this.getInitialDataTime()
                  }, 500);
            }else{
                if (this._isMounted) {
                    this.setState({
                        retries: 0,
                        loading: false
                    })
                }
            }
        }
    }
    
    filterOrDataChange(){
        let finalDataToShow = this.props.notesData;
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            if (this._isMounted) {
                this.setState({
                    data: finalData,
                    loading: false
                })
            }
        }, 50);
    }
    
    propsSortType(type){
        let sortingObj = [];
        for(let i=0; i<this.state.sorting.length; i++){
            if(this.state.sorting[i].column===type){
                if(this.state.sorting[i].active===true){
                    let item = this.state.sorting[i];
                    item.type==='ASC'? item.type="DESC" : item.type='ASC';
                    sortingObj.push(item);
                }else{
                    let item = this.state.sorting[i];
                    item.type='ASC';
                    item.active = true;
                    sortingObj.push(item);
                }
            }else{
                let item = this.state.sorting[i];
                item.type='ASC';
                item.active = false;
                sortingObj.push(item);
            }
        }
        this.setState({sorting: sortingObj});
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        pagingItem: {
                            totalItems: data.length,
                            itemsPerPage: prevState.pagingItem.itemsPerPage,
                            currentPage: 1,
                            visiblePages: 5,
                            pageFrom: 1,
                            pageTo: 5,
                            totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                        }
                    }
                });
            }
        }else{
            if (this._isMounted) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        pagingItem: {
                        ...prevState.pagingItem,
                        totalItems: data.length,
                        pageFrom: prevState.pagingItem.pageFrom,
                        pageTo: prevState.pagingItem.pageTo,
                        currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                        totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                        }
                    }
                });
            }
        }
    }

    rejectModalToggle = () => {
        if(this.state.editToggle===true){
            this.addClick();
        }
        if (this._isMounted) {
            this.setState({editToggle: !this.state.editToggle});
        }
    }

    errorModal = () => {
        if (this._isMounted) {
            this.setState({errorModal: !this.state.errorModal});
        }
    }

    openModalWithNoteData(element){
        if (this._isMounted) {
            this.setState({
                noteData: JSON.parse(JSON.stringify(element)),
                noteTemp: JSON.parse(JSON.stringify(element)),
                showEdit: true,
                type: "EDIT"
            })
        }
    }

    noteChange(text){
        if (this._isMounted) {
            this.setState({
                noteData:{
                    ...this.state.noteData,
                    Note: text
                }
            });
        }
    }

    cancelEdit = () =>{
        if (this._isMounted) {
            this.setState({
                noteData: this.state.noteTemp,
                showEdit: false
            })
        }
        this.addClick();
    }

    saveNoteData = ()=>{
        if (this._isMounted) {
            this.setState({loaderModal: true})
        }
        this.props.addNote(this.state.noteData, this.state.clientId, "CLIENT")
        .then((res)=>{
            let newObj={
                Id: res,
                Note: this.state.noteData.Note,
                Created: Math.floor(new Date().getTime() / 1000),
                CreatedBy: ReqHelper.prepareMeLoginName(this.props.loginName)
            }
            if (this._isMounted) {
                this.setState(previousState => ({
                    ...previousState,
                    loaderModal: false,
                    showEdit: false,
                    data: [...previousState.data, newObj]
                }));
            }
            this.props.add(newObj);
            this.props.closeAdd();
            this.filterOrDataChange();
        })
        .catch((err)=>{
            console.log(err);
            if (this._isMounted) {
                this.setState({
                    loaderModal: false,
                    saveErrorMessage: this.props.translateKey("fail_to_add_note")
                })
            }
            this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                    this.setState({saveErrorMessage: ''});
                }
            }, 2000);
        })
    }

    addClick = () =>{
        if (this._isMounted) {
            this.setState({
                noteData: {
                    Id: 0,
                    Note: ''
                },
                noteTemp:  {
                    Id: 0,
                    Note: ''
                },
                type: "ADD"
            })
        }
    }

    removeItem(item){
        if (this._isMounted) {
            this.setState({
                confirmDeleteNotes: true,
                noteToDelete: item
            })
        }
    }

    removeNote = () =>{
        if (this._isMounted) {
            this.setState({loaderModal: true})
        }
        this.props.removeNote(this.state.noteToDelete.Id)
        .then((res)=>{
            this.props.remove(this.state.noteToDelete);
            if (this._isMounted) {
                this.setState({
                    confirmDeleteNotes: false,
                    loaderModal: false,
                    data: this.state.data.filter(value => value.Id !== this.state.noteToDelete.Id)
                })
            }
            this.filterOrDataChange();
        })
        .catch((err)=>{
            console.log(err);
            if (this._isMounted) {
                this.setState({
                    confirmDeleteNotes: true,
                    loaderModal: false,
                    errorModal: true,
                    errorComponent: {
                        ...this.state.errorComponent,
                        message: this.props.translateKey("fail_to_remove_note")
                    }
                })
            }
        })
      }
    
    rejectMeNote = () =>{
        if (this._isMounted) {
            this.setState({
            confirmDeleteNotes: false,
            loaderModal: false
            })
        }
    }

    render() {
        const {translateKey} = this.props;
        let emptyHandler;
        let patientData;
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={translateKey("no_data_to_show")} /></div>
            }else{
                patientData = this.state.data.map(element => (
                <tr className="table-row-wrapper cursor-pointer" key={element.Id}>
                    <td className={"table-cell-wrapper " + (element.Note !== null ? "got-data" : 'no-data')} onClick={()=>{this.openModalWithNoteData(element)}}>{element.Note}</td>
                    <td className={"table-cell-wrapper " + (element.CreatedBy !== null ? "got-data" : 'no-data')} onClick={()=>{this.openModalWithNoteData(element)}}>{element.CreatedBy}</td>
                    <td className={"table-cell-wrapper " + (element.Created !== null ? "got-data" : 'no-data')} onClick={()=>{this.openModalWithNoteData(element)}}><TableDateFormat timestamp={element.Created}/></td>
                    <td className="table-cell-wrapper remove-icon-item-table" onClick={()=> this.removeItem(element)}><i className="fas fa-trash-alt fa-2x empty-table-icon cursor-pointer remove-icon-item"></i></td>
                </tr>
                ))
            }
        }
        let alertModal = (
            <MDBModal isOpen={this.state.errorModal} toggle={this.errorModal} size="lg">
                <MDBModalBody>
                    <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                        {this.state.errorComponent.message}
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <button type="button" className="btn btn-primary" onClick={this.errorModal}>{translateKey('Ok')}</button>
                </MDBModalFooter>
            </MDBModal>
        )
        let noteEditModal = (
            <MDBModal isOpen={this.state.showEdit} toggle={this.editToggle} size="lg">
                <MDBModalHeader toggle={this.editToggle}>{translateKey('note')}</MDBModalHeader>
                    <MDBModalBody>
                        <span>{this.state.noteData.Note}</span>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <button type="button" className="btn btn-primary" onClick={this.cancelEdit}>{translateKey('Ok')}</button>
                    </MDBModalFooter>
            </MDBModal>
        )

        let addNotes = (
            <MDBModal isOpen={this.props.showAddModal} toggle={this.props.closeAdd} size="lg">
                <MDBModalHeader toggle={this.props.closeAdd}>{translateKey('note')}</MDBModalHeader>
                    <MDBModalBody>
                        <div>
                            <textarea type="text" className="form-control" rows="3" onChange={e => this.noteChange(e.target.value)} value={this.state.noteData.Note}/>
                            {this.state.saveErrorMessage.length>0?
                                <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                                    {this.state.saveErrorMessage}
                                </div>
                                :
                                ''
                            }
                        </div>
                    </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn btn-secondary" onClick={this.props.closeAdd}>{translateKey('cancel')}</button>
                            <button type="button" className="btn btn-primary" onClick={()=>this.saveNoteData()}>{translateKey('save')}</button>
                        </MDBModalFooter>
                    </MDBModal>
        )
        return (
            <div className="client-data-wrapper-tab">
                <LoaderModal show={this.state.loaderModal}/>
                <ConfirmModal rejectMe={this.rejectMeNote} acceptMe={this.removeNote} show={this.state.confirmDeleteNotes} titleMessage="" mainMessage={translateKey("delete")+"?"} messageYes={translateKey("yes")} messageNo={translateKey("no")}/>
                {addNotes}
                {noteEditModal}
                {alertModal}
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                            <TableColumHead sorting={this.state.sorting} name={translateKey("note")} onSortType={(data) => this.propsSortType(data)} type="Note" />
                            <TableColumHead sorting={this.state.sorting} name={translateKey("author")} onSortType={(data) => this.propsSortType(data)} type="CreatedBy" />
                            <TableColumHead sorting={this.state.sorting} name={translateKey("created")} onSortType={(data) => this.propsSortType(data)} type="Created" />
                            <th scope="col" className="table-add-data"></th>
                            </tr>
                        </thead>
                        <tbody>{patientData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    loginName: state.auth.appLogin,
    userId: state.auth.userId
}}
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    addNote: (note, userid, type) => dispatch(addNoteData(note, userid, type)),
    removeNote: (id) => dispatch(removeNoteData(id)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientNotesData));