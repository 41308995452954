import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { 
  setLeadsData, 
  pendingLeadsData, 
  removeLeadsData, 
  translateKey, 
  authSetActionTimestamp, 
  removeLeadStatusData, 
  getLeadStatusData, 
  updateLeadData, 
  getTasks, 
  setTasksData,
  needsSaveData, 
  doesNotNeedsSave
} from "../../_redux/actions/index";
import { 
  ConfirmModal,
  LoaderModal,
  TableProperties,
  TableColumHead,
  TablePagination,
  NavBar,
  SideBar, 
  LoaderTable,
  EmptyTableIcon,
  LeadDetailsBasicComponent,
  LeadClientComponent,
  LeadPatientComponent,
  LeadCancelSaveComponent,
  TableDateFormat,
  TaskStatusHandler,
  ConsentListComponent,
  AlertModalInfo,
  TaskPriorityConverter
} from "../../_components/index";
import { Alert, LeadTable, Paging, ContractTable } from "../../_constance/classes/index";
import { IconsMap, ReqHelper, shouldRefresh } from "../../_helpers/index";
import { addNoteData, removeNoteData, leadSave } from "../../_redux/actions/auth";
import { ResponseStatus } from '../../_helpers/response_status'
import axiosAuth from "../../_services/config/axios-auth";
import { SortingTasks, SortingNotes, SortingPatient } from "../../_constance/classes/sortingClasses/index";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { refreshTokenTime } from '../../_constance/base_data';
import { LeadDetailsPatientsDataComponent, LeadContractData}  from '../../_components/LeadDetailsComponents/index';
import { TableEnums } from "../../_constance";
import * as RecordType from "../../_constance/values/recordType";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as Entity from '../../_forms/BBForm/values/entity';
import { getDynamicFields, saveDynamicFields } from '../../_services/user_service';
import { baseData } from '../../_constance/base_data';
const statisContractOffertStatus = '717700010';

class LeadsDetailsPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  autoSaveTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      multiId: 0,
      activeTab: "lead",
      leadId: 0,
      leadData: false,
      leadBackup: false,
      savingDataLead: false,
      errorComponent: Alert,
      loadingLeads: true,
      loadingPatients: true,
      loadingNotes: true,
      leadNotesData: [],
      loadingTasks: true,
      leadTaskData:[],
      leadReminderTasks: [],
      contractsData: [],
      loadingContracts: false,
      patientPaging: Paging,
      notesPaging: Paging,
      tasksPaging: Paging,
      sortingPatients: JSON.parse(JSON.stringify(SortingPatient)),
      sortingNotes: JSON.parse(JSON.stringify(SortingNotes)),
      sortingTasks: new SortingTasks(),
      noteData: {Id:0, Note: ''},
      noteTemp: {Id:0, Note: ''},
      saveErrorMessage: '',
      showEdit: false, 
      loaderModal: false,
      type: "EDIT",
      errorModal: false,
      canAddContract: false,
      confirmDeleteNotes: false,
      noteToDelete: {},
      showValidation: false,
      consentsDe7: false,
      dynamics:{
        is_lead_description: null,
        is_degree_relationship_with_patient_carer: null,
        is_last_note: null,
        is_last_call: null,
        is_last_meeting: null,
        is_referral_other: false,
        is_other_source_description: null,
        is_sid: null
      },
      dynamicsTemp:{
        is_lead_description: null,
        is_degree_relationship_with_patient_carer: null,
        is_last_note: null,
        is_last_call: null,
        is_last_meeting: null,
        is_referral_other: false,
        is_other_source_description: null,
        is_sid: null
      },
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ],
      assasmentDates: {
        birthdate: null,
        anniversary: null,
      }
    };
  }

  componentDidUpdate() {
    if (this.props.needsSave) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.props.doesNotNeedsSave();
    this.getInitIsLeadDescription();
    this.myTimeout = setTimeout(() => {this.getInitialDataTime();}, 200);
    this.autoSaveTimeOut = setInterval(() => {
      this.checkIfShouldWeSave();
    }, 15000);
    this.getDatesFromDynamic();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              leadData: {...prevState.leadBackup}
          }
        });
      }
    }
    clearInterval(this.autoSaveTimeOut);
    clearTimeout(this.myTimeout);
  };

  getInitIsLeadDescription(){
    const { match: { params } } = this.props;
    let dataToSend = [
      {
          "Id": params.leadId,
          "Entity": Entity.CONTACT,
          "Fields": [
            "is_lead_description",
            "is_degree_relationship_with_patient_carer",
            "is_last_note",
            "is_last_call",
            "is_last_meeting",
            "is_referral_other",
            "is_other_source_description",
            "is_sid"
          ]
      }
    ];
    getDynamicFields(dataToSend).then(res => {
      if(res.length>0){
        if (this._isMounted) {
          const isLastCallDate = new Date(res[0].fields["is_last_call"]).getTime();
          const isLastMeetingDate = new Date(res[0].fields["is_last_meeting"]).getTime();
          this.setState({
            dynamics:{
              is_lead_description: res[0].fields["is_lead_description"],
              is_degree_relationship_with_patient_carer: res[0].fields["is_degree_relationship_with_patient_carer"],
              is_last_note: res[0].fields["is_last_note"],
              is_last_call: isLastCallDate,
              is_last_meeting: isLastMeetingDate,
              is_referral_other: res[0].fields["is_referral_other"],
              is_other_source_description: res[0].fields["is_other_source_description"],
              is_sid: res[0].fields["is_sid"],
            },
            dynamicsTemp:{
              is_lead_description: res[0].fields["is_lead_description"],
              is_degree_relationship_with_patient_carer: res[0].fields["is_degree_relationship_with_patient_carer"],
              is_last_note: res[0].fields["is_last_note"],
              is_last_call: isLastCallDate,
              is_last_meeting: isLastMeetingDate,
              is_referral_other: res[0].fields["is_referral_other"],
              is_other_source_description: res[0].fields["is_other_source_description"],
              is_sid: res[0].fields["is_sid"],
            }
          })
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  setActiveTab(tabName){
    if (this._isMounted) {
      this.setState({activeTab: tabName})
    }
  }

  checkIfShouldWeSave = () =>{
    const currentTime = new Date().getTime();
    if(this.props.lastActionTime - currentTime <= 30000){
      this.saveChange(true);
    }
  }

  getInitialDataTime(){
    if(this.props.leadsStatus.length>0){
      clearTimeout(this.myTimeout);
      this.getLeadsDataByTimestamp();
    }else{
      this.props.getStatusLead(this.props.appLang);
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 1000);
    }
  }

  getMeStatusText(status){
    let text = '';
    let statuses = this.props.leadsStatus;
    for(let i=0; i<statuses.length; i++){
      if(statuses[i].Value===status.toString()){
        text = statuses[i].Label;
        break;
      }
    }
    return text;
  }

  getMeTextStatus(text){
    let status = '';
    let statuses = this.props.leadsStatus;
    for(let i=0; i<statuses.length; i++){
      if(statuses[i].Label===text){
        status = statuses[i].Value;
        break;
      }
    }
    return status;
  }

  getLeadsList = () => {
    this.props.pendingRequestLeads();
    axiosAuth.get("leads/list/0/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          element.typeText = this.getMeStatusText(element.status);
          return new LeadTable(element);
        });
        this.props.setLeadsData(mapedRes);
        this.getDataBasic();
      })
      .catch((error) => {
        this.props.clearDataLeads();
        this.getDataBasic();
      });
  }

  getLeadsDataByTimestamp(){
      const lastUpdate = this.props.leadsTimestamp;
      const actualTime = new Date().getTime();
      if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
        this.getLeadsList();
      } else {
        if(this.props.leadsData.length>0){
          this.getDataBasic();
        }else{
          this.getLeadsList();
        }
      }
  }

  ///// Dynamic

  getDynamicMulti(){
    const { match: { params } } = this.props;
    let dataToSend = [
      {
          "Id": params.leadId,
          "Entity": Entity.CONTACT,
          "Fields": ["is_contact_recomendedid"]
      }
    ];
    getDynamicFields(dataToSend).then(res => {
      if(res.length>0){
        if(res[0].fields["is_contact_recomendedid"] !==null && res[0].fields["is_contact_recomendedid"] !== undefined ){
          if (this._isMounted) {
            this.setState({
              multiId: res[0].fields["is_contact_recomendedid"]
            })
          }
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  changeMultiValue = (value) =>{
    if (this._isMounted) {
      this.setState({multiId: value})
    }
    if(value!==0){this.basicEdit();}
  }
  ///// BASIC CONTRACT TAB DATA LOAD

  getBasicContractsData(){
    if (this._isMounted) {
      this.setState({loadingContracts: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/contracts/list/"+params.leadId+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new ContractTable(element);});
        if (this._isMounted) {
          this.setState({
            contractsData: mapedRes,
            loadingContracts: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingContracts: false })
        }
      });
  }

  ///// BASIC NOTES

  getBasicNotes(){
    if (this._isMounted) {
      this.setState({loadingNotes: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/notes/LEAD/"+params.leadId+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        if (this._isMounted) {
          this.setState({
            leadNotesData: res,
            loadingNotes: false
          })
        }
        this.sortDataByColumnActiveNotes();
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingNotes: false })
        }
      });
  }

  preparePagingDataNotes(){
    if(this.state.leadNotesData.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            notesPaging: {
              totalItems: this.state.leadNotesData.length,
              itemsPerPage: prevState.notesPaging.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            notesPaging: {
              ...prevState.notesPaging,
              totalItems: this.state.leadNotesData.length,
              pageFrom: 1,
              pageTo: 5,
              currentPage: prevState.notesPaging.currentPage>Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)? Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage) : prevState.notesPaging.currentPage,
              totalPages: Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)
            }
          }
        });
      }
    }
  }

  sortTypeNotes(type){
    let sortingObj = [];
    for(let i=0; i<this.state.sortingNotes.length; i++){
      if(this.state.sortingNotes[i].column===type){
        if(this.state.sortingNotes[i].active===true){
          let item = this.state.sortingNotes[i];
          item.type==='ASC'? item.type="DESC" : item.type='ASC';
          sortingObj.push(item);
        }else{
          let item = this.state.sortingNotes[i];
          item.type='ASC';
          item.active = true;
          sortingObj.push(item);
        }
      }else{
        let item = this.state.sortingNotes[i];
        item.type='ASC';
        item.active = false;
        sortingObj.push(item);
      }
    }
    if (this._isMounted) {
      this.setState({sortingNotes: sortingObj});
    }
    this.sortDataByColumnActiveNotes();
  }

  sortDataByColumnActiveNotes(){
    let finalDataToShow = this.state.leadNotesData;
    ReqHelper.sortMeData(finalDataToShow, this.state.sortingNotes);
    if (this._isMounted) {
      this.setState({leadNotesData: finalDataToShow})
      this.preparePagingDataNotes();
    }
  }

  openModalWithNoteData(element){
    if (this._isMounted) {
      this.setState({
          noteData: JSON.parse(JSON.stringify(element)),
          noteTemp: JSON.parse(JSON.stringify(element)),
          showEdit: true,
          type: "EDIT"
      })
    }
  }

  noteChange(text){
    if (this._isMounted) {
      this.setState({
          noteData:{
              ...this.state.noteData,
              Note: text
          }
      });
    }
  }

  cancelEdit = () =>{
    if (this._isMounted) {
      this.setState({
          noteData: this.state.noteTemp,
          showEdit: false
      })
    }
  }

  saveNoteData = ()=>{
      if (this._isMounted) {
        this.setState({loaderModal: true})
      }
      this.props.addNote(this.state.noteData, this.state.leadId, "LEAD")
      .then((res)=>{
          let newObj={
              Id: res,
              Note: this.state.noteData.Note,
              Created: Math.floor(new Date().getTime() / 1000),
              CreatedBy: ReqHelper.prepareMeLoginName(this.props.loginName)
          }
          if (this._isMounted) {
            this.setState(previousState => ({
                ...previousState,
                loaderModal: false,
                showEdit: false,
                leadNotesData: [...previousState.leadNotesData, newObj]
            }));
          }
      })
      .catch((err)=>{
          console.log(err);
          if (this._isMounted) {
            this.setState({
                loaderModal: false,
                saveErrorMessage: this.props.translateKey("fail_to_add_note")
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({saveErrorMessage: ''});
            }
          }, 2000);
      })
  }

  addClick = () =>{
    if (this._isMounted) {
      this.setState({
          noteData: {
              Id: 0,
              Note: ''
          },
          noteTemp:  {
              Id: 0,
              Note: ''
          },
          showEdit: true,
          type: "ADD"
      })
    }
  }

  removeItem(item){
    if (this._isMounted) {
      this.setState({
        confirmDeleteNotes: true,
        noteToDelete: item
      })
    }
  }

  errorModal = () => {
    if (this._isMounted) {
      this.setState({errorModal: !this.state.errorModal});
    }
  }

  getBasicTasksData(){
    if(this.props.tasksStatus === ResponseStatus.READY){
      clearTimeout(this.myTimeout);
      this.getLeadTasksData();
    }else{
      if(this.props.tasksStatus === ResponseStatus.FREE){
        this.props.getGlobalTasksData();
        this.myTimeout = setTimeout(() => {
          this.getBasicTasksData()
        }, 1000);
      }else{
        if(this.props.tasksStatus === ResponseStatus.ERROR){
          clearTimeout(this.myTimeout);
          if (this._isMounted) {
            this.setState({ loadingTasks: false })
          }
        }else{
          this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
          }, 1000);
        }
      }
    }
  }

  getLeadTasksData() {
    const { match: { params } } = this.props;
    let newTasksList = [];
    let newCompleatedList = [];
    for(let i=0;i<this.props.tasksData.length; i++){
      if(this.props.tasksData[i].concerns===params.leadId){
        if(this.props.tasksData[i].status == "Open") {
          newTasksList.push(this.props.tasksData[i]);
        } else if (this.props.tasksData[i].status == "Completed") {
          newCompleatedList.push(this.props.tasksData[i]);
        } 
      }
    }
    if (this._isMounted) {
      this.setState({
        leadTaskData: newCompleatedList,
        leadReminderTasks: newTasksList,
        loadingTasks: false,
      })
    }
    this.preparePagingDataTasks();
  }

  getDataBasic() {
    const { match: { params } } = this.props;
    let found = false;
    for(let i=0;i<this.props.leadsData.length; i++){
      if(this.props.leadsData[i].leadId===params.leadId){
        found=true;
        let name='---';
        if(this.props.leadsData[i].client.lastName!==null){
          name = this.props.leadsData[i].client.lastName;
          if(this.props.leadsData[i].client.firstName!==null){
            name += ' '+this.props.leadsData[i].client.firstName;
          }
        }else{
          if(this.props.leadsData[i].client.firstName!==null){
            name = this.props.leadsData[i].client.firstName;
          }
        }
        if (this._isMounted) {
          this.setState({
            leadData: JSON.parse(JSON.stringify(this.props.leadsData[i])),
            leadBackup: JSON.parse(JSON.stringify(this.props.leadsData[i])),
            loadingLeads: false,
            leadId: params.leadId,
            breadCrumbs:[
              {
                link: "leads",
                key: "leads",
                name: null
              },
              {
                link: "",
                key: "",
                name: name
              }
            ],
            canAddContract: this.props.leadsData[i].status.toString()!==statisContractOffertStatus
          })
        }
        this.getDynamicMulti();
        this.getBasicNotes();
        this.getBasicTasksData();
        this.getBasicContractsData();
        break;
      }
    }
    if(!found){
      if (this._isMounted) {
        this.setState({
          leadData: false,
          loadingLeads: false,
          loadingPatients: false,
          loadingNotes: false,
          loadingTasks: false,
        })
      }
    }
  }

  cancelChange(){
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            leadData: JSON.parse(JSON.stringify(prevState.leadBackup)),
            dynamics: JSON.parse(JSON.stringify(prevState.dynamicsTemp)),
            showValidation: false,
        }
      });
    }
  }

  getDatesFromDynamic(){
    const { match: { params } } = this.props;

    let dataToSend = [
      {
        "Id": params.leadId,
        "Entity": Entity.CONTACT,
        "Fields": [
          "birthdate", 
          "anniversary",
        ]
      }
    ];
    getDynamicFields(dataToSend)
    .then(res => {
      this.setState({
        assasmentDates: {
          birthdate: res[0].fields.birthdate,
          anniversary: res[0].fields.anniversary,
        }
      });
    })
    .catch(error => {
      console.log(error);
    });
  }

  tryToSave(leadId, shouldLogout=false){
    const {multiId, dynamics,dynamicsTemp, leadData, assasmentDates, consentsDe7} = this.state
    let fields = {};
    fields = {"is_zg_de_7": consentsDe7};
    if(leadData.source!==null && leadData.source === baseData.multiToLeadSelection){
      if(multiId!==0){
        fields.is_contact_recomendedid = multiId;
      }else{
        if (this._isMounted) {
          this.setState({multiId:0})
        }
        fields.is_contact_recomendedid = 0;
      }
    }else{
      if (this._isMounted) {
        this.setState({multiId:0})
      }
      fields.is_contact_recomendedid = 0;
    }
    if(dynamicsTemp.is_degree_relationship_with_patient_carer !== dynamics.is_degree_relationship_with_patient_carer){
      fields.is_degree_relationship_with_patient_carer = dynamics.is_degree_relationship_with_patient_carer;
    }
    if(assasmentDates.birthdate) {
      fields.birthdate = assasmentDates.birthdate;
    }
    if(assasmentDates.anniversary) {
      fields.anniversary = assasmentDates.anniversary;
    }
    if(dynamicsTemp.is_last_note !== dynamics.is_last_note) {
      fields.is_last_note = dynamics.is_last_note;
    }
    if(dynamicsTemp.is_last_call != dynamics.is_last_call) {
      fields.is_last_call = dynamics.is_last_call;
    }
    if(dynamicsTemp.is_last_meeting != dynamics.is_last_meeting) {
      fields.is_last_meeting = dynamics.is_last_meeting;
    }

      fields.is_referral_other = dynamics.is_referral_other;
    if((dynamicsTemp.is_other_source_description != dynamics.is_other_source_description) && dynamics.is_referral_other) {
      fields.is_other_source_description = dynamics.is_other_source_description;
    }
    if(fields !== {}){
      let dataToSend = [
        {
            "Id": leadId,
            "Entity": Entity.CONTACT,
            "Fields": fields
        }
      ];
      saveDynamicFields(dataToSend).then(response => {
        this.props.doesNotNeedsSave();
        if(shouldLogout){
          this.props.history.push(`${baseData.subFolders}logout`);
        }else{
          this.saveMessage(leadId, shouldLogout);
        }
      })
      .catch((err) => {
        this.saveMessage(leadId, shouldLogout);
      });
    }else{
      this.saveMessage(leadId, shouldLogout);
    }
  }

  saveMessage(leadId, shouldLogout=false){
    if(shouldLogout){
      this.props.history.push(`${baseData.subFolders}logout`);
    }else{
      if (this._isMounted) {
        this.setState({
          savingDataLead: false,
          errorComponent: {
            show: true,
            type: "success",
            message: this.props.translateKey("lead_successfully_saved")
          },
          showValidation:false
        })    
      }  
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {this.hideAlertModalComponent();}, 3000);
      this.props.updateLeadDataFun(leadId,this.state.leadData);
    }
  }

  hideAlertModalComponent(){
    if (this._isMounted) {
      this.setState({errorComponent: {show: false}})
    }
    document.body.classList.remove('modal-open');
  }
  
  handleDinamicDateChange(key, value) {
    let timestamp = new Date(value).getTime();
    const hoursLeftFromMidnight = (timestamp/1000)%86400;
    if (hoursLeftFromMidnight > 79200) {
      timestamp = timestamp/1000 + 72000;
    } else {
      timestamp = timestamp/1000 + 72000 - hoursLeftFromMidnight;
    }
    this.setState(prevState => {
      const newDynamics = prevState.dynamics;

      if(key === 'is_last_call') {
        newDynamics.is_last_call = timestamp;
      }
      if(key === 'is_last_meeting') {
        newDynamics.is_last_meeting = timestamp;
      }
      return {
          ...prevState,
          dynamics: newDynamics
      }
    });

    this.basicEdit();
  }

  handleDinamicChange(key, value) {
    this.setState(prevState => {
      const newDynamics = prevState.dynamics;

      newDynamics[key] = value;

      return {
          ...prevState,
          dynamics: newDynamics
      }
    });

    this.basicEdit();
  }

  saveChange(shouldLogout=false){
    this.props.tookAction();
    if(this.props.needsSave){
      if(this.checkRequiredFields()){
        if (this._isMounted) {
          this.setState({savingDataLead: true})
        }
        this.props.saveLeadData(this.state.leadData, this.props.appLang)
        .then((res)=>{
          this.props.doesNotNeedsSave();
          this.tryToSave(res, shouldLogout)
        })
        .catch((err)=>{
          if(shouldLogout){
            this.props.history.push(`${baseData.subFolders}logout`);
          }else{
            if (this._isMounted) {
              this.setState(prevState => {
                return {
                    ...prevState,
                    savingDataLead: false,
                    errorComponent: {
                      show: true,
                      type: "danger",
                      message: err.message
                    }
                }
              });
            }
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {this.hideAlertModalComponent();}, 3000);
          }
        })
      }else{
        if(shouldLogout){
          this.props.history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                ...prevState,
                showValidation: true,
                errorComponent: {
                  show: true,
                  type: "danger",
                  message: this.props.translateKey("some-mandatory-data-are-missing")
                }
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {this.hideAlertModalComponent();}, 3000);
        }
      }
    }
  }

  checkRequiredFields(){
    const { leadData, multiId } = this.state;
    let allRequiredFilled = true;
    if(leadData.status==="" || leadData.status===0 || leadData.status===null){
      allRequiredFilled = false;
    }
    if(leadData.leadType == '717700001' && (leadData.referralContactId==="" || leadData.referralContactId===0 || leadData.referralContactId===null)) {
      allRequiredFilled = false;
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.client.firstName) || !ReqHelper.checkNotNullAndNotEmptyString(leadData.client.lastName)){
      allRequiredFilled = false;
    }
    if(!ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.firstName) || !ReqHelper.checkNotNullAndNotEmptyString(leadData.patientObj.lastName)){
      allRequiredFilled = false;
    }
    if ((`${multiId}` === "" || multiId === 0 || multiId===null) && leadData.source === 'E33DD1F7-B99C-E911-80D1-00155D2D0E3C') {
      allRequiredFilled = false;
    }
    return allRequiredFilled;
  }

  basicEdit = () =>{this.props.needsSaveData();}

  preparePagingDataTasks(){
    if(this.state.leadTaskData.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            tasksPaging: {
              totalItems: this.state.leadTaskData.length,
              itemsPerPage: prevState.tasksPaging.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            tasksPaging: {
              ...prevState.tasksPaging,
              totalItems: this.state.leadTaskData.length,
              pageFrom: 1,
              pageTo: 5,
              currentPage: prevState.tasksPaging.currentPage>Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)? Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage) : prevState.tasksPaging.currentPage,
              totalPages: Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)
            }
          }
        });
      }
    }
  }

  sortTypeTasks(type){
    this.state.sortingTasks.changeColumnByColumn(type);
    this.sortDataByColumnActiveTasks();
  }

  sortDataByColumnActiveTasks(){
    let finalData = this.state.leadTaskData;
    ReqHelper.sortMeData(finalData, this.state.sortingTasks.sortingColumns);
    this.props.setGlobalTasksData(finalData);
    if (this._isMounted) {
      this.setState({leadTaskData: finalData})
    }
  }

  _prepareFilterObjTasks() {
    let filterData = [];
    let Options = [];
    filterData.push({
      filterName: "status",
      filterOptions: [
        {
          column: this.props.translateKey('open'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('closed'),
          active: false,
          type: "text",
        }
      ]
    });
    for (let i = 0; i < this.props.tasksPriority.length; i++) {
      let filtering = {
        column: this.props.taskPriority[i].Label,
        active: false,
        type: "text"
      }
      Options.push(filtering);
    }
    filterData.push({
      filterName: "priority",
      filterOptions: Options
    });
    filterData.push({
      filterName: "type",
      filterOptions: [
        {
          column: this.props.translateKey('task_call'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('task_event'),
          active: false,
          type: "text",
        },
        {
          column: this.props.translateKey('task_task'),
          active: false,
          type: "text",
        }
      ]
    });
    filterData.push({
      filterName: "new_task",
      filterOptions: [
        {
          column: this.props.translateKey('task_youger7'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('task_youger14'),
          active: false,
          type: "text",
        },
        {
          column: this.props.translateKey('task_youger21'),
          active: false,
          type: "text",
        }
      ]
    });
    return filterData;
  }

  navigateToDetailsTasks(element){
    this.props.history.push(`${baseData.subFolders}taskDetails/${element.id}`);
  }

  setConsent = (value) =>{
    if(value !== null ){
      if (this._isMounted) {
        this.setState({consentsDe7: value})
      }
    }else{
      if (this._isMounted) {
        this.setState({consentsDe7: false})
      }
    }
  }

  removeNote = () =>{
    if (this._isMounted) {
      this.setState({loaderModal: true})
    }
    this.props.removeNote(this.state.noteToDelete.Id)
    .then((res)=>{
        if (this._isMounted) {
          this.setState({
              confirmDeleteNotes: false,
              loaderModal: false,
              leadNotesData: this.state.leadNotesData.filter(value => value.Id !== this.state.noteToDelete.Id)
          })
        }
    })
    .catch((err)=>{
      if (this._isMounted) {
        this.setState({
            confirmDeleteNotes: true,
            loaderModal: false,
            errorModal: true,
            errorComponent: {
                ...this.state.errorComponent,
                message: this.props.translateKey("fail_to_remove_note")
            }
        })
      }
    })
  }

  rejectMeNote = () =>{
    if (this._isMounted) {
      this.setState({
        confirmDeleteNotes: false,
        loaderModal: false
      })
    }
  }

  showMeQuatation =() => {
    if(this.state.canAddContract===true && this.props.appLang === LanguageSelect.DE){
      const { match: { params } } = this.props;
      this.props.history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/0/${FormTypeToShow.ENQUIRY}`);
    }else{
      alert(this.props.translateKey("form-text-processing-offer"));
    }
  }

  showMeContract =() => {
    if(this.state.canAddContract===true && this.props.appLang === LanguageSelect.DE){
      const { match: { params } } = this.props;
      this.props.history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/0/${FormTypeToShow.CONTRACT}`);
    }else{
      alert(this.props.translateKey("form-text-processing-offer"));
    }
  }

  addClickButton=()=>{
    const { match: { params }, history } = this.props;
    switch(this.state.activeTab){
      case "patients":  if(this.props.appLang === LanguageSelect.EN){
                          history.push(`${baseData.subFolders}leadPatient/${params.leadId}/0`);
                        }else{
                          history.push(`${baseData.subFolders}bb-form-lead/${params.leadId}/0`);
                        }
                        break;
      case "tasks":     history.push(`${baseData.subFolders}newtask/${params.leadId}/${RecordType.LEAD}`);
                        break;
      default:
    }
  }

  prepareMeCaAdd(){
    let options = [];
    switch(this.state.activeTab){
      case "patients":  if( this.props.appLang === LanguageSelect.DE && this.state.leadData!==false){
                          if(this.state.canAddContract===true){
                            options = [TableEnums.ADD];
                          }
                        }else{
                          options = [TableEnums.ADD];
                        }
                        break
      case "contracts": if( this.props.appLang === LanguageSelect.DE && this.state.leadData!==false){
                          // hidden due to FP-426
                          //options = [TableEnums.CREATECONTRACT, TableEnums.CREATEQUATATION];
                          options = [TableEnums.CREATECONTRACT];
                        }
                        break;
      case "tasks":     if(this.state.leadData!==false){
                          options = [TableEnums.ADD]
                        };
                        break;
      default:
    }
    return options;
  }

  render() {
    const {translateKey, needsSave, appLang} = this.props;
    const {sortingNotes, sortingTasks, dynamics} = this.state;
    let leadsEmptyHandler, patientsEmptyHandler, notesEmptyHandler, tasksEmptyHandler;
    let alertModal = (
        <MDBModal isOpen={this.state.errorModal} toggle={this.errorModal} size="lg">
          <MDBModalBody>
            <div className="alert alert-danger centeredAlertNoLimit" role="alert">
              {this.state.errorComponent.message}
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <button type="button" className="btn btn-primary" onClick={this.errorModal}>{translateKey('Ok')}</button>
          </MDBModalFooter>
        </MDBModal>
    )
    let noteEditModal = (
        <MDBModal isOpen={this.state.showEdit} toggle={this.editToggle} size="lg">
          <MDBModalHeader toggle={this.editToggle}>{translateKey('note')}</MDBModalHeader>
          <MDBModalBody>
            {this.state.type!=="EDIT"?
                <div>
                  <textarea type="text" className="form-control" rows="3" onChange={e => this.noteChange(e.target.value)} value={this.state.noteData.Note}/>
                  {this.state.saveErrorMessage.length>0 &&
                      <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                        {this.state.saveErrorMessage}
                      </div>
                  }
                </div>
                :
                <span>{this.state.noteData.Note}</span>
            }
          </MDBModalBody>
          {this.state.type!=="EDIT"?
              <MDBModalFooter>
                <button type="button" className="btn btn-secondary" onClick={this.cancelEdit}>{translateKey('cancel')}</button>
                <button type="button" className="btn btn-primary" onClick={()=>this.saveNoteData()}>{translateKey('save')}</button>
              </MDBModalFooter>
              :
              <MDBModalFooter>
                <button type="button" className="btn btn-primary" onClick={this.cancelEdit}>{translateKey('Ok')}</button>
              </MDBModalFooter>
          }
        </MDBModal>
    )
    if (this.state.loadingLeads) {
      leadsEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.leadData===false){
        leadsEmptyHandler = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={translateKey("lead_does_not_exist")} />
      }else{ 
        let FirstRow =(
          <LeadDetailsBasicComponent 
            dynamics={dynamics} 
            multiId={this.state.multiId} 
            editedMulti={this.changeMultiValue} 
            disabled={!this.state.canAddContract} 
            editedData={this.basicEdit} 
            showValidation={this.state.showValidation} 
            edited={needsSave} 
            key="leadDetailsComponent02" 
            element={this.state.leadData} 
            assasmentDates={this.state.assasmentDates}
            handleDinamicDateChange={(key, value) => this.handleDinamicDateChange(key, value)}
            handleDinamicChange={(key, value) => this.handleDinamicChange(key, value)}
            />
        )
        let ClientRow =(
          <LeadClientComponent dynamics={dynamics} disabled={!this.state.canAddContract} editedData={this.basicEdit} showValidation={this.state.showValidation} edited={needsSave} key="leadClientComponent02" element={this.state.leadData}/>
        )
        let PatientRow =(
          <LeadPatientComponent disabled={!this.state.canAddContract} editedData={this.basicEdit} showValidation={this.state.showValidation} edited={needsSave} key="leadPatientComponent02" element={this.state.leadData}/>
        )
        let Consents =(
          <ConsentListComponent isEditable={false}  editedData={this.basicEdit} key="consents-lead-03" id={this.state.leadData.leadId} setConsents={this.setConsent}/>
        )
        let CancelSave = '';
        if(this.state.canAddContract){
          CancelSave = (
            <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="leadCancelSaveComponent02" edited={needsSave} savingData={this.state.savingDataLead}/>
          );
        }
        const { errorComponent } = this.state;
        let SaveAlert = (
          <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
        )

        //// NOTES DATA
        let notesContent,emptyNoteData;
        if (this.state.loadingNotes) {
          emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
          if(this.state.leadData===false){
            emptyNoteData = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={translateKey("lead_does_not_exist")} />
          }else{
            if (this.state.leadNotesData === null) {
              emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
            } else {
              if (this.state.leadNotesData.length === 0) {
                emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
              }else{
                let finalData = this.state.leadNotesData.slice((this.state.notesPaging.currentPage-1)*this.state.notesPaging.itemsPerPage, this.state.notesPaging.currentPage*this.state.notesPaging.itemsPerPage);
                if(finalData.length>0){
                  notesContent = finalData.map(element => (
                      <tr className="table-row-wrapper cursor-pointer" key={element.Id}>
                        <td className={"table-cell-wrapper " + (element.Note !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}>{element.Note}</td>
                        <td className={"table-cell-wrapper " + (element.CreatedBy !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}>{element.CreatedBy}</td>
                        <td className={"table-cell-wrapper " + (element.Created !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}><TableDateFormat timestamp={element.Created}/></td>
                        <td className="table-cell-wrapper remove-icon-item-table" onClick={()=> this.removeItem(element)}><i className="fas fa-trash-alt fa-2x empty-table-icon cursor-pointer remove-icon-item"></i></td>
                      </tr>
                  ))
                }else{
                  emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
                }
              }
            }
          }
        }
        notesEmptyHandler = (
            <div className="lead-client-row">
              <div className="lead-details-header">
                <div className="filter-modal-option-text">{translateKey("notes")}</div>
                <button type="button" onClick={()=>this.addClick()} className="btn btn-success norbsoft-filter-button">
                  <i className="fas fa-plus norbsoft-filter-icon"></i>
                  {translateKey("add_new")}
                </button>
              </div>
              <div className="listData">
                <ConfirmModal rejectMe={this.rejectMeNote} acceptMe={this.removeNote} show={this.state.confirmDeleteNotes} titleMessage="" mainMessage={translateKey("delete")+"?"} messageYes={translateKey("yes")} messageNo={translateKey("no")}/>
                <table className="table">
                  <thead>
                  <tr>
                    <TableColumHead sorting={sortingNotes} name={translateKey("notes")} onSortType={(data) => this.sortTypeNotes(data)} type="Note" />
                    <TableColumHead sorting={sortingNotes} name={translateKey("author")} onSortType={(data) => this.sortTypeNotes(data)} type="CreatedBy" />
                    <TableColumHead sorting={sortingNotes} name={translateKey("created")} onSortType={(data) => this.sortTypeNotes(data)} type="Created" />
                    <th scope="col" className="table-add-data no_editable"></th>
                  </tr>
                  </thead>
                  <tbody>{notesContent}</tbody>
                </table>
                {emptyNoteData}
                <TablePagination recalculateAll={()=>this.preparePagingDataNotes()} simpleDataUpdate={()=>this.preparePagingDataNotes()} loading={this.state.loadingNotes} paginationObj={this.state.notesPaging} totalItems={this.state.leadNotesData.length}/>
              </div>
            </div>
        )

        leadsEmptyHandler = [
          FirstRow,
          ClientRow,
          PatientRow,
          Consents,
          noteEditModal,
          alertModal,
          notesEmptyHandler,
          SaveAlert,
          CancelSave
        ]
      }
    }

    patientsEmptyHandler = (
      <LeadDetailsPatientsDataComponent mainLoader={this.state.loadingLeads} gotLead={this.state.leadData!==false} GetLeadData={() => this.getInitialDataTime()} RefreshMe={() => this.getBasicPatientLead()} id={this.state.leadId}/>
    )

    let ContractsData;
    if (this.state.loadingCarers) {
      ContractsData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      const { match: { params } } = this.props;
      ContractsData = <LeadContractData RefreshMe={() => this.getBasicContractsData()} id={this.state.id} leadId={params.leadId} contractsData={this.state.contractsData}/>
    }

    let taskContent, emptyTaskData;
    if(this.state.loadingTasks) {
      emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.leadData===false){
        emptyTaskData = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={translateKey("lead_does_not_exist")} />
      }else{
        if (this.state.leadTaskData === null) {
          emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTasksData()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (this.state.leadTaskData.length === 0) {
            emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTasksData()} text={translateKey("no_data_to_show")} /></div>
          }else{
            let finalData = this.state.leadTaskData.slice((this.state.tasksPaging.currentPage-1)*this.state.tasksPaging.itemsPerPage, this.state.tasksPaging.currentPage*this.state.tasksPaging.itemsPerPage);
            if(finalData.length>0){
              taskContent = finalData.map(element => (
                <tr className={"table-row-wrapper cursor-pointer "+(element.ViewedInApplication===false?"not-readed-record":"")} key={Math.random().toString(36).substr(2, 5)} onClick={()=>{this.navigateToDetailsTasks(element)}}>
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_status', appLang) &&
                    <td className="table-cell-wrapper"><TaskStatusHandler element={element}/></td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_title', appLang) &&
                    <td className="table-cell-wrapper">{element.title}</td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_status', appLang) &&
                    <td className="table-cell-wrapper">{element.status}</td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_concern', appLang) &&
                    <td className="table-cell-wrapper">{element.concernName}</td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_priority', appLang) &&
                    <td className="table-cell-wrapper"><TaskPriorityConverter priority={element.priority} /></td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_createdDate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.createdDate}/></td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_dueDate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dueDay}/></td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_description', appLang) &&
                    <td className="table-cell-wrapper">{element.description}</td>
                  }
                </tr>
              ))
            }else{
              emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTasksData()} text={translateKey("no_data_to_show")} /></div>
            }
          }
        }
      }
    }
    tasksEmptyHandler = (
      <div className="listData">
        <table className="table">
          <thead>
            <tr>
              {
                  sortingTasks.sortingColumns.map(column => {
                    if(sortingTasks.isColumnVisibleByKey(column.keyName, appLang)){
                      return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sortingTasks.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.sortTypeTasks(data)} type={column.column} />
                    }else{
                      return null
                    }
                  }).filter(element => element !==null )
              }
            </tr>
          </thead>
          <tbody>{taskContent}</tbody>
        </table>
        {emptyTaskData}
        <TablePagination recalculateAll={()=>this.preparePagingDataTasks()} simpleDataUpdate={()=>this.preparePagingDataTasks()} loading={this.state.loadingTasks} paginationObj={this.state.tasksPaging} totalItems={this.state.leadTaskData.length}/>
      </div>
    )
    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties showMeQuatation={this.showMeQuatation} showMeContract={this.showMeContract} addClick={this.addClickButton} options={this.prepareMeCaAdd()} filterData={[]}/>
        <LoaderModal show={this.state.loaderModal}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active nav-link-invoices" onClick={()=>{this.setActiveTab("lead")}} id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                  {translateKey("lead")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("patients")}} id="nav-patient-tab" data-toggle="tab" href="#nav-patient" role="tab" aria-controls="nav-patient" aria-selected="false">
                  {translateKey("patients")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("contracts")}} id="nav-contracts-tab" data-toggle="tab" href="#nav-contracts" role="tab" aria-controls="nav-contracts" aria-selected="false">
                  {translateKey("offers")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("tasks")}} id="nav-tasks-tab" data-toggle="tab" href="#nav-tasks" role="tab" aria-controls="nav-tasks" aria-selected="false">
                  {translateKey("reminders")}
                </a>
              </div>
            </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {leadsEmptyHandler}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-patient" role="tabpanel" aria-labelledby="nav-patient-tab">
                <div className="leadSection">
                  {patientsEmptyHandler}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-contracts" role="tabpanel" aria-labelledby="nav-contracts-tab">
                <div className="leadSection">
                  {ContractsData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-tasks" role="tabpanel" aria-labelledby="nav-tasks-tab">
                <div className="leadSection">
                  {tasksEmptyHandler}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={needsSave}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginName: state.auth.appLogin,
    appLang: state.lang.appLanguage,
    lastActionTime: state.auth.actionTimestamp,
    leadsStatus: state.enums.leadStatus,
    leadsData: state.database.leads,
    leadsSourceStatus: state.enums.leadSource,
    leadsTimestamp: state.database.leadsTimeStamp,
    tasksPriority: state.enums.taskPriority,
    tasksData: state.database.tasks,
    tasksStatus: state.database.tasksState,
    needsSave: state.refreshTokenStatus.needsDataSave
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  saveLeadData: (data,lang) => dispatch(leadSave(data, lang)),
  updateLeadDataFun: (leadId, data) => dispatch(updateLeadData(leadId, data)),
  getGlobalTasksData: () => dispatch(getTasks()),
  setGlobalTasksData: (data) => dispatch(setTasksData(data)),
  addNote: (note, userid, type) => dispatch(addNoteData(note, userid, type)),
  removeNote: (id) => dispatch(removeNoteData(id)),
  setLeadsData: (data) => dispatch(setLeadsData(data)),
  pendingRequestLeads: () => dispatch(pendingLeadsData()),
  clearDataLeads: () => dispatch(removeLeadsData()),
  needsSaveData: () => dispatch(needsSaveData()),
  doesNotNeedsSave: () => dispatch(doesNotNeedsSave()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadsDetailsPage));