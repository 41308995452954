import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import * as LocationRange from "../../../_forms/BBForm/values/locationRange";
import * as HouseType from "../../../_forms/BBForm/values/houseType";
import ListComponent from "../../form-bb-components/list-component/list-component";
import SelectComponent from "../../form-bb-components/select-component/SelectComponent";
import produce from "immer";
import * as MarketingID from '../../../_forms/BBForm/values/marketingId';

class Section18 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-18'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setLiveTypeChange = (key, value) => {
        const newValue = value ? '717700004' : value;
        this.setTypeChange(key, newValue);
    };


    render() {
        const { disabled, translateKey, showValidationErrors } = this.props;
        const { section } = this.state;
        const isLocation = section.getFieldByKey('is_location');
        const isHouseConditions = section.getFieldByKey('is_housing_conditions');
        const isShoppingAreMadeByFamily = section.getFieldByKey('is_shopping_are_made_by_family');
        const isShoppingOnFoot = section.getFieldByKey('is_shopping_on_foot');
        const isPatientLives = section.getFieldByKey('is_patient_lives');
        const isActivitiesAdditionalPeople = section.getFieldByKey('is_activities_additional_people');
        const isPets = section.getFieldByKey('is_pets');
        const isMarketingId = section.getFieldByKey('is_marketingid');
        const isBike = section.getFieldByKey('is_bike');
        const isGarden = section.getFieldByKey('is_garden');
        const isTakingCareAnimals =  section.getFieldByKey('is_taking_care_of_animals');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">{translateKey("version")}: {baseData.bbFormVersion}</div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span>
                        <span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">{this.props.subsectionNumber}. {this.props.subsectionName}</div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                        <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={showValidationErrors}
                                className="form-margin-bottom-fix"
                                transKey="form-text-personlebt"
                                key="is_location"
                                keyName="is_location"
                                value={isLocation.value}
                                validationErrors={isLocation.validationErrors}
                                valuesList={[
                                    { value: LocationRange.BIGCITY, label: translateKey("form-text-grobstadt-z") },
                                    { value: LocationRange.CITY, label: translateKey("form-text-grobstadt-v") },
                                    { value: LocationRange.SMALLCITY, label: translateKey("form-text-kleinstadt") },
                                    { value: LocationRange.VILLAGE, label: translateKey("form-text-dorf") },
                                ]}
                        />

                        <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={showValidationErrors}
                                className="form-margin-bottom-fix"
                                transKey=""
                                key="is_housing_conditions"
                                keyName="is_housing_conditions"
                                value={isHouseConditions.value}
                                validationErrors={isHouseConditions.validationErrors}
                                valuesList={[
                                    { value: HouseType.HOUSE, label: translateKey("form-text-einfamilienhaus") },
                                    { value: HouseType.BIGHOUSE, label: translateKey("form-text-mehrfamilienhaus") },
                                    { value: HouseType.APARTMENT, label: translateKey("form-text-Wohnung") },
                                    { value: HouseType.OTHER, label: translateKey("form-text-sonstige-gem") },
                                ]}
                        />

                        <CheckBoxComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isShoppingAreMadeByFamily.value} validationErrors={isShoppingAreMadeByFamily.validationErrors} text={translateKey("form-text-erledigt")} transKey="form-text-erledigt" keyName="is_shopping_are_made_by_family"/>
                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="form-margin-bottom-fix"
                            transKey="form-text-einkaufsmöglichkeiten"
                            key="is_shopping_on_foot"
                            keyName="is_shopping_on_foot"
                            value={isShoppingOnFoot.value}
                            validationErrors={isShoppingOnFoot.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("form-text-ca10min") },
                                { value: '717700001', label: translateKey("form-text-ca20min") },
                                { value: '717700002', label: translateKey("form-text-ca30min") },
                            ]}
                        />

                        <CheckBoxComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setLiveTypeChange} value={isPatientLives.value === '717700004'} validationErrors={isPatientLives.validationErrors} text={translateKey("form-text-wohntdie")} transKey="form-text-wohntdie" keyName="is_patient_lives"/>
                        {isPatientLives.value !== '717700004' &&
                            <React.Fragment>
                                <div className="row col-sm-12 ">
                                    <div className="col-sm-12 form-margin-bottom-fix">
                                        <div className="radio-button-group-wrapper" onChange={e => this.setTypeChange('is_patient_lives', e.target.value)}>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700000? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie1" value="717700000"/>
                                                <label className="form-check-label cursor-pointer" htmlFor="form-text-wohntdie1">{translateKey("form-text-ehefrau")}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700005? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie5" value="717700005"/>
                                                <label className="form-check-label cursor-pointer" htmlFor="form-text-wohntdie5">{translateKey("form-text-enkel-enkelin")}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700003? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie4" value="717700003"/>
                                                <label className="form-check-label cursor-pointer" htmlFor="form-text-wohntdie4">{translateKey("form-text-mehrgenerationenhaushalt")}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700001? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie2" value="717700001"/>
                                                <label className="form-check-label cursor-pointer" htmlFor="form-text-wohntdie2">{translateKey("form-text-sohn-tochter")}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline hidden">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700004 || isPatientLives.value===true? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie44" value="717700004"/>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700006? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie6" value="717700006"/>
                                                <label className="form-check-label cursor-pointer" htmlFor="form-text-wohntdie6">{translateKey("form-text-bruder-schwester")}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input className="form-check-input" defaultChecked={isPatientLives.value===717700002? true: false} data-field-name="is_patient_lives" type="radio" name="form-text-wohntdie" id="form-text-wohntdie3" value="717700002"/>
                                                <label className="form-check-label cursor-pointer" htmlFor="form-text-wohntdie3">{translateKey("form-text-andre")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <CheckBoxComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isActivitiesAdditionalPeople.value} validationErrors={isActivitiesAdditionalPeople.validationErrors} text={translateKey("form-text-mitbewohner")} transKey="form-text-mitbewohner" keyName="is_activities_additional_people"/>
                        {isActivitiesAdditionalPeople.value===true &&
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_activities_regarding_additional_people_de')} text={translateKey("form-text-fallsja")} transKey="form-text-fallsja" keyName="is_activities_regarding_additional_people_de"/> 
                            </div>
                        }

                        <CheckBoxComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isPets.value} validationErrors={isPets.validationErrors} text={translateKey("form-text-haustiere")} transKey="form-text-haustiere" keyName="is_pets"/>
                        {isPets.value===true &&
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_taking_care_of_animals_which_de')} text={translateKey("form-text-fallsja")} transKey="form-text-fallsja" keyName="is_taking_care_of_animals_which_de"/> 
                            </div>
                        }

                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="form-margin-bottom-fix"
                            transKey="form-text-sollendie"
                            key="is_taking_care_of_animals"
                            keyName="is_taking_care_of_animals"
                            value={isTakingCareAnimals.value}
                            validationErrors={isTakingCareAnimals.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("yes") },
                                { value: '717700002', label: translateKey("no") },
                                { value: '717700003', label: translateKey("contract-text-is-identical-patient-data1") },
                            ]}
                        />

                        <div className="hilfsmittel-wrapper">
                            <div className="form-label-basic cursor-pointer quick-label-position-fix">{translateKey("form-text-zimmerausstattung")}</div>
                            <div className="row">
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_separate_bathroom_list').value} validationErrors={section.getFieldByKey('is_separate_bathroom_list').validationErrors} text={translateKey("form-text-eigenesbad")} transKey="form-text-eigenesbad" keyName="is_separate_bathroom_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_bed_list').value} validationErrors={section.getFieldByKey('is_bed_list').validationErrors} text={translateKey("form-text-bett")} transKey="form-text-bett" keyName="is_bed_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_table_list').value} validationErrors={section.getFieldByKey('is_table_list').validationErrors} text={translateKey("form-text-tisch")} transKey="form-text-tisch" keyName="is_table_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_wardrobe_list').value} validationErrors={section.getFieldByKey('is_wardrobe_list').validationErrors} text={translateKey("form-text-schrank")} transKey="form-text-schrank" keyName="is_wardrobe_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_radio_list').value} validationErrors={section.getFieldByKey('is_radio_list').validationErrors} text={translateKey("form-text-radio")} transKey="form-text-radio" keyName="is_radio_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_television_list').value} validationErrors={section.getFieldByKey('is_television_list').validationErrors} text={translateKey("form-text-tv")} transKey="form-text-tv" keyName="is_television_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_internet_list').value} validationErrors={section.getFieldByKey('is_internet_list').validationErrors} text={translateKey("form-text-internet")} transKey="form-text-internet" keyName="is_internet_list"/>
                                <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-3" setTypeChangeToParent={this.setTypeChange} value={section.getFieldByKey('is_pc_list').value} validationErrors={section.getFieldByKey('is_pc_list').validationErrors} text={translateKey("form-text-computer")} transKey="form-text-computer" keyName="is_pc_list"/>
                            </div>
                        </div>

                        <div className="row col-sm-12 col-lg-6">
                            <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_how_often_carer_free_de')} text={translateKey("form-text-geregelt")} transKey="form-text-geregelt" keyName="is_how_often_carer_free_de"/> 
                        </div>

                        <div className="row col-sm-12 col-lg-6">
                            <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_who_take_care_when_carer_free_de')} text={translateKey("form-text-werubernimmt")} transKey="form-text-werubernimmt" keyName="is_who_take_care_when_carer_free_de"/> 
                        </div>

                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="form-margin-bottom-fix"
                            transKey="form-text-garten"
                            key="is_garden"
                            keyName="is_garden"
                            value={isGarden.value}
                            validationErrors={isGarden.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("yes") },
                                { value: '717700001', label: translateKey("no") },
                            ]}
                        />
                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="form-margin-bottom-fix"
                            transKey="form-text-fahrrad"
                            key="is_bike"
                            keyName="is_bike"
                            value={isBike.value}
                            validationErrors={isBike.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("yes") },
                                { value: '717700001', label: translateKey("no") },
                            ]}
                        />

                        <SelectComponent
                            showValidation={this.state.showValidation}
                            keyName="is_marketingid"
                            valuesList={MarketingID.getOptions(translateKey)}
                            value={isMarketingId.value!==null?isMarketingId.value.toUpperCase():isMarketingId.value}
                            validationErrors={isMarketingId.validationErrors}
                            label={translateKey("form-text-from-where-you-know")}
                            setTypeChangeToParent={this.setTypeChange}
                        />
                        </fieldset>
                    </form>
                </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section18));