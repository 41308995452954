import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";

class LeadPatientDataHealthComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element
        };
    }
    
    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    getMepflegegrad(element){
        let finder = this.props.pflegegrad.find(x => x.Value.toString() === element.toString());
        if(finder){
            return finder.Label
        }else{
            return '---'
        }
    }

    createMepflegegrad(){
        return (
            <select className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangeMepflegegrad(e.target.value)} value={this.props.element.patientHealth.pflegegrad}>
            <option value='' hidden>{this.props.translateKey('choose_select')}</option>
            {this.props.pflegegrad.map((value, index) => {
                if(value.Value === this.props.element.patientHealth.pflegegrad){
                  return <option defaultValue key={"pflegegrad-"+value.Value} value={value.Value}>{value.Label}</option>
                }else{
                  return <option  key={"pflegegrad-"+value.Value} value={value.Value}>{value.Label}</option>
                }
            })}
            </select>
        )
    }

    handleChangeMepflegegrad(event){
        this.props.element.patientHealth.pflegegrad = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    patientHealth: {
                        ...prevState.element.patientHealth,
                        pflegegrad: event
                    }
                }
            }
        });
        this.props.editedData();
    }    
    
    createMepflegegradApplication(){
        return (
            <select className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangeMepflegegradApplication(e.target.value)} value={this.props.element.patientHealth.pflegegradApplication}>
            <option value='' hidden>{this.props.translateKey('choose_select')}</option>
            {this.props.pflegegradApplication.map((value, index) => {
                if(value.Value === this.props.element.patientHealth.pflegegradApplication){
                  return <option defaultValue key={"pflegegradApplication-"+value.Value} value={value.Value}>{value.Label}</option>
                }else{
                  return <option  key={"pflegegradApplication-"+value.Value} value={value.Value}>{value.Label}</option>
                }
            })}
            </select>
        )
    }

    handleChangeMepflegegradApplication(event){
        this.props.element.patientHealth.pflegegradApplication = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    patientHealth: {
                        ...prevState.element.patientHealth,
                        pflegegradApplication: event
                    }
                }
            }
        });
        this.props.editedData();
    }    

    render() {
        let leadPatientHealth;
        if(this.state.isOpen){
            leadPatientHealth = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("pflegegrad")}</label>
                            <div className="col-sm-8">
                                {this.createMepflegegrad()}
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("pflegegrad_application")}</label>
                            <div className="col-sm-8">
                                {this.createMepflegegradApplication()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{this.props.translateKey("health_status_data")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                    </div>
                </div>
                {leadPatientHealth}
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    i18: state.lang.i18data,
    pflegegrad: state.enums.careDegree,
    pflegegradApplication: state.enums.careDegreeApplication
};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientDataHealthComponent));