import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import produce from "immer";


class Section17 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-17'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey, showValidationErrors, isImportant } = this.props;
        const { section } = this.state;
        const is_cooking = section.getFieldByKey('is_cooking');
        const is_purchases = section.getFieldByKey('is_purchases');
        const is_laundry = section.getFieldByKey('is_laundry');
        const is_poted_flower_care = section.getFieldByKey('is_poted_flower_care');
        const is_ironing = section.getFieldByKey('is_ironing');
        const is_accompanying = section.getFieldByKey('is_accompanying_patient_while_out');
        const is_cleaning_the_house = section.getFieldByKey('is_cleaning_the_house');
        const is_company = section.getFieldByKey('is_company_during_medical_visits');
        const is_common_trips = section.getFieldByKey('is_common_trips');
        const is_organization_of_free_time = section.getFieldByKey('is_organization_of_free_time');
        const is_driving = section.getFieldByKey('is_driving')
        const is_driving_automat_gearbox = section.getFieldByKey('is_driving_automat_gearbox');
        return (
            <div className="form-section-main">
                <div className="form-section-version">{translateKey("version")}: {baseData.bbFormVersion}</div>
                <div className="form-section-main-number">
                    <span>{translateKey("mainFormName")}</span>
                    <span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">{this.props.subsectionNumber}. {this.props.subsectionName}</div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                        <CheckBoxComponent isImportant={isImportant&&is_cooking.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_cooking.value} validationErrors={is_cooking.validationErrors} text={translateKey("form-text-kochen")} transKey="form-text-kochen" keyName="is_cooking"/>
                        <CheckBoxComponent isImportant={isImportant&&is_purchases.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_purchases.value} validationErrors={is_purchases.validationErrors} text={translateKey("form-text-einkaufengehen")} transKey="form-text-einkaufengehen" keyName="is_purchases"/>
                        <CheckBoxComponent isImportant={isImportant&&is_laundry.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_laundry.value} validationErrors={is_laundry.validationErrors} text={translateKey("form-text-waschewaschen")} transKey="form-text-waschewaschen" keyName="is_laundry"/>
                        <CheckBoxComponent isImportant={isImportant&&is_poted_flower_care.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_poted_flower_care.value} validationErrors={is_poted_flower_care.validationErrors} text={translateKey("form-text-zimmerpflanzen")} transKey="form-text-zimmerpflanzen" keyName="is_poted_flower_care"/>
                        <CheckBoxComponent isImportant={isImportant&&is_ironing.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_ironing.value} validationErrors={is_ironing.validationErrors} text={translateKey("form-text-bugeln")} transKey="form-text-bugeln" keyName="is_ironing"/>
                        <CheckBoxComponent isImportant={isImportant&&is_accompanying.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_accompanying.value} validationErrors={is_accompanying.validationErrors} text={translateKey("form-text-spazierengehen")} transKey="form-text-spazierengehen" keyName="is_accompanying_patient_while_out"/>
                        <CheckBoxComponent isImportant={isImportant&&is_cleaning_the_house.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_cleaning_the_house.value} validationErrors={is_cleaning_the_house.validationErrors} text={translateKey("form-text-arbeitsplatzes")} transKey="form-text-arbeitsplatzes" keyName="is_cleaning_the_house"/>
                        <CheckBoxComponent isImportant={isImportant&&is_company.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_company.value} validationErrors={is_company.validationErrors} text={translateKey("form-text-arztbesuche")} transKey="form-text-arztbesuche" keyName="is_company_during_medical_visits"/>
                        <CheckBoxComponent isImportant={isImportant&&is_common_trips.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_common_trips.value} validationErrors={is_common_trips.validationErrors} text={translateKey("form-text-gemeinsame")} transKey="form-text-gemeinsame" keyName="is_common_trips"/>
                        <CheckBoxComponent isImportant={isImportant&&is_organization_of_free_time.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_organization_of_free_time.value} validationErrors={is_organization_of_free_time.validationErrors} text={translateKey("form-text-freizeitgestaltung")} transKey="form-text-freizeitgestaltung" keyName="is_organization_of_free_time"/>
                        <CheckBoxComponent isImportant={isImportant&&is_driving.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_driving.value} validationErrors={is_driving.validationErrors} text={translateKey("form-text-schaltgetriebe")} transKey="form-text-schaltgetriebe" keyName="is_driving"/>
                        <CheckBoxComponent isImportant={isImportant&&is_driving_automat_gearbox.checkImportant}  showValidation={showValidationErrors} className="col-lg-4" setTypeChangeToParent={this.setTypeChange} value={is_driving_automat_gearbox.value} validationErrors={is_driving_automat_gearbox.validationErrors} text={translateKey("form-text-automatikgetriebe")} transKey="form-text-automatikgetriebe" keyName="is_driving_automat_gearbox"/>
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section17));