import { MockClient } from './mockClient'
import { MockPatient } from './mockPatient'
export const MockLeadData = {
    client: {...MockClient},
    converted_date: '',
    dateOfEntry: new Date().getTime()/1000,
    email: '',
    lead: '',
    leadId: '',
    location: '',
    noteFp: '',
    patient: '',
    patientObj: {...MockPatient},
    payer: '',
    phone: '',
    source: '',
    sourceCountry: 0,
    status: '',
    typeText: "",
    dateFirstInterview: '',
    ViewedInApplication: true,
    leadExclusiveForPm24: '',
    marketingId: '',
    rating: '',
    reasonForRejection: '',
    reasonsForRejectionDetails: '',
    leadType: '',
    referralContactId: '',
};