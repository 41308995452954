import { ReqHelper } from "../../_helpers/index";
export class TaskTable {
    constructor(element, Tasktype){
        let converted_Createddate = ReqHelper.dateConversion(element.CreatedDate);
        let converted_Duedate = ReqHelper.dateConversion(element.DueDay);
        this.concernName = element.ConcernName;
        this.concernType = element.ConcernType;
        this.concerns = element.Concerns;
        this.createdDate = element.CreatedDate;
        this.convertedCreatedDate = converted_Createddate;
        this.description = element.Description;
        this.dueDay = element.DueDay;
        this.convertedDueDay= converted_Duedate;
        this.id = element.Id;
        this.direction = element.Direction!==null? element.Direction:'';
        this.owner = element.Owner;
        this.priority = element.Priority;
        this.status = element.Status;
        this.subject = element.Subject;
        this.title = element.Title;
        this.type = element.Type;
        this.taskType = Tasktype;
        this.StartTime = element.StartTime;
        this.FinishTime = element.FinishTime;
        this.ViewedInApplication = element.ViewedInApplication;
        this.FpUkEvent = element.FpUkEvent;
    }
};