import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import produce from "immer";

class Section19 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-20'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        if(value===0){value = null;}
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey } = this.props;
        const { section } = this.state;
        let isdisabledfields = disabled;
        const isZGDE2 = section.getFieldByKey('is_zg_de_2');
        const isZGDE3 = section.getFieldByKey('is_zg_de_3');
        const isZGDE4 = section.getFieldByKey('is_zg_de_4');
        const isZGDE5 = section.getFieldByKey('is_zg_de_5');
        const isZGDE6 = section.getFieldByKey('is_zg_de_6');
        const isZGDE7 = section.getFieldByKey('is_zg_de_7');
        const isZGDE8 = section.getFieldByKey('is_zg_de_8');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={isdisabledfields}>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_2"
                                    value={isZGDE2.value}
                                    validationErrors={isZGDE2.validationErrors}
                                    text={translateKey("is_zg_de_2")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_3"
                                    value={isZGDE3.value}
                                    validationErrors={isZGDE3.validationErrors}
                                    text={translateKey("is_zg_de_3")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_4"
                                    value={isZGDE4.value}
                                    validationErrors={isZGDE4.validationErrors}
                                    text={translateKey("is_zg_de_4")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_5"
                                    value={isZGDE5.value}
                                    validationErrors={isZGDE5.validationErrors}
                                    text={translateKey("is_zg_de_5")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_6"
                                    value={isZGDE6.value}
                                    validationErrors={isZGDE6.validationErrors}
                                    text={translateKey("is_zg_de_6")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_7"
                                    value={isZGDE7.value}
                                    validationErrors={isZGDE7.validationErrors}
                                    text={translateKey("is_zg_de_7")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={this.props.showValidationErrors}
                                    keyName="is_zg_de_8"
                                    value={isZGDE8.value}
                                    validationErrors={isZGDE8.validationErrors}
                                    text={translateKey("is_zg_de_8")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                    isClearable={!isdisabledfields}
                                />
                            </div>
                        </fieldset>
                    </form>
                </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section19));
