import React from 'react';
import DatePickerComponent from "../../date-picker-component/DatePickerComponent";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

export default class WrappedDatePickerComponent extends React.Component {
    handleDueDayChange = event => {
        const { setTypeChangeToParent, keyName } = this.props;
        let timestamp = new Date(event).getTime();
        timestamp = Math.floor(timestamp / 1000);
        setTypeChangeToParent(keyName, timestamp);
    };

    render() {
        const { value, validationErrors, showValidation, keyName, text, withTime, withYear, disabled, isClearable, placeholderText, inline } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`col-sm-12 form-margin-bottom-fix wrapped-date-picker-component ${showErrors ? 'is-invalid' : ''}`}>
                <label className="form-label-basic"><span className="quick-label-position-fix">{text}</span></label>
                <DatePickerComponent key={keyName} data-field-name={keyName}
                                     className={`display-block ${showErrors ? 'is-invalid' : ''}`}
                                     timestamp={value}
                                     withTime={withTime}
                                     withYear={withYear}
                                     disabled={disabled}
                                     onDateChange={this.handleDueDayChange}
                                     inline={inline}
                                     isClearable={isClearable}
                                     placeholderText={placeholderText} />
                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName}/>
                )}
            </div>
        );
    }
}
