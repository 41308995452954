import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp, addNewTaskData, getTasks, setClientsData, setPatientClientData } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, AlertMessage, TaskDetailsBasicComponent, LeadCancelSaveComponent } from "../../_components/index";
import { Alert, ClientTable} from "../../_constance/classes/index";
import { IconsMap, ResponseStatus } from "../../_helpers/index";
import { taskSaveTaskData } from "../../_redux/actions/auth";
import { MockTaskData } from "../../_constance/classes/mockTask/mockTaskData";
import axiosAuth from "../../_services/config/axios-auth";
import * as RecordType from "../../_constance/values/recordType";
import { baseData } from '../../_constance/base_data';

class NewTaskPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      taskData: JSON.parse(JSON.stringify(MockTaskData)),
      callData: JSON.parse(JSON.stringify(MockTaskData)),
      eventData: JSON.parse(JSON.stringify(MockTaskData)),
      meetingData: JSON.parse(JSON.stringify(MockTaskData)),
      savingDataTask: false,
      errorComponent: Alert,
      loading: true,
      editedTask: false,
      editedCall: false,
      editedEvent: false,
      editedMeeting: false,
      errorsMessage: null,
      errorsType: "danger",
      breadCrumbs:[
        {
          link: "tasks",
          key: "tasks",
          name: null
        },
        {
          link: "newtask",
          key: "new_task",
          name: null
        }
      ]
    };
  }

  componentDidUpdate() {
    let unsavedSomething = this.state.editedTask || this.state.editedCall || this.state.editedEvent || this.state.editedMeeting;
    this._promptUnsavedChange(unsavedSomething);
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            taskData:{
              ...prevState.taskData,
              taskType:'Task',
              owner: this.props.userId
            },
            callData: {
              ...prevState.callData,
              taskType:"Call",
              owner: this.props.userId
            },
            eventData: {
              ...prevState.eventData,
              taskType: 'Event',
              owner: this.props.userId,
              FpUkEvent: '717700000',
            },
            meetingData: {
              ...prevState.meetingData,
              taskType: 'Event',
              owner: this.props.userId,
              FpUkEvent: '717700001',
            },
        }
      });
    }
    window.scrollTo(0, 0)
    this.myTimeout = setTimeout(() => {
      this.getInitialDataTime()
    }, 200);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.state.edited){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              taskData: JSON.parse(JSON.stringify(MockTaskData)),
              callData: JSON.parse(JSON.stringify(MockTaskData)),
              eventData: JSON.parse(JSON.stringify(MockTaskData)),
              meetingData: JSON.parse(JSON.stringify(MockTaskData)),
              editedTask: false,
              editedCall: false,
              editedEvent: false,
              editedMeeting: false,
          }
        });
      }
    }
    clearTimeout(this.myTimeout);
  };

  _promptUnsavedChange(isUnsaved = false) {
    window.onbeforeunload = isUnsaved;
  }

  setActiveTab(tabNumber){
    if(this.state.activeTab!==tabNumber){
      if (this._isMounted) {
        this.setState({activeTab: tabNumber})
      }
    }
  }

  getClientsList = () => {
    if(this.props.clientsData.length<=0){
      axiosAuth.get("clients/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          for(let i=0; i<this.props.patientData.length; i++){
            if(this.props.patientData[i].clientId===element.id){
              element.locationP = this.props.patientData[i].patientAddress ? this.props.patientData[i].patientAddress.city:'';
              element.patientName = this.props.patientData[i].name;
              element.patient = this.props.patientData[i]
            }
          }
          return new ClientTable(element);}
        );
        this.props.setClientData(mapedRes);
        if (this._isMounted) {
          this.setState({loading:false})
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({loading:false})
        }
      });
    }else{
      if (this._isMounted) {
        this.setState({loading:false})
      }
    }
  }

  getPatientList(){
    if(this.props.patientData.length<=0){
      axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getClientsList();
      })
      .catch((error) => {
        console.log(error);
        this.getClientsList();
      });
    }else{
      this.getClientsList();
    }
  }

  getBasicTasksData(){
    if(this.props.tasksStatus === ResponseStatus.READY){
      clearTimeout(this.myTimeout);
      this.getPatientList();
    }else{
      if(this.props.tasksStatus === ResponseStatus.FREE){
        this.props.getGlobalTasksData();
        this.myTimeout = setTimeout(() => {
          this.getBasicTasksData()
        }, 500);
      }else{
        if(this.props.tasksStatus === ResponseStatus.ERROR){
          clearTimeout(this.myTimeout);
          if (this._isMounted) {
            this.setState({ loadingTasks: false })
          }
        }else{
          this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
          }, 500);
        }
      }
    }
  }

  getInitialDataTime(){
    if(this.props.tasksData.length>0){
      clearTimeout(this.myTimeout);
      this.getPatientList();
    }else{
      this.myTimeout = setTimeout(() => {
        this.getBasicTasksData()
      }, 300);
    }
  }

  cancelChange(){
    if (this._isMounted) {
      switch(this.state.activeTab){
        case 1:   this.setState(prevState => {
                    return {
                        ...prevState,
                        callData: {
                          ...MockTaskData,
                          taskType: 'Call',
                          owner: this.props.userId
                        },
                        editedCall: false
                    }
                  });
                  break;
        case 2:   this.setState(prevState => {
                    return {
                        ...prevState,
                        eventData: {
                          ...MockTaskData,
                          taskType: 'Event',
                          owner: this.props.userId,
                          FpUkEvent: '717700000',
                        },
                        editedEvent: false
                    }
                  });
                  break;
        case 3:   this.setState(prevState => {
                    return {
                        ...prevState,
                        meetingData: {
                          ...MockTaskData,
                          taskType: 'Event',
                          owner: this.props.userId,
                          FpUkEvent: '717700001',
                        },
                        editedMeeting: false
                    }
                  });
                  break;
        default:  this.setState(prevState => {
                    return {
                        ...prevState,
                        taskData: {
                          ...MockTaskData,
                          taskType: 'Task',
                          owner: this.props.userId
                        },
                        editedTask: false
                    }
                  });
      }
    }
  }

  saveChange(){
    if(this.state.editedTask || this.state.editedCall || this.state.editedEvent || this.state.editedMeeting){
      if (this._isMounted) {
        this.setState({savingDataTask: true})
      }
      let tempTaskData = this.state.taskData;
      switch(this.state.activeTab){
        case 1: tempTaskData = this.state.callData;
                break;
        case 2: tempTaskData = this.state.eventData;
                break;
        case 3: tempTaskData = this.state.meetingData;
                break;
        default: tempTaskData = this.state.taskData;
      }
      this.props.saveTaskData(tempTaskData, this.props.appLang)
      .then((res)=>{
        if (this._isMounted) {
          this.setState({
            savingDataTask: false,
            errorsMessage: "task_successfully_saved",
            errorsType: "success",
            edited: false,
          })   
        }   
        this.cancelEditing();
        clearTimeout(this.myTimeout);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              errorsMessage: null,
              errorsType: "danger"
            }) 
          }
          const { match: { params }, history } = this.props;
          switch(params.type){
            case RecordType.LEAD: history.push(`${baseData.subFolders}leadDetails/${params.assignTo}`);break;
            case RecordType.CLIENT : history.push(`${baseData.subFolders}clientDetails/${params.assignTo}`);break;
            case RecordType.MULTIPLIER : history.push(`${baseData.subFolders}multiplierDetails/${params.assignTo}`);break;
            default: history.push(`${baseData.subFolders}tasks`);
          }
          
        }, 1500);
        this.props.addTaskDataFun(res,tempTaskData);
      })
      .catch((err)=>{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                  ...prevState,
                  savingDataTask: false,
                  errorsMessage: err.message,
                  errorsType: "danger"
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({
                errorsMessage: null,
                errorsType: "danger"
              }) 
            }
          }, 3000);
      })
    }
  }

  cancelEditing(){
    if (this._isMounted) {
      switch(this.state.activeTab){
        case 1: this.setState({editedCall: false})
                break;
        case 2: this.setState({editedEvent: false})
                break;
        case 3: this.setState({editedMeeting: false})
                break;
        default: this.setState({editedTask: false})
      }
    }
  }

  basicEdit(){
    if (this._isMounted) {
      switch(this.state.activeTab){
        case 1: if(!this.state.editedCall){
                  this.setState({editedCall: true})
                }
                break;
        case 2: if(!this.state.editedEvent){
                  this.setState({editedEvent: true})
                }
                break;
        case 3: if(!this.state.editedMeeting){
                  this.setState({editedMeeting: true})
                }
                break;
        default:  if(!this.state.editedTask){
                    this.setState({editedTask: true})
                  }
      }
    }
  }

  render() {
    let taskData;
    if (this.state.loading) {
      taskData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      let FirstRow = '';
      let CancelSave = '';
      if(this.state.activeTab === 1){
        FirstRow =(
          <TaskDetailsBasicComponent newOne={true} editedData={()=>this.basicEdit()} edited={this.state.editedCall} key="taskDetails1" element={this.state.callData}/>
        )
        CancelSave = (
          <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="taskCancelSave1" edited={this.state.editedCall} savingData={this.state.savingDataTask}/>
        );
      }else{
        if(this.state.activeTab === 2){
          FirstRow =(
            <TaskDetailsBasicComponent newOne={true} editedData={()=>this.basicEdit()} edited={this.state.editedEvent} key="taskDetails2" element={this.state.eventData}/>
          )
          CancelSave = (
            <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="taskCancelSave1" edited={this.state.editedEvent} savingData={this.state.savingDataTask}/>
          );
        } else if(this.state.activeTab === 3){
          FirstRow =(
            <TaskDetailsBasicComponent newOne={true} editedData={()=>this.basicEdit()} edited={this.state.editedMeeting} key="taskDetails3" element={this.state.meetingData}/>
          )
          CancelSave = (
            <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="taskCancelSave1" edited={this.state.editedMeeting} savingData={this.state.savingDataTask}/>
          );
        }else{
          FirstRow =(
            <TaskDetailsBasicComponent newOne={true} editedData={()=>this.basicEdit()} edited={this.state.editedTask} key="taskDetails4" element={this.state.taskData}/>
          )
          CancelSave = (
            <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="taskCancelSave1" edited={this.state.editedTask} savingData={this.state.savingDataTask}/>
          );
        }
      }
      let SaveAlert;
      if(this.state.errorsMessage!==null){
        SaveAlert = (
          <AlertMessage centeredAlert={true} show={this.state.errorsMessage!==null} key="taskAlert1" type={this.state.errorsType} message={this.props.translateKey(this.state.errorsMessage)}/>
        )
      }
      taskData =  [
        FirstRow,
        SaveAlert,
        CancelSave
      ]
    }

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass overflowInitial">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a className="nav-item nav-link active nav-link-invoices"  onClick={()=>{this.setActiveTab(0)}} id="nav-task-tab" data-toggle="tab" href="#nav-task" role="tab" aria-controls="nav-task" aria-selected="true">
                {this.props.translateKey("task_task")}
              </a>
              <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab(1)}} id="nav-call-tab" data-toggle="tab" href="#nav-call" role="tab" aria-controls="nav-call" aria-selected="true">
                {this.props.translateKey("task_call")}
              </a>
              <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab(2)}}id="nav-event-tab" data-toggle="tab" href="#nav-event" role="tab" aria-controls="nav-event" aria-selected="true">
                {this.props.translateKey("task_event")}
              </a>
              <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab(3)}}id="nav-meeting-tab" data-toggle="tab" href="#nav-event" role="tab" aria-controls="nav-event" aria-selected="true">
                {this.props.translateKey("task_meeting")}
              </a>
            </div>
          </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-task" role="tabpanel" aria-labelledby="nav-task-tab">
                <div className="leadSection">
                  {taskData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-call" role="tabpanel" aria-labelledby="nav-call-tab">
                <div className="leadSection">
                  {taskData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-event" role="tabpanel" aria-labelledby="nav-event-tab">
                <div className="leadSection">
                  {taskData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-event" role="tabpanel" aria-labelledby="nav-meeting-tab">
                <div className="leadSection">
                  {taskData}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={this.state.editedTask || this.state.editedCall || this.state.editedEvent || this.state.editedMeeting}
            message={this.props.translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    i18: state.lang.i18data,
    userId: state.auth.userId,
    tasksData: state.database.tasks,
    tasksTimestamp: state.database.tasksTimeStamp,
    taskPriority: state.enums.taskPriority,
    tasksStatus: state.database.tasksState,
    clientsData: state.database.clients,
    patientData: state.database.patientClient,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  saveTaskData: (data) => dispatch(taskSaveTaskData(data)),
  addTaskDataFun: (id, data) => dispatch(addNewTaskData(id, data)),
  getGlobalTasksData: () => dispatch(getTasks()),
  setClientData: (data) => dispatch(setClientsData(data)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewTaskPage));