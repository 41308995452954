import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PieChart, Pie, Cell, Legend} from 'recharts';
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable} from "../../../index";
import {MultiplierQuality} from "../../../../_constance/values/multiplierQuality";
import { Colors } from "../../../../_constance/enums/colors";
import { baseData } from '../../../../_constance/base_data';


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, value}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  )
};

class MultpiliersPerQuality extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      data: [],
      loadingDashboard: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    let data = [];
    let emptyObj = {
        amount: 0,
        name: this.props.translateKey("no_information"),
        status: 0,
        color: Colors.GRAY
    }
    if(this.props.multipliersData.length>0){
      this.props.multipliersData.forEach(element=>{
        if(element.multiplierQuality===null){
          emptyObj.amount +=1;
        }else{
          this.prepareMeMultiplierQuality(data, element);
        }
      })
    }
    if(emptyObj.amount>0){
      data = [emptyObj, ...data]
      this.setState({
        loadingDashboard: false,
        data: data,
        show: true
      })
    }else{
      this.setState({
        loadingDashboard: false,
        data: data,
        show: this.props.multipliersData.length>0
      })
    }
  }

  prepareMeMultiplierQuality(data, element){
    for(let i=0; i<MultiplierQuality.length; i++){
      if(MultiplierQuality[i].value === element.multiplierQuality.toString()){
        let item = data.find(element=>element.status===MultiplierQuality[i].value)
        if(item){
          item.amount =  item.amount+1;
        }else{
          data.push({
            amount: 1,
            name: this.props.translateKey(MultiplierQuality[i].key),
            status: MultiplierQuality[i].value,
            color: MultiplierQuality[i].color
          })
        }
      }
    }
  }

  handleClick = (data) => {
    if(data.value>0 && data.status>0 && data.status !== null){
      this.props.history.push(`${baseData.subFolders}multipliers/?type=quality&param=${data.status}`);
    }
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashboard, data } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashboard){
      Content = (
        <PieChart width={700} height={420}>
          <Pie
            data={data}
            cx={200}
            cy={200}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            dataKey="amount"
            onClick={this.handleClick}
            className="cursor-pointer"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="middle"
            align="right"
            layout="vertical"
            width={200}
            height={420}
            wrapperStyle={{
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              fontSize: "14px"
            }}
          />
        </PieChart>
      );
    }
    return (
      <React.Fragment>
        {this.state.show===true &&
          <div className="charts-pie-container-wrapper">
            <h5 className="text-center">{translateKey("multipliers_quality_structute")}</h5>
            {Content}
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    multipliersData: state.database.multipliers
  };
};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultpiliersPerQuality));
