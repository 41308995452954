
import axiosAuth from './config/axios-auth';
export const getDashboardData = function (lang) {
    return axiosAuth.get(`statistics/dashboard/${lang}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}