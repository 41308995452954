import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  setLeadsData,
  pendingLeadsData,
  removeLeadsData,
  authSetActionTimestamp,
  setLeadStatusData,
  removeLeadStatusData,
  updateLeadData
} from "../../_redux/actions/index";
import {
  LeadSourceStatusComponent,
  TableColumHead,
  TableProperties,
  LoaderModal,
  LoaderTable,
  NavBar,
  SideBar,
  EmptyTableIcon,
  AlertMessage,
  TableDateFormat,
  MailToEmailComponent,
  MailToPhoneComponent,
  TablePagination
} from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import { TableEnums } from "../../_constance/enums";
import { Rating } from "../../_constance/enums/ratingLead"
import { Alert, LeadTable, Paging } from "../../_constance/classes/index";
import { SortingLeads } from "../../_constance/classes/sortingClasses/index";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { shouldRefresh, ReqHelper, IconsMap } from "../../_helpers/index";
import * as LeadStatusExceptions from "../../_constance/values/LeadStatusExceptions";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import * as MarkAsViewedEntity from "../../_constance/values/markAsViewedEntity";
import { markAsViewed } from "../../_services/user_service";
import {setFiltersData} from "../../_redux/actions";

//COLUMNS
import {
  setColumnsData,
  removeColumnsData,
  addColumnData,
  updateColumTypeData
} from "../../_redux/actions/active-columns";
import * as ColumnsNames from "../../_constance/values/columnsNames";
import {saveColumnsData, getColumnsData} from "../../_services/firebase_service";
import * as FilterType from "../../_constance/enums/filterType";

class LeadsMainPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      sorting: new SortingLeads(),
      searchQuery: null,
      filterData: [],
      showModalLoader: false,
      errorComponent: Alert,
      pagingItem: Paging,
      rerender: Date.now(),
      firstLoad: true,
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    const query = new URLSearchParams(this.props.location.search);
    if(query.get('type')==="dateOfEntry"){
      this.state.sorting.changeColumnByColumnSortType('dateOfEntry', 'ASC');
    }
    this.getMeColumnConfiguration();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };



  getMeColumnConfiguration(){
    const lastUpdate = this.props.activeColumnsTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getColumnsDataFromApi();
    } else {
      if (this._isMounted) {
        this.prepareMeSortingBasedOnColumns()
        this.getDataBasic();
      }
    }
  }
  getColumnsDataFromApi(){
   getColumnsData(this.props.userIdColumn)
    .then(res => {
        this.props.setColumns(res);
        this.prepareMeSortingBasedOnColumns();
        this.getDataBasic();
    })
    .catch((error) => {
      this.prepareMeSortingBasedOnColumns();
      this.getDataBasic();
    });
  }
  prepareMeSortingBasedOnColumns(){
    if(this.props.activeColumns){
      this.props.activeColumns.forEach(element => {
        if(element.name === ColumnsNames.LEADS){
          element.columns.forEach(item => {
            this.state.sorting.setColumnEnabled(item.enabled, item.name)
          });
        }
      });
    }
    this.setState({rerender: Date.now()})
  }
  prepareColumnsData = (data) =>{
    data.forEach(column => {
      this.state.sorting.setColumnEnabled(column.enabled, column.keyName)
    });
    let columnsData = this.props.activeColumns.find(element => element.name === ColumnsNames.LEADS); 
    if(columnsData){
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.LEADS, data, false);
      this.props.updateColumnTypeData(ColumnsNames.LEADS, dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.LEADS, dataToSave.columns)
    }else{
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.LEADS, data, false);
      this.props.addNewColumnTable(dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.LEADS, dataToSave.columns)
    }
  }







  

  _prepareFilterObj() {
    let filterData = [];
    let Options = [];
    const query = new URLSearchParams(this.props.location.search);
    if(query.get('type')==="source"){
      let param = query.get('param');
      if(param!==undefined && param!==null){
        this.setState({
          searchQuery: param
        })
      }
    }
    for (let i = 0; i < this.props.leadsStatus.length; i++) {
      let filtering = null;
      if(this.props.appLang===LanguageSelect.DE){
        let existsInException = LeadStatusExceptions.DE_LIST.filter(element=>element===this.props.leadsStatus[i].Value)
        if(existsInException.length!==0){
          if(this.state.firstLoad===true){
            if(query.get('type')==="status"){
              filtering = {
                column: this.props.leadsStatus[i].Label,
                active: existsInException[0]===query.get('param')?true:false,
                type: "text"
              }
              if(existsInException[0]===query.get('param')){
                this.setState({firstLoad:false})
              }
            }else{
              filtering = {
                column: this.props.leadsStatus[i].Label,
                active: false,
                type: "text"
              }
            }
          }else{
            filtering = {
              column: this.props.leadsStatus[i].Label,
              active: false,
              type: "text"
            }
          }
        }
      }else{
        let existsInException = LeadStatusExceptions.EN_LIST.filter(element=>element===this.props.leadsStatus[i].Value)
        if(existsInException.length!==0){
          if(this.state.firstLoad===true){
            if(query.get('type')==="status"){
              filtering = {
                column: this.props.leadsStatus[i].Label,
                active: existsInException[0]===query.get('param')?true:false,
                type: "text"
              }
              if(existsInException[0]===query.get('param')){
                this.setState({firstLoad:false})
              }
            }else{
              filtering = {
                column: this.props.leadsStatus[i].Label,
                active: false,
                type: "text"
              }
            }
          }else{
            filtering = {
              column: this.props.leadsStatus[i].Label,
              active: false,
              type: "text"
            }
          }
        }
      }
      if(filtering){
        Options.push(filtering);
      }
    }
    filterData.push({
      filterName: "status",
      filterKey: "typeText",
      filterOptions: Options
    });
    filterData.push({
      filterName: "source_of_acquisition",
      filterKey: "source",
      filterType: FilterType.STATUS,
      filterOptions: this.props.leadSource.map(function(item) {
        return {
          column: item.Label,
          value: item.Value,
          active: false,
          type: "text"
        }
      })
    });
    filterData.push({
      filterName: "reason_for_rejection",
      filterKey: "reasonForRejection",
      filterType: FilterType.STATUS,
      filterOptions: this.props.rejectReasonsList.map(function(item) {
        return {
          column: item.Label,
          value: item.Value,
          active: false,
          type: "text"
        }
      })
    });
    filterData.push({
      filterName: "marketing_type",
      filterKey: "marketingId",
      filterType: FilterType.STATUS,
      filterOptions: this.props.marketingsList.map(function(item) {
        return {
          column: item.Label,
          value: item.Value,
          active: false,
          type: "text"
        }
      })
    });
    filterData.push({
      filterName: "date_of_lead",
      filterKey: "dateOfEntry",
      filterOptions: [
        {
          column: this.props.translateKey('date_from'),
          active: false,
          type: "dateFrom",
          date: new Date().getTime()
        },
        {
          column: this.props.translateKey('date_to'),
          active: false,
          type: "dateTo",
          date: new Date().getTime()
        }
      ]
    });
    let saved = this.props.savedFilters;
    if(saved !== null) {
      return saved;
    } else {
      return filterData
    }
  }

  getLeadsList = () => {
    this.props.pendingRequest();
    axiosAuth.get("leads/list/0/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          element.typeText = ReqHelper.getMeLeadStatusText(element.status, this.props.leadsStatus);
          return new LeadTable(element);
        });
        this.props.setData(mapedRes);
        if (this._isMounted) {
          this.setState({
            data: mapedRes,
            loading:false,
            filterData: this._prepareFilterObj()
          }, () => this.filterOrDataChange());
        }
      })
      .catch((error) => {
        this.props.clearData();
        this.alertD.show = true;
        this.alertD.type = "danger";
        this.alertD.message = this.props.translateKey("basic_error_message");
        if (this._isMounted) {
          this.setState({
            data: [],
            loading: false,
            errorComponent: this.alertD
          }, () => this.filterOrDataChange());
        }
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  getLeadsDataByTimestamp(){
    const lastUpdate = this.props.leadsTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getLeadsList();
    } else {
      if (this._isMounted) {
        this.setState({
          data: this.props.leadsData,
          loading: false,
          filterData: this._prepareFilterObj()
        }, () => this.filterOrDataChange());
      }
    }
  }

  getLeadsStatus = () => {
    axiosAuth.get("lists/leadStatus/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setLeadsStatus(res);
        this.getLeadsDataByTimestamp();
      })
      .catch((error) => {
        this.props.removeLeadsStatus();
        this.getLeadsDataByTimestamp();
      });
  }

  getDataBasic() {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    if (this.props.leadsStatus.length === 0) {
      if (this.props.leadsStatus.length !== 0 && this.props.leadStatus !== undefined) {
        this.getLeadsDataByTimestamp();
      } else {
        this.getLeadsStatus();
      }
    }else{
      this.getLeadsDataByTimestamp();
    }
  }

  filterOrDataChange(val){
    let finalDataToShow = this.propsFilterSearch(val);
    ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
    this.propsPagingData(finalDataToShow);
    setTimeout(() => {
      let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
      if (this._isMounted) {
        this.setState({data: finalData});
      }
    }, 50);
    this.props.setFilters('leads', this.state.filterData);
  }

  toggleModalLoader = () => {
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          ...prevState,
          showModalLoader: !prevState.showModalLoader
        }
      });
    }
  }

  propsSearchTable = async (val) => {
    const { history } = this.props;
    if(val.lastIndexOf(baseData.deepLinkStart, 0) === 0){
      this.toggleModalLoader();
      let url = new URL(val);
      let id = url.searchParams.get("id");
      let form = url.searchParams.get("extraqs");
      if(form!==null && id!==null){
        const { appLang } = this.props
        id = id.replace('{', '').replace('}', '').toLowerCase();
        switch(form){
            case baseData.enumClient:           axiosAuth.get("leads/list/0/" + appLang)
                                                .then(res => {
                                                    let statusObj = res.data.find((element) => {return element.leadId.toString().toLowerCase()===id.toString()})
                                                    if(statusObj){
                                                      this.toggleModalLoader();
                                                      history.push(`${baseData.subFolders}leadDetails/${id}`);
                                                    }else{
                                                        axiosAuth
                                                        .get("clients/list/0")
                                                        .then(res => {
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              this.toggleModalLoader();
                                                              history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                            }else{
                                                                axiosAuth.get("multipliers/list/0")
                                                                .then(res => {
                                                                  this.toggleModalLoader();
                                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                    if(statusObj){
                                                                      history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                    }
                                                                }).catch(()=>this.toggleModalLoader())
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    }
                                                }).catch(()=>{
                                                    axiosAuth
                                                    .get("clients/list/0")
                                                    .then(res => {
                                                        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                        if(statusObj){
                                                          this.toggleModalLoader();
                                                          history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                        }else{
                                                            axiosAuth.get("multipliers/list/0")
                                                            .then(res => {
                                                                this.toggleModalLoader();
                                                                let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                if(statusObj){
                                                                  history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                }
                                                            }).catch(()=>this.toggleModalLoader())
                                                        }
                                                    }).catch(()=>{
                                                        axiosAuth.get("multipliers/list/0")
                                                        .then(res => {
                                                            this.toggleModalLoader();
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    })
                                                })
                                                break;
            case baseData.enumPatient:          if(this.props.patientLeadData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientLeadData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/LEAD/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }
                                                if(this.props.patientClientData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientClientData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/CLIENT/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }                   
                                                break;
            case baseData.enumCarer:            axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundCarer = false;
                                                  for(let i=0; i<res.data.length && !foundCarer; i++){
                                                    foundCarer = this.getMeCarer(res.data[i].id, appLang, id, i===res.data.length-1);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumContract:         let gotHim = false
                                                await axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundContract = false;
                                                  for(let i=0; i<res.data.length && !gotHim; i++){
                                                    foundContract = this.getMeContract(res.data[i].id, appLang, id, "CLIENT");
                                                  }
                                                  if(!foundContract){
                                                    this.getMeLeads(appLang, id);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumInvoice:          this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}invoices/${id}`);
                                                break;
            case baseData.enumTaskTypeEvent:    this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeTask:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeCall:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            default: this.toggleModalLoader()
        }    
      }
    }else{
      if (this._isMounted) {
        this.setState({ searchQuery: val.trim() });
      }
      this.filterOrDataChange(val);
    }
  }

  getMeCarer = (id, lang, carerId, redirect=false) =>{
    axiosAuth.get(`/carers/list/${id}/0/${lang}`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.wzid.toString().toLowerCase()===carerId.toString())
        if(statusObj){
          this.toggleModalLoader()
          this.props.history.push(`${baseData.subFolders}clientDetails/${statusObj.clientId}/${carerId}`);
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeContract = (id, appLang, contractId, type, redirect=false) => {
    const { history } = this.props;
    axiosAuth.get(`/contracts/list/${id}/0`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===contractId.toString())
        if(statusObj){
          this.toggleModalLoader()
          if(appLang === LanguageSelect.DE){
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}bb-form-lead/${id}/${contractId}/ENQUIRY`);                            
            }else{
              history.push(`${baseData.subFolders}bb-form-client/${id}/${contractId}/ENQUIRY`);   
            }
          }else{
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}leadDetails/${id}`);                            
            }else{
              history.push(`${baseData.subFolders}contract/${id}/${contractId}`);
            }
          }
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeLeads = (appLang, contractId) =>{
    axiosAuth.get("leads/list/0/" + appLang)
      .then((res) => {
        for(let i=0; i<res.data.length; i++){
          this.getMeContract(res.data[i].leadId, appLang, contractId, "LEAD", i===res.data.length-1);
        }
      }).catch(()=>this.toggleModalLoader())
  }
  
  /*getMeContractListRecurent = (list, iterationNumber, appLang, contractId, type) =>{
    if(iterationNumber<list.length){
      this.getMeContract(list[iterationNumber].leadId, appLang, contractId, type).then(e=>{
        if(e===false){
          this.getMeContractListRecurent(list, iterationNumber+1, appLang, contractId, type);
        }
      }).catch(()=>{
        this.getMeContractListRecurent(list, iterationNumber+1, appLang, contractId, type);
      })
    }
  }*/


  propsSortType(type){
    this.state.sorting.changeColumnByColumn(type);
    this.filterOrDataChange();
  }

  propsFilterSearch = (val) => {
    const { appLang } = this.props;
    const { sortingColumns } = this.state.sorting
    let finalDataAfter = [];
    if (this.props.leadsData !== null && this.props.leadsData.length > 0) {
      if(val && val!==null){
        // searching
        let filters = ReqHelper.getMeFilersObj(this.state.filterData);
        if(filters.length>0){
          // mamy search i filtry
          // search filter
          let afterSearchData = this.props.leadsData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
          });
          finalDataAfter = afterSearchData.filter((value) => {
            let gotAllFilters = true;
            for(let i=0; i<filters.length; i++){ 
              if(gotAllFilters){
                if(filters[i].name==='status'){
                  let gotStatus = false;
                  for(let v=0; v<filters[i].values.length; v++){
                    let didWeGotIt = ReqHelper.valueExistsInObjBasedOnSorting(value, filters[i].values[v].column, sortingColumns, appLang);
                    if(didWeGotIt){
                      gotStatus=true;
                      break;
                    }
                  }
                  gotAllFilters = gotStatus;
                }else if(filters[i].name==='dateOfEntry'){
                    let gotDateFilter = true;
                    for(let v=0; v<filters[i].values.length; v++){
                      if(gotDateFilter){
                        if(filters[i].values[v].type==="dateFrom"){
                          if(filters[i].values[v].date>value.dateOfEntry){
                            gotDateFilter = false;
                          }
                        }else{
                          if(filters[i].values[v].type==="dateTo"){
                            if(filters[i].values[v].date<value.dateOfEntry){
                              gotDateFilter = false;
                            }
                          }
                        }
                      }
                    }
                    gotAllFilters = gotDateFilter;
                } else if(filters[i].type) {
                  switch(filters[i].type){
                    case FilterType.STATUS:
                      let gotStatusFilter = false;
                      for(let v=0; v<filters[i].values.length; v++){
                        if(value[filters[i].key]!==null && value[filters[i].key]!== undefined){
                          if(value[filters[i].key].toString().toLowerCase()===filters[i].values[v].value.toString().toLowerCase()){
                            gotStatusFilter=true;
                            break;
                          }
                        }
                      }
                      gotAllFilters = gotStatusFilter;
                      break;
                  }
                }
              }
            }
            return gotAllFilters;
          });
        }else{
          // samo search bez filtrów
          finalDataAfter = this.props.leadsData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
          });
        }
      }else{
        let search = this.state.searchQuery;
        if(search && search!==null && val!==''){
          // filtrowanie z wpisanym search
          let filters = ReqHelper.getMeFilersObj(this.state.filterData);
          if(filters.length>0){
            // mamy search i filtry
            let afterSearchData = this.props.leadsData.filter((value) => {
              return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
            });
            finalDataAfter = afterSearchData.filter((value) => {
              let gotAllFilters = true;
              for(let i=0; i<filters.length; i++){ 
                if(gotAllFilters){
                  if(filters[i].name==='status'){
                    let gotStatus = false;
                    for(let v=0; v<filters[i].values.length; v++){
                      let didWeGotIt = ReqHelper.valueExistsInObjBasedOnSorting(value, filters[i].values[v].column, sortingColumns, appLang);
                      if(didWeGotIt){
                        gotStatus=true;
                        break;
                      }
                    }
                    gotAllFilters = gotStatus;
                  }else if(filters[i].name==='dateOfEntry'){
                      let gotDateFilter = true;
                      for(let v=0; v<filters[i].values.length; v++){
                        if(gotDateFilter){
                          if(filters[i].values[v].type==="dateFrom"){
                            if(filters[i].values[v].date>value.dateOfEntry){
                              gotDateFilter = false;
                            }
                          }else{
                            if(filters[i].values[v].type==="dateTo"){
                              if(filters[i].values[v].date<value.dateOfEntry){
                                gotDateFilter = false;
                              }
                            }
                          }
                        }
                      }
                      gotAllFilters = gotDateFilter;
                  } else if(filters[i].type) {
                    switch(filters[i].type){
                      case FilterType.STATUS:
                        let gotStatusFilter = false;
                        for(let v=0; v<filters[i].values.length; v++){
                          if(value[filters[i].key]!==null && value[filters[i].key]!== undefined){
                            if(value[filters[i].key].toString().toLowerCase()===filters[i].values[v].value.toString().toLowerCase()){
                              gotStatusFilter=true;
                              break;
                            }
                          }
                        }
                        gotAllFilters = gotStatusFilter;
                        break;
                    }
                  }
                }
              }
              return gotAllFilters;
            });
          }else{
            // samo search bez filtrów
            finalDataAfter = this.props.leadsData.filter((value) => {
              return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
            });
          }
        }else{
          //samo filtrowanie
          let filters = ReqHelper.getMeFilersObj(this.state.filterData);
          if(filters.length>0){
            finalDataAfter = this.props.leadsData.filter((value) => {
              let gotAllFilters = true;
              for(let i=0; i<filters.length; i++){ 
                if(gotAllFilters){
                  if(filters[i].name==='status'){
                    let gotStatus = false;
                    for(let v=0; v<filters[i].values.length; v++){
                      let didWeGotIt = ReqHelper.valueExistsInObjBasedOnSorting(value, filters[i].values[v].column, sortingColumns, appLang);
                      if(didWeGotIt){
                        gotStatus=true;
                        break;
                      }
                    }
                    gotAllFilters = gotStatus;
                  } else if(filters[i].name==='date_of_lead'){
                      let gotDateFilter = true;
                      for(let v=0; v<filters[i].values.length; v++){
                        if(gotDateFilter){
                          if(filters[i].values[v].type==="dateFrom"){
                            if(filters[i].values[v].date>value.dateOfEntry){
                              gotDateFilter = false;
                            }
                          }else{
                            if(filters[i].values[v].type==="dateTo"){
                              if(filters[i].values[v].date<value.dateOfEntry){
                                gotDateFilter = false;
                              }
                            }
                          }
                        }
                      }
                      gotAllFilters = gotDateFilter;
                  } else if(filters[i].type) {
                    switch(filters[i].type){
                      case FilterType.STATUS:
                        let gotStatusFilter = false;
                        for(let v=0; v<filters[i].values.length; v++){
                          if(value[filters[i].key]!==null && value[filters[i].key]!== undefined){
                            if(value[filters[i].key].toString().toLowerCase()===filters[i].values[v].value.toString().toLowerCase()){
                              gotStatusFilter=true;
                              break;
                            }
                          }
                        }
                        gotAllFilters = gotStatusFilter;
                        break;
                    }
                  }
                }
              }
              return gotAllFilters;
            });
          }else{
            //brak search, brak filtrowania
            finalDataAfter = this.props.leadsData;
          }
        }
      }
    }
    return finalDataAfter;
  }

  propsPagingData(data){
    if(data.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              totalItems: data.length,
              itemsPerPage: prevState.pagingItem.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              ...prevState.pagingItem,
              totalItems: data.length,
              pageFrom: prevState.pagingItem.pageFrom,
              pageTo: prevState.pagingItem.pageTo,
              currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }
  }

  navigateToDetails(leadData){
    const { history } = this.props;
    if(leadData.ViewedInApplication===false){
      markAsViewed(MarkAsViewedEntity.CONTACT, leadData.leadId).then(res => {
        leadData.ViewedInApplication = true;
        this.props.updateLeadDataFun(leadData.leadId, JSON.parse(JSON.stringify(leadData)));
        history.push(`${baseData.subFolders}leadDetails/${leadData.leadId}`);
      })
      .catch((error) => {
        history.push(`${baseData.subFolders}leadDetails/${leadData.leadId}`);
      });
    }else{
      history.push(`${baseData.subFolders}leadDetails/${leadData.leadId}`);
    }
  }

  render() {
    const { translateKey, appLang, history } = this.props;
    const { sorting } = this.state;
    let emptyHandler;
    let PreparedTableContent
    if (this.state.loading) {
      emptyHandler = <LoaderTable />
    } else {
      if (this.props.leadsData===null) {
        emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")} />
      } else {
        if (this.props.leadsData.length === 0) {
          emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
        }else{
          if(this.state.data.lengh===0){
            emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
          }else{
            PreparedTableContent = this.state.data.map(element => (
              <tr className={"table-row-wrapper cursor-pointer "+(element.ViewedInApplication===false?"not-readed-record":"")} key={element.leadId} onClick={()=>{this.navigateToDetails(element)}}>
                {
                  sorting.isColumnVisibleByKey('leads_lead', appLang) &&
                    <td className="table-cell-wrapper">{element.lead}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient', appLang) &&
                    <td className="table-cell-wrapper">{element.patient}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_date_of_lead', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dateOfEntry}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_status', appLang) &&
                    <td className="table-cell-wrapper">{ReqHelper.getMeLeadStatusText(element.status, this.props.leadsStatus)}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_source_of_acquisition', appLang) &&
                    <td className="table-cell-wrapper"><LeadSourceStatusComponent source={element.source.toUpperCase()}/></td>
                }



                {
                  sorting.isColumnVisibleByKey('leads_client_cellPhone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.client.cellPhone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_client_phone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.client.phone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_client_telephone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.phone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_client_email', appLang) &&
                    <td className="table-cell-wrapper"><MailToEmailComponent emailAddress={element.client.email}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_client_city', appLang) &&
                    <td className="table-cell-wrapper">{element.client.address.city}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_client_postal_code', appLang) &&
                    <td className="table-cell-wrapper">{element.client.address.postalCode}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_client_street', appLang) &&
                    <td className="table-cell-wrapper">{element.client.address.street}</td>
                }



                {
                  sorting.isColumnVisibleByKey('leads_patient_cellPhone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.patientObj.cellPhone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient_phone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.patientObj.phone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient_telephone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.patientPhone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient_email', appLang) &&
                    <td className="table-cell-wrapper"><MailToEmailComponent emailAddress={element.patientObj.email}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient_city', appLang) &&
                    <td className="table-cell-wrapper">{element.patientObj.address.city}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient_postal_code', appLang) &&
                    <td className="table-cell-wrapper">{element.patientObj.address.postalCode}</td>
                }
                {
                  sorting.isColumnVisibleByKey('leads_patient_street', appLang) &&
                    <td className="table-cell-wrapper">{element.patientObj.address.street}</td>
                }




                {
                  sorting.isColumnVisibleByKey('leads_exclusive', appLang) &&
                    <td className="table-cell-wrapper">{element.leadExclusiveForPm24}</td>
                }


              </tr>
            ))
          }
        }
      }
    }
    let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties addClick={()=>history.push(`${baseData.subFolders}newlead`)} prepareColumnsData={this.prepareColumnsData} onShowMeData={()=>this.filterOrDataChange()} onSearch={(val) => this.propsSearchTable(val)} options={[TableEnums.SEARCH, TableEnums.FILTER, TableEnums.ADD, TableEnums.ACTIVECOLUMNS]} filterData={this.state.filterData} sortData={sorting.sortingColumns} />
        <div className="mainContent">
          <SideBar />
          <LoaderModal show={this.state.showModalLoader}/>
          <div className="listDataWrapper">
            <div className="listData flipped">
              <div className="flipped">
                <table className="table">
                  <thead>
                    <tr>
                      {
                        sorting.sortingColumns.map(column => {
                          if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                            return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                          }else{
                            return null
                          }
                        }).filter(element => element !==null )
                      }
                    </tr>
                  </thead>
                  <tbody>{PreparedTableContent}</tbody>
                </table>
                {emptyHandler}
                {alertComponent}
                <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
              </div>
            </div>
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    userIdColumn: state.auth.userId,
    leadsStatus: state.enums.leadStatus,
    leadsData: state.database.leads,
    leadsTimestamp: state.database.leadsTimeStamp,
    patientLeadData: state.database.patientLead,
    patientClientData: state.database.patientClient,
    activeColumns: state.activeColumns.data,
    activeColumnsTimeStamp: state.activeColumns.columnsTimeStamp,
    savedFilters: state.filters.data.leads,
    leadSource: state.enums.leadSource,
    marketingsList: state.enums.marketingsList,
    rejectReasonsList: state.enums.rejectReasonsList
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setData: (data) => dispatch(setLeadsData(data)),
  pendingRequest: () => dispatch(pendingLeadsData()),
  clearData: () => dispatch(removeLeadsData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setLeadsStatus: (data) => dispatch(setLeadStatusData(data)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  updateLeadDataFun: (leadId, data) => dispatch(updateLeadData(leadId, data)),
  setColumns: (data) => dispatch(setColumnsData(data)),
  addNewColumnTable: (data) => dispatch(addColumnData(data)),
  updateColumnTypeData: (type,data) => dispatch(updateColumTypeData(type, data)),
  removeColumns: () => dispatch(removeColumnsData()),
  setFilters: (type, data) => dispatch(setFiltersData(type, data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadsMainPage));