import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { ContractStatus, TableDateFormat, ContractRateType} from "../../index";
import { ReqHelper } from "../../../_helpers/index";
import * as LanguageSelect from "../../../_constance/values/languageSelect";

class ContractBasicsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
        };
    }    

    render() {        
        const { appLang, translateKey, element } = this.props;          
        return (
            <div className="lead-details-client-info">
                <div className="col-sm-12 col-lg-5">
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("patient")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {element.contract}
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("status")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <ContractStatus element={element.status}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_aggreement")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <TableDateFormat timestamp={element.agreementDate}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_beginning")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <TableDateFormat timestamp={element.dateOfBeginning}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_end")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <TableDateFormat timestamp={element.dateOfEnd}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("product_name")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">{element.product}</span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("rate_type")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                <ContractRateType element={element.rateType}/>
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("rate")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {ReqHelper.numberWithSpaces(element.rate) + ' ' + element.currency}
                            </span>
                        </div>
                    </div>
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("business_partner")}</label>
                        <div className="col-sm-8">
                            <span className="lead-input-wrapper">
                                {element.businessPartner}
                            </span>
                        </div>
                    </div>
                    {appLang === LanguageSelect.EN &&
                        <React.Fragment>
                            {element.inquiryReceiptDate!==0 && element.inquiryReceiptDate!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("inquiry_receipt_date")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            <TableDateFormat timestamp={element.inquiryReceiptDate}/>
                                        </span>
                                    </div>
                                </div>
                            }
                            {element.careManagerName &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("care_manager")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">{element.careManagerName}</span>
                                    </div>
                                </div>
                            }
                            {element.registerManagerName &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("register_manager")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">{element.registerManagerName}</span>
                                    </div>
                                </div>
                            }
                            {element.companyName &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("company_name")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">{element.companyName}</span>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage}};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractBasicsComponent));