import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router-dom";
import {
  translateKey,
  authSetActionTimestamp,
  updateMultiplierData,
  getTasks,
  setTasksData,
  needsSaveData, 
  doesNotNeedsSave
} from "../../_redux/actions/index";
import {
  ConfirmModal,
  TableProperties,
  LoaderModal,
  TableColumHead,
  TablePagination,
  NavBar,
  SideBar,
  LoaderTable,
  AlertMessage,
  MultiplierComponent,
  LeadCancelSaveComponent,
  EmptyTableIcon,
  TableDateFormat,
  TaskStatusHandler,
  ConsentListComponent
} from "../../_components/index";
import { Alert, Paging } from "../../_constance/classes/index";
import { IconsMap, ResponseStatus, ReqHelper } from "../../_helpers/index";
import {
  multiplierSave,
  addNoteData,
  removeNoteData
} from "../../_redux/actions/auth";
import axiosAuth from "../../_services/config/axios-auth";
import { MockMultiplier } from "../../_constance/classes/mockMultiplier/mockMultiplier";
import {
  SortingTasks,
  SortingNotes,
  SortingPatient
} from "../../_constance/classes/sortingClasses/index";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";
import { saveDynamicFields } from '../../_services/user_service';
import { TableEnums } from "../../_constance";
import * as Entity from '../../_forms/BBForm/values/entity';
import * as RecordType from "../../_constance/values/recordType";
import { baseData } from '../../_constance/base_data';

class EditMultiplierPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  autoSaveTimeOut;
  constructor(props) {
    super(props);
    this.state = {
      multiplierData: {...MockMultiplier},
      multiplierDataBackup: {...MockMultiplier},
      savingMultiplier: false,
      loadingMultiplier: true,
      errorsMessage: null,
      errorsType: "danger",
      errorComponent: Alert,
      activeTab: "multiplier",
      loadingNotes: true,
      leadNotesData: [],
      loadingTasks: true,
      leadTaskData:[],
      patientPaging: Paging,
      notesPaging: Paging,
      tasksPaging: Paging,
      sortingPatients: JSON.parse(JSON.stringify(SortingPatient)),
      sortingNotes: JSON.parse(JSON.stringify(SortingNotes)),
      sortingTasks: new SortingTasks(),
      noteData: {Id:0, Note: ''},
      noteTemp: {Id:0, Note: ''},
      saveErrorMessage: '',
      showEdit: false, 
      loaderModal: false,
      type: "EDIT",
      errorModal: false,
      id: 0,
      confirmDeleteNotes: false,
      noteToDelete: {},
      consentsDe8: false,
      breadCrumbs:[
        {
          link: "multipliers",
          key: "multipliers",
          name: null
        }
      ]
    };
  }

  componentDidUpdate() {
    if (this.props.needsSave) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.props.doesNotNeedsSave();
    this.myTimeout = setTimeout(() => {
      this.getInitialDataTime()
    }, 200);
    this.autoSaveTimeOut = setInterval(() => {
      this.checkIfShouldWeSave();
    }, 15000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              multiplierData: {...MockMultiplier}
          }
        });
      }
    }
    clearInterval(this.autoSaveTimeOut);
    clearTimeout(this.myTimeout);
  };

  setActiveTab(tabName){
    if (this._isMounted) {
      this.setState({activeTab: tabName})
    }
  }

  checkIfShouldWeSave = () =>{
    const currentTime = new Date().getTime();
    if(this.props.lastActionTime - currentTime <= 30000){
      this.saveChange(true);
    }
  }

  getInitialDataTime(){
    if(this.props.multipliersData.length>0){
      clearTimeout(this.myTimeout);
      this.getDataBasic();
    }else{
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 300);
    }
  }

  removeItem(item){
    if (this._isMounted) {
      this.setState({
        confirmDeleteNotes: true,
        noteToDelete: item
      })
    }
  }

  getBasicNotes(){
    if (this._isMounted) {
      this.setState({loadingNotes: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/notes/MULTIPLIER/"+params.id+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        if (this._isMounted) {
          this.setState({
            leadNotesData: res,
            loadingNotes: false
          })
        }
        this.preparePagingDataNotes();
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingNotes: false })
        }
      });
  }

  errorModal = () => {
    if (this._isMounted) {
      this.setState({
          errorModal: !this.state.errorModal
      });
    }
  }

  openModalWithNoteData(element){
    if (this._isMounted) {
      this.setState({
          noteData: JSON.parse(JSON.stringify(element)),
          noteTemp: JSON.parse(JSON.stringify(element)),
          showEdit: true,
          type: "EDIT"
      })
    }
  }

  noteChange(text){
    if (this._isMounted) {
      this.setState({
          noteData:{
              ...this.state.noteData,
              Note: text
          }
      });
    }
  }

  cancelEdit = () =>{
    if (this._isMounted) {
      this.setState({
          noteData: this.state.noteTemp,
          showEdit: false
      })
    }
  }

  saveNoteData = ()=>{
      if (this._isMounted) {
        this.setState({loaderModal: true})
      }
      this.props.addNote(this.state.noteData, this.state.id, "MULTIPLIER")
      .then((res)=>{
          let newObj={
              Id: res,
              Note: this.state.noteData.Note,
              Created: Math.floor(new Date().getTime() / 1000),
              CreatedBy: ReqHelper.prepareMeLoginName(this.props.loginName)
          }
          if (this._isMounted) {
            this.setState(previousState => ({
                ...previousState,
                loaderModal: false,
                showEdit: false,
                leadNotesData: [...previousState.leadNotesData, newObj]
            }));
          }
          this.preparePagingDataNotes();
      })
      .catch((err)=>{
          console.log(err);
          if (this._isMounted) {
            this.setState({
                loaderModal: false,
                saveErrorMessage: this.props.translateKey("fail_to_add_note")
            })
          }
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({saveErrorMessage: ''});
            }
          }, 2000);
      })
  }

  addClick = () =>{
    if (this._isMounted) {
      this.setState({
          noteData: {
              Id: 0,
              Note: ''
          },
          noteTemp:  {
              Id: 0,
              Note: ''
          },
          showEdit: true,
          type: "ADD"
      })
    }
  }

  getBasicTasksData(){
    if(this.props.tasksStatus === ResponseStatus.READY){
      clearTimeout(this.myTimeout);
      this.getLeadTasksData();
    }else{
      if(this.props.tasksStatus === ResponseStatus.FREE){
        this.props.getGlobalTasksData();
        this.myTimeout = setTimeout(() => {
          this.getBasicTasksData()
        }, 1000);
      }else{
        if(this.props.tasksStatus === ResponseStatus.ERROR){
          clearTimeout(this.myTimeout);
          if (this._isMounted) {
            this.setState({ loadingTasks: false })
          }
        }else{
          this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
          }, 1000);
        }
      }
    }
  }

  getLeadTasksData() {
    const { match: { params } } = this.props;
    let newList = [];
    for(let i=0;i<this.props.tasksData.length; i++){
      if(this.props.tasksData[i].concerns===params.id){
        newList.push(this.props.tasksData[i])
      }
    }
    if (this._isMounted) {
      this.setState({
        leadTaskData: newList,
        loadingTasks: false,
      })
   }
    this.preparePagingDataTasks();
  }

  getDataBasic() {
    const { match: { params } } = this.props;
    let found = false;
    for(let i=0;i<this.props.multipliersData.length; i++){
      if(this.props.multipliersData[i].id===params.id){
        found=true;
        let name='---';
        if(this.props.multipliersData[i].lastName!==null){
          name = this.props.multipliersData[i].lastName;
          if(this.props.multipliersData[i].firstName!==null){
            name += ' '+this.props.multipliersData[i].firstName;
          }
        }else{
          if(this.props.multipliersData[i].firstName!==null){
            name = this.props.multipliersData[i].firstName;
          }
        }
        if (this._isMounted) {
          this.setState({
            multiplierData: JSON.parse(JSON.stringify(this.props.multipliersData[i])),
            multiplierDataBackup: JSON.parse(JSON.stringify(this.props.multipliersData[i])),
            loadingMultiplier: false,
            id: params.id,
            breadCrumbs:[
              {
                link: "multipliers",
                key: "multipliers",
                name: null
              },
              {
                link: "",
                key: "",
                name: name
              }
            ]
          })
        }
        this.getBasicNotes();
        this.getBasicTasksData();
        break;
      }
    }
    if(!found){
      if (this._isMounted) {
        this.setState({
          multiplierData: null,
          loadingMultiplier: false,
          loadingNotes: false,
          loadingTasks: false,
        })
      }
    }
    
  }

  cancelChange(){
    this.props.doesNotNeedsSave();
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            multiplierData: JSON.parse(JSON.stringify(prevState.multiplierDataBackup))
        }
      });
    }
  }


  tryToSave(){
    const { match: { params } } = this.props;
    const {consentsDe8} = this.state;
    let fields = {};
    fields = {"is_zg_de_8": consentsDe8};
    let dataToSend = [
      {
          "Id": params.id,
          "Entity": Entity.CONTACT,
          "Fields": fields
      }
    ];
    saveDynamicFields(dataToSend).then(response => {
      this.props.doesNotNeedsSave();
    })
    .catch((err) => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              savingMultiplier: false,
              errorsMessage: err.message,
              errorsType: "danger"
          }
        });
      }
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            errorsMessage: null,
            errorsType: "danger"
          }) 
        }
      }, 3000);
    });
  }

  saveChange(shouldLogout=false){
    const { history } = this.props;
    this.props.tookAction();
    if(this.props.needsSave){
      if (this._isMounted) {
        this.setState({savingMultiplier: true})
      }
      this.props.multiplierSave(this.state.multiplierData)
      .then((res)=>{
        this.props.doesNotNeedsSave();
        this.tryToSave();
        if(shouldLogout){
          history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState({
              savingMultiplier: false,
              errorsMessage: "multiplier_successfully_saved",
              errorsType: "success"
            })   
          }   
          let dataToSend = [
            {
                "Id": res,
                "Entity": Entity.CONTACT,
                "Fields": {
                  'jobtitle': this.state.multiplierData.jobtitle
                }
            }
          ];
          saveDynamicFields(dataToSend);
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({
                errorsMessage: null,
                multiplierData: {...MockMultiplier},
                multiplierDataBackup: {...MockMultiplier},
                errorsType: "danger"
              })
            } 
            history.push(`${baseData.subFolders}multipliers`);
          }, 3000);
          this.props.updateMultiplierData(res,this.state.multiplierData);
        }
      })
      .catch((err)=>{
        if(shouldLogout){
          history.push(`${baseData.subFolders}logout`);
        }else{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                  ...prevState,
                  savingMultiplier: false,
                  errorsMessage: err.message,
                  errorsType: "danger"
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({
                errorsMessage: null,
                errorsType: "danger"
              }) 
            }
          }, 3000);
        }
      })
    }
  }

  basicEdit = () =>{this.props.needsSaveData();}

  preparePagingDataNotes(){
    if(this.state.leadNotesData.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            notesPaging: {
              totalItems: this.state.leadNotesData.length,
              itemsPerPage: prevState.notesPaging.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            notesPaging: {
              ...prevState.notesPaging,
              totalItems: this.state.leadNotesData.length,
              pageFrom: 1,
              pageTo: 5,
              currentPage: prevState.notesPaging.currentPage>Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)? Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage) : prevState.notesPaging.currentPage,
              totalPages: Math.ceil(this.state.leadNotesData.length/prevState.notesPaging.itemsPerPage)
            }
          }
        });
      }
    }
  }

  sortTypeNotes(type){
    let sortingObj = [];
    for(let i=0; i<this.state.sortingNotes.length; i++){
      if(this.state.sortingNotes[i].column===type){
        if(this.state.sortingNotes[i].active===true){
          let item = this.state.sortingNotes[i];
          item.type==='ASC'? item.type="DESC" : item.type='ASC';
          sortingObj.push(item);
        }else{
          let item = this.state.sortingNotes[i];
          item.type='ASC';
          item.active = true;
          sortingObj.push(item);
        }
      }else{
        let item = this.state.sortingNotes[i];
        item.type='ASC';
        item.active = false;
        sortingObj.push(item);
      }
    }
    if (this._isMounted) {
      this.setState({sortingNotes: sortingObj});
    }
    this.sortDataByColumnActiveNotes();
  }

  sortDataByColumnActiveNotes(){
    let finalDataToShow = this.state.leadNotesData;
    ReqHelper.sortMeData(finalDataToShow, this.state.sortingNotes);
    if (this._isMounted) {
      this.setState({leadNotesData: finalDataToShow})
    }
  }

  preparePagingDataTasks(){
    if(this.state.leadTaskData.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            tasksPaging: {
              totalItems: this.state.leadTaskData.length,
              itemsPerPage: prevState.tasksPaging.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            tasksPaging: {
              ...prevState.tasksPaging,
              totalItems: this.state.leadTaskData.length,
              pageFrom: 1,
              pageTo: 5,
              currentPage: prevState.tasksPaging.currentPage>Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)? Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage) : prevState.tasksPaging.currentPage,
              totalPages: Math.ceil(this.state.leadTaskData.length/prevState.tasksPaging.itemsPerPage)
            }
          }
        });
      }
    }
  }

  sortTypeTasks(type){
    this.state.sortingTasks.changeColumnByColumn(type);
    this.sortDataByColumnActiveTasks();
  }

  sortDataByColumnActiveTasks(){
    let finalData = this.state.leadTaskData;
    ReqHelper.sortMeData(finalData, this.state.sortingTasks.sortingColumns);
    this.props.setGlobalTasksData(finalData);
    if (this._isMounted) {
      this.setState({leadTaskData: finalData})
    }
  }

  _prepareFilterObjTasks() {
    let filterData = [];
    let Options = [];
    filterData.push({
      filterName: "status",
      filterOptions: [
        {
          column: this.props.translateKey('open'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('closed'),
          active: false,
          type: "text",
        }
      ]
    });
    for (let i = 0; i < this.props.tasksPriority.length; i++) {
      let filtering = {
        column: this.props.taskPriority[i].Label,
        active: false,
        type: "text"
      }
      Options.push(filtering);
    }
    filterData.push({
      filterName: "priority",
      filterOptions: Options
    });
    filterData.push({
      filterName: "type",
      filterOptions: [
        {
          column: this.props.translateKey('task_call'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('task_event'),
          active: false,
          type: "text",
        },
        {
          column: this.props.translateKey('task_task'),
          active: false,
          type: "text",
        }
      ]
    });
    filterData.push({
      filterName: "new_task",
      filterOptions: [
        {
          column: this.props.translateKey('task_youger7'),
          active: false,
          type: "text",

        },
        {
          column: this.props.translateKey('task_youger14'),
          active: false,
          type: "text",
        },
        {
          column: this.props.translateKey('task_youger21'),
          active: false,
          type: "text",
        }
      ]
    });
    return filterData;
  }

  navigateToDetailsTasks(element){
    this.props.history.push(`${baseData.subFolders}taskDetails/${element.id}`);
  }

  setConsent = (value) =>{
    if(value !== null ){
      if (this._isMounted) {
        this.setState({consentsDe8: value})
      }
    }else{
      if (this._isMounted) {
        this.setState({consentsDe8: false})
      }
    }
  }

  removeNote = () =>{
    if (this._isMounted) {
      this.setState({loaderModal: true})
    }
    this.props.removeNote(this.state.noteToDelete.Id)
    .then((res)=>{
      if(this._isMounted) {
        this.setState({
            confirmDeleteNotes: false,
            loaderModal: false,
            leadNotesData: this.state.leadNotesData.filter(value => value.Id !== this.state.noteToDelete.Id)
        })
      }
      this.preparePagingDataNotes();
    })
    .catch((err)=>{
        console.log(err);
        if (this._isMounted) {
          this.setState({
              confirmDeleteNotes: true,
              loaderModal: false,
              errorModal: true,
              errorComponent: {
                  ...this.state.errorComponent,
                  message: this.props.translateKey("fail_to_remove_note")
              }
          })
        }
    })
  }

  rejectMeNote = () =>{
    if (this._isMounted) {
      this.setState({
        confirmDeleteNotes: false,
        loaderModal: false
      })
    }
  }

  addClickButton=()=>{
    const { match: { params } } = this.props;
    switch(this.state.activeTab){
      case "notes":     if(!this.state.loadingNotes){
                          if(this.state.leadNotesData!==null){
                            this.addClick();
                          }
                        }
                        break;
      case "tasks":     this.props.history.push(`${baseData.subFolders}newtask/${params.id}/${RecordType.MULTIPLIER}`);
                        break;
      default:
    }
  }

  prepareMeCaAdd(){
    let options = [];
    switch(this.state.activeTab){
      case "notes":     if(this.state.multiplierData!==null){
                          options = [TableEnums.ADD]
                        }
                        break;
      case "tasks":     if(this.state.multiplierData!==null){
                          options = [TableEnums.ADD]
                        }
                        break
      default:
    }
    return options;
  }

  render() {
    const {translateKey, needsSave, appLang} = this.props;
    const {sortingNotes, sortingTasks} = this.state;
    const { match: { params } } = this.props;
    let multiplierEmptyHandler, notesEmptyHandler, tasksEmptyHandler;
    if (this.state.loadingMultiplier) {
      multiplierEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.multiplierData===null){
        multiplierEmptyHandler = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={this.props.translateKey("multiplier_does_not_exist")} />
      }else{
        let MultiplierRow =(
          <MultiplierComponent 
            new={false} 
            editedData={this.basicEdit} 
            edited={needsSave} 
            key="editMultiplierComponenet01" 
            element={this.state.multiplierData}
          />
        )
        let Consents = (
          <ConsentListComponent 
            isEditable={false}  
            editedData={this.basicEdit}
            key="consents-lead-03" 
            id={params.id} 
            setConsents={this.setConsent}
            multiplierConsent={true}
          />
        );
        let CancelSave = (
          <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="cancelSaveMultiplier01" edited={needsSave} savingData={this.state.savingMultiplier}/>
        );
        let SaveAlert;
        if(this.state.errorsMessage!==null){
          SaveAlert = (
            <AlertMessage centeredAlert={true} show={this.state.errorsMessage!==null} key="alertMultiplier01" type={this.state.errorsType} message={translateKey(this.state.errorsMessage)}/>
          )
        }
        multiplierEmptyHandler = [
          MultiplierRow,
          Consents,
          SaveAlert,
          CancelSave
        ]
      }
    }
    if (this.state.loadingNotes) {
      notesEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.multiplierData===null){
        notesEmptyHandler = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={this.props.translateKey("multiplier_does_not_exist")} />
      }else{
        let notesContent,emptyNoteData
        if (this.state.leadNotesData === null) {
          emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
        }else{
          if (this.state.leadNotesData.length === 0) {
            emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
          }else{
            let finalData = this.state.leadNotesData.slice((this.state.notesPaging.currentPage-1)*this.state.notesPaging.itemsPerPage, this.state.notesPaging.currentPage*this.state.notesPaging.itemsPerPage);
            if(finalData.length>0){
              notesContent = finalData.map(element => (
                <tr className="table-row-wrapper cursor-pointer" key={element.Id}>
                  <td className={"table-cell-wrapper " + (element.Note !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}>{element.Note}</td>
                  <td className={"table-cell-wrapper " + (element.CreatedBy !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}>{element.CreatedBy}</td>
                  <td className={"table-cell-wrapper " + (element.Created !== null ? "got-data" : 'no-data')} onClick={()=>this.openModalWithNoteData(element)}><TableDateFormat timestamp={element.Created}/></td>
                  <td className="table-cell-wrapper remove-icon-item-table" onClick={()=> this.removeItem(element)}><i className="fas fa-trash-alt fa-2x empty-table-icon cursor-pointer remove-icon-item"></i></td>
                </tr>
              ))
            }else{
              emptyNoteData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicNotes()} text={translateKey("no_data_to_show")} /></div>
            }
          }
        }
        notesEmptyHandler = (
          <div className="listData">
            <table className="table">
              <thead>
                <tr>
                  <TableColumHead sorting={sortingNotes} name={translateKey("notes")} onSortType={(data) => this.sortTypeNotes(data)} type="Note" />
                  <TableColumHead sorting={sortingNotes} name={translateKey("author")} onSortType={(data) => this.sortTypeNotes(data)} type="CreatedBy" />
                  <TableColumHead sorting={sortingNotes} name={translateKey("created")} onSortType={(data) => this.sortTypeNotes(data)} type="Created" />
                  <th scope="col" className="table-add-data"></th>
                </tr>
              </thead>
              <tbody>{notesContent}</tbody>
            </table>
            {emptyNoteData}
            <TablePagination recalculateAll={()=>this.preparePagingDataNotes()} simpleDataUpdate={()=>this.preparePagingDataNotes()} loading={this.state.loadingNotes} paginationObj={this.state.notesPaging} totalItems={this.state.leadNotesData.length}/>
          </div>
        )
      }
    }

    if (this.state.loadingTasks) {
      tasksEmptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.multiplierData===null){
        tasksEmptyHandler = <EmptyTableIcon RefreshMe={() => this.getInitialDataTime()} text={this.props.translateKey("multiplier_does_not_exist")} />
      }else{
        let taskContent, emptyTaskData;
        if (this.state.leadTaskData === null) {
          emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTask()} text={translateKey("no_data_to_show")} /></div>
        } else {
          if (this.state.leadTaskData.length === 0) {
            emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTask()} text={translateKey("no_data_to_show")} /></div>
          }else{
            let finalData = this.state.leadTaskData.slice((this.state.tasksPaging.currentPage-1)*this.state.tasksPaging.itemsPerPage, this.state.tasksPaging.currentPage*this.state.tasksPaging.itemsPerPage);
            if(finalData.length>0){
              taskContent = finalData.map(element => (
                <tr className={"table-row-wrapper cursor-pointer "+(element.ViewedInApplication===false?"not-readed-record":"")} key={Math.random().toString(36).substr(2, 5)} onClick={()=>{this.navigateToDetailsTasks(element)}}>
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_status', appLang) &&
                    <td className="table-cell-wrapper"><TaskStatusHandler element={element}/></td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_title', appLang) &&
                    <td className="table-cell-wrapper">{element.title}</td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_concern', appLang) &&
                    <td className="table-cell-wrapper">{element.concernName}</td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_createdDate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.createdDate}/></td>
                  }
                  {
                  sortingTasks.isColumnVisibleByKey('tasks_dueDate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dueDay}/></td>
                  }
                </tr>
              ))
            }else{
              emptyTaskData = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.getBasicTask()} text={translateKey("no_data_to_show")} /></div>
            }
          }
        }
        tasksEmptyHandler = (
          <div className="listData">
            <table className="table">
              <thead>
                <tr>
                  {
                    sortingTasks.sortingColumns.map(column => {
                    if(sortingTasks.isColumnVisibleByKey(column.keyName, appLang)){
                      return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sortingTasks.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.sortTypeTasks(data)} type={column.column} />
                    }else{
                      return null
                    }
                    }).filter(element => element !==null )
                  }
                </tr>
              </thead>
              <tbody>{taskContent}</tbody>
            </table>
            {emptyTaskData}
            <TablePagination recalculateAll={()=>this.preparePagingDataTasks()} simpleDataUpdate={()=>this.preparePagingDataTasks()} loading={this.state.loadingTasks} paginationObj={this.state.tasksPaging} totalItems={this.state.leadTaskData.length}/>
          </div>
        )
      }
    }
    let alertModal = (
      <MDBModal isOpen={this.state.errorModal} toggle={this.errorModal} size="lg">
          <MDBModalBody>
              <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                  {this.state.errorComponent.message}
              </div>
          </MDBModalBody>
          <MDBModalFooter>
              <button type="button" className="btn btn-primary" onClick={this.errorModal}>{translateKey('Ok')}</button>
          </MDBModalFooter>
      </MDBModal>
    )
    let noteEditModal = (
        <MDBModal isOpen={this.state.showEdit} toggle={this.editToggle} size="lg">
            <MDBModalHeader toggle={this.editToggle}>{translateKey('note')}</MDBModalHeader>
                <MDBModalBody>
                    {this.state.type!=="EDIT"?
                        <div>
                            <textarea type="text" className="form-control" rows="3" onChange={e => this.noteChange(e.target.value)} value={this.state.noteData.Note}/>
                            {this.state.saveErrorMessage.length>0 &&
                                <div className="alert alert-danger centeredAlertNoLimit" role="alert">
                                    {this.state.saveErrorMessage}
                                </div>
                            }
                        </div>
                        :
                        <span>{this.state.noteData.Note}</span>
                    }
                </MDBModalBody>
                {this.state.type!=="EDIT"?
                    <MDBModalFooter>
                        <button type="button" className="btn btn-secondary" onClick={this.cancelEdit}>{translateKey('cancel')}</button>
                        <button type="button" className="btn btn-primary" onClick={()=>this.saveNoteData()}>{translateKey('save')}</button>
                    </MDBModalFooter>
                    :
                    <MDBModalFooter>
                        <button type="button" className="btn btn-primary" onClick={this.cancelEdit}>{translateKey('Ok')}</button>
                    </MDBModalFooter>
                }
        </MDBModal>
    )

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties addClick={this.addClickButton} options={this.prepareMeCaAdd()} filterData={[]}/>
        <LoaderModal show={this.state.loaderModal}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active nav-link-invoices" onClick={()=>{this.setActiveTab("notes")}} id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                {translateKey("multiplier")}
              </a>
              <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("notes")}} id="nav-notes-tab" data-toggle="tab" href="#nav-notes" role="tab" aria-controls="nav-notes" aria-selected="false">
                  {translateKey("notes")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("tasks")}} id="nav-tasks-tab" data-toggle="tab" href="#nav-tasks" role="tab" aria-controls="nav-tasks" aria-selected="false">
                  {translateKey("tasks")}
                </a>
            </div>
          </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {multiplierEmptyHandler}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab">
                <div className="leadSection">
                  <ConfirmModal rejectMe={this.rejectMeNote} acceptMe={this.removeNote} show={this.state.confirmDeleteNotes} titleMessage="" mainMessage={translateKey("delete")+"?"} messageYes={translateKey("yes")} messageNo={translateKey("no")}/>
                  {noteEditModal}
                  {alertModal}
                  {notesEmptyHandler}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-tasks" role="tabpanel" aria-labelledby="nav-tasks-tab">
                <div className="leadSection">
                  {tasksEmptyHandler}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={needsSave}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    loginName: state.auth.appLogin,
    multipliersData: state.database.multipliers,
    multipliersTimeStamp: state.database.multipliersTimeStamp,
    tasksPriority: state.enums.taskPriority,
    tasksData: state.database.tasks,
    tasksStatus: state.database.tasksState,
    needsSave: state.refreshTokenStatus.needsDataSave,
    lastActionTime: state.auth.actionTimestamp    
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  updateMultiplierData: (id, data) => dispatch(updateMultiplierData(id, data)),
  multiplierSave: (data) => dispatch(multiplierSave(data)),
  getGlobalTasksData: () => dispatch(getTasks()),
  setGlobalTasksData: (data) => dispatch(setTasksData(data)),
  addNote: (note, userid, type) => dispatch(addNoteData(note, userid, type)),
  removeNote: (id) => dispatch(removeNoteData(id)),
  needsSaveData: () => dispatch(needsSaveData()),
  doesNotNeedsSave: () => dispatch(doesNotNeedsSave())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditMultiplierPage));