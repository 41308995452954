export const sortingContracts = [
    {
        type: "ASC",
        column: 'contract',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'status',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'product',
        active: false,
        format: "date"
    },
    {
        type: "ASC",
        column: 'rate',
        active: false,
        format: "date"
    },
    {
        type: "ASC",
        column: 'agreementDate',
        active: true,
        format: "date"
    }
];