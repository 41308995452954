export const MockDiseaseListResource = {
    is_allergies_list: false,
    is_alzheimers_disease_the_list: false,
    is_arrhythmias_list: false,
    is_asthma_list: false,
    is_bedsores_list: false,
    is_cancer_list: false,
    is_chronic_diarrhea_list: false,
    is_dementia_list: false,
    is_depression_list: false,
    is_diabetes: false,
    is_diabetes_mellitus_list: false,
    is_difficulty_in_walking_determined_by_age: false,
    is_heart_failure_list: false,
    is_hypertension_list: false,
    is_incontinence_list: false,
    is_initial_stages_of_dementia_list: false,
    is_left_sided_paresis_list: false,
    is_multiple_sclerosis_the_list: false,
    is_myocardial_infarction_list: false,
    is_osteoporosis_list: false,
    is_parkinsons_disease_list: false,
    is_rheumatic_disease_list: false,
    is_right_sided_paresis_list: false,
    is_stoma_list: false,
};