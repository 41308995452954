import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, InvoiceTableDetails, TableDateFormat, InvoiceApprovalHandler, InvoicePaymentHandler} from "../../index";
import { SortingInvoices } from "../../../_constance/classes/sortingClasses/index";
import { Paging } from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";


class ClientInvoicesData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: new SortingInvoices(),
            pagingItem: Paging,
            selectedKeyToShow: 0
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
        this.filterOrDataChange();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    filterOrDataChange(){

        let finalDataToShow = this.props.invoicesData.filter(element => {
            if(element.ClientId === this.props.id){
              return element;
            }else{
              return false;
            }
        })
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.setState({
                data: finalData,
                loading: false
            })
        }, 50);
    }
    
    propsSortType(type){
        this.state.sorting.changeColumnByColumn(type);
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    showDetails(key){
        if(key!== this.state.selectedKeyToShow){
          this.setState({selectedKeyToShow: key})
        }else{
          this.setState({selectedKeyToShow: 0})
        }
    }

    previousNextClick(key, type){
        let previous = 0;
        let next = 0;
        for(let j=0; j<this.state.data.length; j++){
          if(j>0){
            previous = this.state.data[j-1].Id;
          }else{
            previous = 0
          }
          if(j+1<=this.state.data.length-1){
            next = this.state.data[j+1].Id
          }else{
            next = 0
          }
          if(this.state.data[j].Id === key){
            if(type==="next"){
              this.showDetails(next);
              break;
            }else{
              this.showDetails(previous);
              break;
            }
          }
        }
    }

    render() {
        const { translateKey, appLang } = this.props;
        const { sorting, selectedKeyToShow } = this.state;
        let emptyHandler;
        let invoiceData = [];
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={translateKey("no_data_to_show")} /></div>
            }else{
                let next= 0;
                let previous= 0;
                for(let j=0; j<this.state.data.length; j++){
                    if(j>0){
                        previous = true;
                    }else{
                        previous = 0
                    }
                    if(j+1<=this.state.data.length-1){
                        next = true
                    }else{
                        next = 0
                    }
                    invoiceData.push(
                        <tr className={"table-row-wrapper cursor-pointer"+(selectedKeyToShow===this.state.data[j].Id? " active-row-invoice": "")} key={this.state.data[j].Id} onClick={()=> this.showDetails(this.state.data[j].Id)}>
                            {
                            sorting.isColumnVisibleByKey('invoices_clientName', appLang) &&
                                <td className="table-cell-wrapper">{this.state.data[j].ClientName}</td>
                            }
                            {
                            sorting.isColumnVisibleByKey('invoices_amount', appLang) &&
                                <td className="table-cell-wrapper">{ReqHelper.numberWithSpaces(this.state.data[j].invoice.amount) + ' ' + this.state.data[j].invoice.currency}</td>
                            }
                            {
                            sorting.isColumnVisibleByKey('invoices_approval', appLang) &&
                                <td className="table-cell-wrapper"><InvoiceApprovalHandler status={this.state.data[j].approval.Status} statusData={this.props.enumsInvoiceApprovalStatus}/></td>
                            }
                            {
                            sorting.isColumnVisibleByKey('invoices_payment', appLang) &&
                                <td className="table-cell-wrapper"><InvoicePaymentHandler status={this.state.data[j].invoiceStatus} statusData={this.props.enumsInvoicePaymentStatus}/></td>
                            }
                            {
                            sorting.isColumnVisibleByKey('invoices_number', appLang) &&
                                <td className="table-cell-wrapper">{this.state.data[j].invoiceNumber}</td>
                            }
                            {
                            sorting.isColumnVisibleByKey('invoices_periodForm', appLang) &&
                                <td className="table-cell-wrapper"><TableDateFormat timestamp={this.state.data[j].periodFrom}/></td>
                            }
                            {
                            sorting.isColumnVisibleByKey('invoices_periodTo', appLang) &&
                                <td className="table-cell-wrapper"><TableDateFormat timestamp={this.state.data[j].periodTo}/></td>
                            }
                        </tr>
                    )
                    if(this.state.selectedKeyToShow===this.state.data[j].Id){
                        invoiceData.push(
                            <tr key={Math.random().toString(36).substr(2, 9)}>
                                <td colSpan="7" className="no_padding invoice-details-border-wrapper">
                                    <InvoiceTableDetails previousNextClick={(key, type) => this.previousNextClick(key, type)}  next={next} previous={previous} element={this.state.data[j]} paymentStatus={this.props.enumsInvoiceApprovalStatus} invoiceStatus={this.props.enumsInvoicePaymentStatus}/>
                                </td>
                            </tr>
                        )
                    }
                }
            }
        }
        return (
            <div className="client-data-wrapper-tab">
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                {
                                sorting.sortingColumns.map(column => {
                                    if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                                        return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                                    }else{
                                        return null
                                    }
                                }).filter(element => element !==null )
                                }
                            </tr>
                        </thead>
                        <tbody>{invoiceData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        invoicesData: state.database.invoices,
        enumsInvoicePaymentStatus: state.enums.invoicePayment,
        enumsInvoiceApprovalStatus: state.enums.invoiceApproval
    };
}
  
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientInvoicesData));
