import React from 'react';

export default class SelectUniversalComponent extends React.Component {
    static defaultProps = {
        valuesList: [],
    };

    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    render() {
        const { valuesList, keyName, value, optionValueKey, optionLabelKey } = this.props;
        let hasNullOption = valuesList.filter((i) => i.value === 'null').length > 0;
        let valToSet = value !== null ? value : (hasNullOption ? 'null' : '');
        return (
            <select className="custom-select col-sm-10 lead-input-wrapper" onChange={this.setTypeChange} value={valToSet}>
                <option hidden />
                { valuesList.map(possibleValue => {
                    const id = `${keyName}+${possibleValue[optionValueKey]}`;
                    return (
                        <option key={id} value={possibleValue[optionValueKey]}>{possibleValue[optionLabelKey]}</option>
                    );
                })}
            </select>
        );
    }
}