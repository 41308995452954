import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey, authSetActionTimestamp } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar} from "../../_components/index";
import { DashboardLeadsPage, DashboardMultipliersPage, DashboardClientsPage, DashboardTasksPage} from "../../_components/Dashboard/index";
import { getDashboardData } from "../../_services/dashboard_service";
import { IconsMap } from "../../_helpers/index";
import { Alert } from "../../_constance/classes/index";

class DashboardPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      activeTab:"nav-dashboard-leads",
      dashboardLoading: true,
      dashboardData: null,
      breadCrumbs:[
        {
          link: this.props.translateKey("dashboard"),
          key: "dashboard",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.getDataBasic();
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  tabClicked(tabIndex){
    if (this._isMounted) {
      this.setState({activeTab: tabIndex})
    }
  }

  getDataBasic() {
    getDashboardData(this.props.appLang)
    .then(res => {
      if (this._isMounted) {
        if(res.TerminationReasonsStatistics){
          if(res.TerminationReasonsStatistics.Months){
            let sorted = res.TerminationReasonsStatistics.Months.reverse().sort((a,b)=>{
              return new Date(a.Year, a.Month, 1) - new Date(b.Year, b.Month, 1)
            });
            res.TerminationReasonsStatistics.Months = sorted;
          }
        }
        this.setState({ 
          dashboardLoading: false,
          dashboardData: res
        })
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  render() {
    const { translateKey } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass position-relative">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className={"nav-item nav-link nav-link-invoices "+(activeTab==="nav-dashboard-leads"?"active":"")} id="nav-dashboard-leads" data-toggle="tab" href="#nav-dashboard-leads" role="tab" aria-controls="nav-dashboard-leads" aria-selected={activeTab==="nav-dashboard-leads"?"true":"false"} onClick={()=> this.tabClicked("nav-dashboard-leads")}>{translateKey("leads")}</a>
                <a className={"nav-item nav-link nav-link-invoices "+(activeTab==="nav-dashboard-multipliers"?"active":"")} id="nav-dashboard-multipliers" data-toggle="tab" href="#nav-dashboard-multipliers" role="tab" aria-controls="nav-dashboard-multipliers" aria-selected={activeTab==="nav-dashboard-multipliers"?"true":"false"} onClick={()=> this.tabClicked("nav-dashboard-multipliers")}>{translateKey("multipliers")}</a>
                <a className={"nav-item nav-link nav-link-invoices "+(activeTab==="nav-dashboard-clients"?"active":"")} id="nav-dashboard-clients" data-toggle="tab" href="#nav-dashboard-clients" role="tab" aria-controls="nav-dashboard-clients" aria-selected={activeTab==="nav-dashboard-clients"?"true":"false"} onClick={()=> this.tabClicked("nav-dashboard-clients")}>{translateKey("clients")}</a>
                <a className={"nav-item nav-link nav-link-invoices "+(activeTab==="nav-dashboard-tasks"?"active":"")} id="nav-dashboard-tasks" data-toggle="tab" href="#nav-dashboard-tasks" role="tab" aria-controls="nav-dashboard-tasks" aria-selected={activeTab==="nav-dashboard-tasks"?"true":"false"} onClick={()=> this.tabClicked("nav-dashboard-tasks")}>{translateKey("tasks")}</a>
              </div>
            </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <DashboardLeadsPage activeTab={activeTab} tabName="nav-dashboard-leads" dashboardData={this.state.dashboardData} dashboardLoading={this.state.dashboardLoading}/>
              <DashboardMultipliersPage activeTab={activeTab} tabName="nav-dashboard-multipliers" dashboardData={this.state.dashboardData} dashboardLoading={this.state.dashboardLoading}/>
              <DashboardClientsPage activeTab={activeTab} tabName="nav-dashboard-clients" dashboardData={this.state.dashboardData} dashboardLoading={this.state.dashboardLoading}/>
              <DashboardTasksPage activeTab={activeTab} tabName="nav-dashboard-tasks" dashboardData={this.state.dashboardData} dashboardLoading={this.state.dashboardLoading}/>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage};};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));