import React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

export default class SelectComponent extends React.Component {
    static defaultProps = {
        valuesList: [],
        validationErrors: [],
        optionValueKey: 'value',
        optionLabelKey: 'label',
        className: '',
    };

    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    render() {
        const { valuesList, keyName, value, validationErrors, showValidation, className, label, optionValueKey, optionLabelKey } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`row col-sm-12 col-lg-6 ${className}`}>
                <div className={`col-sm-12 form-margin-bottom-fix select-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                    <label className="form-label-basic"><span className="quick-label-position-fix">{label}</span></label>
                    <select className={`form-control ${showErrors ? 'is-invalid' : ''}`} data-field-name={keyName} onChange={this.setTypeChange} value={value}>
                        <option hidden />
                        { valuesList.map(possibleValue => {
                            const id = `${keyName}+${possibleValue[optionValueKey]}`;

                            return (
                                <option key={id} value={possibleValue[optionValueKey]}>{possibleValue[optionLabelKey]}</option>
                            );
                        })}
                    </select>
                    { showErrors && (
                        <ValidationErrorsList keyName={keyName} errors={validationErrors} />
                    )}
                </div>
            </div>
        );
    }
}
