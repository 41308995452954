export const MockAddress = {
    apartmentNumber: "",
    buildingNumber: '',
    city: '',
    community: '',
    countryregion:'',
    county: '',
    latitude: 0,
    location: '',
    longitude: 0,
    postalCode: '',
    stateprovince: '',
    street: '',
};