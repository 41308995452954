import {SET_FILTERS_DATA,} from "../actions/action-types";

const initialState = {
    data: {
        leads : null,
        clients: null,
        multipliers: null,
        invoices: null,
        tasks: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS_DATA:
            let added = state.data
            added[action.list] = action.data
            return {
                ...state,
                data: added
            };
        default:
            return state;
    }
};

export default reducer;