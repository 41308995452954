import * as React from 'react';

export class CheckBoxSmallMultipleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
        };
    }

    setTypeChange(key, value){
        this.props.setTypeChangeToParent(key, value);
    }

    render() {
        return (
            <div className={"custom-control custom-checkbox "+this.props.className}>
                <input className="form-check-input" checked={this.props.field.value==="True" || this.props.field.value===true? true: false} onChange={e => this.setTypeChange(this.props.keyName, e.target.checked)} value={this.props.field.value} data-field-name={this.props.keyName}  type="checkbox" name={this.props.fieldName} id={this.props.fieldName}/>
                <label className="form-check-label cursor-pointer break-word-all" htmlFor={this.props.fieldName}>{this.props.text}</label>
            </div>
        );
    }
}