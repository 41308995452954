import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp, updateTaskData, getTasks, setClientsData, setPatientClientData } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, AlertMessage, TaskDetailsBasicComponent, LeadCancelSaveComponent } from "../../_components/index";
import { Alert, ClientTable} from "../../_constance/classes/index";
import { IconsMap, ResponseStatus } from "../../_helpers/index";
import { taskSaveTaskData, markTaskAsCompleted, markTaskAsCancelled } from "../../_redux/actions/auth";
import { MockTaskData } from "../../_constance/classes/mockTask/mockTaskData";
import axiosAuth from "../../_services/config/axios-auth";
import { baseData } from '../../_constance/base_data';

class EditTaskPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      taskData: {...MockTaskData},
      taskDataBackup: {...MockTaskData},
      savingDataTask: false,
      errorComponent: Alert,
      loading: true,
      edited: false,
      errorsMessage: null,
      errorsType: "danger",
      id: 0,
      breadCrumbs:[
        {
          link: "tasks",
          key: "tasks",
          name: null
        }
      ]
    };
  }

  componentDidUpdate() {
    this._promptUnsavedChange(this.state.edited);
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.myTimeout = setTimeout(() => {
      this.getInitialDataTime()
    }, 200);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.onbeforeunload = null
    if(this.state.edited){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              taskData: {...MockTaskData},
          }
        });
      }
    }
    clearTimeout(this.myTimeout);
  };

  _promptUnsavedChange(isUnsaved = false) {
    window.onbeforeunload = isUnsaved;
  }

  getClientsList = () => {
    if(this.props.clientsData.length<=0){
      axiosAuth.get("clients/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          for(let i=0; i<this.props.patientData.length; i++){
            if(this.props.patientData[i].clientId===element.id){
              element.locationP = this.props.patientData[i].patientAddress ? this.props.patientData[i].patientAddress.city:'';
              element.patientName = this.props.patientData[i].name;
              element.patient = this.props.patientData[i]
            }
          }
          return new ClientTable(element);}
        );
        this.props.setClientData(mapedRes);
        this.getDataBasic();
      })
      .catch((error) => {
        console.log(error);
        this.getDataBasic();
      });
    }else{
      this.getDataBasic();
    }
  }

  getPatientList(){
    if(this.props.patientData.length<=0){
      axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getClientsList();
      })
      .catch((error) => {
        console.log(error);
        this.getClientsList();
      });
    }else{
      this.getClientsList();
    }
  }

  getBasicTasksData(){
    if(this.props.tasksStatus === ResponseStatus.READY){
      clearTimeout(this.myTimeout);
      this.getPatientList();
    }else{
      if(this.props.tasksStatus === ResponseStatus.FREE){
        this.props.getGlobalTasksData();
        this.myTimeout = setTimeout(() => {
          this.getBasicTasksData()
        }, 500);
      }else{
        if(this.props.tasksStatus === ResponseStatus.ERROR){
          clearTimeout(this.myTimeout);
          if (this._isMounted) {
            this.setState({ loadingTasks: false })
          }
        }else{
          this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
          }, 500);
        }
      }
    }
  }

  getInitialDataTime(){
    if(this.props.tasksData.length>0){
      clearTimeout(this.myTimeout);
      this.getPatientList();
    }else{
      this.myTimeout = setTimeout(() => {
        this.getBasicTasksData()
      }, 300);
    }
  }

  getDataBasic() {
    const { match: { params } } = this.props;
    for(let i=0;i<this.props.tasksData.length; i++){
      if(this.props.tasksData[i].id===params.id){
        if (this._isMounted) {
          this.setState({
            taskData: JSON.parse(JSON.stringify(this.props.tasksData[i])),
            taskDataBackup: JSON.parse(JSON.stringify(this.props.tasksData[i])),
            loading: false,
            id: params.id,
            breadCrumbs:[
              {
                link: "tasks",
                key: "tasks",
                name: null
              },
              {
                link: "",
                key: "",
                name: this.props.tasksData[i].title
              }
            ]
          })
        }
        break;
      }
    }
  }

  cancelChange(){
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            taskData: JSON.parse(JSON.stringify(prevState.taskDataBackup)),
            edited: false
        }
      });
    }
  }

  markasCompletedService(){
    this.props.markTaskAsCompleted(this.state.taskData)
    .then((res)=>{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              errorsMessage: null,
              errorsType: "danger",
              savingDataTask: false,
              taskData:{
                ...prevState.taskData,
                status:"Completed"
              },
              edited: false
          }
        });
      }
      this.props.updateTaskDataFun(this.state.taskData.id,this.state.taskData);
      this.props.history.push(`${baseData.subFolders}tasks`);
    })
    .catch((err)=>{
      console.log(err);
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              savingDataTask: false,
              errorsMessage: err.message,
              errorsType: "danger"
          }
        });
      }
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            errorsMessage: null,
            errorsType: "danger"
          }) 
        }
      }, 3000);
    })
  }

  markAsCompleted(){
    if(this.state.taskData.status==="Open" || this.state.taskData.status==="Scheduled"){
      if(this.state.edited){
        if (this._isMounted) {
          this.setState({savingDataTask: true})
        }
        this.props.saveTaskData(this.state.taskData, this.props.appLang)
        .then((res)=>{
          this.markasCompletedService();
        })
        .catch((err)=>{
          this.markasCompletedService();
        })
      }else{
        if (this._isMounted) {
          this.setState({savingDataTask: true})
        }
        this.markasCompletedService();
      }
    }
  }




  markAsCancelledMain(){
    if(this.state.taskData.status==="Open" || this.state.taskData.status==="Scheduled"){
      if(this.state.edited){
        if (this._isMounted) {
          this.setState({savingDataTask: true})
        }
        this.props.saveTaskData(this.state.taskData, this.props.appLang)
        .then((res)=>{
          this.markasCancelledService();
        })
        .catch((err)=>{
          this.markasCancelledService();
        })
      }else{
        if (this._isMounted) {
          this.setState({savingDataTask: true})
        }
        this.markasCancelledService();
      }
    }
  }

  markasCancelledService(){
    this.props.markTaskAsCancelled(this.state.taskData)
    .then((res)=>{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              errorsMessage: null,
              errorsType: "danger",
              savingDataTask: false,
              taskData:{
                ...prevState.taskData,
                status:"Cancelled"
              },
              edited: false
          }
        });
      }
      this.props.updateTaskDataFun(this.state.taskData.id,this.state.taskData);
      this.props.history.push(`${baseData.subFolders}tasks`);
    })
    .catch((err)=>{
        console.log(err);
        if (this._isMounted) {
          this.setState(prevState => {
            return {
                ...prevState,
                savingDataTask: false,
                errorsMessage: err.message,
                errorsType: "danger"
            }
          });
        }
        clearTimeout(this.myTimeout);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              errorsMessage: null,
              errorsType: "danger"
            }) 
          }
        }, 3000);
    })
  }



  saveChange(){
    if(this.state.edited){
      if (this._isMounted) {
        this.setState({savingDataTask: true})
      }
      this.props.saveTaskData(this.state.taskData, this.props.appLang)
      .then((res)=>{
        if (this._isMounted) {
          this.setState({
            savingDataTask: false,
            errorsMessage: "task_successfully_saved",
            errorsType: "success",
            edited: false,
          })    
        }  
        clearTimeout(this.myTimeout);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              errorsMessage: null,
              errorsType: "danger",
              taskData: {...MockTaskData},
              taskDataBackup: {...MockTaskData}
            }) 
          }
          this.props.history.push(`${baseData.subFolders}tasks`);
        }, 3000);
        this.props.updateTaskDataFun(this.state.taskData.id,this.state.taskData);
      })
      .catch((err)=>{
          if (this._isMounted) {
            this.setState(prevState => {
              return {
                  ...prevState,
                  savingDataTask: false,
                  errorsMessage: err.message,
                  errorsType: "danger"
              }
            });
          }
          clearTimeout(this.myTimeout);
          this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
              this.setState({
                errorsMessage: null,
                errorsType: "danger"
              }) 
            }
          }, 3000);
      })
    }
  }

  basicEdit(){
    if(this.state.taskData.status!=="Completed" && this.state.taskData.status!=="Closed"){
      if (this._isMounted) {
        this.setState({edited: true})
      }
    }
  }

  render() {
    let taskData
    const { translateKey } = this.props;
    const { errorsMessage, errorsType, edited, savingDataTask, breadCrumbs} = this.state;
    if (this.state.loading) {
      taskData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      let FirstRow =(
        <TaskDetailsBasicComponent markAsComplete={()=>this.markAsCompleted()} markAsCancelled={()=>this.markAsCancelledMain()} newOne={false} editedData={()=>this.basicEdit()} edited={this.state.edited} key="taskDetails1" element={this.state.taskData}/>
      )
      let CancelSave = (
        <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="taskCancelSave1" edited={edited} savingData={savingDataTask}/>
      );
      let SaveAlert;
      if(errorsMessage!==null){
        SaveAlert = (
          <AlertMessage centeredAlert={true} show={errorsMessage!==null} key="taskAlert1" type={errorsType} message={translateKey(errorsMessage)}/>
        )
      }
      taskData =  [
        FirstRow,
        SaveAlert,
        CancelSave
      ]
    }

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass overflowInitial">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active nav-link-invoices" id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                {translateKey("task")}
              </a>
            </div>
          </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {taskData}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={edited}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    i18: state.lang.i18data,
    tasksData: state.database.tasks,
    tasksTimestamp: state.database.tasksTimeStamp,
    taskPriority: state.enums.taskPriority,
    tasksStatus: state.database.tasksState,
    clientsData: state.database.clients,
    patientData: state.database.patientClient,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  saveTaskData: (data) => dispatch(taskSaveTaskData(data)),
  markTaskAsCompleted: (data) => dispatch(markTaskAsCompleted(data)),
  markTaskAsCancelled: (data) => dispatch(markTaskAsCancelled(data)),
  updateTaskDataFun: (id, data) => dispatch(updateTaskData(id, data)),
  getGlobalTasksData: () => dispatch(getTasks()),
  setClientData: (data) => dispatch(setClientsData(data)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTaskPage));