export const sortingCarers = [
    {
        type: "ASC",
        column: 'carer',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'status',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'from',
        active: false,
        format: "date"
    },
    {
        type: "ASC",
        column: 'to',
        active: true,
        format: "date"
    }
];