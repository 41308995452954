export const CarerPreferences = {
    AgeFrom1: 0,
    AgeFrom2: 0,
    AgeTo1: 0,
    AgeTo2: 0,
    DrivingLicense: false,
    Gender: 0,
    OtherRequirements: '',
    SeparateRoom: false,
    SmokingAttitude: 0
};