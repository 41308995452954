import ColorElement from "./colorElement";
export const DARKBLUE = '#0056a4';
export const LIGHTBLUE = '#45b6fe'
export const DARKGREEN = '#64b445';
export const LIGHTGREEN = '#bbd854';
export const ORANGE = '#F5821F';
export const GRAY = "#d9d9d9";

const ColorList = [
    new ColorElement(
        "1B176659-A798-E911-80D1-00155D140163",
        DARKBLUE,
    ),
    new ColorElement(
        "0F696795-A798-E911-80D1-00155D140163",
        LIGHTBLUE,
    ),
    new ColorElement(
        "512F5161-A798-E911-80D1-00155D140163 ",
        DARKGREEN,
    ),
    new ColorElement(
        "F9E0D487-A798-E911-80D1-00155D140163",
        ORANGE,
    ),
    new ColorElement(
        "0AE2872B-4A78-E811-80D7-00155D00F809",
        //"F1226248-A798-E911-80D1-00155D140163",
        LIGHTGREEN,
    ),
];
export default class COLORSBYSTATUS {
    colorsbyStatus = [];

    constructor() {
        this.colorsbyStatus = ColorList;
    }

    getColorByStatus(status) {
        return this.colorsbyStatus.find(element => element.status === status);
    }

    giveMeRandomColor(){
        return '#' + ("000000" + Math.random().toString(16).slice(2, 8).toUpperCase()).slice(-6)
    }
}