import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import ListMovementComponent from "../../form-bb-components/list-movement-component/list-movement-component";
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import produce from "immer";


class Section13 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-13'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey, isImportant, showValidationErrors } = this.props;
        const { section } = this.state;
        const isRemarksSleepsDe = section.getFieldByKey('is_remarks_sleeps_de');
        const isHowOftenCarerMustGetUpAtNightDe = section.getFieldByKey('is_how_often_carer_must_get_up_at_night_de');
        const isActivitiesPBCAND = section.getFieldByKey('is_activities_performed_by_carer_at_night_de')
        const isPatientMHD = section.getFieldByKey('is_patient_medicines_hypnotics_de')

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                            <ListMovementComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-einschlafen" key="is_falling_asleep" keyName="is_falling_asleep" value={section.getFieldByKey('is_falling_asleep').value} validationErrors={section.getFieldByKey('is_falling_asleep').validationErrors}  />
                            <ListMovementComponent showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-durchschlafen" key="is_sleep_over" keyName="is_sleep_over" value={section.getFieldByKey('is_sleep_over').value} validationErrors={section.getFieldByKey('is_sleep_over').validationErrors} />

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <InputTypeTextComponent isImportant={isImportant&&isPatientMHD.checkImportant} showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isPatientMHD} text={translateKey("form-text-schlafmedikation")} transKey="form-text-schlafmedikation" keyName="is_patient_medicines_hypnotics_de" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isRemarksSleepsDe} text={translateKey("form-text-bemerkungen")} transKey="form-text-bemerkungen" keyName="is_remarks_sleeps_de" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isHowOftenCarerMustGetUpAtNightDe} text={translateKey("form-text-wieoftmuss")} transKey="form-text-wieoftmuss" keyName="is_how_often_carer_must_get_up_at_night_de" />
                                </div>
                            </div>
                            { isHowOftenCarerMustGetUpAtNightDe.value && isHowOftenCarerMustGetUpAtNightDe.value.length > 0 && (
                                <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                        <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isActivitiesPBCAND} text={translateKey("form-text-beitatigkeiten")} transKey="form-text-beitatigkeiten" keyName="is_activities_performed_by_carer_at_night_de" />
                                    </div>
                                </div>
                            )}
                        </fieldset>
                    </form>
                </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section13));