import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { ReqHelper } from "../../../_helpers/index";

class IncludesComponenet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }
    
    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    render() {
        let IncludesData;
        if(this.state.isOpen){
            let mapedData = [];
            let liveData = [];
            let discountsData = [];
            let otherData = [];
            if(this.props.element.InvoiceLines.length>0){
                mapedData = ReqHelper.invoiceLinesSortByGroup(this.props.element.InvoiceLines);
            }
            if(mapedData.live.length>0){
                liveData.push(
                    <div className="col-sm-12" key={Math.random().toString(36).substr(2, 9)}>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("invoice_contract_line")}</label>
                        </div>
                    </div>
                )
                for(let i=0; i<mapedData.live.length; i++){
                    liveData.push(
                        <div className="col-sm-12 col-lg-12" key={Math.random().toString(36).substr(2, 9)}>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-8 col-form-label form-label-includes-other">{mapedData.live[i].Description}</label>
                                <div className="col-sm-4">
                                    <span className="lead-input-wrapper">
                                        {ReqHelper.numberWithSpaces(mapedData.live[i].Amount) + ' ' + this.props.element.invoice.currency}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            if(mapedData.discounts.length>0){
                discountsData.push(
                    <div className="col-sm-12" key={Math.random().toString(36).substr(2, 9)}>
                        <div className="form-group row flexAlignCenter no-margin-row" >
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("invoice_deduction_line")}</label>
                        </div>
                    </div>
                )
                for(let i=0; i<mapedData.discounts.length; i++){
                    discountsData.push(
                        <div className="col-sm-12 col-lg-12" key={Math.random().toString(36).substr(2, 9)}>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-8 col-form-label form-label-includes-other">{mapedData.discounts[i].Description}</label>
                                <div className="col-sm-4">
                                    <span className="lead-input-wrapper">
                                        {ReqHelper.numberWithSpaces(mapedData.discounts[i].Amount) + ' ' + this.props.element.invoice.currency}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            if(mapedData.other_services.length>0){
                otherData.push(
                    <div className="col-sm-12" key={Math.random().toString(36).substr(2, 9)}>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("invoice_additive_line")}</label>
                        </div>
                    </div>
                )
                for(let i=0; i<mapedData.other_services.length; i++){
                    otherData.push(
                        <div className="col-sm-12 col-lg-12" key={Math.random().toString(36).substr(2, 9)}>
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-8 col-form-label form-label-includes-other">{mapedData.other_services[i].Description}</label>
                                <div className="col-sm-4">
                                    <span className="lead-input-wrapper">
                                        {ReqHelper.numberWithSpaces(mapedData.other_services[i].Amount) + ' ' + this.props.element.invoice.currency}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            IncludesData = (
                <div className="lead-details-client-info">
                    {liveData}
                    {discountsData}
                    {otherData}
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{this.props.translateKey("invoice_include")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                    </div>
                </div>
                {IncludesData}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(IncludesComponenet));