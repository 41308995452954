import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

class ListComponent extends React.Component {
    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    render() {
        const { valuesList = [], keyName, value, validationErrors, showValidation, className, translateKey, transKey, keyFix } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;

        return (
            <div className={`row col-sm-12 ${className}`}>
                <div className={`col-sm-12 form-margin-bottom-fix list-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                    <label className="form-label-basic"><span className="quick-label-position-fix">{translateKey(transKey)}</span></label>
                    <div className="radio-button-group-wrapper" onChange={this.setTypeChange}>
                        { valuesList.map(({ value: possibleValue, label }) => {
                            let id = `${keyName}_${possibleValue}`;
                            if(keyFix){
                                id = `${keyFix}_${possibleValue}`;
                            }
                            return (
                                <div className="custom-control custom-radio custom-control-inline" key={id}>
                                    <input className="form-check-input" checked={value === possibleValue} onChange={()=>{}} data-field-name={keyName} type="radio" name={keyName} id={id} value={possibleValue} disabled={this.props.shouldBeEdited}/>
                                    <label className="form-check-label cursor-pointer" htmlFor={id}>{label}</label>
                                </div>
                            )
                        })}
                    </div>
                    { showErrors && (
                        <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                    )}
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(ListComponent));