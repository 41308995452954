import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { CheckBoxSmallMultipleComponent } from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import ListComponent from "../../form-bb-components/list-component/list-component";
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import produce from "immer";

class Section14 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-14'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, showValidationErrors, isImportant} = this.props;
        const { section } = this.state;
        const isOutpatientHelp = section.getFieldByKey('is_outpatient_help');
        const isDayCareHome = section.getFieldByKey('is_day_care_home');
        const isEmergencyCallSystem = section.getFieldByKey('is_emergency_call_system');
        const isContractPflegedienstServicesSigned = section.getFieldByKey('is_contract_pflegedienst_services_signed');
        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {this.props.translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">
                    {this.props.subsectionNumber}. {this.props.subsectionName}
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                        <CheckBoxComponent isImportant={isImportant&&isEmergencyCallSystem.checkImportant} showValidation={showValidationErrors} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} value={isEmergencyCallSystem.value} validationErrors={isEmergencyCallSystem.validationErrors} text={this.props.translateKey("form-text-notrufsystem")} transKey="form-text-notrufsystem" keyName="is_emergency_call_system"/>

                        <CheckBoxComponent isImportant={isImportant&&isOutpatientHelp.checkImportant} showValidation={showValidationErrors} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} value={isOutpatientHelp.value} validationErrors={isOutpatientHelp.validationErrors} text={this.props.translateKey("form-text-pflegedienst")} transKey="form-text-pflegedienst" keyName="is_outpatient_help"/>
                        {isOutpatientHelp.value===false &&
                            <React.Fragment>
                                <CheckBoxComponent isImportant={isImportant&&isContractPflegedienstServicesSigned.checkImportant} showValidation={showValidationErrors} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} value={isContractPflegedienstServicesSigned.value} validationErrors={isContractPflegedienstServicesSigned.validationErrors} text={this.props.translateKey("form-text-beauftragt")} transKey="form-text-beauftragt" keyName="is_contract_pflegedienst_services_signed"/>
                            </React.Fragment>
                        }

                        <div className="row col-sm-12 col-lg-6">
                            <InputTypeTextComponent showValidation={this.state.showValidation} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_how_often_outpatient_help_de')} text={this.props.translateKey("form-text-wie-pflegedienst")} transKey="form-text-wie-pflegedienst" keyName="is_how_often_outpatient_help_de"/>
                        </div>

                        <div className="row col-sm-12 col-lg-6">
                            <InputTypeTextComponent showValidation={this.state.showValidation} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_outpatient_services_list_de')} text={this.props.translateKey("form-text-tatigkeiten")} transKey="form-text-tatigkeiten" keyName="is_outpatient_services_list_de"/>
                        </div>

                        <div className="hilfsmittel-wrapper">
                            <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("form-text-welche-leistungen")}</div>
                            <div className="checkboxes_wrapper">
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-behandlungspflege"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_medical_care_sgb_v')} text={this.props.translateKey("form-text-behandlungspflege")} transKey="form-text-behandlungspflege" keyName="is_medical_care_sgb_v"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-pflegesachleistung"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_benefits_kind_for_sgb_care')} text={this.props.translateKey("form-text-pflegesachleistung")} transKey="form-text-pflegesachleistung" keyName="is_benefits_kind_for_sgb_care"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-betreuungsleistungen"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_anicillary_services_45b')} text={this.props.translateKey("form-text-betreuungsleistungen")} transKey="form-text-betreuungsleistungen" keyName="is_anicillary_services_45b"/>
                            </div>
                        </div>


                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix top-too"
                            transKey="form-text-tagespflege"
                            key="is_day_care_home"
                            keyName="is_day_care_home"
                            value={isDayCareHome.value}
                            validationErrors={isDayCareHome.validationErrors}
                            valuesList={[
                                { value: IsContactPersonDataSameAs.YES, label: this.props.translateKey("yes")},
                                { value: IsContactPersonDataSameAs.NO, label: this.props.translateKey("no")}
                            ]}
                        />
                        {isDayCareHome.value === IsContactPersonDataSameAs.YES &&
                            <React.Fragment>
                                <div className="row col-sm-12 col-lg-6">
                                    <InputTypeTextComponent showValidation={this.state.showValidation} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_home_care_day_frequency_de')} text={this.props.translateKey("form-text-wieoft")} transKey="form-text-wieoft" keyName="is_home_care_day_frequency_de"/>
                                </div>
                            </React.Fragment>
                        }
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section14));