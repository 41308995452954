import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import ListComponent from "../../form-bb-components/list-component/list-component";
import WrappedDatePickerComponent
    from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import produce from "immer";

class Section3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-3'),
            showValidation: props.showValidationErrors,
            is_kein_pflegegrad: false
        };
    }

    componentDidMount(){
        const { section } = this.state;
        let isPflegegrad = section.getFieldByKey('is_pflegegrad');
        let isAppliedPflegegrad = section.getFieldByKey('is_applied_pflegegrad');
        let is_pflegegrad_posting_date = section.getFieldByKey('is_pflegegrad_posting_date');
        if(isPflegegrad.value!=='' || isAppliedPflegegrad.value!=='' || is_pflegegrad_posting_date.value!==''){
            this.setState({is_kein_pflegegrad: false})
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    clearMeFields = (key, value) =>{
        if(value===true){
            const { section } = this.state;
            section.getFieldByKey('is_pflegegrad').value = '';
            section.getFieldByKey('is_applied_pflegegrad').value = '717700007';
            section.getFieldByKey('is_krankenkasse').value = '';
        }else{
            const { section } = this.state;
            section.getFieldByKey('is_pflegegrad').value = '';
            section.getFieldByKey('is_applied_pflegegrad').value = '';
            section.getFieldByKey('is_krankenkasse').value = '';
        }
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    }

    render() {
        const { disabled, showValidationErrors, translateKey } = this.props;
        const { section } = this.state;
        const isPflegegrad = section.getFieldByKey('is_pflegegrad');
        const isAppliedPflegegrad = section.getFieldByKey('is_applied_pflegegrad');
        const isInsuranceType = section.getFieldByKey('is_insurance_type');
        const isPflegegradPostingDate = section.getFieldByKey('is_pflegegrad_posting_date');
        const isKeinPflegegrad = section.getFieldByKey('is_kein_pflegegrad');
        if(isAppliedPflegegrad.value==="717700007"){
            isKeinPflegegrad.value=true
        }

        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">
                    {this.props.subsectionNumber}. {this.props.subsectionName}
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.clearMeFields} value={isKeinPflegegrad.value} text={translateKey("form-text-kein-pflegegrad")} transKey="form-text-kein-pflegegrad" keyName="is_kein_pflegegrad"/>

                        {isKeinPflegegrad.value===false &&
                            <React.Fragment>
                                <ListComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="form-margin-bottom-fix"
                                    transKey="form-text-beantragt"
                                    key="is_applied_pflegegrad"
                                    keyName="is_applied_pflegegrad"
                                    value={isAppliedPflegegrad.value}
                                    validationErrors={isAppliedPflegegrad.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: 1 },
                                        { value: '717700001', label: 2 },
                                        { value: '717700002', label: 3 },
                                        { value: '717700003', label: 4 },
                                        { value: '717700004', label: 5 }
                                    ]}
                                />
                                <ListComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="form-margin-bottom-fix"
                                    transKey="pflegegrad"
                                    key="is_pflegegrad"
                                    keyName="is_pflegegrad"
                                    value={isPflegegrad.value}
                                    validationErrors={isPflegegrad.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: 1 },
                                        { value: '717700001', label: 2 },
                                        { value: '717700002', label: 3 },
                                        { value: '717700003', label: 4 },
                                        { value: '717700004', label: 5 }
                                    ]}
                                />
                            </React.Fragment>
                        }

                        <div className="row col-sm-12 col-lg-6">
                            <WrappedDatePickerComponent
                                showValidation={showValidationErrors}
                                keyName="is_pflegegrad_posting_date"
                                value={isPflegegradPostingDate.value}
                                validationErrors={isPflegegradPostingDate.validationErrors}
                                text={translateKey("form-text-seit-wann")}
                                setTypeChangeToParent={this.setTypeChange}
                                disabled={disabled}
                            />
                        </div>

                        {isKeinPflegegrad.value===false &&
                            <React.Fragment>
                                <div className="row col-sm-12 col-lg-6">
                                    <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_krankenkasse')} text={translateKey("form-text-krankenkasse")} transKey="form-text-krankenkasse" keyName="is_krankenkasse"/>
                                </div>
                            </React.Fragment>
                        }

                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={showValidationErrors}
                            className="form-margin-bottom-fix"
                            transKey="form-text-beantragt"
                            key="is_insurance_type"
                            keyName="is_insurance_type"
                            value={isInsuranceType.value}
                            validationErrors={isInsuranceType.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("form-text-gesetzlich")},
                                { value: '717700001', label: translateKey("form-text-privat")}
                            ]}
                        />
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section3));