import * as React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

export class CheckBoxComponent extends React.Component {
    static defaultProps = {
        validationErrors: [],
        className: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            isIm: props.isImportant
        };
    }

    setTypeChange = (e) => {
        this.props.setTypeChangeToParent(this.props.keyName, e.target.checked);
    };

    render() {
        const { value, validationErrors, showValidation, keyName, className, transKey, isImportant } = this.props;
        const showErrors = validationErrors.length > 0 && showValidation;

        return (
            <div className={`row col-sm-12 ${className}`}>
                <div className="col-sm-12 form-margin-bottom-fix top-too">
                    <div className={`checkbox-button-group-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                        <div className="custom-control custom-checkbox custom-control-inline">
                            <input className="form-check-input" onChange={this.setTypeChange} checked={value} data-field-name={keyName} type="checkbox" name={transKey} id={transKey} value={value} />
                            <label className={`form-label-basic cursor-pointer ${isImportant ? 'input_important_class' : ''}`} htmlFor={transKey}><span className="quick-label-position-fix">{this.props.text}</span></label>
                        </div>
                        { showErrors && (
                            <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
