import * as React from 'react';
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import {
  MDBModal,
  MDBModalBody,
  MDBCloseIcon,
  MDBNav,
} from 'mdbreact';
import { translateKey, authSetActionTimestamp, updateTaskData, getTasks, setClientsData, setPatientClientData, pendingClientsData } from "../../_redux/actions/index";
import { TableDateFormat, TaskStatusHandler, TaskPriorityConverter, TaskStatusLang} from "../../_components/index";
import { AlertMessage, TaskDetailsBasicComponent, LeadCancelSaveComponent } from "../../_components/index";
import { Alert, ClientTable } from "../../_constance/classes/index";
import { ResponseStatus } from "../../_helpers/index";
import { taskSaveTaskData, markTaskAsCompleted, markTaskAsCancelled } from "../../_redux/actions/auth";
import { MockTaskData } from "../../_constance/classes/mockTask/mockTaskData";
import axiosAuth from "../../_services/config/axios-auth";
import { baseData } from '../../_constance/base_data';

class TaskModal extends React.Component {
    _isMounted = false;
    alertD = Alert;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
        taskData: {...MockTaskData},
        taskDataBackup: {...MockTaskData},
        savingDataTask: false,
        errorComponent: Alert,
        loading: false,
        edited: false,
        errorsMessage: null,
        errorsType: "danger",
        id: 0,
        isTaskOpen: false,
        breadCrumbs:[
            {
            link: "tasks",
            key: "tasks",
            name: null
            }
        ]
        };
    }

    componentDidUpdate() {
        this._promptUnsavedChange(this.state.edited);
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0)
        this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
        }, 200);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.onbeforeunload = null
        if(this.state.edited){
        if (this._isMounted) {
            this.setState(prevState => {
            return {
                ...prevState,
                taskData: {...MockTaskData},
            }
            });
        }
        }
        clearTimeout(this.myTimeout);
    };

    _promptUnsavedChange(isUnsaved = false) {
        window.onbeforeunload = isUnsaved;
    }

    getPatientList(){
        if(this.props.patientData.length<=0){
        axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            this.props.setPatientData(res);
            this.getClientsList();
        })
        .catch((error) => {
            console.log(error);
            this.getClientsList();
        });
        }else{
            // this.getClientsList();
        }
    }

    getClientsList = () => {
        if(this.props.clientsState !== ResponseStatus.PENDING){
          this.props.pendingRequestClient();
          axiosAuth.get("clients/list/0")
            .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
            .then(json => { return json.data })
            .then(res => {
              let mapedRes = res.map((element)=>{
                for(let i=0; i<this.props.patientData.length; i++){
                  if(this.props.patientData[i].clientId===element.id){
                    element.locationP = this.props.patientData[i].patientAddress ? this.props.patientData[i].patientAddress.city:'';
                    element.patientName = this.props.patientData[i].name;
                    element.patient = this.props.patientData[i]
                  }
                }
                return new ClientTable(element);}
              );
              this.props.setClientData(mapedRes);
            })
            .catch((error) => {this.props.clearDataClient();});
        }
      }

    getBasicTasksData(){
        if(this.props.tasksStatus === ResponseStatus.FREE){
            this.props.getGlobalTasksData();
            this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
            }, 500);
        }else{
            if(this.props.tasksStatus === ResponseStatus.ERROR){
            clearTimeout(this.myTimeout);
            if (this._isMounted) {
                this.setState({ loadingTasks: false })
            }
            }else{
            this.myTimeout = setTimeout(() => {
                this.getBasicTasksData()
            }, 500);
            }
        }
    }

    getInitialDataTime(){
        if(this.props.tasksData.length>0){
        clearTimeout(this.myTimeout);
        this.getPatientList();
        }else{
        this.myTimeout = setTimeout(() => {
            this.getBasicTasksData()
        }, 300);
        }
    }

    getDataBasic() {
        const { match: { params } } = this.props;
        for(let i=0;i<this.props.tasksData.length; i++){
        if(this.props.tasksData[i].id===params.id){
            if (this._isMounted) {
            this.setState({
                taskData: JSON.parse(JSON.stringify(this.props.tasksData[i])),
                taskDataBackup: JSON.parse(JSON.stringify(this.props.tasksData[i])),
                loading: false,
                id: params.id,
                breadCrumbs:[
                {
                    link: "tasks",
                    key: "tasks",
                    name: null
                },
                {
                    link: "",
                    key: "",
                    name: this.props.tasksData[i].title
                }
                ]
            })
            }
            break;
        }
        }
    }

    cancelChange(){
        if (this._isMounted) {
        this.setState(prevState => {
            return {
                ...prevState,
                taskData: JSON.parse(JSON.stringify(prevState.taskDataBackup)),
                edited: false
            }
        });
        }
    }

    markasCompletedService(){
        this.props.markTaskAsCompleted(this.state.taskData)
        .then((res)=>{
        if (this._isMounted) {
            this.setState(prevState => {
            return {
                ...prevState,
                errorsMessage: null,
                errorsType: "danger",
                savingDataTask: false,
                taskData:{
                    ...prevState.taskData,
                    status:"Completed"
                },
                edited: false
            }
            });
        }
        this.props.updateTaskDataFun(this.state.taskData.id,this.state.taskData);
        this.props.history.push(`${baseData.subFolders}tasks`);
        })
        .catch((err)=>{
        console.log(err);
        if (this._isMounted) {
            this.setState(prevState => {
            return {
                ...prevState,
                savingDataTask: false,
                errorsMessage: err.message,
                errorsType: "danger"
            }
            });
        }
        clearTimeout(this.myTimeout);
        this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
            this.setState({
                errorsMessage: null,
                errorsType: "danger"
            }) 
            }
        }, 3000);
        })
    }

    markAsCompleted(){
        if(this.state.taskData.status==="Open" || this.state.taskData.status==="Scheduled"){
        if(this.state.edited){
            if (this._isMounted) {
            this.setState({savingDataTask: true})
            }
            this.props.saveTaskData(this.state.taskData, this.props.appLang)
            .then((res)=>{
            this.markasCompletedService();
            })
            .catch((err)=>{
            this.markasCompletedService();
            })
        }else{
            if (this._isMounted) {
            this.setState({savingDataTask: true})
            }
            this.markasCompletedService();
        }
        }
    }




    markAsCancelledMain(){
        if(this.state.taskData.status==="Open" || this.state.taskData.status==="Scheduled"){
        if(this.state.edited){
            if (this._isMounted) {
            this.setState({savingDataTask: true})
            }
            this.props.saveTaskData(this.state.taskData, this.props.appLang)
            .then((res)=>{
            this.markasCancelledService();
            })
            .catch((err)=>{
            this.markasCancelledService();
            })
        }else{
            if (this._isMounted) {
            this.setState({savingDataTask: true})
            }
            this.markasCancelledService();
        }
        }
    }

    markasCancelledService(){
        this.props.markTaskAsCancelled(this.state.taskData)
        .then((res)=>{
        if (this._isMounted) {
            this.setState(prevState => {
            return {
                ...prevState,
                errorsMessage: null,
                errorsType: "danger",
                savingDataTask: false,
                taskData:{
                    ...prevState.taskData,
                    status:"Cancelled"
                },
                edited: false
            }
            });
        }
        this.props.updateTaskDataFun(this.state.taskData.id,this.state.taskData);
        this.props.history.push(`${baseData.subFolders}tasks`);
        })
        .catch((err)=>{
            console.log(err);
            if (this._isMounted) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    savingDataTask: false,
                    errorsMessage: err.message,
                    errorsType: "danger"
                }
            });
            }
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
                this.setState({
                errorsMessage: null,
                errorsType: "danger"
                }) 
            }
            }, 3000);
        })
    }



    saveChange(){
        if(this.state.edited){
        if (this._isMounted) {
            this.setState({savingDataTask: true})
        }
        this.props.saveTaskData(this.state.taskData, this.props.appLang)
        .then((res)=>{
            if (this._isMounted) {
            this.setState({
                savingDataTask: false,
                errorsMessage: "task_successfully_saved",
                errorsType: "success",
                edited: false,
            })    
            }  
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {
            if (this._isMounted) {
                this.setState({
                errorsMessage: null,
                errorsType: "danger",
                taskData: {...MockTaskData},
                taskDataBackup: {...MockTaskData}
                }) 
            }
            this.props.history.push(`${baseData.subFolders}tasks`);
            }, 3000);
            this.props.updateTaskDataFun(this.state.taskData.id,this.state.taskData);
        })
        .catch((err)=>{
            if (this._isMounted) {
                this.setState(prevState => {
                return {
                    ...prevState,
                    savingDataTask: false,
                    errorsMessage: err.message,
                    errorsType: "danger"
                }
                });
            }
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {
                if (this._isMounted) {
                this.setState({
                    errorsMessage: null,
                    errorsType: "danger"
                }) 
                }
            }, 3000);
        })
        }
    }

    basicEdit(){
        if(this.state.taskData.status!=="Completed" && this.state.taskData.status!=="Closed"){
        if (this._isMounted) {
            this.setState({edited: true})
        }
        }
    }

    openTask(task) {
        this.setState({
            isTaskOpen: true,
            taskData: task,
        });
    }

    closeTask() {
        this.setState({
            isTaskOpen: false,
        });
    }

    render() {
        let taskData
        const { translateKey } = this.props;
        const { errorsMessage, errorsType, edited, savingDataTask } = this.state;
        let Return = (
            <button className="btn btn-success norbsoft-filter-button" onClick={() => this.closeTask()}>
                <i class="fas fa-arrow-left norbsoft-filter-icon"></i>
                {translateKey('return')}
            </button>
        )
        let FirstRow =(
            <TaskDetailsBasicComponent
                markAsComplete={()=>this.markAsCompleted()}
                markAsCancelled={()=>this.markAsCancelledMain()}
                newOne={false}
                editedData={()=>this.basicEdit()}
                edited={this.state.edited}
                key="taskDetails1"
                element={this.state.taskData}
            />
        )
        let CancelSave = (
            <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="taskCancelSave1" edited={edited} savingData={savingDataTask}/>
        );
        let SaveAlert;
        if(errorsMessage!==null){
            SaveAlert = (
            <AlertMessage centeredAlert={true} show={errorsMessage!==null} key="taskAlert1" type={errorsType} message={translateKey(errorsMessage)}/>
            )
        }
        taskData =  [
            Return,
            FirstRow,
            SaveAlert,
            CancelSave
        ]

        return (
            <>
                <MDBModal isOpen={this.props.show} fullHeight size="lg" toggle={()=> {}} centered={true}>
                    <MDBNav className="task-popup-nav">
                        <MDBCloseIcon onClick={this.props.onClose}></MDBCloseIcon>
                    </MDBNav>
                    <MDBModalBody className="task-popup-body">
                        {this.state.isTaskOpen ? taskData : 
                            <>
                                <h4 className="task-popup-title">{translateKey('task_popup_title')}</h4>
                                <table className="table">
                                    <thead>
                                        <tr className={`table-row-wrapper cursor-pointer}`}>
                                            <td className="table-cell-wrapper">{translateKey('type')}</td>
                                            <td className="table-cell-wrapper">{translateKey('title')}</td>
                                            <td className="table-cell-wrapper">{translateKey('status')}</td>
                                            <td className="table-cell-wrapper">{translateKey('concerns')}</td>
                                            <td className="table-cell-wrapper">{translateKey('priority')}</td>
                                            <td className="table-cell-wrapper">{translateKey('created')}</td>
                                            <td className="table-cell-wrapper">{translateKey('due_day')}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.props.content.map(element => (
                                            <tr className={`table-row-wrapper cursor-pointer}`} key={Math.random().toString(36).substr(2, 5)} onClick={() => this.openTask(element)}>
                                                <td className="table-cell-wrapper"><TaskStatusHandler element={element}/></td>
                                                <td className="table-cell-wrapper">{element.title}</td>
                                                <td className="table-cell-wrapper"><TaskStatusLang element={element.status}/></td>
                                                <td className="table-cell-wrapper">{element.concernName}</td>
                                                <td className="table-cell-wrapper"><TaskPriorityConverter priority={element.priority}/></td>
                                                <td className="table-cell-wrapper"><TableDateFormat timestamp={element.createdDate}/></td>
                                                <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dueDay}/></td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </>
                        }
                    </MDBModalBody>
                </MDBModal>
            </>
        )
    };
}

const mapStateToProps = state => {
    return {
      appLang: state.lang.appLanguage,
      i18: state.lang.i18data,
      tasksData: state.database.tasks,
      tasksTimestamp: state.database.tasksTimeStamp,
      taskPriority: state.enums.taskPriority,
      tasksStatus: state.database.tasksState,
      clientsData: state.database.clients,
      patientData: state.database.patientClient,
    };
  };

const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    tookAction: () => dispatch(authSetActionTimestamp()),
    saveTaskData: (data) => dispatch(taskSaveTaskData(data)),
    markTaskAsCompleted: (data) => dispatch(markTaskAsCompleted(data)),
    markTaskAsCancelled: (data) => dispatch(markTaskAsCancelled(data)),
    updateTaskDataFun: (id, data) => dispatch(updateTaskData(id, data)),
    getGlobalTasksData: () => dispatch(getTasks()),
    setClientData: (data) => dispatch(setClientsData(data)),
    setPatientData: (data) => dispatch(setPatientClientData(data)),
    pendingRequestClient: () => dispatch(pendingClientsData()),
  });
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskModal));