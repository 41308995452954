import * as React from 'react';
import { MDBModal, MDBModalBody} from 'mdbreact';

export class AlertModalInfoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
    }

    render() {
        const { show , type, message, centeredAlert, size, centeredVertical, toggle } = this.props;
        return (  
            <MDBModal isOpen={show} fullHeight size={size} toggle={() => toggle()} centered={centeredVertical}>
                {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
                <MDBModalBody>
                    <div className={"alert alert-" + type +' '+(centeredAlert? "centeredAlert":"")} role="alert">
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                </MDBModalBody>
            </MDBModal>
        )
    }
}