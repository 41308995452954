export const NotificationLevel = [
    {
        Key: 'first_note',
        Value: "717700000"
    },
    {
        Key: 'second_note',
        Value: "717700001"
    },
    {
        Key: 'third_note',
        Value: "717700002"
    },
    {
        Key: 'last_note',
        Value: "717700003"
    },
    {
        Key: 'letter_from_lawyer',
        Value: "717700004"
    },
]