export class ClientTable {
    constructor(element){
        let client = '';
        if(element.lastName){
            if(element.firstName){
                client = element.lastName + ' ' + element.firstName
            }else{
                client = element.lastName
            }
        }else{
            if(element.firstName){
                client = element.firstName;
            }
        }
        let locationC = element.address !== null ? element.address.city : ''
        this.client = client;
        this.id = element.id;
        this.locationC= locationC;
        this.locationP= element.locationP;
        this.patientName= element.patientName;
        this.patient = element.patient;
        this.address = element.address;
        this.cellPhone = element.cellPhone;
        this.correspondenceAddress = element.correspondenceAddress;
        this.correspondenceSameAsMain = element.correspondenceSameAsMain;
        this.email = element.email;
        this.fax = element.fax;
        this.firstName = element.firstName;
        this.lastName = element.lastName;
        this.other = element.other;
        this.phone = element.phone;
        this.statuscode = element.statuscode;
        this.clientStatus = element.clientStatus;
        this.contractEndDate = element.contractEndDate;
        this.contractStartDate = element.contractStartDate;
        this.contractStatus = element.contractStatus;
        this.nextWzStartDate = element.nextWzStartDate;
        this.wzStartDate = element.wzStartDate;
        this.wzEndDate = element.wzEndDate;
    }
};