export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const REMOVE_APP_LANGUAGE = "REMOVE_APP_LANGUAGE";

export const API_CALL = 'API_CALL';
export const NO_API_CALL = 'NO_API_CALL';
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const END_REFRESHING = "END_REFRESHING";

//Request status
export const REFRESH_PENDING = "REFRESH_PENDING";
export const REFRESH_FINISHED = "REFRESH_FINISHED";

//AUTH
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_START = "AUTH_START";
export const UPDATE_ACTION_TIMESTAMP = 'UPDATE_ACTION_TIMESTAMP';
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const REFRESHED_TOKEN = "REFRESHED_TOKEN";
export const CLOSE_POPUP = "CLOSE_POPUP";

// DATABASE
export const SET_LEADS_DATABASE = "SET_LEADS_DATABASE";
export const PENDING_LEADS_DATABASE = "PENDING_LEADS_DATABASE";
export const REMOVE_LEADS_DATABASE = "REMOVE_LEADS_DATABASE";
export const UPDATE_LEAD_DATA = "UPDATE_LEAD_DATA";
export const REMOVE_LEAD_DATA = "REMOVE_LEAD_DATA";
export const SET_PATIENTCLIENT_DATABASE = "SET_PATIENTCLIENT_DATABASE";
export const PENDING_PATIENTCLIENT_DATABASE = "PENDING_PATIENTCLIENT_DATABASE";
export const REMOVE_PATIENTCLIENT_DATABASE = "REMOVE_PATIENTCLIENT_DATABASE";
export const SET_PATIENTLEAD_DATABASE = "SET_PATIENTLEAD_DATABASE";
export const PENDING_PATIENTLEAD_DATABASE = "PENDING_PATIENTLEAD_DATABASE";
export const REMOVE_PATIENTLEAD_DATABASE = "REMOVE_PATIENTLEAD_DATABASE";
export const UPDATE_PATIENT_DATA = "UPDATE_PATIENT_DATA";
export const ADD_PATIENT_DATA = "ADD_PATIENT_DATA";
export const SET_CLIENTS_DATABASE = "SET_CLIENTS_DATABASE";
export const PENDING_CLIENTS_DATABASE = "PENDING_CLIENTS_DATABASE";
export const REMOVE_CLIENTS_DATABASE = "REMOVE_CLIENTS_DATABASE";
export const SET_MULTIPLIERS_DATABASE = "SET_MULTIPLIERS_DATABASE";
export const PENDING_MULTIPLIERS_DATABASE = "PENDING_MULTIPLIERS_DATABASE";
export const REMOVE_MULTIPLIERS_DATABASE = "REMOVE_MULTIPLIERS_DATABASE";
export const SET_INVOICES_DATABASE = "SET_INVOICES_DATABASE";
export const PENDING_INVOICES_DATABASE = "PENDING_INVOICES_DATABASE";
export const REMOVE_INVOICES_DATABASE = "REMOVE_INVOICES_DATABASE";
export const ACCEPT_INVOICES_DATABASE = "ACCEPT_INVOICES_DATABASE";
export const SET_TASKS_DATABASE = "SET_TASKS_DATABASE";
export const PENDING_TASKS_DATABASE = "PENDING_TASKS_DATABASE";
export const UPDATE_TASKS_DATABASE = "UPDATE_TASKS_DATABASE";
export const REMOVE_TASKS_DATABASE = "REMOVE_TASKS_DATABASE";
export const FINISHED_TASKS_DATABASE = "FINISHED_TASKS_DATABASE";
export const UPDATE_TASK_DATA = "UPDATE_TASK_DATA";
export const ADD_TASK_DATA = "ADD_TASK_DATA";
export const CLEAR_ALL_DATABASE = "CLEAR_ALL_DATABASE";
export const ADD_LEAD_DATA = "ADD_LEAD_DATA";
export const UPDATE_MULTIPLIER_DATA = "UPDATE_MULTIPLIER_DATA";
export const ADD_MULTIPLIER_DATA = "ADD_MULTIPLIER_DATA";


//ENUMS
export const SET_DISEASES_ENUM = "SET_DISEASES_ENUM";
export const PENDING_DISEASES_ENUM= "PENDING_DISEASES_ENUM";
export const REMOVE_DISEASES_ENUM = "REMOVE_DISEASES_ENUM";
export const SET_CAREDEGREEAPPLICATION_ENUM = "SET_CAREDEGREEAPPLICATION_ENUM";
export const PENDING_CAREDEGREEAPPLICATION_ENUM= "PENDING_CAREDEGREEAPPLICATION_ENUM";
export const REMOVE_CAREDEGREEAPPLICATION_ENUM = "REMOVE_CAREDEGREEAPPLICATION_ENUM";
export const SET_INVOICEPAYMENT_ENUM = "SET_INVOICEPAYMENT_ENUM";
export const PENDING_INVOICEPAYMENT_ENUM= "PENDING_INVOICEPAYMENT_ENUM";
export const REMOVE_INVOICEPAYMENT_ENUM = "REMOVE_INVOICEPAYMENT_ENUM";
export const SET_CAREOTHERREQUIREMENTS_ENUM = "SET_CAREOTHERREQUIREMENTS_ENUM";
export const PENDING_CAREOTHERREQUIREMENTS_ENUM= "PENDING_CAREOTHERREQUIREMENTS_ENUM";
export const REMOVE_CAREOTHERREQUIREMENTS_ENUM = "REMOVE_CAREOTHERREQUIREMENTS_ENUM";
export const SET_CONTRACTRATETYPE_ENUM = "SET_CONTRACTRATETYPE_ENUM";
export const PENDING_CONTRACTRATETYPE_ENUM= "PENDING_CONTRACTRATETYPE_ENUM";
export const REMOVE_CONTRACTRATETYPE_ENUM = "REMOVE_CONTRACTRATETYPE_ENUM";
export const SET_HEALTHSTATE_ENUM = "SET_HEALTHSTATE_ENUM";
export const PENDING_HEALTHSTATE_ENUM= "PENDING_HEALTHSTATE_ENUM";
export const REMOVE_HEALTHSTATE_ENUM = "REMOVE_HEALTHSTATE_ENUM";
export const SET_TASKPRIORITY_ENUM = "SET_TASKPRIORITY_ENUM";
export const PENDING_TASKPRIORITY_ENUM= "PENDING_TASKPRIORITY_ENUM";
export const REMOVE_TASKPRIORITY_ENUM = "REMOVE_TASKPRIORITY_ENUM";
export const SET_LEADSTATUS_ENUM = "SET_LEADSTATUS_ENUM";
export const PENDING_LEADSTATUS_ENUM= "PENDING_LEADSTATUS_ENUM";
export const REMOVE_LEADSTATUS_ENUM = "REMOVE_LEADSTATUS_ENUM";
export const SET_INVOICEAPPROVALSTATUS_ENUM = "SET_INVOICEAPPROVALSTATUS_ENUM";
export const PENDING_INVOICEAPPROVALSTATUS_ENUM= "PENDING_INVOICEAPPROVALSTATUS_ENUM";
export const REMOVE_INVOICEAPPROVALSTATUS_ENUM = "REMOVE_INVOICEAPPROVALSTATUS_ENUM";
export const SET_CONTRACTSTATUS_ENUM = "SET_CONTRACTSTATUS_ENUM";
export const PENDING_CONTRACTSTATUS_ENUM= "PENDING_CONTRACTSTATUS_ENUM";
export const REMOVE_CONTRACTSTATUS_ENUM = "REMOVE_CONTRACTSTATUS_ENUM";
export const SET_LIVEALONE_ENUM = "SET_LIVEALONE_ENUM";
export const PENDING_LIVEALONE_ENUM= "PENDING_LIVEALONE_ENUM";
export const REMOVE_LIVEALONE_ENUM = "REMOVE_LIVEALONE_ENUM";
export const SET_LEADSOURCE_ENUM = "SET_LEADSOURCE_ENUM";
export const PENDING_LEADSOURCE_ENUM= "PENDING_LEADSOURCE_ENUM";
export const REMOVE_LEADSOURCE_ENUM = "REMOVE_LEADSOURCE_ENUM";
export const SET_ACTIVITYTYPE_ENUM = "SET_ACTIVITYTYPE_ENUM";
export const PENDING_ACTIVITYTYPE_ENUM= "PENDING_ACTIVITYTYPE_ENUM";
export const REMOVE_ACTIVITYTYPE_ENUM = "REMOVE_ACTIVITYTYPE_ENUM";
export const SET_INVOICECORRECTION_ENUM = "SET_INVOICECORRECTION_ENUM";
export const PENDING_INVOICECORRECTION_ENUM= "PENDING_INVOICECORRECTION_ENUM";
export const REMOVE_INVOICECORRECTION_ENUM = "REMOVE_INVOICECORRECTION_ENUM";
export const SET_CAREDEGREE_ENUM = "SET_CAREDEGREE_ENUM";
export const PENDING_CAREDEGREE_ENUM= "PENDING_CAREDEGREE_ENUM";
export const REMOVE_CAREDEGREE_ENUM = "REMOVE_CAREDEGREE_ENUM";
export const SET_SMOKINGATTITUDE_ENUM = "SET_SMOKINGATTITUDE_ENUM";
export const PENDING_SMOKINGATTITUDE_ENUM= "PENDING_SMOKINGATTITUDE_ENUM";
export const REMOVE_SMOKINGATTITUDE_ENUM = "REMOVE_SMOKINGATTITUDE_ENUM";
export const SET_CALLDIRECTION_ENUM = "SET_CALLDIRECTION_ENUM";
export const PENDING_CALLDIRECTION_ENUM= "PENDING_CALLDIRECTION_ENUM";
export const REMOVE_CALLDIRECTION_ENUM = "REMOVE_CALLDIRECTION_ENUM";
export const FINISH_BASIC_DATA_SAVE = "FINISH_BASIC_DATA_SAVE";
export const REMOVE_ALL_BASIC_DATA_SAVE = "REMOVE_ALL_BASIC_DATA_SAVE";
export const SET_CITIES_ENUM = "SET_CITIES_ENUM";
export const PENDING_CITIES_ENUM= "PENDING_CITIES_ENUM";
export const REMOVE_CITIES_ENUM = "REMOVE_CITIES_ENUM";
export const SET_MARKETING_ENUM = "SET_MARKETING_ENUM";
export const PENDING_MARKETING_ENUM= "PENDING_MARKETING_ENUM";
export const REMOVE_MARKETING_ENUM = "REMOVE_MARKETING_ENUM";
export const SET_REJECT_REASON_ENUM = "SET_REJECT_REASON_ENUM";
export const PENDING_REJECT_REASON_ENUM= "PENDING_REJECT_REASON_ENUM";
export const REMOVE_REJECT_REASON_ENUM = "REMOVE_REJECT_REASON_ENUM";

//SOME UNSAVED DATA
export const NEED_SAVE = "NEED_SAVE";
export const DOES_NOT_NEED_SAVE = "DOES_NOT_NEED_SAVE";

// FIREBASE COLUMNS ACTION
export const SET_COLUMNS_DATA = "SET_COLUMNS_DATA";
export const REMOVE_COLUMNS_DATA = "REMOVE_COLUMNS_DATA";
export const ADD_COLUMN_DATA = "ADD_COLUMN_DATA";
export const UPDATE_COLUMN_DATA = "UPDATE_COLUMN_DATA";

// Filters
export const SET_FILTERS_DATA = "SET_FILTERS_DATA";