import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp,setClientsData, pendingClientsData, removeClientsData, setPatientClientData, 
  pendingPatientClientData, removePatientClientData, getPatientClient, } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, EmptyTableIcon} from "../../_components/index";
import { Alert, ClientTable} from "../../_constance/classes/index";
import { shouldRefresh } from "../../_helpers/index";
import { ResponseStatus } from '../../_helpers/response_status'
import axiosAuth from "../../_services/config/axios-auth";
import { refreshTokenTime } from '../../_constance/base_data';
import { ClientPatientDataAddress, ClientPatientDataContactPerson, ClientPatientDataHealthStatus, ClientPatientDataBasics } from "../../_components/ClientDetailsComponenets/client-patient-data/index";
import BBFormService from '../../_services/bbform';
import * as Entity from '../../_forms/BBForm/values/entity';

class ClientPatientPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  invoiceTimeOut;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      errorComponent: Alert,
      errorsMessage: null,
      errorsType: "danger",
      loadingBasics: true,
      clientRetries: 0,
      patientRetries: 0,
      patientId: 0,
      loadingPatient: true,
      patientToShow: '',
      backUpPatient: '',
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {
      this.getClientDataByTimestamp()
    }, 200);
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
    clearTimeout(this.patientTimeout);
  };

  getDataBasic() {
    const { match: { params } } = this.props;
    let found = false;
    for(let i=0;i<this.props.clientsData.length; i++){
      if(this.props.clientsData[i].id===params.id){
        found=true;
        if (this._isMounted) {
          this.setState({
            breadCrumbs:[
              {
                link: "leads",
                key: "clients",
                name: null
              },
              {
                link: 'clientDetails/'+this.props.clientsData[i].id,
                key: "",
                name: this.props.clientsData[i].client
              }
            ]
          })
        }
        this.getPatientDataByTimestamp();
        break;
      }
    }
    if(!found){
      if (this._isMounted) {
        this.setState({
          loadingBasics: false,
          breadCrumbs:[
            {
              link: "clients",
              key: "clients",
              name: null
            },
            {
              link: "clients",
              key: "",
              name: '---'
            }
          ]
        })
      }
      this.getPatientDataByTimestamp();
    }
  }

  getClientsList = () => {
    if(this.props.clientsState !== ResponseStatus.PENDING){
      this.props.pendingRequestClient();
      axiosAuth.get("clients/list/0")
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{
            for(let i=0; i<this.props.patientClientData.length; i++){
              if(this.props.patientClientData[i].clientId===element.id){
                element.locationP = this.props.patientClientData[i].patientAddress ? this.props.patientClientData[i].patientAddress.city:'';
                element.patientName = this.props.patientClientData[i].name;
                element.patient = this.props.patientClientData[i]
              }
            }
            return new ClientTable(element);}
          );
          this.props.setClientData(mapedRes);
        })
        .catch((error) => {
          this.props.clearDataClient();}
        );
    }
  }

  getInitialDataTimeClient(){
    if(this.props.clientsData.length>0){
      clearTimeout(this.myTimeout);
      this.getDataBasic();
    }else{
      if(this.state.clientRetries<10){
        this.getClientsList();
        if (this._isMounted) {
          this.setState({clientRetries: this.state.clientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTimeClient()}, 1000);
      }else{
        if (this._isMounted) {
          this.setState({
            clientsData: 0,
            loadingPatient: false,
            loadingBasics: false
          })
        } 
      }
    }
  }

  getClientDataByTimestamp(){
      const lastUpdate = this.props.clientsTimeStamp;
      const actualTime = new Date().getTime();
      if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
        this.getInitialDataTimeClient();
      } else {
        if(this.props.clientsData.length>0){
          this.getDataBasic();
        }else{
          this.getInitialDataTimeClient();
        }
      }
  }



  getInitialClientPatient(){
    if(this.props.patientClientData.length>0){
      clearTimeout(this.myTimeout);
      this.getMePatientData();
    }else{
      if(this.state.clientRetries<10){
        this.getInitialPatient();
        if (this._isMounted) {
          this.setState({clientRetries: this.state.clientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTimeLead()}, 300);
      }else{
        if (this._isMounted) {
          this.setState({
            clientRetries: 0,
            loadingPatient: false
          })
        }
      }
    }
  }

  getPatientDataByTimestamp(){
    const lastUpdate = this.props.patientClientTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getInitialClientPatient();
    } else {
      if(this.props.patientClientData.length>0){
        this.getMePatientData();
      }else{
        this.getInitialClientPatient();
      }
    }
  }

  getInitialPatient(){
    if(this.props.patientClientStatus === ResponseStatus.READY){
      clearTimeout(this.patientTimeout);
      if (this._isMounted) {
        this.setState({loadingPatient: false})
      }
      this.getMePatientData();
    }else{
      if(this.props.patientClientStatus === ResponseStatus.FREE){
        this.props.getPatientClientData(this.props.appLang);
        this.patientTimeout = setTimeout(() => {
          this.getInitialPatient()
        }, 500);
      }else{
        if(this.props.patientClientStatus === ResponseStatus.ERROR){
          clearTimeout(this.patientTimeout);
          if (this._isMounted) {
            this.setState({ loadingPatient: false })
          }
        }else{
          this.patientTimeout = setTimeout(() => {
            this.getInitialPatient()
          }, 500);
        }
      }
    }
  }

  getMePatientData(){
    const { match: { params } } = this.props;
    if(params.patientId===0 || params.patientId.length!==36){
      if (this._isMounted) {
        this.setState({
          patientId: params.patientId,
          loadingPatient: false,
          breadCrumbs: [
            ...this.state.breadCrumbs,          
            {
              link: "",
              key: "",
              name: '---'
            }
          ]
        })
      }
    }else{
      let patient = this.props.patientClientData.filter((x)=>x.id===params.patientId);
      BBFormService.retrievePatient(this.prepareMeDynamicDataRertive(params.patientId)).then(response => {
        const { data } = response;
        patient[0].HealthStateListResource.is_lying_patient=data[0].fields.is_lying_patient.toLowerCase() === 'true'?true:false;
        if (this._isMounted) {
          this.setState({
            patientId: params.patientId,
            patientToShow: JSON.parse(JSON.stringify(patient[0])),
            backUpPatient: JSON.parse(JSON.stringify(patient[0])),
            loadingPatient: false,
            breadCrumbs: [
              ...this.state.breadCrumbs,          
              {
                link: "",
                key: "",
                name: patient[0].name
              }
            ]
          })
        }
      }).catch(error =>{
        patient[0].HealthStateListResource.is_lying_patient= false;
        if (this._isMounted) {
          this.setState({
            patientId: params.patientId,
            patientToShow: JSON.parse(JSON.stringify(patient[0])),
            backUpPatient: JSON.parse(JSON.stringify(patient[0])),
            loadingPatient: false,
            breadCrumbs: [
              ...this.state.breadCrumbs,          
              {
                link: "",
                key: "",
                name: patient[0].name
              }
            ]
          })
        }
      });
    }
  }

  prepareMeDynamicDataRertive(id){
    return [{
      id: id,
      entity: Entity.PATIENT,
      fields: [
          'is_lying_patient'
      ],
    }]
  }


  render() {
    let PatientData;
    if (this.state.loadingPatient) {
      PatientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.props.patientClientData===[]){
        PatientData = <EmptyTableIcon RefreshMe={() => this.getInitialPatient()} text={this.props.translateKey("no_data_to_show")} />
      }else{
        PatientData = [
          <ClientPatientDataBasics key='ClientPatientDataBasics' element={this.state.patientToShow} />,
          <ClientPatientDataAddress key='ClientPatientDataAddress' element={this.state.patientToShow.patientAddress} />,
          <ClientPatientDataContactPerson key='ClientPatientDataContactPerson' element={this.state.patientToShow.patientContactPersonAddress} />,
          <ClientPatientDataHealthStatus key='ClientPatientDataHealthStatus' element={this.state.patientToShow} />,
        ]
      }
    }
    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper">
            <div className="listData patient-details-main-class-wrapper">
              {PatientData}
            </div>
          </div>
        </div>
        <Prompt
            when={false}
            message={this.props.translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    clientsData: state.database.clients,
    clientsState: state.database.clientsState,
    clientsTimeStamp: state.database.clientsTimeStamp,
    patientClientData: state.database.patientClient,
    patientClientStatus: state.database.patientClientState,
    patientClientTimeStamp: state.database.patientClientTimeStamp
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setClientData: (data) => dispatch(setClientsData(data)),
  pendingRequestClient: () => dispatch(pendingClientsData()),
  clearDataClient: () => dispatch(removeClientsData()),
  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPatientPage));