import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import ListMovementComponent from "../../form-bb-components/list-movement-component/list-movement-component";
import ListComponent from '../../form-bb-components/list-component/list-component';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import {CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import produce from "immer";

class Section11 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-11'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled } = this.props;
        const { section } = this.state;
        const isSondaPeg = section.getFieldByKey('is_sonda_peg');
        const isDehydration = section.getFieldByKey('is_dehydration');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {this.props.translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                            <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-essen" key="is_eating" keyName="is_eating" value={section.getFieldByKey('is_eating').value} validationErrors={section.getFieldByKey('is_eating').validationErrors} />
                            <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-trinken" key="is_drinking" keyName="is_drinking" value={section.getFieldByKey('is_drinking').value} validationErrors={section.getFieldByKey('is_drinking').validationErrors} />
                            <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-schlucken" key="is_chewing_and_swallowing" keyName="is_chewing_and_swallowing" value={section.getFieldByKey('is_chewing_and_swallowing').value} validationErrors={section.getFieldByKey('is_chewing_and_swallowing').validationErrors} />
                            <CheckBoxComponent showValidation={this.props.showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isSondaPeg.value} validationErrors={isSondaPeg.validationErrors} text={this.props.translateKey("form-text-pegsonde")} transKey="form-text-pegsonde" keyName="is_sonda_peg"/>
                            <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={this.props.showValidationErrors}
                                className="form-margin-bottom-fix"
                                transKey="form-text-ernahrungszustand"
                                key="is_nutritional_status"
                                keyName="is_nutritional_status"
                                value={section.getFieldByKey('is_nutritional_status').value}
                                validationErrors={section.getFieldByKey('is_nutritional_status').validationErrors}
                                valuesList={[
                                    { value: SkillRange.UNLIMITED, label: this.props.translateKey("form-text-normal") },
                                    { value: SkillRange.PARTIAL, label: this.props.translateKey("form-text-unterernahrt") },
                                    { value: SkillRange.LIMITED, label: this.props.translateKey("form-text-fettleibig") },
                                ]}
                            />
                            <CheckBoxComponent showValidation={this.props.showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isDehydration.value} validationErrors={isDehydration.validationErrors} text={this.props.translateKey("form-text-entwassert")} transKey="form-text-entwassert" keyName="is_dehydration"/>
                            <div className="hilfsmittel-wrapper form-margin-bottom-fix">
                                <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("form-text-kostform-diat")}</div>
                                <div className="checkboxes_wrapper">
                                    <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-normal"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_normal')} text={this.props.translateKey("form-text-normal")} transKey="form-text-normal" keyName="is_diet_normal" />
                                    <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-vollwert"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_balanced')} text={this.props.translateKey("form-text-vollwert")} transKey="form-text-vollwert" keyName="is_diet_balanced" />
                                    <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-vegetarisch"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_vegetarian')} text={this.props.translateKey("form-text-vegetarisch")} transKey="form-text-vegetarisch" keyName="is_diet_vegetarian"/>
                                    <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-sonderkost"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_special')} text={this.props.translateKey("form-text-sonderkost")} transKey="form-text-sonderkost" keyName="is_diet_special"/>
                                    <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-diabeteskost"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_diet_diabetes')} text={this.props.translateKey("form-text-diabeteskost")} transKey="form-text-diabeteskost" keyName="is_diet_diabetes"/>
                                </div>
                            </div>
                            <ListComponent
                                setTypeChangeToParent={this.setTypeChange}
                                showValidation={this.props.showValidationErrors}
                                className="form-margin-bottom-fix"
                                transKey="form-text-trinkmenge"
                                key="is_taking_drink"
                                keyName="is_taking_drink"
                                value={section.getFieldByKey('is_taking_drink').value}
                                validationErrors={section.getFieldByKey('is_taking_drink').validationErrors}
                                valuesList={[
                                    { value: SkillRange.UNLIMITED, label: this.props.translateKey("form-text-normal") },
                                    { value: SkillRange.PARTIAL, label: this.props.translateKey("form-text-wenig") },
                                    { value: SkillRange.LIMITED, label: this.props.translateKey("form-text-viel") },
                                ]}
                            />
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <InputTypeTextComponent showValidation={this.state.showValidation} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_favorite_food_drink_de')} text={this.props.translateKey("form-text-lieblingsspeisen")} transKey="form-text-lieblingsspeisen" keyName="is_favorite_food_drink_de" />
                                </div>
                            </div>
                        </fieldset>
                    </form>
</div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section11));