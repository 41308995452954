import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  setClientsData,
  pendingClientsData,
  removeClientsData,
  authSetActionTimestamp,
  setPatientClientData,
  pendingPatientClientData,
  removePatientClientData
} from "../../../_redux/actions/index";
import { AlertModalInfo, LoaderTable} from "../../index";
import axiosAuth from "../../../_services/config/axios-auth";
import { refreshTokenTime } from '../../../_constance/base_data';
import { shouldRefresh } from "../../../_helpers/index";
import { Alert, ClientTable} from "../../../_constance/classes/index";
import { ClientsOffertsAndContractors, ClientsActiveContracts, ClientBaseChange, ClientsRejectReasons} from "./charts-and-table/index";


class DashboardClientsPage extends React.Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab,
      tabName: this.props.tabName,
      dashboardData: this.props.dashboardData,
      dashboardLoading: this.props.dashboardLoading,
      loading: true,
      errorComponent: Alert
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0)
    this.getDataBasic();
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  getDataBasic() {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    if (this.props.patientClientData.length === 0) {
      if (this.props.patientClientData.length !== 0 && this.props.patientClientData !== undefined) {
        this.getClientsList();
      } else {
        this.getPatientList();
      }
    } else {
      this.getClientsList();
    }
  }

  getPatientList(){
    this.props.pendingPatientData();
    axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getClientsDataByTimestamp();
      })
      .catch((error) => {
        console.log(error);
        this.props.clearPatientData();
        this.getClientsDataByTimestamp();
      });
  }

  getClientsDataByTimestamp(){
    const lastUpdate = this.props.clientsTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getClientsList();
    }else{
      if(this._isMounted) {
        this.setState({loading: false})
      }
    }
  }

  getClientsList = () => {
    this.props.pendingRequest();
    axiosAuth.get("clients/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          for(let i=0; i<this.props.patientClientData.length; i++){
            if(this.props.patientClientData[i].clientId===element.id){
              element.locationP = this.props.patientClientData[i].patientAddress ? this.props.patientClientData[i].patientAddress.city:'';
              element.patientName = this.props.patientClientData[i].name;
              element.patient = this.props.patientClientData[i]
            }
          }
          return new ClientTable(element);}
        );
        this.props.setData(mapedRes);
        if (this._isMounted) {
          this.setState({loading: false})
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.clearData();
        if (this._isMounted) {
          this.setState({
            loading: false,
            errorComponent: {
              show:true,
              type: "danger",
              message: this.props.translateKey("basic_error_message")
            }
          })
        }
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  render() {
    const { errorComponent, loading} = this.state;
    const { dashboardLoading, dashboardData } = this.props;
    let ModalAlert = (
      <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
    )
    let Content = (
      <LoaderTable />
    );
    if(!loading && !dashboardLoading){
      Content = (
        <React.Fragment>
          <ClientsOffertsAndContractors loading={dashboardLoading} dashboardData={dashboardData}/>
          <ClientBaseChange loading={dashboardLoading} dashboardData={dashboardData}/>
          <ClientsActiveContracts loading={dashboardLoading} dashboardData={dashboardData}/>
          <ClientsRejectReasons loading={dashboardLoading} dashboardData={dashboardData}/>
        </React.Fragment>
      )
    }
    return (
      <div className={"tab-pane fade "+(this.props.activeTab===this.props.tabName?"show active":"")} id={this.props.tabName} role="tabpanel" aria-labelledby={this.props.tabName}>
        <div className="listData tab-list-data">
          {Content}
          {ModalAlert}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    clientsData: state.database.clients,
    clientsTimestamp: state.database.clientsTimeStamp,
    patientClientData: state.database.patientClient,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setData: (data) => dispatch(setClientsData(data)),
  pendingRequest: () => dispatch(pendingClientsData()),
  clearData: () => dispatch(removeClientsData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardClientsPage));