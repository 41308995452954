import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import ReqHelper from '../../../_helpers/request_helper';
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import WrappedAutoSuggestComponent from "../../../_components/wrapped-autosuggest-component/WrappedAutoSuggestComponent";
import produce from "immer";

class Section2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-2'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidMount() {
        const { match: { params }} = this.props;
        const { section } = this.state;
        const isBirthday = section.getFieldByKey('is_birthday');
        const isHeight = section.getFieldByKey('is_height');
        const isWeight = section.getFieldByKey('is_weight');
        const isHomeDoctor = section.getFieldByKey('is_home_doctor');
        if(params.patientId==="0"){
            if(isBirthday.value !== null){isBirthday.value = null;}
            if(isHeight.value !== null){isHeight.value = null;}
            if(isWeight.value !== null){isWeight.value = null;}
            if(isHomeDoctor.value !== null){isHomeDoctor.value = null;}
        }
      }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChangePostalCode = (key, suggestion) => {
        if(typeof suggestion === 'object' && suggestion !== null){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion.value;
                }
                const city = section.getFieldByKey('is_service_address_city');
                if (city) {
                    city.value = suggestion.city;
                }
            });
            this.setState(nextState);
        }else{
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    setTypeChangeNumber = (key, value) => {
        if(value.trim()===''){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
        }else{
            if(ReqHelper.isNumber(parseInt(value.trim()))){
                const nextState = produce(this.state, draftState => {
                    const { section } = draftState;
                    const field = section.getFieldByKey(key);
                    if (field) {
                        field.value = value;
                    }
                });
                this.setState(nextState);
            }
        }
    };

    prepareMeCitySelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }

    render() {
        const { disabled, translateKey, showValidationErrors, form } = this.props;
        const { section } = this.state;
        const isBirthday = section.getFieldByKey('is_birthday');
        const isHeight = section.getFieldByKey('is_height');
        const isWeight = section.getFieldByKey('is_weight');
        const isHomeDoctor = section.getFieldByKey('is_home_doctor');
        const isServiceAddressPostalCode = section.getFieldByKey('is_service_address_postal_code')
        let isdisabledfields = disabled;
        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
        const preparedSelectGroup = this.prepareMeCitySelect();
        if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {isdisabledfields = true}
        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={isdisabledfields}>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_patient_last_name')} text={translateKey("last_name")} transKey="last_name" keyName="is_patient_last_name"/> 
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_patient_first_name')} text={translateKey("first_name")} transKey="first_name" keyName="is_patient_first_name"/> 
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_street')} text={translateKey("street")} transKey="street" keyName="is_service_address_street"/> 
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_street_number')} text={translateKey("form-text-section-0-2")} transKey="form-text-section-0-2" keyName="is_service_address_street_number"/>
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <WrappedAutoSuggestComponent 
                                    value={isServiceAddressPostalCode.value} 
                                    suggestions={preparedSelectGroup}
                                    showValidation={this.state.showValidation}
                                    keyName="is_service_address_postal_code"
                                    validationErrors={isServiceAddressPostalCode.validationErrors}
                                    text={translateKey("postal_code")}
                                    setTypeChangeToParent={this.setTypeChangePostalCode}
                                    disabled={disabled}
                                    className="col-lg-6 form-margin-bottom-fix"
                                /> 
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_house_number')} text={translateKey("apartment_number")} transKey="apartment_number" keyName="is_service_address_house_number"/> 
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_patient_cellphone')} text={translateKey("form-text-section-0-3")} transKey="form-text-section-0-3" keyName="is_patient_cellphone"/> 
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_city')} text={translateKey("city")} transKey="city" keyName="is_service_address_city"/> 
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <WrappedDatePickerComponent
                                    showValidation={showValidationErrors}
                                    keyName="is_birthday"
                                    value={isBirthday.value}
                                    validationErrors={isBirthday.validationErrors}
                                    text={translateKey("born")}
                                    setTypeChangeToParent={this.setTypeChange}
                                    disabled={isdisabledfields}
                                />
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" valueFormatter={ReqHelper.simpleValueFormatter} setTypeChangeToParent={this.setTypeChangeNumber} field={isHeight} text={translateKey("form-text-height")} transKey="form-text-height" keyName="is_height"/>
                                <InputTypeTextComponent showValidation={showValidationErrors} className="col-lg-6 form-margin-bottom-fix" valueFormatter={ReqHelper.simpleValueFormatter} setTypeChangeToParent={this.setTypeChangeNumber} field={isWeight} text={translateKey("weight")} transKey="weight" keyName="is_weight"/>
                            </div>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={showValidationErrors} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isHomeDoctor} text={translateKey("form-text-home-doctor")} transKey="form-text-home-doctor" keyName="is_home_doctor"/> 
                            </div>
 
                        </fieldset>
                    </form>
                </div>
        );
    }
}

const mapStateToProps = state => {return {
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section2));