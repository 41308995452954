export const Rating = [
    {
        Label: 'Ice (Care > 26 weeks)',
        Value: "717700000",
        Hidden: true
    },
    {
        Label: 'Cold (Care < 26 weeks)',
        Value: "717700001",
        Hidden: false
    },
    {
        Label: 'Warm (Care < 12 weeks)',
        Value: "717700002",
        Hidden: false
    },
    {
        Label: 'Hot (Care < 4 weeks)',
        Value: "717700003",
        Hidden: false
    },
    {
        Label: 'Unknown',
        Value: '',
        Hidden: false
    }
]