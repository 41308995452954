import { MockPatientAddress } from './MockPatientAddress'
import { MockDiseaseListResource } from './MockDiseaseListResource'
import { HealthStateListResource } from './HealthStateListResource'
import { CarerPreferences } from './CarerPreferences'
import { Diseases } from './Diseases'
import { PatientContactPersonAddress } from './PatientContactPersonAddress'
import { MockPatientHealth } from './MockPatientHealth'
import { MockStateInfo } from './MockStateInfo'

export const MockLeadPatient = {
    DiseaseListResource: MockDiseaseListResource,
    HealthStateListResource: HealthStateListResource,
    birthdate: 0,
    carerPreferences: CarerPreferences,
    clientId: "00000000-0000-0000-0000-000000000000",
    contractId: "00000000-0000-0000-0000-000000000000",
    disabilityLevel: 0,
    diseases: Diseases,
    hardCase: false,
    id: 0,
    leadId: 0,
    liveAlone: 0,
    lyingPatient: false,
    name: "",
    otherDiseases: '',
    patientAddress: MockPatientAddress,
    patientContactPersonAddress: PatientContactPersonAddress,
    patientHealth: MockPatientHealth,
    phoneOtherRemarks: '',
    salutationLetter: '',
    stateInfo: MockStateInfo,
    weight: 0
};