import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import { TableDateFormat } from "../../../../_components/index";
import * as LanguageSelect from "../../../../_constance/values/languageSelect";

class ClientPatientDataBasicsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
        };
    }

    getMeLiveAloneText(element){
        let finder = this.props.liveAlone.find(x => x.Value.toString() === element.toString());
        if(finder){
            return finder.Label
        }else{
            return '---'
        }
    }

    render() {
        const { element , translateKey, appLang } = this.props;
        if(element.birthdate!==0 || element.weight!==0 || element.liveAlone!==0 || (appLang===LanguageSelect.EN && element.patientAddress.salutationLetter!==null)){
            return (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        {element.birthdate!==null && element.birthdate!==0 &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("born")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            <TableDateFormat timestamp={element.birthdate}/>
                                        </span>
                                    </div>
                                </div>
                        }
                        {element.weight!==null && element.weight!==0 &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("weight")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {element.weight}
                                        </span>
                                    </div>
                                </div>
                        }
                        {element.liveAlone!==null && element.liveAlone!==0 &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("live_alone")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.getMeLiveAloneText(element.liveAlone)}
                                        </span>
                                    </div>
                                </div>
                        }
                        {appLang===LanguageSelect.EN && element.patientAddress.salutationLetter!==null &&
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("salutation_letter")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {element.patientAddress.salutationLetter}
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            );
        }else{
            return '';
        }
    }
}

const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    liveAlone: state.enums.liveAlone
};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPatientDataBasicsComponent));