export const SortingNotes = [
    {
        type: "ASC",
        column: 'Note',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'CreatedBy',
        active: false,
        format: "text"
    },
    {
        type: "ASC",
        column: 'Created',
        active: true,
        format: "date"
    }
];