import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { TableColumHead, TablePagination, LoaderTable, EmptyTableIcon, TaskStatusHandler, TableDateFormat, TaskPriorityConverter} from "../../index";
import { SortingTasks } from "../../../_constance/classes/sortingClasses/index";
import { Paging } from "../../../_constance/classes/index";
import { ReqHelper } from "../../../_helpers/index";
import { baseData } from '../../../_constance/base_data';

class ClientTasksData extends React.Component {
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            id: props.id,
            loading: true,
            sorting: new SortingTasks(),
            pagingItem: Paging,
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
        this.filterOrDataChange();
    }

    componentWillUnmount() {clearTimeout(this.myTimeout);};

    navigateToDetailsTask(element){
        this.props.history.push(`${baseData.subFolders}taskDetails/${element.id}`);
    }

    filterOrDataChange(){
        let finalDataToShow = this.props.tasksData.filter(element => {
            if(element.concerns === this.props.id){
              return element;
            }else{
              return false;
            }
        })
        ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
        this.propsPagingData(finalDataToShow);
        setTimeout(() => {
            let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
            this.setState({
                data: finalData,
                loading: false
            })
        }, 50);
    }
    
    propsSortType(type){
        this.state.sorting.changeColumnByColumn(type);
        this.filterOrDataChange();
    }

    propsPagingData(data){
        if(data.length<=25){
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                totalItems: data.length,
                itemsPerPage: prevState.pagingItem.itemsPerPage,
                currentPage: 1,
                visiblePages: 5,
                pageFrom: 1,
                pageTo: 5,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }else{
            this.setState(prevState => {
            return {
                ...prevState,
                pagingItem: {
                ...prevState.pagingItem,
                totalItems: data.length,
                pageFrom: prevState.pagingItem.pageFrom,
                pageTo: prevState.pagingItem.pageTo,
                currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
                totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
                }
            }
            });
        }
    }

    render() {
        const { translateKey, appLang, status } = this.props;
        const { sorting } = this.state;
        let emptyHandler;
        let tasksData;
        if (this.state.loading) {
            emptyHandler = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
        }else{
            if (this.state.data.length === 0) {
                emptyHandler = <div className="flexCenterItems emptyLoaderMin"><EmptyTableIcon RefreshMe={() => this.props.RefreshMe()} text={translateKey("no_data_to_show")} /></div>
            }else{
                tasksData = this.state.data.filter(item => item.status == status).map(element => (
                    <tr className={"table-row-wrapper cursor-pointer "+(element.ViewedInApplication===false?"not-readed-record":"")} key={Math.random().toString(36).substr(2, 5)} onClick={()=>{this.navigateToDetailsTask(element)}}>
                        {
                        sorting.isColumnVisibleByKey('tasks_status', appLang) &&
                            <td className="table-cell-wrapper"><TaskStatusHandler element={element}/></td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_title', appLang) &&
                            <td className="table-cell-wrapper">{element.title}</td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_status', appLang) &&
                            <td className="table-cell-wrapper">{element.status}</td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_concern', appLang) &&
                            <td className="table-cell-wrapper">{element.concernName}</td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_priority', appLang) &&
                            <td className="table-cell-wrapper"><TaskPriorityConverter priority={element.priority} /></td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_createdDate', appLang) &&
                            <td className="table-cell-wrapper"><TableDateFormat timestamp={element.createdDate}/></td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_dueDate', appLang) &&
                            <td className="table-cell-wrapper"><TableDateFormat timestamp={element.dueDay}/></td>
                        }
                        {
                        sorting.isColumnVisibleByKey('tasks_description', appLang) &&
                            <td className="table-cell-wrapper">{element.description}</td>
                        }
                    </tr>
                ))
            }
        }
        return (
            <div className="client-data-wrapper-tab">
                <div className="listData">
                    <table className="table">
                        <thead>
                            <tr>
                                {
                                    sorting.sortingColumns.map(column => {
                                        if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                                        return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                                        }else{
                                        return null
                                        }
                                    }).filter(element => element !==null )
                                }
                            </tr>
                        </thead>
                        <tbody>{tasksData}</tbody>
                    </table>
                    {emptyHandler}
                    <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()} loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        tasksPriority: state.enums.taskPriority,
        tasksData: state.database.tasks,
        tasksStatus: state.database.tasksState
    };
}
  
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientTasksData));
