import { MockAddress } from './mockAddress'
export const MockClient = {
    address: {...MockAddress},
    cellPhone: "",
    email: "",
    firstName: "",
    fullName: "",
    lastName: "",
    phone: "",
    emailAddress2: "",
    gender: "",
    middleName: "",
    phoneOtherRemarks: "",
    salutationAddress: "",
    salutationLetter: "",
};