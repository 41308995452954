import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { translateKey, authSetActionTimestamp, setLeadsData, pendingLeadsData, removeLeadsData, removeLeadStatusData, getPatientLead, getLeadStatusData, 
  setPatientLeadData, updateLeadData, addPatientData, updatePatientData } from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, EmptyTableIcon, AlertMessage, LeadCancelSaveComponent, LoaderModal} from "../../_components/index";
import { Alert, LeadTable} from "../../_constance/classes/index";
import { shouldRefresh } from "../../_helpers/index";
import { ResponseStatus } from '../../_helpers/response_status'
import axiosAuth from "../../_services/config/axios-auth";
import { savePatientData } from "../../_redux/actions/auth";
import { refreshTokenTime } from '../../_constance/base_data';
import { LeadPatientDiseasesComponent, LeadPatientInformationComponent, LeadPatientCarerComponent, LeadPatientDataBasicsComponent, LeadPatientDataHealthComponent} from "../../_components/LeadDetailsComponents/lead-patient-data/index";
import { MockLeadPatient } from "../../_constance/classes/mockLeadPatient/MockLeadPatient";
import BBFormService from '../../_services/bbform';
import * as Entity from '../../_forms/BBForm/values/entity';
import { baseData } from '../../_constance/base_data';

class LeadPatientPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  invoiceTimeOut;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      errorComponent: Alert,
      errorsMessage: null,
      errorsType: "danger",
      loadingBasics: true,
      leadRetries: 0,
      patientRetries: 0,
      patientId: 0,
      loadingPatient: true,
      patientToShow: '',
      backUpPatient: '',
      editBasic: false,
      savingData: false,
      loaderModal: false,
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {
      this.getLeadsDataByTimestamp()
    }, 200);
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
    clearTimeout(this.patientTimeout);
  };

  getMeStatusText(status){
    let text = '';
    let statuses = this.props.leadsStatus;
    for(let i=0; i<statuses.length; i++){
      if(statuses[i].Value===status.toString()){
        text = statuses[i].Label;
        break;
      }
    }
    return text;
  }

  getMeTextStatus(text){
    let status = '';
    let statuses = this.props.leadsStatus;
    for(let i=0; i<statuses.length; i++){
      if(statuses[i].Label===text){
        status = statuses[i].Value;
        break;
      }
    }
    return status;
  }

  getDataBasic() {
    const { match: { params } } = this.props;
    let found = false;
    for(let i=0;i<this.props.leadsData.length; i++){
      if(this.props.leadsData[i].leadId===params.leadId){
        found=true;
        let name='---';
        if(this.props.leadsData[i].client.lastName!==null){
          name = this.props.leadsData[i].client.lastName;
          if(this.props.leadsData[i].client.firstName!==null){
            name += ' '+this.props.leadsData[i].client.firstName;
          }
        }else{
          if(this.props.leadsData[i].client.firstName!==null){
            name = this.props.leadsData[i].client.firstName;
          }
        }
        if (this._isMounted) {
          this.setState({
            breadCrumbs:[
              {
                link: "leads",
                key: "leads",
                name: null
              },
              {
                link: 'leadDetails/'+this.props.leadsData[i].leadId,
                key: "",
                name: name
              }
            ]
          })
        }
        this.getPatientDataByTimestamp();
        break;
      }
    }
    if(!found){
      if (this._isMounted) {
        this.setState({
          loadingBasics: false,
          breadCrumbs:[
            {
              link: "leads",
              key: "leads",
              name: null
            },
            {
              link: "leads",
              key: "",
              name: '---'
            }
          ]
        })
      }
      this.getPatientDataByTimestamp();
    }
  }

  getLeadsList = () => {
    if(this.props.leadsState!==ResponseStatus.PENDING){
      this.props.pendingRequestLeads();
      axiosAuth.get("leads/list/0/" + this.props.appLang)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{
            element.typeText = this.getMeStatusText(element.status);
            return new LeadTable(element);
          });
          this.props.setLeadsData(mapedRes);
        })
        .catch((error) => {
          this.props.clearDataLeads();
        });
    }
  }

  getInitialDataTimeLead(){
    if(this.props.leadsData.length>0){
      clearTimeout(this.myTimeout);
      this.getDataBasic();
    }else{
      if(this.state.leadRetries<10){
        this.getLeadsList();
        if (this._isMounted) {
          this.setState({leadRetries: this.state.leadRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTimeLead()}, 300);
      }else{
        if (this._isMounted) {
          this.setState({
            leadRetries: 0,
            loadingBasics: false
          })
        }
      }
    }
  }

  getLeadsDataByTimestamp(){
      const lastUpdate = this.props.leadsTimestamp;
      const actualTime = new Date().getTime();
      if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
        this.getInitialDataTimeLead();
      } else {
        if(this.props.leadsData.length>0){
          this.getDataBasic();
        }else{
          this.getInitialDataTimeLead();
        }
      }
  }

  getInitialLeadPatient(){
    if(this.props.patientLeadData.length>0){
      clearTimeout(this.myTimeout);
      this.getMePatientData();
    }else{
      if(this.state.patientRetries<10){
        this.getInitialPatient();
        if (this._isMounted) {
          this.setState({patientRetries: this.state.patientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTimeLead()}, 300);
      }else{
        if (this._isMounted) {
          this.setState({
            patientRetries: 0,
            loadingPatient: false
          })
        }
      }
    }
  }

  getPatientDataByTimestamp(){
    const lastUpdate = this.props.patientLeadTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getInitialLeadPatient();
    } else {
      if(this.props.patientLeadData.length>0){
        this.getMePatientData();
      }else{
        this.getInitialLeadPatient();
      }
    }
  }

  getInitialPatient(){
    if(this.props.patientLeadStatus === ResponseStatus.READY){
      clearTimeout(this.patientTimeout);
      if (this._isMounted) {
        this.setState({loadingPatient: false})
      }
      this.getMePatientData();
    }else{
      if(this.props.patientLeadStatus === ResponseStatus.FREE){
        this.props.getPatientLeadData(this.props.appLang);
        this.patientTimeout = setTimeout(() => {
          this.getInitialPatient()
        }, 500);
      }else{
        if(this.props.patientLeadStatus === ResponseStatus.ERROR){
          clearTimeout(this.patientTimeout);
          if (this._isMounted) {
            this.setState({ loadingPatient: false })
          }
        }else{
          this.patientTimeout = setTimeout(() => {
            this.getInitialPatient()
          }, 500);
        }
      }
    }
  }

  getMePatientData(){
    const { match: { params } } = this.props;
    if(params.patientId===0 || params.patientId.length!==36){
      if (this._isMounted) {
        this.setState({
          patientId: params.patientId,
          patientToShow: JSON.parse(JSON.stringify(MockLeadPatient)),
          backUpPatient: JSON.parse(JSON.stringify(MockLeadPatient)),
          loadingPatient: false,
          breadCrumbs: [
            ...this.state.breadCrumbs,          
            {
              link: "",
              key: "",
              name: '---'
            }
          ]
        })
      }
    }else{
      let patient = this.props.patientLeadData.filter((x)=>x.id===params.patientId);
      BBFormService.retrievePatient(this.prepareMeDynamicDataRertive(params.patientId)).then(response => {
        const { data } = response;
        patient[0].HealthStateListResource.is_lying_patient=data[0].fields.is_lying_patient.toLowerCase() === 'true'?true:false;
        if (this._isMounted) {
          this.setState({
            patientId: params.patientId,
            patientToShow: JSON.parse(JSON.stringify(patient[0])),
            backUpPatient: JSON.parse(JSON.stringify(patient[0])),
            loadingPatient: false,
            breadCrumbs: [
              ...this.state.breadCrumbs,          
              {
                link: "",
                key: "",
                name: patient[0].name
              }
            ]
          })
        }
      }).catch(error =>{
        patient[0].HealthStateListResource.is_lying_patient= false;
        if (this._isMounted) {
          this.setState({
            patientId: params.patientId,
            patientToShow: JSON.parse(JSON.stringify(patient[0])),
            backUpPatient: JSON.parse(JSON.stringify(patient[0])),
            loadingPatient: false,
            breadCrumbs: [
              ...this.state.breadCrumbs,          
              {
                link: "",
                key: "",
                name: patient[0].name
              }
            ]
          })
        }
      });
    }
  }

  prepareMeDynamicDataRertive(id){
    return [{
      id: id,
      entity: Entity.PATIENT,
      fields: [
          'is_lying_patient'
      ],
    }]
  }

  prepareMeDynamicDataSave(id, element){
    return [{
      id: id,
      entity: Entity.PATIENT,
      fields: {
          'is_lying_patient': element.HealthStateListResource.is_lying_patient
      },
    }]
  }

  editedData(){
    if(!this.state.editBasic){
      if (this._isMounted) {
        this.setState({editBasic: true})
      }
    }
  }

  cancelChange(){
    if (this._isMounted) {
      this.setState({
        patientToShow: JSON.parse(JSON.stringify(this.state.backUpPatient)),
        editBasic: false
      })
    }
  }

  saveDynamicData(id, elementToSave){
    BBFormService.savePatient(this.prepareMeDynamicDataSave(id, elementToSave))
    .then(() => {
      if (this._isMounted) {
        this.setState({
          loaderModal: false,
          errorsMessage: "patient_successfully_saved",
          errorsType: "success",
          editBasic: false,
        })    
      }  
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            errorsMessage: null,
            errorsType: "danger",
            showDetails: false
          }) 
        }
      }, 1500);
    })
    .catch(err => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              loaderModal: false,
              errorsMessage: "patient_not_saved",
              errorsType: "danger"
          }
        });
      }
      clearTimeout(this.myTimeout);
      this.myTimeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            errorsMessage: null,
            errorsType: "danger"
          }) 
        }
      }, 3000);
    });
  }

  saveChange(){
    if(this.state.editBasic){
        if (this._isMounted) {
          this.setState({loaderModal:true})
        }
        let elementToSave = this.state.patientToShow;
        if(elementToSave.id===0){
            const { match: { params } } = this.props;
            elementToSave.leadId =  params.leadId
        }
        this.props.savePatientData(elementToSave)
        .then((res)=>{
          if(elementToSave.id===0){
            this.props.addPatient(res, elementToSave);
            this.saveDynamicData(res, elementToSave);
            if (this._isMounted) {
              this.setState(prevState => {
                return {
                    ...prevState,
                    patientToShow: {
                      ...prevState.patientToShow,
                      id: res
                    }
                }
              });
            }
            this.props.history.push(`${baseData.subFolders}leadPatient/${elementToSave.leadId}/${res}`);
          }else{
            this.props.updatePatient(elementToSave.id, elementToSave);
            this.saveDynamicData(elementToSave.id, elementToSave);
          }
        })
        .catch((err)=>{
            if (this._isMounted) {
              this.setState(prevState => {
                return {
                    ...prevState,
                    loaderModal: false,
                    errorsMessage: "patient_not_saved",
                    errorsType: "danger"
                }
              });
            }
            clearTimeout(this.myTimeout);
            this.myTimeout = setTimeout(() => {
              if (this._isMounted) {
                this.setState({
                  errorsMessage: null,
                  errorsType: "danger"
                }) 
              }
            }, 3000);
        })
    }
  }

  render() {
    let SaveAlert;
    if(this.state.errorsMessage!==null){
      SaveAlert = (
        <AlertMessage centeredAlert={true} show={this.state.errorsMessage!==null} key="leadAlertComponent02" type={this.state.errorsType} message={this.props.translateKey(this.state.errorsMessage)}/>
      )
    }
    let PatientData;
    if (this.state.loadingPatient) {
      PatientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.props.patientData===[]){
        PatientData = <EmptyTableIcon RefreshMe={() => this.getInitialPatient()} text={this.props.translateKey("no_data_to_show")} />
      }else{
        PatientData = [
          <LeadPatientDataBasicsComponent key='LeadPatientDataBasicsComponent' editedData={()=>this.editedData()} element={this.state.patientToShow} />,
          // FP-573 <LeadPatientDataHealthComponent key='LeadPatientDataHealthComponent'editedData={()=>this.editedData()} element={this.state.patientToShow} />,
          <LeadPatientDiseasesComponent key={Math.random().toString(36).substr(2, 9)} editedData={()=>this.editedData()} element={this.state.patientToShow} />,
          <LeadPatientInformationComponent key={Math.random().toString(36).substr(2, 9)} editedData={()=>this.editedData()} element={this.state.patientToShow} />,
          <LeadPatientCarerComponent key='LeadPatientCarerComponent' editedData={()=>this.editedData()} element={this.state.patientToShow} />,
          <LeadCancelSaveComponent saveChange={()=> this.saveChange()} cancelChange={()=> this.cancelChange()} key="leadCancelSaveComponent02" edited={this.state.editBasic} savingData={this.state.savingDataLead}/>,
          SaveAlert
        ]
      }
    }

    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <LoaderModal show={this.state.loaderModal}/>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper">
            <div className="listData patient-details-main-class-wrapper">
              {PatientData}
            </div>
          </div>
        </div>
        <Prompt
            when={false}
            message={this.props.translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsStatus: state.enums.leadStatus,
    leadsState: state.database.leadsState,
    leadsData: state.database.leads,
    leadsSourceStatus: state.enums.leadSource,
    leadsTimestamp: state.database.leadsTimeStamp,
    patientLeadData: state.database.patientLead,
    patientLeadStatus: state.database.patientLeadState,
    patientLeadTimeStamp: state.database.patientLeadTimeStamp,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
  setPatientLeadData: (data) => dispatch(setPatientLeadData(data)),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  updateLeadDataFun: (leadId, data) => dispatch(updateLeadData(leadId, data)),
  setLeadsData: (data) => dispatch(setLeadsData(data)),
  pendingRequestLeads: () => dispatch(pendingLeadsData()),
  clearDataLeads: () => dispatch(removeLeadsData()),
  savePatientData: (data) => dispatch(savePatientData(data)),
  addPatient: (id, data) => dispatch(addPatientData(id, data)),
  updatePatient: (id, data) => dispatch(updatePatientData(id, data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientPage));