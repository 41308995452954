import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import produce from "immer";
import { translateKey, getPatientLead, getPatientClient, removeOneLeadData} from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import { ReqHelper } from "../../../_helpers/index";
import WrappedDatePickerComponent from "../wrapped-date-picker-component/wrapped-date-picker-component";
import {InputTypeTextComponent} from "../input-type-text-component/input-type-text-component";
import ContractForm from "../../../_forms/ContractForm/ContractForm";
import SelectComponent from "../select-component/SelectComponent";
import BBFormService from '../../../_services/bbform';
import ListComponent from "../list-component/list-component";
import * as Entity from '../../../_forms/BBForm/values/entity';
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import { ResponseStatus } from '../../../_helpers/response_status';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import WrappedAutoSuggestComponent from "../../../_components/wrapped-autosuggest-component/WrappedAutoSuggestComponent";
import * as LanguageSelect from "../../../_constance/values/languageSelect";

class ContractComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionList: this.props.sectionList,
            activeSection: this.props.activeSection,
            newOne: this.props.newOne,
            products: this.props.products,
            regionsFP: this.props.regionsFP,
            selectedProduct: null,
            testValue: '',
            offerId: 0,
            form: new ContractForm(),
            showValidation: false,
            isFranchiseRegionText: null,
            isFranchiseRegionOtherText: null,
            patientIdList: [],
            regionsRetries: 0,
            is_driving_licence: null,
            is_second_ooo: null,
            is_transfer: null,
            is_carer_with_diploma: null,
            is_additional_foreign_language: null
        };
    }

    componentDidMount() {  
        const { match: { params }, newOne, appLang } = this.props;
        const { form } = this.state;  
        let offerId = 0;
        if(!newOne){
            this.setState({offerId: params.patientId})
            offerId = params.patientId;
            BBFormService.retrievePatient(form.getRetiveDataALl(params.id, offerId)).then(response => {
                const { data } = response;
                data.forEach(entity => form.setFields(entity.fields));
                this.setState({ rerender: Date.now() });
                const isProductId = form.getFieldByKey('is_product_id');
                if(appLang === LanguageSelect.DE){
                    const fregField = form.getFieldByKey('is_frequency_of_invoicing');
                    if(fregField.value === null || fregField.value === ''){
                        fregField.value = '717700003';
                    }
                }
                if(isProductId.value!==null){
                    this.setMeProductState(isProductId.value);
                }
                this.setMeInitValuesAddition();
            }).then(()=>{
                this.prepareMeFranchiseRegion();
            });
        }else{
            if(appLang === LanguageSelect.DE){
                const fregField = form.getFieldByKey('is_frequency_of_invoicing');
                if(fregField.value === null || fregField.value === ''){
                    fregField.value = '717700003';
                }
            }
            this.prepareMeFranchiseRegion();
        }
        this.getInitialPatient();
        document.body.classList.remove('modal-open');
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.newOne !== this.props.newOne){
            this.setState({
                form: new ContractForm(),
                offerId: 0
            })
        }
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({showValidation: this.props.showValidationErrors});
        }
    }

    setMeInitValuesAddition = () =>{
        const { form } = this.state;
        this.setState({
            is_driving_licence: form.getFieldByKey("is_driving_licence").value,
            is_second_ooo: form.getFieldByKey("is_second_ooo").value,
            is_transfer: form.getFieldByKey("is_transfer").value,
            is_carer_with_diploma: form.getFieldByKey("is_carer_with_diploma").value,
            is_additional_foreign_language: form.getFieldByKey("is_additional_foreign_language").value
        })   
    }

    prepareMeAddition = () => {
        const { form, is_driving_licence, is_second_ooo, is_transfer, is_carer_with_diploma, is_additional_foreign_language } = this.state;
        let isDrivingLicenceForm = form.getFieldByKey("is_driving_licence");
        if(isDrivingLicenceForm.value === '717700001' || isDrivingLicenceForm.value === null){
            if(is_driving_licence !== '717700000'){
                isDrivingLicenceForm.value = null;
            }
        }

        let isSecondOoo = form.getFieldByKey("is_second_ooo");
        if(isSecondOoo.value === '717700001' || isSecondOoo.value === null){
            if(is_second_ooo !== '717700000'){
                isSecondOoo.value = null;
            }
        }

        let isTransfer = form.getFieldByKey("is_transfer")
        if(isTransfer.value === '717700001' || isTransfer.value === null){
            if(is_transfer !== '717700000'){
                isTransfer.value = null;
            }
        }

        let isCarerWithDiploma= form.getFieldByKey("is_carer_with_diploma");
        if(isCarerWithDiploma.value === '717700001' || isCarerWithDiploma.value === null){
            if(is_carer_with_diploma !== '717700000'){
                isCarerWithDiploma.value = null;
            }
        }

        let isAdditionalForeignLanguage = form.getFieldByKey("is_additional_foreign_language");
        if(isAdditionalForeignLanguage.value === '717700001' || isAdditionalForeignLanguage.value === null){
            if(is_additional_foreign_language !== '717700000'){
                isAdditionalForeignLanguage.value = null;
            }
        }
    }

    getInitialPatient(){
        if(this.props.mainType==="CLIENT"){
            if(this.props.patientClientStatus === ResponseStatus.READY){
                if(this.props.wholeResponse===null){
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }else{
                    clearTimeout(this.myTimeout);
                    const { match: { params } } = this.props;
                    let finalPatientList = this.props.patientClientData.filter(element => {
                        if(element.clientId === params.id){
                            return true;
                        }else{
                            return false;
                        }
                    })
                    let patientList = [];
                    if(params.patientId !=='0' && params.patientId !==0){
                        patientList = finalPatientList.filter(element =>{
                            if(element.contractId === params.patientId){
                                return this.checkIfPatientIsValid(element.id);
                            }else{
                                return false
                            }
                        }).map(element => element.id);
                        if(patientList.length===0){
                            patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                        }
                    }else{
                        patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                    }
                    this.setState({
                        loadingPatients: false,
                        patientIdList: patientList
                    })
                    if(this.state.patientIdList.length>0){
                        setTimeout(() => {
                            this.checkIfPatientIsValid(this.state.patientIdList[0])
                        }, 500);
                    }
                }
            }else{
                if(this.props.patientClientStatus === ResponseStatus.FREE){
                    this.props.getPatientClientData(this.props.appLang);
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }else{
                    if(this.props.patientClientStatus === ResponseStatus.ERROR){
                    clearTimeout(this.myTimeout);
                    this.setState({ loadingPatients: false })
                    }else{
                        this.myTimeout = setTimeout(() => {
                            this.getInitialPatient()
                        }, 500);
                    }
                }
            }
        }else{
            if(this.props.patientLeadStatus === ResponseStatus.READY){
                if(this.props.wholeResponse===null){
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                }else{
                    clearTimeout(this.myTimeout);
                    const { match: { params } } = this.props;
                    let finalPatientList = this.props.patientLeadData.filter(element => {
                        if(element.leadId === params.id){
                            return true;
                        }else{
                            return false;
                        }
                    })
                    let patientList = [];
                    if(params.patientId !=='0' && params.patientId !==0){
                        patientList = finalPatientList.filter(element =>{
                            if(element.contractId === params.patientId){
                                return this.checkIfPatientIsValid(element.id);
                            }else{
                                return false
                            }
                        }).map(element => element.id);
                        if(patientList.length===0){
                            patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                        }
                    }else{
                        patientList = finalPatientList.filter(element => this.checkIfPatientIsValid(element.id)).map(element => element.id);
                    }
                    this.setState({
                        loadingPatients: false,
                        patientIdList: patientList
                    })
                    if(this.state.patientIdList.length>0){
                        setTimeout(() => {
                            this.checkIfPatientIsValid(this.state.patientIdList[0])
                        }, 500);
                    }
                }
            }else{
                if(this.props.patientLeadStatus === ResponseStatus.FREE){
                    this.props.getPatientLeadData(this.props.appLang);
                    this.myTimeout = setTimeout(() => {
                    this.getInitialPatient()
                    }, 500);
                }else{
                    if(this.props.patientLeadStatus === ResponseStatus.ERROR){
                    clearTimeout(this.myTimeout);
                    this.setState({ loadingPatients: false })
                    }else{
                    this.myTimeout = setTimeout(() => {
                        this.getInitialPatient()
                    }, 500);
                    }
                }
            }
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    setTypeChangePostalCode = (key, suggestion) => {
        if(typeof suggestion === 'object' && suggestion !== null){
            const nextState = produce(this.state, draftState => {
                const { form } = draftState;
                const field = form.getFieldByKey(key);
                if (field) {
                    field.value = suggestion.value;
                }
                const city = form.getFieldByKey('is_payer_registered_city');
                if (city) {
                    city.value = suggestion.city;
                }
            });
            this.setState(nextState);
        }else{
            const nextState = produce(this.state, draftState => {
                const { form } = draftState;
                const field = form.getFieldByKey(key);
                if (field) {
                    field.value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChangePostalCodePayerCorrespondence = (key, suggestion) => {
        if(typeof suggestion === 'object' && suggestion !== null){
            const nextState = produce(this.state, draftState => {
                const { form } = draftState;
                const field = form.getFieldByKey(key);
                if (field) {
                    field.value = suggestion.value;
                }
                const city = form.getFieldByKey('is_payer_correspondence_city');
                if (city) {
                    city.value = suggestion.city;
                }
            });
            this.setState(nextState);
        }else{
            const nextState = produce(this.state, draftState => {
                const { form } = draftState;
                const field = form.getFieldByKey(key);
                if (field) {
                    field.value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChangeWithKey(key, value){
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    setTypeChangeProduct= (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
        let selectedProductData = this.props.products.filter(element => {
            if(element.ProductId === value){
                return true;
            }else{
                return false;
            }
        })
        this.setState({selectedProduct: selectedProductData[0]})
        const { form } = this.state;
        form.getFieldByKey('is_rate_settlement').value = selectedProductData[0].RateSettlement;
    };

    setMeProductState = (value) =>{
        let selectedProductData = this.props.products.filter(element => {
            if(element.ProductId === value){
                return true;
            }else{
                return false;
            }
        })
        if(selectedProductData.length>0){
            this.setState({selectedProduct: selectedProductData[0]})
            const { form } = this.state;
            form.getFieldByKey('is_rate_settlement').value = selectedProductData[0].RateSettlement;
        }
    }

    setTypeIfNumber(key, value){
        if(ReqHelper.isNumber(parseInt(value))){
            const nextState = produce(this.state, draftState => {
                const { form } = draftState;
                const field = form.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
        }
    }

    giveMePatientId(){
        const { match: { params } } = this.props;
        if(this.props.mainType==="CLIENT"){
            let finalPatientList = this.props.patientClientData.filter(element => {
                if(element.clientId === params.id){
                    return true;
                }else{
                  return false;
                }
            })
            if(finalPatientList.length>0){
                return finalPatientList[0].id
            }else{
                return 0;
            }
        }else{
            let finalPatientList = this.props.patientLeadData.filter(element => {
                if(element.leadId === params.id){
                    return true;
                }else{
                  return false;
                }
            })
            if(finalPatientList.length>0){
                return finalPatientList[0].id
            }else{
                return 0;
            }
        }
    }

    checkIfPatientIsValid = (id) => {
        let patientWIthId = this.props.wholeResponse.filter(entity => entity.entity===Entity.PATIENT && entity.id === id);
        if(patientWIthId.length>0){
            this.props.bbform.setFields(patientWIthId[0].fields);
            return this.props.bbform.isValid;
        }
        return false
    }

    changePatientIdList = (id) =>{
        if(this.state.patientIdList.indexOf(id) !== -1){
            if(this.state.patientIdList.length===1){
                this.props.showMessage("danger", this.props.translateKey("form-at-least-one-patient"));
            }else{
                this.setState(prevState => {
                    return {
                        ...prevState,
                            patientIdList: prevState.patientIdList.filter(item => item!==id)
                        }
                    }
                );
            }
        }else{
            if(this.checkIfPatientIsValid(id)){
                this.setState(prevState => {
                    return {
                        ...prevState,
                            patientIdList: [...prevState.patientIdList,  id]
                        }
                    }
                );
            }else{
                this.props.showMessage("danger", this.props.translateKey("form-text-not-every-section-valid"));
            }
        }
    }

    prepareMePatientList = () =>{
        const { match: { params }, translateKey } = this.props;
        if(this.props.mainType === "CLIENT"){
            let finalPatientList = this.props.patientClientData.filter(element => {
                if(element.clientId === params.id){
                    return true;
                }else{
                return false;
                }
            })
            if(finalPatientList.length>1){
                let prepareValueList = finalPatientList.map((element)=>{
                    return {value: element.id, label: (element.name!=="" && element.name!==null)?element.name:"---"};
                });
                return (
                    <div className="hilfsmittel-wrapper">
                        <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("patients")}</div>
                        {prepareValueList.map((element)=>{
                            return (
                                <div className="custom-control custom-checkbox" key={Math.random().toString(36).substr(2, 9)}>
                                    <input className="form-check-input" checked={this.state.patientIdList.indexOf(element.value) !== -1} onChange={() => this.changePatientIdList(element.value)} value={element.value} type="checkbox" id={element.value}/>
                                    <label className="form-check-label cursor-pointer break-word-all" htmlFor={element.value}>{element.label}</label>
                                </div>
                            )
                        })}
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-text-err_required')}</div>
                            </div>
                        }
                    </div>
                )
            }else{
                return (
                    <React.Fragment>
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-error-no-patient')}</div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }else{
            let finalPatientList = this.props.patientLeadData.filter(element => {
                if(element.leadId === params.id){
                    return true;
                }else{
                return false;
                }
            })
            if(finalPatientList.length>1){
                let prepareValueList = finalPatientList.map((element)=>{
                    return {value: element.id, label: (element.name!=="" && element.name!==null)?element.name:"---"};
                });
                return (
                    <div className="hilfsmittel-wrapper">
                        <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("patients")}</div>
                        {prepareValueList.map((element)=>{
                            return (
                                <div className="custom-control custom-checkbox" key={element.value}>
                                    <input className="form-check-input" checked={this.state.patientIdList.indexOf(element.value) !== -1} onChange={() => this.changePatientIdList(element.value)} value={element.value} type="checkbox" id={element.value}/>
                                    <label className="form-check-label cursor-pointer break-word-all" htmlFor={element.value}>{element.label}</label>
                                </div>
                            )
                        })}
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-text-err_required')}</div>
                            </div>
                        }
                    </div>
                )
            }else{
                return (
                    <React.Fragment>
                        {this.state.showValidation===true && this.state.patientIdList.length<1 &&
                            <div className="invalid-feedback showError custom-error-padding-fix">
                                <div>{translateKey('form-error-no-patient')}</div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    }

    saveOffer = () => {
        if(this.props.mainType !== "CLIENT"){
            this.props.showSaveModal();
            const { bbform, coopId, history } = this.props;
            const { form } = this.state;
            const { match: { params } } = this.props;
            form.getFieldByKey('is_contact_patientID').value = params.id;
            form.getFieldByKey('is_primary_patientid').value = this.giveMePatientId();
            form.getFieldByKey('is_cooperation_condition_id').value = coopId;
            if(params.patientId!=='0'){
                form.getFieldByKey('is_inquiry_based_contract').value="true";
            }else{
                form.getFieldByKey('is_inquiry_based_contract').value="false";
            }
            const isDegreeRelationshipWithPatientCarer = bbform.getFieldByKey('is_degree_relationship_with_patient_carer');
            if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
                form.getFieldByKey('is_identical_patient_data').value = '717700001';
                form.getFieldByKey('is_first_name').value = bbform.getFieldByKey('firstname').value;
                form.getFieldByKey('is_last_name').value = bbform.getFieldByKey('lastname').value;
                form.getFieldByKey('is_street').value = bbform.getFieldByKey('is_registered_street').value;
                form.getFieldByKey('is_house_number').value = bbform.getFieldByKey('is_registered_street_number').value;
                form.getFieldByKey('is_appartment_number').value = bbform.getFieldByKey('is_registered_house_number').value;
                form.getFieldByKey('is_zip_postal_code').value = bbform.getFieldByKey('is_registered_zip_postal_code').value;
                form.getFieldByKey('is_city').value = bbform.getFieldByKey('is_registered_city').value;
            }else{
                form.getFieldByKey('is_identical_patient_data').value = '717700000'
                form.getFieldByKey('is_first_name').value = bbform.getFieldByKey('is_patient_first_name').value;
                form.getFieldByKey('is_last_name').value = bbform.getFieldByKey('is_patient_last_name').value;
                form.getFieldByKey('is_street').value = bbform.getFieldByKey('is_service_address_street').value;
                form.getFieldByKey('is_house_number').value = bbform.getFieldByKey('is_service_address_street_number').value;
                form.getFieldByKey('is_appartment_number').value = bbform.getFieldByKey('is_service_address_house_number').value;
                form.getFieldByKey('is_zip_postal_code').value = bbform.getFieldByKey('is_service_address_postal_code').value;
                form.getFieldByKey('is_city').value = bbform.getFieldByKey('is_service_address_city').value;
            }

            const payerData = form.getFieldByKey('is_identical_payer_data').value
            if (payerData === '717700002') {
                form.getFieldByKey('is_payer_first_name').value = bbform.getFieldByKey('firstname').value;
                form.getFieldByKey('is_payer_last_name').value = bbform.getFieldByKey('lastname').value;
                form.getFieldByKey('is_payer_registered_street').value = bbform.getFieldByKey('is_registered_street').value;
                form.getFieldByKey('is_payer_registered_house_number').value = bbform.getFieldByKey('is_registered_street_number').value;
                form.getFieldByKey('is_payer_registered_appartment_number').value = bbform.getFieldByKey('is_registered_house_number').value;
                form.getFieldByKey('is_payer_registered_zip_code').value = bbform.getFieldByKey('is_registered_zip_postal_code').value;
                form.getFieldByKey('is_payer_registered_city').value = bbform.getFieldByKey('is_registered_city').value;
                form.getFieldByKey('is_payer_email').value = bbform.getFieldByKey('is_email').value;
                form.getFieldByKey('is_payer_mobile_phone').value = bbform.getFieldByKey('is_mobile_phone').value;
            }else{
                if(payerData === '717700001'){
                    form.getFieldByKey('is_payer_first_name').value = bbform.getFieldByKey('is_patient_first_name').value;
                    form.getFieldByKey('is_payer_last_name').value = bbform.getFieldByKey('is_patient_last_name').value;
                    form.getFieldByKey('is_payer_registered_street').value = bbform.getFieldByKey('is_service_address_street').value;
                    form.getFieldByKey('is_payer_registered_house_number').value = bbform.getFieldByKey('is_service_address_street_number').value;
                    form.getFieldByKey('is_payer_registered_appartment_number').value = bbform.getFieldByKey('is_service_address_house_number').value;
                    form.getFieldByKey('is_payer_registered_zip_code').value = bbform.getFieldByKey('is_service_address_postal_code').value;
                    form.getFieldByKey('is_payer_registered_city').value = bbform.getFieldByKey('is_service_address_city').value;
                }
            }

            const InvoiceDeliveryData = form.getFieldByKey('is_identical_invoice_delivery_address').value
            if (InvoiceDeliveryData === '717700000') {
                form.getFieldByKey('is_payer_correspondence_first_name').value = bbform.getFieldByKey('firstname').value;
                form.getFieldByKey('is_payer_correspondence_last_name').value = bbform.getFieldByKey('lastname').value;
                form.getFieldByKey('is_payer_correspondence_street').value = bbform.getFieldByKey('is_registered_street').value;
                form.getFieldByKey('is_payer_correspondence_house_number').value = bbform.getFieldByKey('is_registered_street_number').value;
                form.getFieldByKey('is_payer_correspondance_appartment_number').value = bbform.getFieldByKey('is_registered_house_number').value;
                form.getFieldByKey('is_payer_correspondence_zip_code').value = bbform.getFieldByKey('is_registered_zip_postal_code').value;
                form.getFieldByKey('is_payer_correspondence_city').value = bbform.getFieldByKey('is_registered_city').value;
            }else{
                if(InvoiceDeliveryData === '717700001'){
                    form.getFieldByKey('is_payer_correspondence_first_name').value = form.getFieldByKey('is_payer_first_name').value;
                    form.getFieldByKey('is_payer_correspondence_last_name').value = form.getFieldByKey('is_payer_last_name').value;
                    form.getFieldByKey('is_payer_correspondence_street').value = form.getFieldByKey('is_payer_registered_street').value;
                    form.getFieldByKey('is_payer_correspondence_house_number').value = form.getFieldByKey('is_payer_registered_house_number').value;
                    form.getFieldByKey('is_payer_correspondance_appartment_number').value = form.getFieldByKey('is_payer_registered_appartment_number').value;
                    form.getFieldByKey('is_payer_correspondence_zip_code').value = form.getFieldByKey('is_payer_registered_zip_code').value;
                    form.getFieldByKey('is_payer_correspondence_city').value = form.getFieldByKey('is_payer_registered_city').value;
                }
            }
            if (form.isValid && this.state.patientIdList.length>0) {
                this.prepareMeAddition();
                BBFormService.savePatient(form.getPayload(params.id, this.state.offerId)).then(response => {
                    const { data } = response;
                    let newofferID = 0;
                    data.forEach(element => {if(element.entity === Entity.CONTRACT){
                        newofferID = element.id
                    }});
                    this.props.setNewLeadStatus(form.getFieldByKey('is_customer_status_de').value);
                    let prepareMePatientToAssign = {
                        "ContractId": newofferID,
                        "Patients": this.state.patientIdList
                    }
                    BBFormService.assignPatients(prepareMePatientToAssign).then(() => {
                        this.props.getPatientLeadData(this.props.appLang);
                        this.props.hideSaveModal();
                        this.props.showMessage("success", this.props.translateKey("form-data-saved"));
                        this.props.getPatientClientData(this.props.appLang);
                        this.props.removeLead(params.id);
                        history.push(`${baseData.subFolders}leads`);
                        this.setState({
                            offerId: newofferID,
                            rejectModal: false
                        });
                    }).catch(error => {
                        this.props.hideSaveModal();
                        this.props.showMessage("danger", this.props.translateKey("form-error-data-saved-failed"));
                        console.log(error);
                    });
                }).catch(error => {
                    this.props.hideSaveModal();
                    this.props.showMessage("danger", this.props.translateKey("form-error-data-saved-failed"));
                    console.log(error);
                });
            } else {
                this.setState({showValidation: true});
                this.props.hideSaveModal();
            }
        }
    };

    prepareMeFranchiseRegion = () =>{
        const { bbform } = this.props;
        const { form } = this.state;
        const isDegreeRelationshipWithPatientCarer = bbform.getFieldByKey('is_degree_relationship_with_patient_carer');
        if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
            form.getFieldByKey('is_zip_postal_code').value = bbform.getFieldByKey('is_registered_zip_postal_code').value;
        }else{
            form.getFieldByKey('is_zip_postal_code').value = bbform.getFieldByKey('is_service_address_postal_code').value;
        }
        if(this.props.regionsFP.length!==0 && this.state.regionsRetries<10){
            let zipCodeValue = form.getFieldByKey('is_zip_postal_code').value;
            let todayTimestamp = Math.floor(new Date().getTime() / 1000);
            let dateFilter = this.props.regionsFP.filter(element => {
                if(todayTimestamp >= element.RegionInclusionDate){
                    if(element.RegionExclusionDate!==null && element.RegionExclusionDate!==0){
                        if(todayTimestamp <= element.RegionExclusionDate){
                            return true
                        }else{
                            return false
                        }
                    }else{
                        return true
                    }
                }else{
                    return false
                }
            });
            let isZipCodeTheSame = dateFilter.filter(element => {
                if(element.RegionPostalCodes!==null){
                    let splitArray = element.RegionPostalCodes.split(",");
                    splitArray = splitArray.filter(code => {
                        return code.trim() === zipCodeValue
                    })
                    if(splitArray.length>0){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            });
            if(isZipCodeTheSame.length>0){
                if(isZipCodeTheSame[0].RegionAssignedToUser === true){
                    form.getFieldByKey('is_franchise_regionid').value = isZipCodeTheSame[0].RegionId
                    form.getFieldByKey('is_other_region_franchiseid').value = null;
                    this.setState({
                        isFranchiseRegionText: isZipCodeTheSame[0].RegionName,
                        isFranchiseRegionOtherText: null
                    })
                }else{
                    let regionsAssigned = this.props.regionsFP.filter(element => element.RegionAssignedToUser === true);
                    if(regionsAssigned.length >1){
                        regionsAssigned.sort((a, b) => a.CreatedDate > b.CreatedDate ? -1 : (a.CreatedDate < b.CreatedDate ? 1 : 0))
                        form.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
                        form.getFieldByKey('is_other_region_franchiseid').value = isZipCodeTheSame[0].RegionId;
                        this.setState({
                            isFranchiseRegionText: regionsAssigned[0].RegionName,
                            isFranchiseRegionOtherText: isZipCodeTheSame[0].RegionName
                        })
                    }else{
                        if(regionsAssigned.length === 1){
                            form.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
                            form.getFieldByKey('is_other_region_franchiseid').value = isZipCodeTheSame[0].RegionId;
                            this.setState({
                                isFranchiseRegionText: regionsAssigned[0].RegionName,
                                isFranchiseRegionOtherText: isZipCodeTheSame[0].RegionName
                            })
                        }else{
                            form.getFieldByKey('is_franchise_regionid').value = "";
                            form.getFieldByKey('is_other_region_franchiseid').value = isZipCodeTheSame[0].RegionId;
                            this.setState({
                                isFranchiseRegionText: "",
                                isFranchiseRegionOtherText: isZipCodeTheSame[0].RegionName
                            })
                        }
                    }
                }
            }else{
                let regionsAssigned = this.props.regionsFP.filter(element => element.RegionAssignedToUser === true);
                if(regionsAssigned.length >1){
                    regionsAssigned.sort((a, b) => a.CreatedDate > b.CreatedDate ? -1 : (a.CreatedDate < b.CreatedDate ? 1 : 0))
                    form.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
                    form.getFieldByKey('is_other_region_franchiseid').value = '';
                    this.setState({
                        isFranchiseRegionText: regionsAssigned[0].RegionName,
                        isFranchiseRegionOtherText: null
                    })
                }else{
                    if(regionsAssigned.length === 1){
                        form.getFieldByKey('is_franchise_regionid').value = regionsAssigned[0].RegionId
                        form.getFieldByKey('is_other_region_franchiseid').value = '';
                        this.setState({
                            isFranchiseRegionText: regionsAssigned[0].RegionName,
                            isFranchiseRegionOtherText: null
                        })
                    }else{
                        form.getFieldByKey('is_franchise_regionid').value = '';
                        form.getFieldByKey('is_other_region_franchiseid').value = '';
                        this.setState({
                            isFranchiseRegionText: '',
                            isFranchiseRegionOtherText: ''
                        })
                    }
                }
            }
            /*
                CreatedDate: 1486469568
                RegionAssignedToUser: false
                RegionExclusionDate: 1535666400
                RegionId: "d5e374b7-2eed-e611-80e5-00155d00e22e"
                RegionInclusionDate: 1534888800
                RegionName: "TEST"
                RegionPostalCodes: "12345,QW456"
            */
        }else{
            setTimeout(() => {
                this.setState({regionsRetries: this.state.regionsRetries+1})
                this.prepareMeFranchiseRegion();
             }, 300);
        }
    }

    prepareMeCitySelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }

    setTypeSpecialChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { form } = draftState;
            const field = form.getFieldByKey(key);
            if (field) {
                field.value = value===true?'717700000':'717700001'
            }
        });
        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey, showButtons, appLang, products} = this.props;
        const { form } = this.state;
        const preparedSelectGroup = this.prepareMeCitySelect();
        const isStartDate = form.getFieldByKey('is_start_date');
        const isPermanentContract = form.getFieldByKey('is_permanent_contract');
        const isEndDate = form.getFieldByKey('is_end_date');
        const isProductId = form.getFieldByKey('is_product_id');
        const isRate = form.getFieldByKey('is_rate');
        const isContractDate = form.getFieldByKey('is_contract_date');
        const isInvoiceDeliveryMethodLetter = form.getFieldByKey('is_invoice_delivery_method_letter');
        const isInvoiceDeliveryMethodMail = form.getFieldByKey('is_invoice_delivery_method_mail');
        const isFrequencyOfInvoicing = form.getFieldByKey('is_frequency_of_invoicing');
        const isIdenticalPayerData = form.getFieldByKey('is_identical_payer_data');
        const isIdenticalInvoiceDeliveryAddress = form.getFieldByKey('is_identical_invoice_delivery_address');
        const isCorrespondenceSalutationInvoice = form.getFieldByKey('is_correspondence_salutation_invoice');
        const isOtherRegionFranchiseid = form.getFieldByKey('is_other_region_franchiseid');
        const isPayerRegisteredZipCode = form.getFieldByKey('is_payer_registered_zip_code');
        const isPayerCorrespondenceZipCode = form.getFieldByKey('is_payer_correspondence_zip_code');

        const productList = products.filter(element=>{
            return !element.ProductName.startsWith("Franchise");
        });

        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{translateKey("contract")}</span>
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                    <div className="row col-sm-12 col-lg-6">
                        <WrappedDatePickerComponent
                            showValidation={this.state.showValidation}
                            keyName="is_start_date"
                            value={isStartDate.value}
                            validationErrors={isStartDate.validationErrors}
                            text={translateKey("contract-text-voraussichtlicher-begin")}
                            setTypeChangeToParent={this.setTypeChange}
                            disabled={disabled}
                        />
                    </div>

                    <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.state.showValidation}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            transKey="contract-text-unbefristeter"
                            key="is_permanent_contract"
                            keyName="is_permanent_contract"
                            value={isPermanentContract.value}
                            validationErrors={isPermanentContract.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("yes")},
                                { value: '717700001', label: translateKey("no")},
                            ]}
                    />

                    { isPermanentContract.value === '717700001' && (
                        <div className="row col-sm-12 col-lg-6">
                            <WrappedDatePickerComponent
                                showValidation={this.state.showValidation}
                                keyName="is_end_date"
                                value={isEndDate.value}
                                validationErrors={isEndDate.validationErrors}
                                text={translateKey("contract-text-voraussichtlicher-dauer")}
                                setTypeChangeToParent={this.setTypeChange}
                                disabled={disabled}
                            />
                        </div>
                    )}

                    <SelectComponent
                        showValidation={this.state.showValidation}
                        keyName="is_product_id"
                        valuesList={productList}
                        value={isProductId.value}
                        validationErrors={isProductId.validationErrors}
                        label={translateKey("product")}
                        setTypeChangeToParent={this.setTypeChangeProduct}
                        optionValueKey="ProductId"
                        optionLabelKey="ProductName"
                    />

                    <div className="row col-sm-12 col-lg-6">
                        <div className="col-sm-12 form-margin-bottom-fix">
                            <label className="form-label-basic">
                                <span className="quick-label-position-fix">
                                    {translateKey("additional_services")}
                                </span>
                            </label>
                        </div>
                    </div>
                    <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_driving_licence').value==='717700000'} text={translateKey("form-text-is-driving-licence")} transKey="form-text-is-driving-licence" keyName="is_driving_licence"/>
                    <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_second_ooo').value==='717700000'} text={translateKey("second_service_user")} transKey="second_service_user" keyName="is_second_ooo"/>
                    <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_transfer').value==='717700000'} text={translateKey("plus")} transKey="plus" keyName="is_transfer"/>
                    <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_carer_with_diploma').value==='717700000'} text={translateKey("diploma")} transKey="diploma" keyName="is_carer_with_diploma"/>
                    <CheckBoxComponent className="col-lg-6" setTypeChangeToParent={this.setTypeSpecialChange} value={form.getFieldByKey('is_additional_foreign_language').value==='717700000'} text={translateKey("additional_language")} transKey="additional_language" keyName="is_additional_foreign_language"/>

                    <div className="row col-sm-12 col-lg-6">
                        <div className="col-sm-12 form-margin-bottom-fix">
                            <label className="form-label-basic"><span className="quick-label-position-fix">{translateKey("rate")}</span></label>
                            <input type="text" className="form-control" data-field-name="is_rate" onChange={e => this.setTypeChangeWithKey('is_rate', ReqHelper.valueFormatter(e.target.value))} value={ReqHelper.valueFormatter(isRate.value)}/>
                            {this.state.selectedProduct &&
                                !(isRate.value<this.state.selectedProduct.DailyRateTo && isRate.value>this.state.selectedProduct.DailyRateFrom) && isRate.value!=='0' && isRate.value &&
                                <div className="force-gray">{translateKey("contract-out-of-range")}{'('+this.state.selectedProduct.DailyRateFrom+' - '+this.state.selectedProduct.DailyRateTo+')'}</div>
                            }
                        </div>
                    </div>

                    <div className="row col-sm-12 col-lg-6">
                        <WrappedDatePickerComponent
                            showValidation={this.state.showValidation}
                            keyName="is_contract_date"
                            value={isContractDate.value}
                            validationErrors={isContractDate.validationErrors}
                            text={translateKey("contract-text-start-date")}
                            setTypeChangeToParent={this.setTypeChange}
                            disabled={disabled}
                        />
                    </div>

                    <div className="row col-sm-12 col-lg-6">
                        <div className="col-sm-12 form-margin-bottom-fix">
                            <label className="form-label-basic"><span className="quick-label-position-fix">{translateKey("contract-text-gebiet")}</span></label>
                            <input type="text" disabled={true} className="form-control" value={this.state.isFranchiseRegionText?this.state.isFranchiseRegionText:""}/>
                        </div>
                    </div>

                    {isOtherRegionFranchiseid.value !==null && isOtherRegionFranchiseid.value.toString().trim() !=='' &&
                        <div className="row col-sm-12 col-lg-6">
                            <div className="col-sm-12 form-margin-bottom-fix red-ink">
                                <label className="form-label-basic"><span className="quick-label-position-fix">{translateKey("contract-text-region-anderes")}</span></label>
                                <input type="text" disabled={true} className="form-control" value={this.state.isFranchiseRegionOtherText?this.state.isFranchiseRegionOtherText:""}/>
                            </div>
                        </div>
                    }

                    <SelectComponent
                        showValidation={this.state.showValidation}
                        keyName="is_frequency_of_invoicing"
                        valuesList={appLang===LanguageSelect.DE?
                            [
                                { value: '717700001', label: translateKey("contract-text-frequency-2") },
                                { value: '717700003', label: translateKey("contract-text-frequency-3") },
                            ]
                            :
                            [
                                { value: '717700000', label: translateKey("contract-text-frequency-1") },
                                { value: '717700001', label: translateKey("contract-text-frequency-2") },
                            ]
                        }
                        value={isFrequencyOfInvoicing.value}
                        validationErrors={isFrequencyOfInvoicing.validationErrors}
                        label={translateKey("contract-text-rechnungsausstellung")}
                        setTypeChangeToParent={this.setTypeChange}
                    />

                    <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.state.showValidation}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            transKey="contract-text-rechnungslieferung-brief"
                            key="is_invoice_delivery_method_letter"
                            keyName="is_invoice_delivery_method_letter"
                            value={isInvoiceDeliveryMethodLetter.value}
                            validationErrors={isInvoiceDeliveryMethodLetter.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("yes")},
                                { value: '717700001', label: translateKey("no")},
                            ]}
                    />
                    <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.state.showValidation}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            transKey="contract-text-rechnungslieferung-email"
                            key="is_invoice_delivery_method_mail"
                            keyName="is_invoice_delivery_method_mail"
                            value={isInvoiceDeliveryMethodMail.value}
                            validationErrors={isInvoiceDeliveryMethodMail.validationErrors}
                            valuesList={[
                                { value: '717700000', label: translateKey("yes")},
                                { value: '717700001', label: translateKey("no")},
                            ]}
                    />

                    <SelectComponent
                        showValidation={this.state.showValidation}
                        keyName="is_identical_payer_data"
                        valuesList={[
                            { value: '717700000', label: translateKey("contract-text-is-identical-patient-data1") },
                            { value: '717700001', label: translateKey("contract-text-is-identical-patient-data2") },
                            { value: '717700002', label: translateKey("contract-text-is-identical-patient-data3") },
                        ]}
                        value={isIdenticalPayerData.value}
                        validationErrors={isIdenticalPayerData.validationErrors}
                        label={translateKey("contract-text-zahlerdaten")}
                        setTypeChangeToParent={this.setTypeChange}
                    />

                    { isIdenticalPayerData.value === '717700000' && (
                        <React.Fragment>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_last_name')}
                                    text={translateKey("last_name")}
                                    transKey="last_name"
                                    keyName="is_payer_last_name"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_first_name')}
                                    text={translateKey("first_name")}
                                    transKey="first_name"
                                    keyName="is_payer_first_name"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_registered_street')}
                                    text={translateKey("street")}
                                    transKey="street"
                                    keyName="is_payer_registered_street"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_registered_house_number')}
                                    text={translateKey("form-text-section-0-2")}
                                    transKey="form-text-section-0-2"
                                    keyName="is_payer_registered_house_number"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_registered_appartment_number')}
                                    text={translateKey("apartment_number")}
                                    transKey="apartment_number"
                                    keyName="is_payer_registered_appartment_number"
                                />
                                <WrappedAutoSuggestComponent 
                                    value={isPayerRegisteredZipCode.value} 
                                    suggestions={preparedSelectGroup}
                                    showValidation={this.state.showValidation}
                                    keyName="is_payer_registered_zip_code"
                                    validationErrors={isPayerRegisteredZipCode.validationErrors}
                                    text={translateKey("postal_code")}
                                    setTypeChangeToParent={this.setTypeChangePostalCode}
                                    disabled={disabled}
                                    className="col-lg-6 form-margin-bottom-fix"
                                /> 
                            </div>
             
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_registered_city')}
                                    text={translateKey("location")}
                                    transKey="location"
                                    keyName="is_payer_registered_city"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_registered_country')}
                                    text={translateKey("contract-text-land")}
                                    transKey="contract-text-land"
                                    keyName="is_payer_registered_country"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_mobile_phone')}
                                    text={translateKey("form-text-section-0-3")}
                                    transKey="form-text-section-0-3"
                                    keyName="is_payer_mobile_phone"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_phone')}
                                    text={translateKey("contract-text-telefon-zu-hause")}
                                    transKey="contract-text-telefon-zu-hause"
                                    keyName="is_payer_phone"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_email')}
                                    text={translateKey("email")}
                                    transKey="email"
                                    keyName="is_payer_email"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_email_2')}
                                    text={`${translateKey("email")} 2`}
                                    transKey="email"
                                    keyName="is_payer_email_2"
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <SelectComponent
                        showValidation={this.state.showValidation}
                        keyName="is_identical_invoice_delivery_address"
                        valuesList={[
                            { value: '717700000', label: translateKey("contract-text-is-identical-patient-data3") },
                            { value: '717700001', label: translateKey("contract-text-is-identical-payer-data") },
                            { value: '717700002', label: translateKey("contract-text-is-identical-patient-data1") },
                        ]}
                        value={isIdenticalInvoiceDeliveryAddress.value}
                        validationErrors={isIdenticalInvoiceDeliveryAddress.validationErrors}
                        label={translateKey("contract-text-daten-des-rachnungsversandes")}
                        setTypeChangeToParent={this.setTypeChange}
                    />

                    <SelectComponent
                        showValidation={this.state.showValidation}
                        keyName="is_correspondence_salutation_invoice"
                        valuesList={[
                            { value: '717700000', label: translateKey("form-text-frau") },
                            { value: '717700001', label: translateKey("contract-text-herr") },
                        ]}
                        value={isCorrespondenceSalutationInvoice.value}
                        validationErrors={isCorrespondenceSalutationInvoice.validationErrors}
                        label={translateKey("gender")}
                        setTypeChangeToParent={this.setTypeChange}
                    />

                    { isIdenticalInvoiceDeliveryAddress.value === '717700002' && (
                        <>
                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondence_last_name')}
                                    text={translateKey("last_name")}
                                    transKey="last_name"
                                    keyName="is_payer_correspondence_last_name"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondence_first_name')}
                                    text={translateKey("first_name")}
                                    transKey="first_name"
                                    keyName="is_payer_correspondence_first_name"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondence_street')}
                                    text={translateKey("street")}
                                    transKey="street"
                                    keyName="is_payer_correspondence_street"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondence_house_number')}
                                    text={translateKey("form-text-section-0-2")}
                                    transKey="form-text-section-0-2"
                                    keyName="is_payer_correspondence_house_number"
                                />
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondance_appartment_number')}
                                    text={translateKey("apartment_number")}
                                    transKey="apartment_number"
                                    keyName="is_payer_correspondance_appartment_number"
                                />
                                <WrappedAutoSuggestComponent 
                                    value={isPayerCorrespondenceZipCode.value} 
                                    suggestions={preparedSelectGroup}
                                    showValidation={this.state.showValidation}
                                    keyName="is_payer_correspondence_zip_code"
                                    validationErrors={isPayerCorrespondenceZipCode.validationErrors}
                                    text={translateKey("postal_code")}
                                    setTypeChangeToParent={this.setTypeChangePostalCodePayerCorrespondence}
                                    disabled={disabled}
                                    className="col-lg-6 form-margin-bottom-fix"
                                /> 
                            </div>

                            <div className="row col-sm-12 col-lg-6">
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondence_city')}
                                    text={translateKey("location")}
                                    transKey="location"
                                    keyName="is_payer_correspondence_city"
                                />
                                <InputTypeTextComponent
                                    showValidation={this.state.showValidation}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    setTypeChangeToParent={this.setTypeChange}
                                    field={form.getFieldByKey('is_payer_correspondence_country')}
                                    text={translateKey("contract-text-land")}
                                    transKey="contract-text-land"
                                    keyName="is_payer_correspondence_country"
                                />
                            </div>
                        </>
                    )}

                    {this.prepareMePatientList()}
                    </fieldset>
                </form>
                {showButtons && 
                    <div className="btn-toolbar-fixed btn-toolbar-fixed-onlyone">
                        <div className="previous-next-wrapper">
                            <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.saveOffer}>{translateKey("save")}</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        coopId: state.auth.CooperationId,
        appLang: state.lang.appLanguage,
        patientLeadData: state.database.patientLead,
        patientLeadStatus: state.database.patientLeadState,
        patientClientData: state.database.patientClient,
        patientClientStatus: state.database.patientClientState,
        cities: state.enums.citiesList,
        citiesStatus: state.enums.citiesListStatus,
    };
}
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
    getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
    removeLead: (id) => dispatch(removeOneLeadData(id))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractComponent));