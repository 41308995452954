import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import {CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import {CheckBoxComponent} from "../../form-bb-components/checkbox-component/checkbox-component";
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import produce from "immer";


class Section15 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-15'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, translateKey, showValidationErrors } = this.props;
        const { section } = this.state;
        const isTherapeuticCareAtHome = section.getFieldByKey('is_therapeutic_care_at_home');
        const isTherapeuticCareOutsideHome = section.getFieldByKey('is_therapeutic_care_outside_home');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                            <div className="hilfsmittel-wrapper">
                            <div className="form-label-basic cursor-pointer quick-label-position-fix">{translateKey("form-text-durchgeführt")}</div>
                            <div className="checkboxes_wrapper">
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"is_speech_therapy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_speech_therapy')} text={translateKey("form-text-logopadie")} transKey="form-text-logopadie" keyName="is_speech_therapy" />
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"is_ergotherapy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_ergotherapy')} text={translateKey("form-text-ergotherapie")} transKey="form-text-ergotherapie" keyName="is_ergotherapy" />
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"is_physiotherapy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_physiotherapy')} text={translateKey("form-text-krankengymnastik")} transKey="form-text-krankengymnastik" keyName="is_physiotherapy" />
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"is_occupational_theraphy"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_occupational_theraphy')} text={translateKey("form-text-beschaftigungstherapie")} transKey="form-text-beschaftigungstherapie" keyName="is_occupational_theraphy" />
                            </div>
                        </div>

                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6 top-too" setTypeChangeToParent={this.setTypeChange} value={isTherapeuticCareAtHome.value} validationErrors={isTherapeuticCareAtHome.validationErrors} text={translateKey("form-text-therapeutkommt")} transKey="form-text-therapeutkommt" keyName="is_therapeutic_care_at_home" />
                        <CheckBoxComponent showValidation={showValidationErrors} className="col-lg-6 top-too" setTypeChangeToParent={this.setTypeChange} value={isTherapeuticCareOutsideHome.value} validationErrors={isTherapeuticCareOutsideHome.validationErrors} text={translateKey("form-text-therapeutextern")} transKey="form-text-therapeutextern" keyName="is_therapeutic_care_outside_home" />

                        {(isTherapeuticCareAtHome.value || isTherapeuticCareOutsideHome.value) && (
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <InputTypeTextComponent showValidation={this.state.showValidation} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_therapeutic_care_how_often_in_week_de')} text={translateKey("form-text-prowoche")} transKey="form-text-prowoche" keyName="is_therapeutic_care_how_often_in_week_de" />
                                </div>
                            </div>
                        )}
                        </fieldset>
                    </form>
</div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section15));