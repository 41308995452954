import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import ListComponent from "../../form-bb-components/list-component/list-component";
import produce from "immer";


class Section5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-5'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled } = this.props;
        const { section } = this.state;
        const isCommunicationHearing = section.getFieldByKey('is_communication_hearing');
        const isCommunicationVision = section.getFieldByKey('is_communication_vision');
        const isCommunicationSpeech = section.getFieldByKey('is_communication_speech');
        const isHearingAidList = section.getFieldByKey('is_hearing_aid_list');
        const isHearingAidSelfContainedUse = section.getFieldByKey('is_hearing_aid_self_contained_use');
        const isGlassSelfUse = section.getFieldByKey('is_glasses_self_use');
        const isGlassList= section.getFieldByKey('is_glasses_list');

        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {this.props.translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">
                    {this.props.subsectionNumber}. {this.props.subsectionName}
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                        <ListComponent
                            showValidation={this.props.showValidationErrors}
                            setTypeChangeToParent={this.setTypeChange}
                            className="col-lg-6 form-margin-bottom-fix"
                            transKey="form-text-horen"
                            key="is_communication_hearing"
                            keyName="is_communication_hearing"
                            value={isCommunicationHearing.value}
                            validationErrors={isCommunicationHearing.validationErrors}
                            valuesList={[
                                { value: '717700000', label: this.props.translateKey("form-text-uneingeschrankt") },
                                { value: '717700001', label: this.props.translateKey("form-text-teilweise") },
                                { value: '717700002', label: this.props.translateKey("form-text-eingeschrankt") }
                            ]}
                        />

                        <ListComponent
                            showValidation={this.props.showValidationErrors}
                            setTypeChangeToParent={this.setTypeChange}
                            className="col-lg-6 form-margin-bottom-fix"
                            transKey="form-text-sehen"
                            key="is_communication_vision"
                            keyName="is_communication_vision"
                            value={isCommunicationVision.value}
                            validationErrors={isCommunicationVision.validationErrors}
                            valuesList={[
                                { value: '717700000', label: this.props.translateKey("form-text-uneingeschrankt") },
                                { value: '717700001', label: this.props.translateKey("form-text-teilweise") },
                                { value: '717700002', label: this.props.translateKey("form-text-eingeschrankt") }
                            ]}
                        />

                        <ListComponent
                            showValidation={this.props.showValidationErrors}
                            setTypeChangeToParent={this.setTypeChange}
                            className="col-lg-6 form-margin-bottom-fix"
                            transKey="form-text-sprechen"
                            key="is_communication_speech"
                            keyName="is_communication_speech"
                            value={isCommunicationSpeech.value}
                            validationErrors={isCommunicationSpeech.validationErrors}
                            valuesList={[
                                { value: '717700000', label: this.props.translateKey("form-text-uneingeschrankt") },
                                { value: '717700001', label: this.props.translateKey("form-text-teilweise") },
                                { value: '717700002', label: this.props.translateKey("form-text-eingeschrankt") }
                            ]}
                        />

                        <CheckBoxComponent showValidation={this.props.showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isHearingAidList.value} text={this.props.translateKey("form-text-horgerat")} transKey="form-text-horgerat" keyName="is_hearing_aid_list"/>

                        {isHearingAidList.value===true &&
                            <React.Fragment>
                                <ListComponent
                                    showValidation={this.props.showValidationErrors}
                                    setTypeChangeToParent={this.setTypeChange}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    transKey="form-text-einsetzen"
                                    key="is_hearing_aid_self_contained_use"
                                    keyName="is_hearing_aid_self_contained_use"
                                    value={isHearingAidSelfContainedUse.value}
                                    validationErrors={isHearingAidSelfContainedUse.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: this.props.translateKey("yes") },
                                        { value: '717700001', label: this.props.translateKey("no") }
                                    ]}
                                />
                            </React.Fragment>
                        }

                        <CheckBoxComponent showValidation={this.props.showValidationErrors} className="col-lg-6" setTypeChangeToParent={this.setTypeChange} value={isGlassList.value} text={this.props.translateKey("form-text-brille")} transKey="form-text-brille" keyName="is_glasses_list"/>

                        {isGlassList.value===true &&
                            <React.Fragment>
                                <ListComponent
                                    showValidation={this.props.showValidationErrors}
                                    setTypeChangeToParent={this.setTypeChange}
                                    className="col-lg-6 form-margin-bottom-fix"
                                    transKey="form-text-aufsetzen"
                                    key="is_glasses_self_use"
                                    keyName="is_glasses_self_use"
                                    value={isGlassSelfUse.value}
                                    validationErrors={isGlassSelfUse.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: this.props.translateKey("yes") },
                                        { value: '717700001', label: this.props.translateKey("no") }
                                    ]}
                                />
                            </React.Fragment>
                        }
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section5));