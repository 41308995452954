import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  translateKey,
  setClientsData,
  pendingClientsData,
  removeClientsData,
  authSetActionTimestamp,
  setPatientClientData,
  pendingPatientClientData,
  removePatientClientData
} from "../../_redux/actions/index";
import {
  TableColumHead,
  TableProperties,
  LoaderModal,
  LoaderTable,
  NavBar,
  SideBar,
  EmptyTableIcon,
  AlertMessage,
  TableDateFormat,
  TablePagination,
  ClientStatusHandler,
  ContractStatusHandler,
  MailToPhoneComponent
} from "../../_components/index";
import axiosAuth from "../../_services/config/axios-auth";
import { TableEnums } from "../../_constance/enums";
import { ClientStatus } from "../../_constance/enums/clientStatus";
import * as FilterType from "../../_constance/enums/filterType";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { Alert, ClientTable, Paging } from "../../_constance/classes/index";
import { shouldRefresh, ReqHelper, IconsMap } from "../../_helpers/index";
import { SortingClients } from "../../_constance/classes/sortingClasses/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import {setFiltersData} from "../../_redux/actions";

//COLUMNS
import {
  setColumnsData,
  removeColumnsData,
  addColumnData,
  updateColumTypeData
} from "../../_redux/actions/active-columns";
import * as ColumnsNames from "../../_constance/values/columnsNames";
import {saveColumnsData, getColumnsData} from "../../_services/firebase_service";

class ClientsMainPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      sorting: new SortingClients(),
      searchQuery: null,
      filterData: [],
      errorComponent: Alert,
      pagingItem: Paging,
      showModalLoader: false,
      rerender: Date.now(),
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ]
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.getMeColumnConfiguration();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };



  getMeColumnConfiguration(){
    const lastUpdate = this.props.activeColumnsTimeStamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getColumnsDataFromApi();
    } else {
      if (this._isMounted) {
        this.prepareMeSortingBasedOnColumns()
        this.getDataBasic();
      }
    }
  }
  getColumnsDataFromApi(){
   getColumnsData(this.props.userIdColumn)
    .then(res => {
        this.props.setColumns(res);
        this.prepareMeSortingBasedOnColumns();
        this.getDataBasic();
    })
    .catch((error) => {
      this.prepareMeSortingBasedOnColumns();
      this.getDataBasic();
    });
  }
  prepareMeSortingBasedOnColumns(){
    if(this.props.activeColumns){
      this.props.activeColumns.forEach(element => {
        if(element.name === ColumnsNames.CLIENTS){
          element.columns.forEach(item => {
            this.state.sorting.setColumnEnabled(item.enabled, item.name)
          });
        }
      });
    }
    this.setState({rerender: Date.now()})
  }
  prepareColumnsData = (data) =>{
    data.forEach(column => {
      this.state.sorting.setColumnEnabled(column.enabled, column.keyName)
    });
    let columnsData = this.props.activeColumns.find(element => element.name === ColumnsNames.CLIENTS); 
    if(columnsData){
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.CLIENTS, data, false);
      this.props.updateColumnTypeData(ColumnsNames.CLIENTS, dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.CLIENTS, dataToSave.columns)
    }else{
      let dataToSave = ReqHelper.prepareMeNewColumnToSave(ColumnsNames.CLIENTS, data, false);
      this.props.addNewColumnTable(dataToSave);
      saveColumnsData(this.props.userIdColumn, ColumnsNames.CLIENTS, dataToSave.columns)
    }
  }




  _prepareFilterObj() {
    const { translateKey, appLang } = this.props;
    let filterData = [];
    if(appLang === LanguageSelect.DE){
      // TODO obiekt to dat od do
      filterData.push({
        filterName: "client-contract-start-status",
        filterKey: "contractStartDate",
        filterType: FilterType.FROMTO,
        filterOptions: [
          {
            column: this.props.translateKey('date_from'),
            active: false,
            type: "dateFrom",
            date: 0
          },
          {
            column: this.props.translateKey('date_to'),
            active: false,
            type: "dateTo",
            date: 0
          }
        ]
      });
      filterData.push({
        filterName: "client-contract-end-status",
        filterKey: "contractEndDate",
        filterType: FilterType.FROMTO,
        filterOptions: [
          {
            column: this.props.translateKey('date_from'),
            active: false,
            type: "dateFrom",
            date: 0
          },
          {
            column: this.props.translateKey('date_to'),
            active: false,
            type: "dateTo",
            date: 0
          }
        ]
      });
      let Options = [];
      for (let i = 0; i < this.props.contractStatus.length; i++) {
        let filtering = {
          column: this.props.contractStatus[i].Label,
          value: this.props.contractStatus[i].Value,
          active: false,
          type: "text"
        }
        Options.push(filtering);
      }
      filterData.push({
        filterName: "client-contract-status-text",
        filterKey: "contractStatus",
        filterType: FilterType.STATUS,
        filterOptions : Options
      });
      Options = ClientStatus.map(el=>{
        return {
          column: translateKey(el.Key),
          value: el.Value,
          active: false,
          type: "text"
        }
      })
      filterData.push({
        filterName: "status",
        filterKey: "clientStatus",
        filterType: FilterType.STATUS,
        filterOptions : Options
      });
      filterData.push({
        filterName: "client-carer-start-status",
        filterKey: "wzStartDate",
        filterType: FilterType.FROMTO,
        filterOptions: [
          {
            column: this.props.translateKey('date_from'),
            active: false,
            type: "dateFrom",
            date: 0
          },
          {
            column: this.props.translateKey('date_to'),
            active: false,
            type: "dateTo",
            date: 0
          }
        ]
      });
    }
    filterData.push({
      filterName: "carer_trip_end",
      filterKey: "wzEndDate",
      filterType: FilterType.FROMTO,
      filterOptions: [
        {
          column: this.props.translateKey('date_from'),
          active: false,
          type: "dateFrom",
          date: 0
        },
        {
          column: this.props.translateKey('date_to'),
          active: false,
          type: "dateTo",
          date: 0
        }
      ]
    });
    if(appLang === LanguageSelect.DE){
      filterData.push({
        filterName: "client-carer-next-status",
        filterKey: "nextWzStartDate",
        filterType: FilterType.FROMTO,
        filterOptions: [
          {
            column: this.props.translateKey('date_from'),
            active: false,
            type: "dateFrom",
            date: new Date().getTime()
          },
          {
            column: this.props.translateKey('date_to'),
            active: false,
            type: "dateTo",
            date: new Date().getTime()
          }
        ]
      });
    }
    let saved = this.props.savedFilters;
    if(saved !== null) {
      return saved;
    } else {
      return filterData
    }
  }

  getClientsList = () => {
    this.props.pendingRequest();
    axiosAuth.get("clients/list/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{
          for(let i=0; i<this.props.patientClientData.length; i++){
            if(this.props.patientClientData[i].clientId===element.id){
              element.locationP = this.props.patientClientData[i].patientAddress ? this.props.patientClientData[i].patientAddress.city:'';
              element.patientName = this.props.patientClientData[i].name;
              element.patient = this.props.patientClientData[i]
            }
          }
          return new ClientTable(element);}
        );
        this.props.setData(mapedRes);
        if (this._isMounted) {
          this.setState({
            data: mapedRes,
            loading: false,
            filterData: this._prepareFilterObj()
          },() => this.filterOrDataChange());
        }
      })
      .catch((error) => {
        this.props.clearData();
        this.alertD.show = true;
        this.alertD.type = "danger";
        this.alertD.message = this.props.translateKey("basic_error_message");
        if (this._isMounted) {
          this.setState({
            data: [],
            loading: false,
            errorComponent: this.alertD
          },() => this.filterOrDataChange());
        }
        console.log(error);
        this.myTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ errorComponent: Alert });
          }
        }, 3000);
      });
  }

  getPatientList(){
    this.props.pendingPatientData();
    axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getClientsList();
      })
      .catch((error) => {
        console.log(error);
        this.props.clearPatientData();
        this.getClientsList();
      });
  }

  getDataBasic() {
    if (this._isMounted) {
      this.setState({ loading: true })
    }
    if (this.props.patientClientData.length === 0) {
      if (this.props.patientClientData.length !== 0 && this.props.patientClientData !== undefined) {
        this.getClientsList();
      } else {
        this.getPatientList();
      }
    } else {
      this.getClientsList();
    }
  }

  filterOrDataChange(val){
    let finalDataToShow = this.propsFilterSearch(val);
    ReqHelper.sortMeData(finalDataToShow, this.state.sorting.sortingColumns);
    this.propsPagingData(finalDataToShow);
    setTimeout(() => {
      let finalData = finalDataToShow.slice((this.state.pagingItem.currentPage-1)*this.state.pagingItem.itemsPerPage, this.state.pagingItem.currentPage*this.state.pagingItem.itemsPerPage);
      if (this._isMounted) {
        this.setState({data: finalData})
      }
    }, 50);
    this.props.setFilters('clients', this.state.filterData);
  }

  toggleModalLoader = () => {
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          ...prevState,
          showModalLoader: !prevState.showModalLoader
        }
      });
    }
  }

  propsSearchTable = async (val) => {
    const { history } = this.props;
    if(val.lastIndexOf(baseData.deepLinkStart, 0) === 0){
      this.toggleModalLoader();
      let url = new URL(val);
      let id = url.searchParams.get("id");
      let form = url.searchParams.get("extraqs");
      if(form!==null && id!==null){
        const { appLang } = this.props
        id = id.replace('{', '').replace('}', '').toLowerCase();
        switch(form){
            case baseData.enumClient:           axiosAuth.get("leads/list/0/" + appLang)
                                                .then(res => {
                                                    let statusObj = res.data.find((element) => {return element.leadId.toString().toLowerCase()===id.toString()})
                                                    if(statusObj){
                                                      this.toggleModalLoader();
                                                      history.push(`${baseData.subFolders}leadDetails/${id}`);
                                                    }else{
                                                        axiosAuth
                                                        .get("clients/list/0")
                                                        .then(res => {
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              this.toggleModalLoader();
                                                              history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                            }else{
                                                                axiosAuth.get("multipliers/list/0")
                                                                .then(res => {
                                                                  this.toggleModalLoader();
                                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                    if(statusObj){
                                                                      history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                    }
                                                                }).catch(()=>this.toggleModalLoader())
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    }
                                                }).catch(()=>{
                                                    axiosAuth
                                                    .get("clients/list/0")
                                                    .then(res => {
                                                        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                        if(statusObj){
                                                          this.toggleModalLoader();
                                                          history.push(`${baseData.subFolders}clientDetails/${id}`);
                                                        }else{
                                                            axiosAuth.get("multipliers/list/0")
                                                            .then(res => {
                                                                this.toggleModalLoader();
                                                                let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                                if(statusObj){
                                                                  history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                                }
                                                            }).catch(()=>this.toggleModalLoader())
                                                        }
                                                    }).catch(()=>{
                                                        axiosAuth.get("multipliers/list/0")
                                                        .then(res => {
                                                            this.toggleModalLoader();
                                                            let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                            if(statusObj){
                                                              history.push(`${baseData.subFolders}multiplierDetails/${id}`);
                                                            }
                                                        }).catch(()=>this.toggleModalLoader())
                                                    })
                                                })
                                                break;
            case baseData.enumPatient:          if(this.props.patientLeadData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientLeadData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/LEAD/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-lead/${statusObj.leadId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}leadPatient/${statusObj.leadId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }
                                                if(this.props.patientClientData.length>0){
                                                  this.toggleModalLoader();
                                                  let statusObj = this.props.patientClientData.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                  if(statusObj){
                                                    if(appLang === LanguageSelect.DE){
                                                      history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                    }else{
                                                      history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                    }
                                                  }
                                                }else{
                                                  axiosAuth.get("patients/list/CLIENT/0/" + appLang)
                                                  .then(res => {
                                                    this.toggleModalLoader();
                                                    let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===id.toString())
                                                    if(statusObj){
                                                      if(appLang === LanguageSelect.DE){
                                                        history.push(`${baseData.subFolders}bb-form-client/${statusObj.clientId}/${id}`);
                                                      }else{
                                                        history.push(`${baseData.subFolders}clientPatient/${statusObj.clientId}/${id}`);
                                                      }
                                                    }
                                                  }).catch(()=>this.toggleModalLoader())
                                                }                   
                                                break;
            case baseData.enumCarer:            axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundCarer = false;
                                                  for(let i=0; i<res.data.length && !foundCarer; i++){
                                                    foundCarer = this.getMeCarer(res.data[i].id, appLang, id, i===res.data.length-1);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumContract:         let gotHim = false
                                                await axiosAuth
                                                .get("clients/list/0")
                                                .then(res => {
                                                  let foundContract = false;
                                                  for(let i=0; i<res.data.length && !gotHim; i++){
                                                    foundContract = this.getMeContract(res.data[i].id, appLang, id, "CLIENT");
                                                  }
                                                  if(!foundContract){
                                                    this.getMeLeads(appLang, id);
                                                  }
                                                }).catch(()=>this.toggleModalLoader())
                                                break;
            case baseData.enumInvoice:          this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}invoices/${id}`);
                                                break;
            case baseData.enumTaskTypeEvent:    this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeTask:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            case baseData.enumTaskTypeCall:     this.toggleModalLoader()
                                                history.push(`${baseData.subFolders}taskDetails/${id}`);
                                                break;
            default: this.toggleModalLoader()
        }    
      }
    }else{
      if (this._isMounted) {
        this.setState({ searchQuery: val.trim() });
      }
      this.filterOrDataChange(val);
    }
  }

  getMeCarer = (id, lang, carerId, redirect=false) =>{
    axiosAuth.get(`/carers/list/${id}/0/${lang}`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.wzid.toString().toLowerCase()===carerId.toString())
        if(statusObj){
          this.toggleModalLoader()
          this.props.history.push(`${baseData.subFolders}clientDetails/${statusObj.clientId}/${carerId}`);
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeContract = (id, appLang, contractId, type, redirect=false) => {
    const { history } = this.props;
    axiosAuth.get(`/contracts/list/${id}/0`)
      .then((res) => {
        let statusObj = res.data.find((element) => element.id.toString().toLowerCase()===contractId.toString())
        if(statusObj){
          this.toggleModalLoader()
          if(appLang === LanguageSelect.DE){
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}bb-form-lead/${id}/${contractId}/ENQUIRY`);                            
            }else{
              history.push(`${baseData.subFolders}bb-form-client/${id}/${contractId}/ENQUIRY`);   
            }
          }else{
            if(type==="LEAD"){        
              history.push(`${baseData.subFolders}leadDetails/${id}`);                            
            }else{
              history.push(`${baseData.subFolders}contract/${id}/${contractId}`);
            }
          }
          return true;
        }else{
          if(redirect){
            this.toggleModalLoader()
          }
          return false;
        }
      })
      .catch(() => {
        if(redirect){
          this.toggleModalLoader()
        }
        return false
      });
  }

  getMeLeads = (appLang, contractId) =>{
    axiosAuth.get("leads/list/0/" + appLang)
      .then((res) => {
        for(let i=0; i<res.data.length; i++){
          this.getMeContract(res.data[i].leadId, appLang, contractId, "LEAD", i===res.data.length-1);
        }
      }).catch(()=>this.toggleModalLoader())
  }

  propsSortType(type){
    this.state.sorting.changeColumnByColumn(type);
    this.filterOrDataChange();
  }

  propsFilterSearch = (val) => {
    const { appLang } = this.props;
    const { sortingColumns } = this.state.sorting
    let finalDataAfter = [];
    if (this.props.clientsData !== null && this.props.clientsData.length > 0) {
      if(val && val!==null){
        // searching
        let filters = ReqHelper.getMeFilersObj(this.state.filterData);
        if(filters.length>0){
          // mamy search i filtry
          // search filter
          let afterSearchData = this.props.clientsData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
          });
          finalDataAfter = afterSearchData.filter((value) => {
            let gotAllFilters = true;
            for(let i=0; i<filters.length; i++){
              if(gotAllFilters){
                switch(filters[i].type){
                  case FilterType.FROMTO:   let gotDateFilter = true;
                                            for(let v=0; v<filters[i].values.length; v++){
                                              if(gotDateFilter){
                                                if(filters[i].values[v].type==="dateFrom"){
                                                  if(filters[i].values[v].date>value[filters[i].key]){
                                                    gotDateFilter = false;
                                                  }
                                                }else{
                                                  if(filters[i].values[v].type==="dateTo"){
                                                    if(filters[i].values[v].date<value[filters[i].key]){
                                                      gotDateFilter = false;
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            gotAllFilters = gotDateFilter;
                                            break;
                    case FilterType.STATUS: let gotStatusFilter = false;
                                            for(let v=0; v<filters[i].values.length; v++){
                                              if(value[filters[i].key]!==null && value[filters[i].key]!== undefined){
                                                if(value[filters[i].key].toString().toLowerCase()===filters[i].values[v].value.toString().toLowerCase()){
                                                  gotStatusFilter=true;
                                                  break;
                                                }
                                              }
                                            }
                                            gotAllFilters = gotStatusFilter;
                                            break;
                  default: 
                }
              }
            }
            return gotAllFilters;
          });
        }else{
          // samo search bez filtrów
          finalDataAfter = this.props.clientsData.filter((value) => {
            return ReqHelper.valueExistsInObjBasedOnSorting(value, val, sortingColumns, appLang);
          });
        }
      }else{
        let search = this.state.searchQuery;
        if(search && search!==null && val!==''){
          // filtrowanie z wpisanym search
          let filters = ReqHelper.getMeFilersObj(this.state.filterData);
          if(filters.length>0){
            // mamy search i filtry
            let afterSearchData = this.props.clientsData.filter((value) => {
              return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
            });
            finalDataAfter = afterSearchData.filter((value) => {
              let gotAllFilters = true;
              for(let i=0; i<filters.length; i++){
                if(gotAllFilters){
                  switch(filters[i].type){
                    case FilterType.FROMTO:   let gotDateFilter = true;
                                              for(let v=0; v<filters[i].values.length; v++){
                                                if(gotDateFilter){
                                                  if(filters[i].values[v].type==="dateFrom"){
                                                    if(filters[i].values[v].date>value[filters[i].key]){
                                                      gotDateFilter = false;
                                                    }
                                                  }else{
                                                    if(filters[i].values[v].type==="dateTo"){
                                                      if(filters[i].values[v].date<value[filters[i].key]){
                                                        gotDateFilter = false;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              gotAllFilters = gotDateFilter;
                                              break;
                    case FilterType.STATUS:   let gotStatusFilter = false;
                                              for(let v=0; v<filters[i].values.length; v++){
                                                if(value[filters[i].key]!==null && value[filters[i].key]!== undefined){
                                                  if(value[filters[i].key].toString().toLowerCase()===filters[i].values[v].value.toString().toLowerCase()){
                                                    gotStatusFilter=true;
                                                    break;
                                                  }
                                                }
                                              }
                                              gotAllFilters = gotStatusFilter;
                                              break;
                    default: 
                  }
                }
              }
              return gotAllFilters;
            });
          }else{
            // samo search bez filtrów
            finalDataAfter = this.props.clientsData.filter((value) => {
              return ReqHelper.valueExistsInObjBasedOnSorting(value, search, sortingColumns, appLang);
            });
          }
        }else{
          //samo filtrowanie
          let filters = ReqHelper.getMeFilersObj(this.state.filterData);
          if(filters.length>0){
            finalDataAfter = this.props.clientsData.filter((value) => {
              let gotAllFilters = true;
              for(let i=0; i<filters.length; i++){
                if(gotAllFilters){
                  switch(filters[i].type){
                    case FilterType.FROMTO:   let gotDateFilter = true;
                                              for(let v=0; v<filters[i].values.length; v++){
                                                if(gotDateFilter){
                                                  if(filters[i].values[v].type==="dateFrom"){
                                                    if(filters[i].values[v].date>=value[filters[i].key]){
                                                      gotDateFilter = false;
                                                    }
                                                  }else{
                                                    if(filters[i].values[v].type==="dateTo"){
                                                      if(filters[i].values[v].date<=value[filters[i].key]){
                                                        gotDateFilter = false;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                              gotAllFilters = gotDateFilter;
                                              break;
                    case FilterType.STATUS:   let gotStatusFilter = false;
                                              for(let v=0; v<filters[i].values.length; v++){
                                                if(value[filters[i].key]!==null && value[filters[i].key]!== undefined){
                                                  if(value[filters[i].key].toString().toLowerCase()===filters[i].values[v].value.toString().toLowerCase()){
                                                    gotStatusFilter=true;
                                                    break;
                                                  }
                                                }
                                              }
                                              gotAllFilters = gotStatusFilter;
                                              break;
                    default: 
                  }
                }
              }
              return gotAllFilters;
            });
          }else{
            finalDataAfter = this.props.clientsData;
          }
        }
      }
    }
    return finalDataAfter;
  }

  propsPagingData(data){
    if(data.length<=25){
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              totalItems: data.length,
              itemsPerPage: prevState.pagingItem.itemsPerPage,
              currentPage: 1,
              visiblePages: 5,
              pageFrom: 1,
              pageTo: 5,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }else{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
            ...prevState,
            pagingItem: {
              ...prevState.pagingItem,
              totalItems: data.length,
              pageFrom: prevState.pagingItem.pageFrom,
              pageTo: prevState.pagingItem.pageTo,
              currentPage: prevState.pagingItem.currentPage>Math.ceil(data.length/prevState.pagingItem.itemsPerPage)? Math.ceil(data.length/prevState.pagingItem.itemsPerPage) : prevState.pagingItem.currentPage,
              totalPages: Math.ceil(data.length/prevState.pagingItem.itemsPerPage)
            }
          }
        });
      }
    }
  }

  navigateToDetails(clientData){
    this.props.history.push(`${baseData.subFolders}clientDetails/${clientData.id}`);
  }

  render() {
    const { translateKey, appLang } = this.props;
    const { sorting } = this.state;
    let emptyHandler;
    let PreparedTableContent;
    if (this.state.loading) {
      emptyHandler = <LoaderTable />
    } else {
      if (this.props.clientsData === null) {
        emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data")} />
      } else {
        if (this.props.clientsData.length === 0) {
          emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
        }else{
          if(this.state.data.lengh===0){
            emptyHandler = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("no_data_to_show")} />
          }else{
            PreparedTableContent = this.state.data.map(element => (
              <tr className="table-row-wrapper cursor-pointer" key={Math.random().toString(36).substr(2, 9)} onClick={()=>{this.navigateToDetails(element)}}>
                {
                  sorting.isColumnVisibleByKey('clients_client', appLang) &&
                    <td className="table-cell-wrapper">{element.client}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_patient', appLang) &&
                    <td className="table-cell-wrapper">{element.patientName}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_location_client', appLang) &&
                    <td className="table-cell-wrapper">{element.locationC}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_location_patient', appLang) &&
                    <td className="table-cell-wrapper">{element.locationP}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_contract_start_status', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.contractStartDate}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_contract_end_status', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.contractEndDate}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_contract_status', appLang) &&
                    <td className="table-cell-wrapper"><ContractStatusHandler status={element.contractStatus}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_client_status', appLang) &&
                    <td className="table-cell-wrapper"><ClientStatusHandler status={element.clientStatus} tk={this.props.translateKey} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_carer_status', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.wzStartDate}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_carer_trip_end', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.wzEndDate}/></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_carer_next_start', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.nextWzStartDate}/></td>
                }






                {
                  sorting.isColumnVisibleByKey('clients_client_cellPhone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.cellPhone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_client_phone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.phone} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_client_email', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.email} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_client_street', appLang) &&
                    <td className="table-cell-wrapper">{element.address.street}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_client_street', appLang) &&
                    <td className="table-cell-wrapper">{element.address.postalCode}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_correspondenceSameAsMain', appLang) &&
                    <td className="table-cell-wrapper">{element.correspondenceSameAsMain}</td>
                }







                {
                  sorting.isColumnVisibleByKey('clients_patient_cellPhone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.patient?element.patient.patientAddress?element.patient.patientAddress.cellPhone:'':''} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_patient_phone', appLang) &&
                    <td className="table-cell-wrapper"><MailToPhoneComponent phone={element.patient?element.patient.patientAddress?element.patient.patientAddress.phone:'':''} /></td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_patient_street', appLang) &&
                    <td className="table-cell-wrapper">{element.patient?element.patient.patientAddress?element.patient.patientAddress.street:'':''}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_patient_postal_code', appLang) &&
                    <td className="table-cell-wrapper">{element.patient?element.patient.patientAddress?element.patient.patientAddress.postalCode:'':''}</td>
                }
                {
                  sorting.isColumnVisibleByKey('clients_patient_birthdate', appLang) &&
                    <td className="table-cell-wrapper"><TableDateFormat timestamp={element.patient?element.patient.birthDate:''}/></td>
                }

              </tr>
            ))
          }
        }
      }
    }
    let alertComponent = <AlertMessage options={this.state.errorComponent}></AlertMessage>
    return (
      <div className="mainContentWrapper" onClick={() => this.props.tookAction()}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties onShowMeData={()=>this.filterOrDataChange()} prepareColumnsData={this.prepareColumnsData} onSearch={(val) => this.propsSearchTable(val)} options={[TableEnums.SEARCH, TableEnums.FILTER, TableEnums.ACTIVECOLUMNS]} filterData={this.state.filterData} sortData={sorting.sortingColumns} />
        <div className="mainContent">
          <SideBar />
          <LoaderModal show={this.state.showModalLoader}/>
          <div className="listDataWrapper">
            <div className="listData flipped">
              <div className="flipped">
                <table className="table">
                  <thead>
                    <tr>
                      {
                        sorting.sortingColumns.map(column => {
                          if(sorting.isColumnVisibleByKey(column.keyName, appLang)){
                            return <TableColumHead key={Math.random().toString(36).substr(2, 9)} sorting={sorting.sortingColumns} name={translateKey(column.name)} onSortType={(data) => this.propsSortType(data)} type={column.column} />
                          }else{
                            return null
                          }
                        }).filter(element => element !==null )
                      }
                    </tr>
                  </thead>
                  <tbody>{PreparedTableContent}</tbody>
                </table>
                {emptyHandler}
                {alertComponent}
                <TablePagination recalculateAll={()=>this.filterOrDataChange()} simpleDataUpdate={()=>this.filterOrDataChange()}  loading={this.state.loading} paginationObj={this.state.pagingItem} totalItems={this.state.pagingItem.totalItems}/>
              </div> 
            </div>
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    userIdColumn: state.auth.userId,
    clientsData: state.database.clients,
    patientLeadData: state.database.patientLead,
    patientClientData: state.database.patientClient,
    contractStatus: state.enums.contractStatus,
    activeColumns: state.activeColumns.data,
    activeColumnsTimeStamp: state.activeColumns.columnsTimeStamp,
    savedFilters: state.filters.data.clients
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  setData: (data) => dispatch(setClientsData(data)),
  pendingRequest: () => dispatch(pendingClientsData()),
  clearData: () => dispatch(removeClientsData()),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData()),
  setColumns: (data) => dispatch(setColumnsData(data)),
  addNewColumnTable: (data) => dispatch(addColumnData(data)),
  updateColumnTypeData: (type,data) => dispatch(updateColumTypeData(type, data)),
  removeColumns: () => dispatch(removeColumnsData()),
  setFilters: (type, data) => dispatch(setFiltersData(type, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientsMainPage));