import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import {RouteButtonComponent} from "../../../route-button-compontent/RouteButtonComponent"

class ClientCorespondenceAddressesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            same: this.props.same
        };
    }

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    render() {
        const {translateKey} = this.props;
        let clientData;
        if(this.props.same){
            return (
                <div className="lead-client-row">
                    <div className="lead-details-header">
                        <div className="filter-modal-option-text">{translateKey("correspondence_address")}: <span className="the-same-address-span">{translateKey("the_same")}</span></div>
                    </div>
                </div>
            );
        }else{
            if(this.state.isOpen){
                clientData = (
                    <div className="lead-details-client-info">
                        <div className="col-sm-12 col-lg-5">
                            {this.props.element.fullName!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_and_first_name")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.fullName}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.street!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("street")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.street}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.buildingNumber!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("building_number")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.buildingNumber}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.apartmentNumber!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("apartment_number")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.apartmentNumber}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.postalCode!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("postal_code")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.postalCode}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                        <div className="col-sm-12 col-lg-5">
                            {this.props.element.city!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("city")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.city}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.community!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("community")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.community}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.stateprovince!==null?
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("province")}</label>
                                    <div className="col-sm-8">
                                        <span className="lead-input-wrapper">
                                            {this.props.element.stateprovince}
                                        </span>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {this.props.element.countryregion!==null?
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("geo_region")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {this.props.element.countryregion}
                                    </span>
                                </div>
                            </div>
                                :
                                ''
                            }
                        </div>
                        <div className="col-lg-2 text-right lead-route-container">
                            <RouteButtonComponent element={this.props.element} text={translateKey("route")}/>
                        </div>
                    </div>
                )
            }
            return (
                <div className="lead-client-row">
                    <div className="lead-details-header">
                        <div className="filter-modal-option-text">{translateKey("correspondence_address")}</div>
                        <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                            {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                        </div>
                    </div>
                    {clientData}
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ClientCorespondenceAddressesComponent));