import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setStoreLang, translateKey } from "../../_redux/actions/index";
import { auth } from "../../_redux/actions/auth";
import ReqHelper from "../../_helpers/request_helper";
import Validate from "../../_helpers/validation";
import { SimpleLoader } from "../../_components/index";
import {IconsMap} from "../../_helpers/icons_set"
import { AlertModalInfoLogin } from "../../_components/index";
import { Alert } from "../../_constance/classes/index";

class LoginPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        username: {
          placehoder: this.props.translateKey("enter_login"),
          value:'',
          valid:false,
          validationRules: {
            minLength: 1,
            maxLength: 50
          }
        },
        password: {
          placehoder: this.props.translateKey("login_password"),
          value:'',
          valid:false,
          validationRules: {
            minLength: 1,
            maxLength: 50
          }
        }
      },
      submitted: false,
      selectedLanguage: null,
      errorsMessage: null,
      makingCall: false,
      errorComponent: Alert
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          controls: {
            ...prevState.controls,
            [name]: {
              ...prevState.controls[name],
              value: value,
              valid: Validate(value, prevState.controls[name].validationRules)
            }
          }
        };
      });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this._isMounted) {
      this.setState({ submitted: true });
    }
    if(this.state.selectedLanguage === 'DE') {
      console.log('data from login: ', this.state.selectedLanguage);
      // here shoud goes popup with error
      this.setState({
        errorComponent: {
          show:true,
          type: "danger",
          message: 'Bitte verwenden Sie den Sales Konfigurator – <a href="https://promedica-betreuung.de/app/">https://promedica-betreuung.de/app/</a> – das CRM steht nicht mehr zur Verfügung.'
        }
      })
    } else {
      if (this.state.selectedLanguage && this.state.controls.username.valid === true && this.state.controls.password.valid === true) {
        if (this._isMounted) {
          this.setState({ makingCall: true, errorsMessage:null })
        }
        const { controls } = this.state;
        let credData = {
          login: controls.username.value,
          rsapassword: ReqHelper.rsaPasswordMethod(controls.password.value)
        };
        this.props.authLogin(credData.login, credData.rsapassword)
          .catch((err)=>{
              if(err.code===801){
                if (this._isMounted) {
                  this.setState({
                    errorsMessage: "error_login_failed",
                    makingCall:false
                  })
                }
              }else{
                if (this._isMounted) {
                  this.setState({
                    errorsMessage: err.message,
                    makingCall: false
                  })
                }
              }
            }
          )
      }
    }
  }

  toggleModal() {
    this.setState({
      errorComponent: {
        show: false,
        type: "danger",
        message: 'Bitte verwenden Sie den Sales Konfigurator – <a href="https://promedica-betreuung.de/app/">https://promedica-betreuung.de/app/</a> – das CRM steht nicht mehr zur Verfügung.'
      }
    })
  } 

  handleLanguageChange(e) {
    const { value } = e.target;
    this.props.setAppLangName(value);
    this.setState({ selectedLanguage: value });
  }

  render() {
    const { controls, submitted, selectedLanguage, errorsMessage, makingCall, errorComponent } = this.state;
    return (
      <div className="loginContentWrapper">
        <div className="container loginContainerWrapper">
          <div className="loginContainer">
            <div className="formLoginWrapper">
              <div className="text-center">
                <img className="prom-logo-login" src={IconsMap.svg.promo_logo} alt={"Promedica"}/>
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="form-group text-center">
                  <select className="custom-select language-select" onChange={this.handleLanguageChange} required>
                    <option value="" hidden defaultValue>{this.props.translateKey("login_language_choose_hint")}</option>
                    <option value="DE">Deutsch</option>
                    <option value="EN">English</option>
                  </select>
                  {submitted && !selectedLanguage && (
                    <div className="help-block text-danger text-left">
                      {this.props.translateKey("login_language_please_select")}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="username-input-field">{this.props.translateKey("login_user")}</label>
                  <input id="username-input-field" type="text" className={"form-control" + (submitted && !controls.username.valid ? " is-invalid" : "")}
                    placeholder={this.props.translateKey("enter_login")}
                    name="username"
                    value={controls.username.value}
                    onChange={this.handleChange}
                    required
                  />
                  {submitted && !controls.username.valid && (
                    <div className="help-block text-danger">
                      {this.props.translateKey("login_user_please_enter")}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password-input-field">{this.props.translateKey("login_password")}</label>
                  <input id="password-input-field" type="password" className={"form-control" + (submitted && !controls.password.valid ? " is-invalid" : "")}
                    placeholder={this.props.translateKey("login_password")}
                    name="password"
                    value={controls.password.value}
                    onChange={this.handleChange}
                    required
                  />
                  {submitted && !controls.password.valid && (
                    <div className="help-block text-danger">
                      {this.props.translateKey("login_password_please_enter")}
                    </div>
                  )}
                </div>
                <div className="form-group"><button className="btn btn-success" onClick={this.handleSubmit} style={{ width: "100%" }}>{this.props.translateKey("login_login")}</button></div>
                {submitted &&  errorsMessage && (
                  <div className="help-block text-danger text-center">{this.props.translateKey(errorsMessage)}</div>
                )}
                {makingCall && (
                  <SimpleLoader />
                )}
              </form>
            </div>
            <img className="strangeLineClass" src={IconsMap.svg.pasek_small} alt=""/>
          </div>
        </div>
        <AlertModalInfoLogin size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message} toggle={() => this.toggleModal()}/>
      </div>
    );
  }
}

const mapStateToProps = state => {return {appLanguage: state.lang.appLanguage}};
const mapDispatchToProps = dispatch => ({
  setAppLangName: langName => dispatch(setStoreLang(langName)),
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  authLogin: (login, rsapassword) => dispatch(auth(login, rsapassword))
});
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(LoginPage));