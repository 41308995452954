import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";

class InvoiceCorrectionHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: props.status,
            exits: false,
            text: ''
        }
    }


    componentDidMount() {
        this.getStatusText();
    }

    getStatusText(){
        for(let i=0; i<this.props.correctionData.length; i++){
            if(this.props.correctionData[i].Value.toString().trim().toUpperCase()===this.props.status.toString().trim().toUpperCase()){
                this.setState({
                    exits: true,
                    text: this.props.correctionData[i].Label
                })
                break;
            }
        }
    }

    render() {
        if(this.state.exits){
            return (
                <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("last_correction_reason")}</label>
                    <div className="col-sm-8">
                        <span className="lead-input-wrapper">
                            {this.state.text}
                        </span>
                    </div>
                </div>
            )
        }else{
            return '';
        }

    }
}

const mapStateToProps = state => {
    return {
        i18: state.lang.i18data,
        correctionData: state.enums.invoiceCorrection
    };
  }
  
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceCorrectionHandler));