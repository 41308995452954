import axiosAuth from './config/axios-auth';

class BBFormService {
    savePatient(data) {
        // axiosAuth.post('/dynamic/retrieve', [{
        //     id: "0ba28e06-bbae-e911-80da-00155d00f809",
        //     entity: Entity.CONTACT,
        //     fields: [
        //         'firstname',
        //         'lastname',
        //         'is_mobile_phone',
        //     ],
        // }]).then(resp => console.log('r', resp))

        return axiosAuth.post('/dynamic/send', data);
    }

    retrievePatient(data) {
        return axiosAuth.post('/dynamic/retrieve', data);
    }

    assignPatients(data) {
        return axiosAuth.post('contracts/assignPatients', data)
    }
}

export default new BBFormService();