
import axiosFirebase from './config/axios-firebase';

export const saveColumnsData = function (userId, type, columnsData) {
    let data={
        "userId": userId,
        "viewType": type,
        "columns": columnsData
    }
    return axiosFirebase.post('columns/save', data)
        .then((data) => {
            return data;
        })
        .catch(error => {
            console.log(error);
        });
}

export const getColumnsData = function (userId) {
    return axiosFirebase.get(`columns/get?userId=${userId}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}