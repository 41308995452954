import * as React from 'react';
import { ClientStatus } from "../../_constance/enums/clientStatus";

export class ClientStatusHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: props.status
        }
    }

    getMeClientStatus(status){
        if(status!==null && status!== undefined){
            let objTable = ClientStatus.filter((element)=> {
                return element.Value===status.toString()
            });
            if(objTable.length>0){
                return this.props.tk(objTable[0].Key);
            }else{
                return '';
            }
        }else{
            return '';
        }
    }

    render() {
        const { status } = this.props
        return (
            <div className="flexAlignCenter">
                <span>{this.getMeClientStatus(status)}</span>
            </div>
        );
    }
}