import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import {Toggle} from "react-toggle-component"

class LeadPatientCarerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }

    onOpenShow(){
      this.setState(prevState => {
          return {
              ...prevState,
              isOpen: prevState.isOpen? false: true
              }
          }
      );
    }

    handleChangeOtherRequirements(event){
      this.props.element.carerPreferences.OtherRequirements = event;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                ...prevState.element,
                carerPreferences: {
                  ...prevState.element.carerPreferences,
                  OtherRequirements: event
                }
            }
          }
      });
      this.props.editedData();
    }

    handleChangeAgeFrom1(event){
      if(event.trim()===''){
        this.props.element.carerPreferences.AgeFrom1 = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    carerPreferences: {
                      ...prevState.element.carerPreferences,
                      AgeFrom1: event
                    }
                }
            }
        });
        this.props.editedData();
      }else{
        if(/^\d+$/.test(event.trim())){
          this.props.element.carerPreferences.AgeFrom1 = event;
          this.setState(prevState => {
              return {
                  ...prevState,
                  element: {
                      ...prevState.element,
                      carerPreferences: {
                        ...prevState.element.carerPreferences,
                        AgeFrom1: event
                      }
                  }
              }
          });
          this.props.editedData();
        }
      }

    }

    handleChangeAgeTo1(event){
      if(event.trim()===''){
        this.props.element.carerPreferences.AgeTo1 = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    carerPreferences: {
                      ...prevState.element.carerPreferences,
                      AgeTo1: event
                    }
                }
            }
        });
        this.props.editedData();
      }else{
        if(/^\d+$/.test(event.trim())){
          this.props.element.carerPreferences.AgeTo1 = event;
          this.setState(prevState => {
              return {
                  ...prevState,
                  element: {
                      ...prevState.element,
                      carerPreferences: {
                        ...prevState.element.carerPreferences,
                        AgeTo1: event
                      }
                  }
              }
          });
          this.props.editedData();
        }
      }

    }

    handleChangeAgeFrom2(event){
      if(event.trim()===''){
        this.props.element.carerPreferences.AgeFrom2 = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    carerPreferences: {
                      ...prevState.element.carerPreferences,
                      AgeFrom2: event
                    }
                }
            }
        });
        this.props.editedData();
      }else{
        if(/^\d+$/.test(event.trim())){
          this.props.element.carerPreferences.AgeFrom2 = event;
          this.setState(prevState => {
              return {
                  ...prevState,
                  element: {
                      ...prevState.element,
                      carerPreferences: {
                        ...prevState.element.carerPreferences,
                        AgeFrom2: event
                      }
                  }
              }
          });
          this.props.editedData();
        }
      }

    }

    handleChangeAgeTo2(event){
      if(event.trim()===''){
        this.props.element.carerPreferences.AgeTo2 = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    carerPreferences: {
                      ...prevState.element.carerPreferences,
                      AgeTo2: event
                    }
                }
            }
        });
        this.props.editedData();
      }else{
        if(/^\d+$/.test(event.trim())){
          this.props.element.carerPreferences.AgeTo2 = event;
          this.setState(prevState => {
              return {
                  ...prevState,
                  element: {
                      ...prevState.element,
                      carerPreferences: {
                        ...prevState.element.carerPreferences,
                        AgeTo2: event
                      }
                  }
              }
          });
          this.props.editedData();
        }
      }

    }

    toggleDrivingLicence(){
      this.props.element.carerPreferences.DrivingLicense?this.props.element.carerPreferences.DrivingLicense=false:this.props.element.carerPreferences.DrivingLicense=true;
      this.props.editedData();
    }

    prepareMeGender(){
      return (
          <select className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangeGender(e.target.value)} value={this.props.element.carerPreferences.Gender}>
            <option value='' hidden>{this.props.translateKey('choose_select')}</option>
            <option defaultValue={this.props.element.carerPreferences.Gender==="717700000"} key={"smoking-atititude-717700000"} value={"717700000"}>{this.props.translateKey("male")}</option>
            <option defaultValue={this.props.element.carerPreferences.Gender==="717700001"} key={"smoking-atititude-717700001"} value={"717700001"}>{this.props.translateKey("female")}</option>
          </select>
      )
    }

    handleChangeGender(event){
      this.props.element.carerPreferences.Gender = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                  ...prevState.element,
                  carerPreferences: {
                    ...prevState.element.carerPreferences,
                    Gender: event
                  }
                }
            }
        });
        this.props.editedData();
    }  
    
    prepareMeSmoking(){
      return (
          <select className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangeSmoking(e.target.value)} value={this.props.element.carerPreferences.SmokingAttitude}>
          <option value='' hidden>{this.props.translateKey('choose_select')}</option>
          {this.props.smokingAttitude.map((value, index) => {
              if(value.Value === this.props.element.carerPreferences.SmokingAttitude){
                return <option defaultValue key={"smoking-atititude-"+value.Value} value={value.Value}>{value.Label}</option>
              }else{
                return <option  key={"smoking-atititude-"+value.Value} value={value.Value}>{value.Label}</option>
              }
          })}
          </select>
      )
    }

    handleChangeSmoking(event){
        this.props.element.carerPreferences.SmokingAttitude = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                  ...prevState.element,
                  carerPreferences: {
                    ...prevState.element.carerPreferences,
                    SmokingAttitude: event
                  }
                }
            }
        });
        this.props.editedData();
    }    

    render() {
      const {carerPreferences} = this.props.element;
      let patientHealthData;
      if(this.state.isOpen){
        patientHealthData = (
          <div className="lead-first-row" key='clientDetailsBasicInformationLongKey1'>
            <div className="col-sm-12 col-lg-5">
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("age_from_1")}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeAgeFrom1(e.target.value)} value={carerPreferences?carerPreferences.AgeFrom1!==null?carerPreferences.AgeFrom1:'':''}/>
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("age_to_1")}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeAgeTo1(e.target.value)} value={carerPreferences?carerPreferences.AgeTo1!==null?carerPreferences.AgeTo1:'':''}/>
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("age_from_2")}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeAgeFrom2(e.target.value)} value={carerPreferences?carerPreferences.AgeFrom2!==null?carerPreferences.AgeFrom2:'':''}/>
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("age_to_2")}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeAgeTo2(e.target.value)} value={carerPreferences?carerPreferences.AgeTo2!==null?carerPreferences.AgeTo2:'':''}/>
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("gender")}</label>
                    <div className="col-sm-8">
                      {this.prepareMeGender()}
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("smoking_attitude")}</label>
                    <div className="col-sm-8">
                      {this.prepareMeSmoking()}
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("other_requirements")}</label>
                    <div className="col-sm-8">
                      <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeOtherRequirements(e.target.value)} value={carerPreferences?carerPreferences.OtherRequirements===null?'':carerPreferences.OtherRequirements:''}/>
                    </div>
                  </div>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic break-word-all">{this.props.translateKey("is_driving_licence_required")}</label>
                    <div className="col-sm-8 text-center">
                        <Toggle name="toggle-driving-licence" checked={carerPreferences.DrivingLicense} onToggle={()=> this.toggleDrivingLicence()}/>
                    </div>
                </div>
            </div>
          </div>
        );
      }
      return (
        <div className="lead-client-row">
            <div className="lead-details-header">
                <div className="filter-modal-option-text">{this.props.translateKey("carer_preferences")}</div>
                <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                    {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                </div>
            </div>
            {patientHealthData}
        </div>
      );
    }
}
const mapStateToProps = state => {return {
  i18: state.lang.i18data,
  smokingAttitude: state.enums.smokingAttitude
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientCarerComponent));