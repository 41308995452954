import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ComposedChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer, Tooltip, Legend} from 'recharts';
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";
import { Colors } from "../../../../_constance/enums/colors";

class LeadsPerMeetings extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      dashboardData: this.props.dashboardData,
      loadingDashBoard: true,
      leadsDashBoard: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    let data = [];
    if(this.props.dashboardData!== null && this.props.dashboardData !== undefined ){
      if(this.props.dashboardData.LeadAppointmentsStatistics !== null && this.props.dashboardData.LeadAppointmentsStatistics !== undefined){
        data =  this.props.dashboardData.LeadAppointmentsStatistics.Months.map(element =>{
          return {
            date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
            completed: element.NumberOfCompletedAppointments,
            scheluded: element.NumberOfScheduledAppointments,
            canceled: element.NumberOfCanceledAppointments,
          }
        })
      }
    }
    this.setState({
      loadingDashBoard: false,
      leadsDashBoard: data
    })
  }

  prepareMeMonthData = ( element ) =>{
    if(element<10){
      return "0"+element
    }else{
      return element
    }
  }


  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashBoard} = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashBoard){
      Content = (
        <ResponsiveContainer width="80%" height={400}>
          <ComposedChart data={this.state.leadsDashBoard} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <XAxis name={translateKey("date")} dataKey="date" type="category" />
            <YAxis />
            <Tooltip />
            <Bar name={translateKey("cancelled_appointments")} dataKey="canceled" fill={Colors.DARKBLUE}>
              <LabelList dataKey="canceled" position="top" offset={5} />
            </Bar>
            <Bar name={translateKey("completed_appointments")} dataKey="completed" fill={Colors.DARKGREEN}>
              <LabelList dataKey="completed" position="top" offset={5} />
            </Bar>
            <Bar name={translateKey("scheluded_appointments")} dataKey="scheluded" fill={Colors.LIGHTGREEN}>
              <LabelList dataKey="scheluded" position="top" offset={5} />
            </Bar>
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              width={200}
              height={400}
              wrapperStyle={{
                overflowY: "auto",
                overflowX: "hidden",
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
                fontSize: "14px"
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
    return (
      <React.Fragment>
        <div className="charts-pie-container-wrapper">
          <h5 className="text-center">{translateKey("dashboard_leads_by_meetings")}</h5>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(null, mapDispatchToProps)(LeadsPerMeetings));