import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InvoiceCorrectionHandler} from "../../index";
import { default as InvoiceDataHelperComponent } from "../invoice-data-helper-component/invoice-data-helper-component";
import { ReqHelper } from "../../../_helpers/index";

class InvoiceDataComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            invoiceStatus: this.props.invoiceStatus
        };
    }
    
    
    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    render() {
        const { translateKey, element } = this.props;
        let InvoiceData;
        if(this.state.isOpen){
            InvoiceData = (
                <div className="lead-details-client-info">
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.invoiceNumber} fieldName={translateKey("invoice_number")} conditionField={element.invoice.invoiceNumber}/>
                        <InvoiceDataHelperComponent condition={null} value={ReqHelper.numberWithSpaces(element.invoice.amount) + ' ' + element.invoice.currency} fieldName={translateKey("invoice_amount")} conditionField={element.invoice.amount}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoiceStatus} fieldName={translateKey("invoice_status")} conditionField={element.invoiceStatus} valueList={this.props.invoiceStatus}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.periodFrom} valueType="date" fieldName={translateKey("settlement_period_from")} conditionField={element.invoice.periodFrom}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.periodTo} valueType="date" fieldName={translateKey("settlement_period_to")} conditionField={element.invoice.periodTo}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.daysInPeriods} fieldName={translateKey("settlement_period_days")} conditionField={element.invoice.daysInPeriods}/>
                        <InvoiceDataHelperComponent condition={null} value={element.Company.Name} fieldName={translateKey("company")} conditionField={element.Company.Name}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.swift} fieldName={translateKey("SWIFT")} conditionField={element.invoice.swift}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.iban} fieldName={translateKey("IBAN")} conditionField={element.invoice.iban}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.transferTitle} fieldName={translateKey("invoice_transfer_title")} conditionField={element.invoice.transferTitle}/>
                        <InvoiceDataHelperComponent condition={null} value={ReqHelper.numberWithSpaces(element.invoice.sumOfCurrentContributions) + ' ' + element.invoice.currency} fieldName={translateKey("invoice_sum_of_current_contributions")} conditionField={element.invoice.sumOfCurrentContributions}/>
                        <InvoiceDataHelperComponent condition={null} value={element.invoice.dateOfContributions} valueType="date"  fieldName={translateKey("invoice_payment_date")} conditionField={element.invoice.dateOfContributions}/>
                        <InvoiceDataHelperComponent condition={null} value={ReqHelper.numberWithSpaces(element.invoice.amount-element.invoice.sumOfCurrentContributions) + ' ' + element.invoice.currency} fieldName={translateKey("invoice_left_to_pay")} conditionField={element.invoice.amount}/>
                        {
                            element.invoice.correction.amount!==0 &&
                                <React.Fragment>
                                    <InvoiceDataHelperComponent condition={null} value={element.invoice.correction.date} valueType="date" fieldName={translateKey("last_correction_date")} conditionField={element.invoice.correction.date}/>
                                    <InvoiceDataHelperComponent condition={0} value={ReqHelper.numberWithSpaces(element.invoice.correction.amount) + ' ' + element.invoice.currency} fieldName={translateKey("last_correction_amount")} conditionField={element.invoice.correction.amount}/>
                                    <div className="col-sm-12 col-lg-6">    
                                        <InvoiceCorrectionHandler status={element.invoice.correction.reason}/>
                                    </div>
                                    {element.invoice.correction.comment &&
                                        <InvoiceDataHelperComponent condition={null} value={element.invoice.correction.comment} fieldName={translateKey("last_correction_remarks")} conditionField={element.invoice.correction.comment}/>
                                    }
                                </React.Fragment>
                        }
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{translateKey("invoice_data")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                    </div>
                </div>
                {InvoiceData}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(InvoiceDataComponent));