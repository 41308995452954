import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import DatePicker, { registerLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import deDe from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
registerLocale('en-GB', enGB);
registerLocale('de-De', deDe);

class DatePickerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.timestamp,
            readOnly: this.props.readOnly,
            disabled: this.props.disabled
        };
    }

    dateConversion(timestamp, justHours){
        let myDate;
        if(timestamp){
            if(timestamp.toString().length<=10){
                myDate = new Date(timestamp*1000)
            }else{
                if(timestamp.toString().length===13){
                    myDate = new Date(timestamp);
                }else{
                    if(justHours){
                        if(timestamp.toString().charAt(0)==="-"){
                            myDate = new Date(timestamp*1000)
                        }else{
                            myDate = new Date();
                        }
                    }else{
                        if(timestamp.toString().charAt(0)==="-"){
                            myDate = new Date(timestamp*1000)
                        }else{
                            myDate = new Date();
                            myDate.setUTCHours(0,0,0,0);
                        }
                    }
                }
            }
        }
        return myDate;
    }

    handleDateChange(event){
        this.props.onDateChange(event);
    }

    render() {
        if(this.props.withTime){
            return (
                <DatePicker
                    selected={this.dateConversion(this.props.timestamp, false)}
                    onChange={(e)=>this.handleDateChange(e)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeCaption="time"
                    showTimeSelect
                    withPortal={this.props.withPortal}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    className={"form-control "+ this.props.className}
                    readOnly = {this.state.readOnly}
                    disabled = {this.state.disabled}
                    locale={this.props.appLang==="DE"? "de-De": "en-GB"}
                />
            );
        }else{
            if(this.props.withYear){
                return (
                    <DatePicker
                        selected={this.dateConversion(this.props.timestamp, true)}
                        onChange={(e)=>this.handleDateChange(e)}
                        dateFormat="dd.MM.yyyy"
                        withPortal={this.props.withPortal}
                        className={"form-control "+ this.props.className}
                        readOnly = {this.state.readOnly}
                        disabled = {this.state.disabled}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        locale={this.props.appLang==="DE"? "de-De": "en-GB"}
                        maxDate={new Date()}
                    />
                );
            }else{
                return (
                    <DatePicker
                        selected={this.dateConversion(this.props.timestamp, true)}
                        onChange={(e)=>this.handleDateChange(e)}
                        dateFormat="dd.MM.yyyy"
                        withPortal={this.props.withPortal}
                        className={"form-control "+ this.props.className}
                        readOnly = {this.state.readOnly}
                        disabled = {this.state.disabled}
                        locale={this.props.appLang==="DE"? "de-De": "en-GB"}
                        inline={this.props.inline}
                        isClearable={this.props.isClearable}
                        placeholderText={this.props.placeholderText}
                    />
                );
            }
        }
    }
}
const mapStateToProps = state => {return {appLang: state.lang.appLanguage};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatePickerComponent));