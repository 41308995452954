import React from 'react';
import Select from 'react-select';

function setValue(value, filterTable) {
    const itemValue = filterTable.map(item => item.options.filter(item => {
        return item.value == value;
    }));
    return itemValue.flat();
}
const GroupedSelect = ({ options, value, disabled, onChange, searchable, name }) => {
    setValue(value, options);
    return (
        <Select
            disabled={disabled} 
            name={name}
            value={ setValue(value, options) }
            options={options}
            isDisabled={disabled}
            onChange={onChange}
            searchable={searchable}
        />
    )
}

export default GroupedSelect