import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PieChart, Pie, Cell, Legend} from 'recharts';
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable} from "../../../index";
import * as MultiplierStatus from "../../../../_constance/enums/MultiplierStatus";
import { Colors } from "../../../../_constance/enums/colors";
import { baseData } from '../../../../_constance/base_data';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, value}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  )
};

class MultpiliersPerStatus extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      data: [],
      loadingDashboard: true,
      show: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    let data = [];
    if(this.props.multipliersData.length>0){
      MultiplierStatus.LIST.forEach(status=>{
        let total = this.props.multipliersData.reduce((acc, item) => {
          if(item.statusMultiplier!==null){
            if(item.statusMultiplier.toString() === status){
              return acc = acc+1
            }else{
              return acc;
            }
          }else{
            return acc;
          }
        }, 0);
        if(total>0){
          data.push({
            amount: total,
            name: this.getMeStatusText(status),
            status: status,
            color: this.getMeStatusColor(status)
          })
        }
      })
      let total = this.props.multipliersData.reduce((acc, item) => {
        if(item.statusMultiplier === null){
          return acc = acc+1
        }else{
          return acc;
        }
      }, 0);
      if(total>0){
        let emptyObj = {
          amount: total,
          name: this.props.translateKey("no_information"),
          status: '---',
          color: Colors.GRAY
        }
        data = [emptyObj, ...data]
      }
    }
    this.setState({
      loadingDashboard: false,
      data: data,
      show: this.props.multipliersData.length>0
    })
  }

  getMeStatusText = (status) =>{
    const { translateKey } = this.props;
    let text = "---";
    if (status !== null && status !== undefined) {
      switch (status) {
        case MultiplierStatus.POTENTIAL:
          text = translateKey("multiplier_status_potential");
          break;
        case MultiplierStatus.ACTIVE:
          text = translateKey("multiplier_status_active");
          break;
        case MultiplierStatus.NOT_ACTIVE:
          text = translateKey("multiplier_status_inactive");
          break;
        default:
      }
    }
    return text;    
  }

  getMeStatusColor = (status) =>{
    let color = "---";
    if (status !== null && status !== undefined) {
      switch (status) {
        case MultiplierStatus.POTENTIAL:
          color = Colors.DARKBLUE
          break;
        case MultiplierStatus.ACTIVE:
          color = Colors.DARKGREEN
          break;
        case MultiplierStatus.NOT_ACTIVE:
          color = Colors.LIGHTGREEN
          break;
        default:
      }
    }
    return color;    
  }

  handleClick = (data) => {
    if(data.status!=="---"){
      this.props.history.push(`${baseData.subFolders}multipliers/?type=status&param=${data.status}`);
    }
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingSource } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingSource){
      Content = (
        <PieChart width={700} height={420}>
          <Pie
            data={this.state.data}
            cx={200}
            cy={200}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={150}
            fill="#8884d8"
            dataKey="amount"
            onClick={this.handleClick}
            className="cursor-pointer"
          >
            {this.state.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="middle"
            align="right"
            layout="vertical"
            width={200}
            height={420}
            wrapperStyle={{
              overflowY: "auto",
              overflowX: "hidden",
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
              fontSize: "14px"
            }}
          />
        </PieChart>
      );
    }
    return (
      <React.Fragment>
        {this.state.show===true &&
          <div className="charts-pie-container-wrapper">
            <h5 className="text-center">{translateKey("multipliers_status_structute")}</h5>
            {Content}
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    multipliersData: state.database.multipliers
  };
};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultpiliersPerStatus));
