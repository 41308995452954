import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import {Toggle} from "react-toggle-component"

class LeadPatientDiseasesComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
            arrayDisease: []
        };
    }

    componentDidMount() {this.prepareDiseaseSort()} 
    
    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    handleChangeOtherDiseases(event){
        this.props.element.otherDiseases = event;
        this.setState(prevState => {
            return {
                ...prevState,
                element: {
                    ...prevState.element,
                    otherDiseases: event
                }
            }
        });
        this.props.editedData();
    }

    toggleThisElement(element){
        this.props.element.DiseaseListResource[element.Schema]? this.props.element.DiseaseListResource[element.Schema]=false: this.props.element.DiseaseListResource[element.Schema]=true;
        element.Checked? element.Checked = false: element.Checked = true;
        this.props.editedData();
    }

    getMeDiseaseName(key){
        for(let i=0; i < this.props.diseasesList.length; i++){
            if(this.props.diseasesList[i].SchemaName === key){
                return this.props.diseasesList[i].Name
            }
        }
        return key;
    }

    prepareMeToggleData(){
        return this.state.arrayDisease.map(element => (
            <div className="form-group row flexAlignCenter no-margin-row" key={element.Schema}>
                <label className="col-sm-4 col-form-label form-label-basic break-word-all">{element.Name}</label>
                <div className="col-sm-8 text-center">
                    <Toggle name={"toggle-"+element.Schema} checked={element.Checked} onToggle={()=> this.toggleThisElement(element)}/>
                </div>
            </div>
        ));
    }

    prepareDiseaseSort(){
        let newArray = Object.keys(this.props.element.DiseaseListResource).map(e => {
            return {
                Schema: e,
                Name: this.getMeDiseaseName(e),
                Checked: this.props.element.DiseaseListResource[e]
            }
        });
        newArray.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
        this.setState({arrayDisease: newArray});
    }

    render() {
        let patientAddressData;
        if(this.state.isOpen){
            patientAddressData = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        {this.prepareMeToggleData()}
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{this.props.translateKey("other_diseases")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeOtherDiseases(e.target.value)} value={this.props.element.otherDiseases?this.props.element.otherDiseases:''}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{this.props.translateKey("diseases")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?this.props.translateKey("show_less"):this.props.translateKey("show_more")}
                    </div>
                </div>
                {patientAddressData}
            </div>
        );
    }
}

const mapStateToProps = state => {return {diseasesList: state.enums.diseases};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadPatientDiseasesComponent));