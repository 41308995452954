import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import { CheckBoxSmallMultipleComponent } from '../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component';
import produce from "immer";

class Section4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-4'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, isImportant, translateKey } = this.props;
        const { section } = this.state;
        const isPatientSmokes = section.getFieldByKey('is_patient_smokes');
        const isInfectiousDiseases = section.getFieldByKey('is_infectious_diseases');
        const isFamilySufferInfectionusDiseases = section.getFieldByKey('is_family_suffer_infectionus_diseases');
        //first row fields
        const isAllergiesList = section.getFieldByKey('is_allergies_list')
        const isDifficultyIWDBA = section.getFieldByKey('is_difficulty_in_walking_determined_by_age')
        const isAlzheimersDTL = section.getFieldByKey('is_alzheimers_disease_the_list')
        const isAsthmaList = section.getFieldByKey('is_asthma_list')
        const isChronicDL = section.getFieldByKey('is_chronic_diarrhea_list')
        const isBedsoresL = section.getFieldByKey('is_bedsores_list')
        //second row fields
        const isInitialSODL = section.getFieldByKey('is_initial_stages_of_dementia_list')
        const isDementiaL = section.getFieldByKey('is_dementia_list')
        const isAtopicD = section.getFieldByKey('is_atopic_dermatitis')
        const isDiabets = section.getFieldByKey('is_diabetes')
        const isDiabetsML = section.getFieldByKey('is_diabetes_mellitus_list')
        const isMyocardialIL = section.getFieldByKey('is_myocardial_infarction_list')
        //third row fields
        const isHeartFL = section.getFieldByKey('is_heart_failure_list')
        const isArrhythmiasL = section.getFieldByKey('is_arrhythmias_list')
        const isHyperL = section.getFieldByKey('is_hypertension_list')
        const isIncontinenceL = section.getFieldByKey('is_incontinence_list')
        const isMultipleCTL = section.getFieldByKey('is_multiple_sclerosis_the_list')
        const isOsteoporosisL = section.getFieldByKey('is_osteoporosis_list')
        //fourth row fields
        const isParkinsonDL = section.getFieldByKey('is_parkinsons_disease_list')
        const isRheumaticDL = section.getFieldByKey('is_rheumatic_disease_list')
        const isLSPL = section.getFieldByKey('is_left_sided_paresis_list')
        const isRSPL = section.getFieldByKey('is_right_sided_paresis_list')
        const isStomaL = section.getFieldByKey('is_stoma_list')
        const isCancerL = section.getFieldByKey('is_cancer_list')
        //REST
        const isOtherDD= section.getFieldByKey('is_other_description_de')
        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">
                    {this.props.subsectionNumber}. {this.props.subsectionName}
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                    <div className="row col-sm-12">
                        <div className="col-sm-12 form-margin-bottom-fix">
                            <label className="form-label-basic"><span className="quick-label-position-fix">{translateKey("form-text-choroben")}</span></label>
                            <div className="checkbox-button-group-wrapper row">
                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isAllergiesList.checkImportant)?' input_important_class':'')}  fieldName={"allergies"} setTypeChangeToParent={this.setTypeChange} field={isAllergiesList} text={translateKey("allergies")} transKey="allergies" keyName="is_allergies_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isDifficultyIWDBA.checkImportant)?' input_important_class':'')}  fieldName={"gehschwache"} setTypeChangeToParent={this.setTypeChange} field={isDifficultyIWDBA} text={translateKey("form-text-gehschwache")} transKey="form-text-gehschwache" keyName="is_difficulty_in_walking_determined_by_age"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isAlzheimersDTL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-alzheimer"} setTypeChangeToParent={this.setTypeChange} field={isAlzheimersDTL} text={translateKey("form-text-alzheimer")} transKey="form-text-alzheimer" keyName="is_alzheimers_disease_the_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isAsthmaList.checkImportant)?' input_important_class':'')}  fieldName={"form-text-asthma"} setTypeChangeToParent={this.setTypeChange} field={isAsthmaList} text={translateKey("form-text-asthma")} transKey="form-text-asthma" keyName="is_asthma_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isChronicDL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-chronische"} setTypeChangeToParent={this.setTypeChange} field={isChronicDL} text={translateKey("form-text-chronische")} transKey="form-text-chronische" keyName="is_chronic_diarrhea_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isBedsoresL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-dekubitus"} setTypeChangeToParent={this.setTypeChange} field={isBedsoresL} text={translateKey("form-text-dekubitus")} transKey="form-text-dekubitus" keyName="is_bedsores_list"/>
                                </div>

                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isInitialSODL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-demenz-a"} setTypeChangeToParent={this.setTypeChange} field={isInitialSODL} text={translateKey("form-text-demenz-a")} transKey="form-text-demenz-a" keyName="is_initial_stages_of_dementia_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isDementiaL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-demenz-f"} setTypeChangeToParent={this.setTypeChange} field={isDementiaL} text={translateKey("form-text-demenz-f")} transKey="form-text-demenz-f" keyName="is_dementia_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isAtopicD.checkImportant)?' input_important_class':'')}  fieldName={"form-text-dermatitis"} setTypeChangeToParent={this.setTypeChange} field={isAtopicD} text={translateKey("form-text-dermatitis")} transKey="form-text-dermatitis" keyName="is_atopic_dermatitis"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isDiabets.checkImportant)?' input_important_class':'')}  fieldName={"form-text-diabetes"} setTypeChangeToParent={this.setTypeChange} field={isDiabets} text={translateKey("form-text-diabetes")} transKey="form-text-diabetes" keyName="is_diabetes"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isDiabetsML.checkImportant)?' input_important_class':'')}  fieldName={"form-text-diabetes-insulin"} setTypeChangeToParent={this.setTypeChange} field={isDiabetsML} text={translateKey("form-text-diabetes-insulin")} transKey="form-text-diabetes-insulin" keyName="is_diabetes_mellitus_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isMyocardialIL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-herzinfarkt"} setTypeChangeToParent={this.setTypeChange} field={isMyocardialIL} text={translateKey("form-text-herzinfarkt")} transKey="form-text-herzinfarkt" keyName="is_myocardial_infarction_list"/>
                                </div>

                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isHeartFL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-herzinsuffizienz"} setTypeChangeToParent={this.setTypeChange} field={isHeartFL} text={translateKey("form-text-herzinsuffizienz")} transKey="form-text-herzinsuffizienz" keyName="is_heart_failure_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isArrhythmiasL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-herzrhythmusstörung"} setTypeChangeToParent={this.setTypeChange} field={isArrhythmiasL} text={translateKey("form-text-herzrhythmusstörung")} transKey="form-text-herzrhythmusstörung" keyName="is_arrhythmias_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isHyperL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-hypertonie"} setTypeChangeToParent={this.setTypeChange} field={isHyperL} text={translateKey("form-text-hypertonie")} transKey="form-text-hypertonie" keyName="is_hypertension_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isIncontinenceL.checkImportant)?' input_important_class':'')}  fieldName={"bb_defecation_data_p_9"} setTypeChangeToParent={this.setTypeChange} field={isIncontinenceL} text={translateKey("bb_defecation_data_p_9")} transKey="bb_defecation_data_p_9" keyName="is_incontinence_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isMultipleCTL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-multiple-sklerose"} setTypeChangeToParent={this.setTypeChange} field={isMultipleCTL} text={translateKey("form-text-multiple-sklerose")} transKey="form-text-multiple-sklerose" keyName="is_multiple_sclerosis_the_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isOsteoporosisL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-osteoporose"} setTypeChangeToParent={this.setTypeChange} field={isOsteoporosisL} text={translateKey("form-text-osteoporose")} transKey="form-text-osteoporose" keyName="is_osteoporosis_list"/>
                                </div>

                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isParkinsonDL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-parkinson"} setTypeChangeToParent={this.setTypeChange} field={isParkinsonDL} text={translateKey("form-text-parkinson")} transKey="form-text-parkinson" keyName="is_parkinsons_disease_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isRheumaticDL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-rheuma"} setTypeChangeToParent={this.setTypeChange} field={isRheumaticDL} text={translateKey("form-text-rheuma")} transKey="form-text-rheuma" keyName="is_rheumatic_disease_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isLSPL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-schlaganfall-li"} setTypeChangeToParent={this.setTypeChange} field={isLSPL} text={translateKey("form-text-schlaganfall-li")} transKey="form-text-schlaganfall-li" keyName="is_left_sided_paresis_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isRSPL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-schlaganfall-re"} setTypeChangeToParent={this.setTypeChange} field={isRSPL} text={translateKey("form-text-schlaganfall-re")} transKey="form-text-schlaganfall-re" keyName="is_right_sided_paresis_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isStomaL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-stoma"} setTypeChangeToParent={this.setTypeChange} field={isStomaL} text={translateKey("form-text-stoma")} transKey="form-text-stoma" keyName="is_stoma_list"/>
                                    <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isCancerL.checkImportant)?' input_important_class':'')}  fieldName={"form-text-tumor"} setTypeChangeToParent={this.setTypeChange} field={isCancerL} text={translateKey("form-text-tumor")} transKey="form-text-tumor" keyName="is_cancer_list"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <InputTypeTextComponent showValidation={this.props.showValidationErrors} isImportant={isImportant} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isOtherDD} text={translateKey("form-text-sonstige")} transKey="form-text-sonstige" keyName="is_other_description_de"/>

                    <CheckBoxComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isPatientSmokes.value} validationErrors={isPatientSmokes.validationErrors} text={translateKey("form-text-betreuende")} transKey="form-text-betreuende" keyName="is_patient_smokes"/>

                    <CheckBoxComponent isImportant={isImportant&&isInfectiousDiseases.checkImportant} showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isInfectiousDiseases.value} validationErrors={isInfectiousDiseases.validationErrors} text={translateKey("form-text-alzheimer-krankheiten")} transKey="form-text-alzheimer-krankheiten" keyName="is_infectious_diseases"/>

                    { isInfectiousDiseases.value === true && (
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_family_suffer_infectionus_diseases_des_de')} text={translateKey("form-text-welche")} transKey="form-text-welche" keyName="is_family_suffer_infectionus_diseases_des_de"/>
                    )}

                    <CheckBoxComponent isImportant={isImportant&&isFamilySufferInfectionusDiseases.checkImportant} showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isFamilySufferInfectionusDiseases.value} validationErrors={isFamilySufferInfectionusDiseases.validationErrors} text={translateKey("form-text-anstecken")} transKey="form-text-anstecken" keyName="is_family_suffer_infectionus_diseases"/>

                    { isFamilySufferInfectionusDiseases.value === true && (
                        <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_infectious_diseases_what_de')} text={translateKey("form-text-welche")} transKey="form-text-welche" keyName="is_infectious_diseases_what_de"/>
                    )}

                    </fieldset>
                </form>
            </div>
        ); 
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section4));
