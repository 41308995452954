import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InvoicePaymentHandler, TableDateFormat } from "../../index";

class InvoiceDataHelperComponent extends React.Component {
    render() {
        const { condition, value, fieldName, conditionField, valueType, valueList } = this.props;
        return (
            conditionField!==condition &&
            <div className="col-sm-12 col-lg-6">
                <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{fieldName}</label>
                    <div className="col-sm-8">
                        {valueList &&
                            <InvoicePaymentHandler status={value} statusData={valueList}/>
                        }
                        {!valueList && 
                            <span className="lead-input-wrapper">
                                {valueType==="date"?
                                    <TableDateFormat timestamp={value}/>
                                    :
                                    value
                                }
                            </span>
                        }   
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(InvoiceDataHelperComponent));