
import { SET_COLUMNS_DATA, REMOVE_COLUMNS_DATA, ADD_COLUMN_DATA, UPDATE_COLUMN_DATA} from "../actions/action-types";

const initialState = {
  data: [],
  columnsTimeStamp:null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLUMNS_DATA:
      return {
        ...state,
        data: action.data,
        columnsTimeStamp: new Date().getTime()
      };
    case ADD_COLUMN_DATA:
      return {
        ...state,
        data: [...state.data, action.data],
      };
    case REMOVE_COLUMNS_DATA:
      return {
        data: [],
        columnsTimeStamp:null
      };
    case UPDATE_COLUMN_DATA:
      return {
        ...state,
        data: state.data.map((element)=>{
          if(element.name === action.rowType){
            return action.data
          }else{
            return element;
          }
        }),
      };
    default:
      return state;
  }
};

export default reducer;