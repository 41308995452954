export const IconsMap = {
    hdpi:{
      clients: require("../_assets/img/hdpi/clients.png"),
      white_logo: require("../_assets/img/hdpi/E01_logo.png"),
      information: require("../_assets/img/hdpi/information.png"),
      leads: require("../_assets/img/hdpi/leads.png"),
      mail_icon: require("../_assets/img/hdpi/Mail.png"),
      search_icon: require("../_assets/img/hdpi/search_icon.png"),
      tasks: require("../_assets/img/hdpi/tasks.png"),
      strange_line: require("../_assets/img/hdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/hdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/hdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/hdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/hdpi/right-arrow-wall.png"),     
      carer_no_image: require("../_assets/img/hdpi/test_carer_image.png")
    },
    mdpi:{
      clients: require("../_assets/img/mdpi/clients.png"),
      white_logo: require("../_assets/img/mdpi/E01_logo.png"),
      information: require("../_assets/img/mdpi/information.png"),
      leads: require("../_assets/img/mdpi/leads.png"),
      mail_icon: require("../_assets/img/mdpi/Mail.png"),
      search_icon: require("../_assets/img/mdpi/search_icon.png"),
      tasks: require("../_assets/img/mdpi/tasks.png"),
      strange_line: require("../_assets/img/mdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/mdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/mdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/mdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/mdpi/right-arrow-wall.png")
    },
    xhdpi:{
      clients: require("../_assets/img/xhdpi/clients.png"),
      white_logo: require("../_assets/img/xhdpi/E01_logo.png"),
      information: require("../_assets/img/xhdpi/information.png"),
      leads: require("../_assets/img/xhdpi/leads.png"),
      mail_icon: require("../_assets/img/xhdpi/Mail.png"),
      search_icon: require("../_assets/img/xhdpi/search_icon.png"),
      tasks: require("../_assets/img/xhdpi/tasks.png"),
      strange_line: require("../_assets/img/xhdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/xhdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/xhdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/xhdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/xhdpi/right-arrow-wall.png"),
      carer_no_image: require("../_assets/img/xhdpi/test_carer_image.png")
    },
    xxhdpi:{
      clients: require("../_assets/img/xxhdpi/clients.png"),
      white_logo: require("../_assets/img/xxhdpi/E01_logo.png"),
      information: require("../_assets/img/xxhdpi/information.png"),
      leads: require("../_assets/img/xxhdpi/leads.png"),
      mail_icon: require("../_assets/img/xxhdpi/Mail.png"),
      search_icon: require("../_assets/img/xxhdpi/search_icon.png"),
      tasks: require("../_assets/img/xxhdpi/tasks.png"),
      strange_line: require("../_assets/img/xxhdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/xxhdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/xxhdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/xxhdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/xxhdpi/right-arrow-wall.png")
    },
    xxxhdpi:{
      clients: require("../_assets/img/xxxhdpi/clients.png"),
      white_logo: require("../_assets/img/xxxhdpi/E01_logo.png"),
      information: require("../_assets/img/xxxhdpi/information.png"),
      leads: require("../_assets/img/xxxhdpi/leads.png"),
      mail_icon: require("../_assets/img/xxxhdpi/Mail.png"),
      search_icon: require("../_assets/img/xxxhdpi/search_icon.png"),
      tasks: require("../_assets/img/xxxhdpi/tasks.png"),
      strange_line: require("../_assets/img/xxxhdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/xxxhdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/xxxhdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/xxxhdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/xxxhdpi/right-arrow-wall.png"),
      carer_no_image: require("../_assets/img/xxxhdpi/test_carer_image.png")
    },
    svg:{
      menu_leads: require("../_assets/img/svg/01-leads-on.svg"),
      menu_clients: require("../_assets/img/svg/02-clients-off.svg"),
      menu_multipliers: require("../_assets/img/svg/03-multi-off.svg"),
      menu_invoices: require("../_assets/img/svg/04-invoices-off.svg"),
      menu_tasks: require("../_assets/img/svg/05-tasks-off.svg"),
      menu_info: require("../_assets/img/svg/06-info-off.svg"),
      clock_blue: require("../_assets/img/svg/209-future.svg"),
      current_green: require("../_assets/img/svg/210-current.svg"),
      clock_orange: require("../_assets/img/svg/211-ended.svg"),
      phone: require("../_assets/img/svg/group.svg"),
      notes: require("../_assets/img/svg/notes.svg"),
      pasek_small: require("../_assets/img/svg/paski.svg"),
      pasek_big: require("../_assets/img/svg/paski_big.svg"),
      promo_logo: require("../_assets/img/svg/prom_logo.svg"),
      search_icon: require("../_assets/img/svg/search-icon.svg"),
      email: require("../_assets/img/svg/shape.svg"),
      call_black: require("../_assets/img/svg/e-6-call-black.svg"),
      call_red: require("../_assets/img/svg/e-6-call-red.svg"),
      clock_black: require("../_assets/img/svg/e-6-clock-black.svg"),
      clock_red: require("../_assets/img/svg/e-6-clock-red.svg"),
      red_status_icon: require("../_assets/img/svg/red-dot.svg"),
      orange_status_icon: require("../_assets/img/svg/orange-dot.svg"),
      blue_status_icon: require("../_assets/img/svg/blue-dot.svg"),
      green_status_icon: require("../_assets/img/svg/green-dot.svg"),
      white_status_icon: require("../_assets/img/svg/white-dot.svg"),
      future: require("../_assets/img/svg/future.svg"),
      current: require("../_assets/img/svg/current.svg"),
      ended: require("../_assets/img/svg/ended.svg"),
      tick_oval_green: require("../_assets/img/svg/oval.svg"),
      dashboard: require("../_assets/img/svg/dashboard_icon.svg"),
      meeting : require("../_assets/img/svg/meeting.svg"),
      meeting_red : require("../_assets/img/svg/meeting_red.svg"),
    },
    rest:{
      white_logo : require("../_assets/img/e-01-logo@3x.png"),
      tick_black : require("../_assets/img/tick_black.png"),
      tick_red : require("../_assets/img/tick_red.png"),
      dashboard : require("../_assets/img/dashboard_icon.png"),
    }
  };