import * as React from 'react';
import { Link } from 'react-router-dom';

class SideBarElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: this.props.icon,
            label: this.props.label
        };
    }

    prepareMeBadge(){
        let badgeCount = 0;
        const { data, badge, type } = this.props;
        if(badge===true){
            if(data.length>0){
                for(let i=0; i<data.length;i++){
                    if(data[i].ViewedInApplication===false){
                        if(type==="TASKS"){
                            if(data[i].status !== 'Completed' && data[i].status !== 'Abgeschlossen'){
                                badgeCount++;
                            }
                        }else{
                            badgeCount++;
                        }
                    }
                }
            }
        }
        if(badgeCount!==0){
            return (<span className="badge badge-prom">{badgeCount}</span>);
        }
    }

    render() {
        const { topage, isActive, icon, label } = this.props
        return (
            <Link to={topage}>
                <div className={"sideBarElement"+(isActive==="true" ? ' active' : ' no_active')}>
                    <div className="sideBarElementContent">
                        <img className="sideBarIcon" src={icon} alt={"Icon"}/>
                        <div className="sideBarText">{label}</div>
                        {this.prepareMeBadge()}
                    </div>
                </div>
            </Link>
        );
    }
}


export default SideBarElement;
