import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ComposedChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList} from 'recharts';
import * as LeadDashBoardExceptions from "../../../../_constance/values/LeadStatusDashboardException";
import * as LanguageSelect from "../../../../_constance/values/languageSelect";
import { ReqHelper } from "../../../../_helpers/index";
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";
import { CustomizedLabel } from "../../../../_constance/classes/LabelListCustomized";
import { Colors } from "../../../../_constance/enums/colors";
import { baseData } from '../../../../_constance/base_data';

class LeadsPerStatusChar extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      leadsPerStatus: [],
      loadingStatus: true
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeLeadPerStatus();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeLeadPerStatus = () =>{
    const { appLang } = this.props;
    let data = [];
    if(appLang === LanguageSelect.DE){
      LeadDashBoardExceptions.DE_LIST_DASHBOARD.forEach(status=>{
        let total = this.props.leadsData.reduce((acc, item) => {
          if(item.status.toString() === status){
            return acc = acc+1
          }else{
            return acc;
          }
        }, 0);
        data.push({
          amount: total,
          name: ReqHelper.getMeLeadStatusText(status, this.props.leadsStatus),
          status: status
        })
      })
    }else{
      LeadDashBoardExceptions.EN_LIST_DASHBOARD.forEach(status=>{
        let total = this.props.leadsData.reduce((acc, item) => {
          if(item.status.toString() === status){
            return acc = acc+1
          }else{
            return acc;
          }
        }, 0);
        data.push({
          amount: total,
          name: ReqHelper.getMeLeadStatusText(status, this.props.leadsStatus),
          status: status
        })
      })
    }
    this.setState({
      loadingStatus: false,
      leadsPerStatus: data
    })
  }

  handleClick = (data) => {
    this.props.history.push(`${baseData.subFolders}leads/?type=status&param=${data.status}`);
  }

  render() {
    const { translateKey, loading, history } = this.props;
    const { loadingStatus } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingStatus){
      Content = (
        <ResponsiveContainer width="90%" height={666}>
          <ComposedChart layout="vertical" data={this.state.leadsPerStatus} margin={{top: 20, right:25, bottom: 20, left: 0}}>
            <XAxis dataKey="amount" type="number" />
            <YAxis tick={{fontSize: 10}} width={140} dataKey="name" type="category"/>
            <Bar dataKey="amount" fill={Colors.DARKBLUE} onClick={this.handleClick} className="cursor-pointer">
              <LabelList dataKey="amount" position="right" content={<CustomizedLabel external={this.state.leadsPerStatus} history={history}/>}/>
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      )
    }
    return (
      <React.Fragment>
        <div className="charts-container-wrapper">
          <h5 className="text-center">{translateKey("dashboard_lead_stage")}</h5>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsStatus: state.enums.leadStatus,
    leadsData: state.database.leads
  };
};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadsPerStatusChar));