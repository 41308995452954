import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import { CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import { InputTypeTextComponent } from "../../form-bb-components/input-type-text-component/input-type-text-component";
import produce from "immer";


class Section7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-7'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled, isImportant, translateKey } = this.props;
        const { section, showValidation } = this.state;
        const isFearful = section.getFieldByKey('is_fearful')
        const isApathetic = section.getFieldByKey('is_apathetic')
        const isDemanding = section.getFieldByKey('is_demanding')
        const isCheerful = section.getFieldByKey('is_cheerful')
        const isNervous = section.getFieldByKey('is_nervous')
        const isOpen= section.getFieldByKey('is_open')
        const isNice = section.getFieldByKey('is_nice')
        const isMoody = section.getFieldByKey('is_moody')
        const isEuphoric = section.getFieldByKey('is_euphoric')
        const isDecisive = section.getFieldByKey('is_decisive')
        const isSensitive = section.getFieldByKey('is_sensitive')
        const isPositive = section.getFieldByKey('is_positive')
        const isUnstable = section.getFieldByKey('is_unstable')
        const isWithdrawn = section.getFieldByKey('is_withdrawn')
        const isCritical = section.getFieldByKey('is_critical')
        const isNegative = section.getFieldByKey('is_negative')
        const isEmotionalCOD = section.getFieldByKey('is_emotional_condition_others_de')
        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                        <div className="col-sm-12 form-margin-bottom-fix">
                            <label className="form-label-basic"><span className="quick-label-position-fix">{translateKey("bb_characteristic_data_p_7")}</span></label>
                            <div className="checkbox-button-group-wrapper">
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isFearful.checkImportant)?' input_important_class':'')}  fieldName="is_fearful" setTypeChangeToParent={this.setTypeChange} field={isFearful} text={translateKey("form-text-angstlich")} transKey="form-text-angstlich" keyName="is_fearful" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isApathetic.checkImportant)?' input_important_class':'')}  fieldName="is_apathetic" setTypeChangeToParent={this.setTypeChange} field={isApathetic} text={translateKey("form-text-apathisch")} transKey="form-text-apathisch" keyName="is_apathetic" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isDemanding.checkImportant)?' input_important_class':'')}  fieldName="is_demanding" setTypeChangeToParent={this.setTypeChange} field={isDemanding} text={translateKey("form-text-fordernd")} transKey="form-text-fordernd" keyName="is_demanding" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isCheerful.checkImportant)?' input_important_class':'')}  fieldName="is_cheerful" setTypeChangeToParent={this.setTypeChange} field={isCheerful} text={translateKey("form-text-frohlich")} transKey="form-text-frohlich" keyName="is_cheerful" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isNervous.checkImportant)?' input_important_class':'')}  fieldName="is_nervous" setTypeChangeToParent={this.setTypeChange} field={isNervous} text={translateKey("form-text-gereizt")} transKey="form-text-gereizt" keyName="is_nervous" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isOpen.checkImportant)?' input_important_class':'')}  fieldName="is_open" setTypeChangeToParent={this.setTypeChange} field={isOpen} text={translateKey("form-text-offen")} transKey="form-text-offen" keyName="is_open" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isNice.checkImportant)?' input_important_class':'')}  fieldName="is_nice" setTypeChangeToParent={this.setTypeChange} field={isNice} text={translateKey("form-text-lieb")} transKey="form-text-lieb" keyName="is_nice" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isMoody.checkImportant)?' input_important_class':'')}  fieldName="is_moody" setTypeChangeToParent={this.setTypeChange} field={isMoody} text={translateKey("form-text-launisch")} transKey="form-text-launisch" keyName="is_moody" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isEuphoric.checkImportant)?' input_important_class':'')}  fieldName="is_euphoric" setTypeChangeToParent={this.setTypeChange} field={isEuphoric} text={translateKey("form-text-euphorisch")} transKey="form-text-euphorisch" keyName="is_euphoric" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isDecisive.checkImportant)?' input_important_class':'')}  fieldName="is_decisive" setTypeChangeToParent={this.setTypeChange} field={isDecisive} text={translateKey("form-text-bestimmend")} transKey="form-text-bestimmend" keyName="is_decisive" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isSensitive.checkImportant)?' input_important_class':'')}  fieldName="is_sensitive" setTypeChangeToParent={this.setTypeChange} field={isSensitive} text={translateKey("form-text-sensibel")} transKey="form-text-sensibel" keyName="is_sensitive" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isPositive.checkImportant)?' input_important_class':'')}  fieldName="is_positive" setTypeChangeToParent={this.setTypeChange} field={isPositive} text={translateKey("form-text-positiv")} transKey="form-text-positiv" keyName="is_positive" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isUnstable.checkImportant)?' input_important_class':'')}  fieldName="is_unstable" setTypeChangeToParent={this.setTypeChange} field={isUnstable} text={translateKey("form-text-labil")} transKey="form-text-labil" keyName="is_unstable" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isWithdrawn.checkImportant)?' input_important_class':'')}  fieldName="is_withdrawn" setTypeChangeToParent={this.setTypeChange} field={isWithdrawn} text={translateKey("form-text-zuruckhaltend")} transKey="form-text-zuruckhaltend" keyName="is_withdrawn" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isCritical.checkImportant)?' input_important_class':'')}  fieldName="is_critical" setTypeChangeToParent={this.setTypeChange} field={isCritical} text={translateKey("form-text-kritisch")} transKey="form-text-kritisch" keyName="is_critical" />
                                <CheckBoxSmallMultipleComponent className={"custom-control-inline illness-wrapper"+((isImportant && isNegative.checkImportant)?' input_important_class':'')}  fieldName="is_negative" setTypeChangeToParent={this.setTypeChange} field={isNegative} text={translateKey("form-text-negativ")} transKey="form-text-negativ" keyName="is_negative" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <InputTypeTextComponent isImportant={isImportant&&isEmotionalCOD.checkImportant} showValidation={showValidation} className="form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isEmotionalCOD} text={translateKey("form-text-sonstige-gem")} transKey="form-text-sonstige-gem" keyName="is_emotional_condition_others_de"/>
                        </div>
                        </fieldset>
                    </form>
                </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section7));