import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";

class ClientsActiveContracts extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loading,
      dashboardData: this.props.dashboardData,
      loadingDashBoard: true,
      activeContracts: {
        Total: 0,
        Active: 0,
        ActiveTerminated: 0,
        DuringCreation: 0,
        ForAcceptance: 0
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    if(this.props.dashboardData!== null && this.props.dashboardData !== undefined ){
      if(this.props.dashboardData.ContractsStatistics !== null && this.props.dashboardData.ContractsStatistics !== undefined){
        const contrSt = this.props.dashboardData.ContractsStatistics;
        this.setState({
          loadingDashBoard: false,
          activeContracts: {
            Total: contrSt.Total,
            Active: contrSt.Active,
            ActiveTerminated: contrSt.ActiveTerminated,
            DuringCreation: contrSt.DuringCreation,
            ForAcceptance: contrSt.ForAcceptance
          }
        });
      }
    }
  }

  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashBoard } = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashBoard){
      Content = (
          <table className="table table-dashboard">
            <thead>
              <tr>
                <th className="dashboard-table-head"></th>
                <th className="dashboard-table-head text-right">{translateKey("dashboard_actual_state")}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("dashboard_active")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.activeContracts.Active}</td>
              </tr>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("dashboard_active_terminated")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.activeContracts.ActiveTerminated}</td>
              </tr>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("dashboard_during_creation")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.activeContracts.DuringCreation}</td>
              </tr>
              <tr className="table-row-wrapper">
                <td className="table-cell-wrapper-dashboard">{translateKey("dashboard_to_acceptation")}</td>
                <td className="table-cell-wrapper-dashboard bold text-right">{this.state.activeContracts.ForAcceptance}</td>
              </tr>
            </tbody>
          </table>
      )
    }
    return (
      <React.Fragment>
        <div className="table-container-wrapper">
          <div className="table-name-class">{translateKey("dashboard_active_contracts_structure")}</div>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(null, mapDispatchToProps)(ClientsActiveContracts));