import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import languagesReducer from './reducers/language-reducer';
import authReducer from './reducers/auth';
import refreshTokenReducer from './reducers/refresh-status';
import databaseReducer from './reducers/database';
import enumsReducer from './reducers/enums';
import columnsReducer from './reducers/columns-reducer';
import filtersReducer from './reducers/filters-reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  lang: languagesReducer,
  auth: authReducer,
  refreshTokenStatus: refreshTokenReducer,
  database: databaseReducer,
  enums: enumsReducer,
  activeColumns: columnsReducer,
  filters: filtersReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export default store;