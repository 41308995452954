import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend, Line} from 'recharts';
import { translateKey } from "../../../../_redux/actions/index";
import { LoaderTable } from "../../../index";

class ClientBaseChange extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading:this.props.loading,
      dashboardData: this.props.dashboardData,
      loadingDashBoard: true,
      data: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareMeData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  };

  prepareMeData = () =>{
    let data = [];
    if(this.props.dashboardData!== null && this.props.dashboardData !== undefined ){
      if(this.props.dashboardData.ClientsBaseStatistics !== null && this.props.dashboardData.ClientsBaseStatistics !== undefined){
        data =  this.props.dashboardData.ClientsBaseStatistics.Months.map(element =>{
          return {
            date: this.prepareMeMonthData(element.Month) + '.' + element.Year,
            totalNewContracts: element.TotalNewContracts,
            totalEndingContracts: element.TotalEndingContracts,
            balance: element.TotalNewContracts-element.TotalEndingContracts
          }
        })
      }
    }
    this.setState({
      loadingDashBoard: false,
      data: data
    })
  }

  prepareMeMonthData = ( element ) =>{
    if(element<10){
      return "0"+element
    }else{
      return element
    }
  }


  render() {
    const { translateKey, loading } = this.props;
    const { loadingDashBoard} = this.state;
    let Content = <LoaderTable />;
    if(!loading && !loadingDashBoard){
      Content = (
        <ResponsiveContainer width="80%" height={400}>
          <ComposedChart
            data={this.state.data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis name={translateKey("date")} dataKey="date" type="category" />
            <YAxis />
            <Tooltip />
            <Bar
              name={translateKey("dashboard_new_care_agreement")}
              dataKey="totalNewContracts"
              fill="#5fb647"
            />
            <Bar
              name={translateKey("dashboard_ending_care_agreement")}
              dataKey="totalEndingContracts"
              fill="#fc524f"
            />
            <Line
              name={translateKey("dashboard_balance")}
              type="monotone"
              dataKey="balance"
              stroke="#0056a4"
            />
            <Legend
              verticalAlign="top"
              align="left"
              wrapperStyle={{
                paddingLeft: "20px",
                paddingBottom: "20px"
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
    return (
      <React.Fragment>
        <div className="charts-pie-container-wrapper">
          <h5 className="text-center">{translateKey("dashboard_clients_change")}</h5>
          {Content}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),});
export default withRouter(connect(null, mapDispatchToProps)(ClientBaseChange));