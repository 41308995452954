import * as React from 'react';
import {IconsMap} from "../../../_helpers/icons_set"

class NavigationBarElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionNumber: this.props.sectionNumber
        };
    }

    changeMeSectionIfYouCan = () =>{
        this.props.changeSection(this.props.sectionNumber)
    }

    render() {
        return (
            <div className={"navigationBarElement"+ (this.props.isDisabled===true ? ' hideMe' : '')}>
                <div className="navigationBarElementContent">
                    <div onClick={this.changeMeSectionIfYouCan} className={"cursor-pointer navigationBarText"+ (this.props.isActive===true ? ' active' : '')}>
                        <div className="navigation-section-text">
                            <span className={"section-number-in-navigation-menu"+(this.props.isActive===true ? ' section-text-bold' : '')}>{this.props.sectionNumber+1}.</span>
                            <span className={"section-text-in-navigation-menu"+(this.props.isActive===true ? ' section-text-bold' : '')}>{this.props.sectionName}</span>
                        </div>
                        {this.props.showSectionValidation ===true && this.props.validated===true && this.props.activeSection>this.props.sectionNumber?
                            <img className="navigationBarIcon" src={IconsMap.svg.tick_oval_green} alt={"Tick"}/>
                            :
                            <span className="navigationBarIcon" src={IconsMap.svg.menu_tasks} alt={"Tick"}>{this.props.validated}</span>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default NavigationBarElement;