import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";

class ContractStatusHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carer: props.carer
        }
    }

    getEnumsData(status){
        if(status !== null && status!== undefined){
            const found = this.props.contractStatus.filter(element => element.Value===status.toString());
            if(found.length===0){
                return '';
            }else{
                return found[0].Label;
            }
        }else{
            return '';
        }
    }

    render() {
        const { status } = this.props
        return (
            <div className="flexAlignCenter">
                {this.getEnumsData(status)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      appLang: state.lang.appLanguage,
      contractStatus: state.enums.contractStatus,
    };
};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractStatusHandler));