import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import ListControlComponent from "../../form-bb-components/list-control-component/list-control-component";
import ListMovementComponent from "../../form-bb-components/list-movement-component/list-movement-component";
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import {InputTypeTextComponent} from "../../form-bb-components/input-type-text-component/input-type-text-component";
import {CheckBoxSmallMultipleComponent} from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import produce from "immer";

class Section9 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-9'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled } = this.props;
        const { section } = this.state;
        const isUseToiletOrToiletChairAlone = section.getFieldByKey('is_use_toilet_or_toilet_chair_alone');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {this.props.translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
                        <ListControlComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-urininkontinenz" key="is_urination_control" keyName="is_urination_control" value={section.getFieldByKey('is_urination_control').value} validationErrors={section.getFieldByKey('is_urination_control').validationErrors} />
                        <ListControlComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-stuhlinkontinenz" key="is_control_of_defecation" keyName="is_control_of_defecation" value={section.getFieldByKey('is_control_of_defecation').value} validationErrors={section.getFieldByKey('is_control_of_defecation').validationErrors} />

                        <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-toilettenstuhlsmoglich" key="is_use_toilet_or_toilet_chair_alone" keyName="is_use_toilet_or_toilet_chair_alone" value={section.getFieldByKey('is_use_toilet_or_toilet_chair_alone').value} validationErrors={section.getFieldByKey('is_use_toilet_or_toilet_chair_alone').validationErrors} />

                        { isUseToiletOrToiletChairAlone.value === SkillRange.LIMITED && (
                            <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_if_limited_how_often')} text={this.props.translateKey("form-text-wieoft")} transKey="form-text-wieoft" keyName="is_if_limited_how_often" />
                        )}

                        <div className="hilfsmittel-wrapper">
                            <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("form-text-hilfsmittel")}</div>
                            <div className="checkboxes_wrapper">
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-blasenkatheter"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_catheter_list')} text={this.props.translateKey("form-text-blasenkatheter")} transKey="form-text-blasenkatheter" keyName="is_catheter_list" />
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-suprapubischer"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_catheterized_through_the_abdominal_wall')} text={this.props.translateKey("form-text-suprapubischer")} transKey="form-text-suprapubischer" keyName="is_catheterized_through_the_abdominal_wall" />
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-urinflasche"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_bedpan_a_list')} text={this.props.translateKey("form-text-urinflasche")} transKey="form-text-urinflasche" keyName="is_bedpan_a_list"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-vorlagen"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_inserts_list')} text={this.props.translateKey("form-text-vorlagen")} transKey="form-text-vorlagen" keyName="is_inserts_list"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-windeln"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_nappies_list')} text={this.props.translateKey("form-text-windeln")} transKey="form-text-windeln" keyName="is_nappies_list"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <InputTypeTextComponent showValidation={this.props.showValidationErrors} className="form-margin-bottom-fix top-too" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_aids_other_de')} text={this.props.translateKey("form-text-sonstige-gem")} transKey="form-text-sonstige-gem" keyName="is_aids_other_de" />
                            </div>
                        </div>
                        </fieldset>
                    </form>
</div>
        );
    }
}

const mapStateToProps = state => {return {}};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section9));
