import * as React from "react";
import {connect} from "react-redux";
import {Prompt, withRouter} from "react-router-dom";
import {
    authSetActionTimestamp,
    getInvoices,
    getPatientClient,
    pendingClientsData,
    pendingPatientClientData,
    removeClientsData,
    removePatientClientData,
    setClientsData,
    setPatientClientData,
    translateKey
} from "../../_redux/actions/index";
import {EmptyTableIcon, LoaderTable, NavBar, SideBar, TableProperties} from "../../_components/index";
import {Alert, CarerTable, ClientTable, ContractTable} from "../../_constance/classes/index";
import {IconsMap} from "../../_helpers/index";
import {ResponseStatus} from '../../_helpers/response_status'
import axiosAuth from "../../_services/config/axios-auth";
import {
    ClientCarersData,
    ClientInvoicesData,
    ClientPatientData
} from '../../_components/ClientDetailsComponenets/index';
import {ContractData} from '../../_components/contract-data-component/index';
import {getDynamicFields} from '../../_services/user_service';
import * as Entity from "../../_forms/BBForm/values/entity";

class ContractPage extends React.Component {
  _isMounted = false;
  alertD = Alert;
  myTimout;
  invoiceTimeOut;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "contract",
      contractData: null,
      errorComponent: Alert,
      errorsMessage: null,
      errorsType: "danger",
      loadingBasics: true,
      clientRetries: 0,
      loadingPatients: true,
      loadingInvoices: true,
      carerData: [],
      loadingCarers: true,
      clientId: 0,
      contractId: 0,
      clientData: null,
      breadCrumbs:[
        {
          link: "clients",
          key: "clients",
          name: null
        }
      ]
    };
  }

  setActiveTab(tabName){this.setState({activeTab: tabName})}

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {
      this.getDataBasic()
    }, 200);
  }

  componentWillUnmount() {
      this._isMounted = false;
      clearTimeout(this.myTimeout);
      clearTimeout(this.invoiceTimeOut);
      clearTimeout(this.patientTimeout);
  };

  ////// BASIC CLIENT TAB DATA LOAD

  getPatientList(){
    this.props.pendingPatientData();
    axiosAuth.get("patients/list/CLIENT/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        this.props.setPatientData(res);
        this.getInitialDataTime();
      })
      .catch((error) => {
        this.props.clearPatientData();
        this.getInitialDataTime();
      });
  }

  getDataBasic() {
    if (this._isMounted) {
      this.setState({loadingBasics: true});
    }
    if (this.props.patientData.length === 0) {
      if (this.props.patientData.length !== 0 && this.props.patientData !== undefined) {
        this.getInitialDataTime();
      } else {
        this.getPatientList();
      }
    } else {
      this.getInitialDataTime();
    }
  }

  getInitialDataTime(){
    if(this.props.clientsData.length>0){
      clearTimeout(this.myTimeout);
      const { match: { params } } = this.props;
      let name = '---';
      for(let ij=0; ij<this.props.clientsData.length;ij++){
        if(this.props.clientsData[ij].id === params.id){
          name = this.props.clientsData[ij].client;
          break;
        }
      }
      if (this._isMounted) {
        this.setState({
          breadCrumbs:[...this.state.breadCrumbs, {
            link: "clientDetails/"+params.id,
            key: "",
            name: name
          }],
        })
      }
      this.getBasicContractsData();
    }else{
      if(this.state.clientRetries<10){
        this.getClientsList();
        if (this._isMounted) {
          this.setState({clientRetries: this.state.clientRetries+1})
        }
        this.myTimeout = setTimeout(() => {this.getInitialDataTime()}, 300);
      }else{
        if (this._isMounted) {
          this.setState({
            clientRetries: 0,
            loadingBasics: false
          })
        }
      }
    }
  }

  getClientsList = () => {
    if(this.props.clientsState !== ResponseStatus.PENDING){
      this.props.pendingRequestClient();
      axiosAuth.get("clients/list/0")
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{
            for(let i=0; i<this.props.patientData.length; i++){
              if(this.props.patientData[i].clientId===element.id){
                element.locationP = this.props.patientData[i].patientAddress ? this.props.patientData[i].patientAddress.city:'';
                element.patientName = this.props.patientData[i].name;
                element.patient = this.props.patientData[i]
              }
            }
            return new ClientTable(element);}
          );
          this.props.setClientData(mapedRes);
        })
        .catch((error) => {this.props.clearDataClient();});
    }
  }

  getBasicContractsData(){
    if (this._isMounted) {
      this.setState({loadingBasics: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/contracts/list/"+params.id+"/0")
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        for(let i=0; i<res.length; i++){
          if(res[i].id === params.contractId){
            let name='---';
            if(res[i].lastName!==null){
              name = res[i].lastName;
              if(res[i].firstName!==null){
                name += ' '+res[i].firstName;
              }
            }else{
              if(res[i].firstName!==null){
                name = res[i].firstName;
              }
            }
            if (this._isMounted) {
              this.setState({
                contractData: new ContractTable(res[i]),
                loadingBasics: true,
                clientId: params.id,
                contractId: params.contractId,
                breadCrumbs:[...this.state.breadCrumbs,
                  {
                    link: "",
                    key: "",
                    name: name
                  }
                ]
              })
              this.getPCMPlanningData(params.contractId)
            }
            this.getInitialPatient();
            this.getBasicInvoiceData();
            this.getBasicCarersData();
            break;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingBasics: false })
        }
      });
  }

    getPCMPlanningData(id) {
        let request = [{"Id": id, "Entity": Entity.CONTRACT, "Fields": ["is_planning_pcmid"]}];
        getDynamicFields(request).then(res => {
            if (res.length > 0 && this._isMounted) {
                let pcmPlanningId = res[0].fields["is_planning_pcmid"]
                if (pcmPlanningId != null) {
                    this.getPCMPlanningDetails(pcmPlanningId)
                } else {
                    if (this._isMounted) this.setState({loadingBasics: false})
                }
            }
        }).catch((error) => {
            if (this._isMounted) this.setState({loadingBasics: false})
            console.log(error);
        });
    }

    getPCMPlanningDetails(pcmPlanningId) {
        let requestDetails = [{
            "Id": pcmPlanningId,
            "Entity": Entity.SYSTEM_USER,
            "Fields": ["fullname", "internalemailaddress"]
        }];
        getDynamicFields(requestDetails).then(res => {
            if (res.length > 0 && this._isMounted) {
                let fullName = res[0].fields["fullname"];
                let emailAddress = res[0].fields["internalemailaddress"];
                let planningElement = {name: fullName, email: emailAddress};
                this.state.contractData.fillWithPlanningElement(planningElement)
                this.setState({loadingBasics: false})
            }
        }).catch((error) => {
            if (this._isMounted) this.setState({loadingBasics: false})
            console.log(error);
        });
    }

  ///// BASIC PATIENT TAB DATA LOAD

  getInitialPatient(){
    if(this.props.patientClientStatus === ResponseStatus.READY){
      clearTimeout(this.patientTimeout);
      if (this._isMounted) {
        this.setState({loadingPatients: false})
      }
    }else{
      if(this.props.patientClientStatus === ResponseStatus.FREE){
        this.props.getPatientClientData(this.props.appLang);
        this.patientTimeout = setTimeout(() => {
          this.getInitialPatient()
        }, 500);
      }else{
        if(this.props.patientClientStatus === ResponseStatus.ERROR){
          clearTimeout(this.patientTimeout);
          if (this._isMounted) {
            this.setState({ loadingPatients: false })
          }
        }else{
          this.patientTimeout = setTimeout(() => {
            this.getInitialPatient()
          }, 500);
        }
      }
    }
  }



  ///// BASIC INVOICE TAB DATA LOAD

  getBasicInvoiceData(){
    if(this.props.invoiceStatus === ResponseStatus.READY){
      clearTimeout(this.invoiceTimeOut);
      if (this._isMounted) {
        this.setState({loadingInvoices: false})
      }
    }else{
      if(this.props.invoiceStatus === ResponseStatus.FREE){
        this.props.getInvoicesData();
        this.invoiceTimeOut = setTimeout(() => {
          this.getBasicInvoiceData()
        }, 500);
      }else{
        if(this.props.invoiceStatus === ResponseStatus.ERROR){
          clearTimeout(this.invoiceTimeOut);
          if (this._isMounted) {
            this.setState({ loadingInvoices: false })
          }
        }else{
          this.invoiceTimeOut = setTimeout(() => {
            this.getBasicInvoiceData()
          }, 500);
        }
      }
    }
  }









  ///// BASIC CARER TAB DATA LOAD

  getBasicCarersData(){
    if (this._isMounted) {
      this.setState({loadingCarers: true})
    }
    const { match: { params } } = this.props;
    axiosAuth.get("/carers/list/"+params.id+"/0/"+this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        let mapedRes = res.map((element)=>{return new CarerTable(element);});
        if (this._isMounted) {
          this.setState({
            carerData: mapedRes,
            loadingCarers: false
          })
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loadingCarers: false })
        }
      });
  }


  render() {
    const { translateKey } = this.props;
    let ContractDataX;
    if (this.state.loadingBasics) {
      ContractDataX = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.state.contractData===null){
        ContractDataX = <EmptyTableIcon RefreshMe={() => this.getDataBasic()} text={translateKey("client_does_not_exist")} />
      }else{
        ContractDataX = <ContractData contractData={this.state.contractData}/>
      }
    }

    let PatientData;
    if (this.state.loadingPatients) {
      PatientData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.props.patientData===[]){
        PatientData = <EmptyTableIcon RefreshMe={() => this.getInitialPatient()} text={translateKey("no_data_to_show")} />
      }else{
        PatientData = <ClientPatientData type="contract" RefreshMe={() => this.getInitialPatient()} id={this.state.contractId}/>
      }
    }

    let InvoiceData;
    if (this.state.loadingInvoices) {
      InvoiceData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      if(this.props.invoicesData===[]){
        InvoiceData = <EmptyTableIcon RefreshMe={() => this.getBasicInvoiceData()} text={translateKey("no_data_to_show")} />
      }else{
        InvoiceData = <ClientInvoicesData RefreshMe={() => this.getBasicInvoiceData()} id={this.state.clientId}/>
      }
    }

    let CarersData;
    if (this.state.loadingCarers) {
      CarersData = <div className="flexCenterItems emptyLoaderMin"><LoaderTable /></div>
    }else{
      const { match: { params } } = this.props;
      CarersData = <ClientCarersData RefreshMe={() => this.getBasicCarersData()} id={this.state.id} clientId={params.id} carerData={this.state.carerData}/>
    }


    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties options={[]} filterData={[]}/>
        <div className="mainContent">
          <SideBar />
          <div className="listDataWrapper transparentClass">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active nav-link-invoices" onClick={()=>{this.setActiveTab("client")}} id="nav-details-tab" data-toggle="tab" href="#nav-details" role="tab" aria-controls="nav-details" aria-selected="true">
                  {translateKey("contract")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("carers")}} id="nav-carers-tab" data-toggle="tab" href="#nav-carers" role="tab" aria-controls="nav-carers" aria-selected="false">
                  {translateKey("carers")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("patients")}} id="nav-patients-tab" data-toggle="tab" href="#nav-patients" role="tab" aria-controls="nav-patients" aria-selected="false">
                  {translateKey("patients")}
                </a>
                <a className="nav-item nav-link nav-link-invoices" onClick={()=>{this.setActiveTab("invoices")}} id="nav-invoices-tab" data-toggle="tab" href="#nav-invoices" role="tab" aria-controls="nav-invoices" aria-selected="false">
                  {translateKey("invoices")}
                </a>
              </div>
            </nav>
            <div className="tab-content position-relative" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab">
                <div className="leadSection">
                  {ContractDataX}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-carers" role="tabpanel" aria-labelledby="nav-careres-tab">
                <div className="leadSection">
                  {CarersData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-patients" role="tabpanel" aria-labelledby="nav-patients-tab">
                <div className="leadSection">
                  {PatientData}
                </div>
              </div>
              <div className="tab-pane fade" id="nav-invoices" role="tabpanel" aria-labelledby="nav-invoices-tab">
                <div className="leadSection">
                  {InvoiceData}
                </div>
              </div>
              <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
            </div>
          </div>
        </div>
        <Prompt
            when={false}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    clientsData: state.database.clients,
    clientsState: state.database.clientsState,
    patientData: state.database.patientClient,
    patientClientStatus: state.database.patientClientState,
    invoicesData: state.database.invoices,
    invoiceStatus: state.database.invoicesState,
  };
};

const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),

  setClientData: (data) => dispatch(setClientsData(data)),
  pendingRequestClient: () => dispatch(pendingClientsData()),
  clearDataClient: () => dispatch(removeClientsData()),

  getPatientClientData: (lang) => dispatch(getPatientClient(lang)),
  setPatientData: (data) => dispatch(setPatientClientData(data)),
  pendingPatientData: () => dispatch(pendingPatientClientData()),
  clearPatientData: () => dispatch(removePatientClientData()),

  tookAction: () => dispatch(authSetActionTimestamp()),

  getInvoicesData: () => dispatch(getInvoices()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractPage));