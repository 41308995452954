import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import  ListMovementComponent  from '../../form-bb-components/list-movement-component/list-movement-component';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import { CheckBoxSmallMultipleComponent } from "../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component";
import produce from "immer";


class Section10 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-10'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled } = this.props;
        const { section } = this.state;
        const isBedCare = section.getFieldByKey('is_bed_care');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {this.props.translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
<ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-duschen-baden" key="is_bathing_shower" keyName="is_bathing_shower" value={section.getFieldByKey('is_bathing_shower').value} validationErrors={section.getFieldByKey('is_bathing_shower').validationErrors}/>
                        <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-hautpflege" key="is_skin_care" keyName="is_skin_care" value={section.getFieldByKey('is_skin_care').value} validationErrors={section.getFieldByKey('is_skin_care').validationErrors}/>
                        <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-mundpflege" key="is_oral_care_and_dentures" keyName="is_oral_care_and_dentures" value={section.getFieldByKey('is_oral_care_and_dentures').value} validationErrors={section.getFieldByKey('is_oral_care_and_dentures').validationErrors}/>
                        <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-haarpflege" key="is_hair_care" keyName="is_hair_care" value={section.getFieldByKey('is_hair_care').value} validationErrors={section.getFieldByKey('is_hair_care').validationErrors}/>
                        <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-intimpflege" key="is_intimate_hygiene" keyName="is_intimate_hygiene" value={section.getFieldByKey('is_intimate_hygiene').value} validationErrors={section.getFieldByKey('is_intimate_hygiene').validationErrors}/>
                        <ListMovementComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="form-margin-bottom-fix" transKey="form-text-rasieren" key="is_shaving" keyName="is_shaving" value={section.getFieldByKey('is_shaving').value} validationErrors={section.getFieldByKey('is_shaving').validationErrors}/>
                        <CheckBoxComponent showValidation={this.props.showValidationErrors} className="top-too" setTypeChangeToParent={this.setTypeChange} value={isBedCare.value} validationErrors={isBedCare.validationErrors} text={this.props.translateKey("form-text-betreuendeperson")} transKey="form-text-betreuendeperson" keyName="is_bed_care"/>
                        <div className="hilfsmittel-wrapper">
                            <div className="form-label-basic cursor-pointer quick-label-position-fix">{this.props.translateKey("form-text-hilfsmittel")}</div>
                            <div className="checkboxes_wrapper">
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-nachtstuhl"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_toilet_chair')} text={this.props.translateKey("form-text-nachtstuhl")} transKey="form-text-nachtstuhl" keyName="is_toilet_chair"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-duschstuhl"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_shower_chair')} text={this.props.translateKey("form-text-duschstuhl")} transKey="form-text-duschstuhl" keyName="is_shower_chair"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-badewannenlift"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_lift_by_the_bath')} text={this.props.translateKey("form-text-badewannenlift")} transKey="form-text-badewannenlift" keyName="is_lift_by_the_bath"/>
                                <CheckBoxSmallMultipleComponent className="custom-control-inline" fieldName={"form-text-toilettensitzerhöhung"} setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_toilet_seat')} text={this.props.translateKey("form-text-toilettensitzerhöhung")} transKey="form-text-toilettensitzerhöhung" keyName="is_toilet_seat"/>   
                            </div>
                        </div>
                        </fieldset>
                    </form>
</div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section10));