import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey} from "../../_redux/actions/index";
import { getCarerPhoto } from "../../_redux/actions/auth";
import { IconsMap } from "../../_helpers/icons_set";
import { ReqHelper } from "../../_helpers/index";
import { PhoneEmailContactComponent } from "../../_components/index";
import * as ContactTypes from '../../_constance/values/contactFieldType';

class CarerTableDetails extends React.Component {
    _isMounted = false;
    myTimout;
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            loadingPhoto: true,
            photoBase: '',
            photoVersion: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList.remove('modal-open');
        this.getCarerPhoto()
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    getCarerPhoto(){
        this.props.getPhoto(this.props.element.contactId, this.props.element.photoVersion)
        .then((res)=>{
            if (this._isMounted) {
                this.setState({
                    loadingPhoto: false,
                    photoBase: res.Photo,
                    photoVersion: res.PhotoVersion
                })
            }
        })
        .catch((err)=>{
            console.log(err);
            if (this._isMounted) {
                this.setState({loadingPhoto: false})
            }
        })
    }

    getMeExperienceText(monthsCount){
        const { translateKey } = this.props;
        let rest = Math.floor(monthsCount%12);
        let finalString = '';
        if(rest===0){
            let years = monthsCount/12;
            if(years===1){
                finalString = years + ' ' + translateKey("time_year") + ' ';
            }else{
                if(years===0){
                    finalString = rest + ' ' + translateKey("time_months");
                }else{
                    finalString = years + ' ' + translateKey("time_years") + ' ';
                }
            }
        }else{
            let years = monthsCount/12;
            if(years===0){
                if(rest===1){
                    finalString += rest + ' ' + translateKey("time_month");
                }else{
                    finalString = rest + ' ' + translateKey("time_months");
                }
            }else{
                finalString = parseInt(years) + ' ' + translateKey("time_year_short") + ' ';
                finalString += rest + ' ' + translateKey("time_month_short");
            }

        }
        return finalString;
    }

    render() {
        const { translateKey, element } = this.props;
        return (
            <div className="invoice-table-details-wrapper">
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-3 flexCenterItems">
                        {this.state.loadingPhoto?
                            <i className="fas fa-spinner fa-spin fa-3x empty-table-icon"></i>
                            :
                            this.state.photoBase==='' || this.state.photoBase===null?
                                <img className="client-carer-image" src={IconsMap.xhdpi.carer_no_image} alt="Icon"/>
                                :
                                <img className="client-carer-image" src={`data:image/jpeg;base64,${this.state.photoBase}`} alt="Icon"/>
                        }
                    </div>
                    <div className="col-sm-12 col-lg-9">
                        {element.age>0 &&
                            <div className="carer-details-info hideMe">
                                <div className="carer-descriptions-header">{translateKey("age")}:</div>
                                <div>{element.age}</div>
                            </div>
                        }
                        {element.country.trim().length>0 &&
                            <div className="carer-details-info">
                                <div className="carer-descriptions-header">{translateKey("citizenship")}:</div>
                                <div>{element.country}</div>
                            </div>
                        }
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("driving_licence_manual")}:</div>
                            <div>
                                {element.drivingManual?translateKey("yes"):translateKey("no")}
                            </div>
                        </div>
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("driving_licence_automatic")}:</div>
                            <div>
                                {element.drivingAutomatic?translateKey("yes"):translateKey("no")}
                            </div>
                        </div>
                        <div className="carer-details-info">
                            <div className="carer-descriptions-header">{translateKey("experience_in_care")}:</div>
                            <div>{this.getMeExperienceText(element.experience)}</div>
                        </div>
                        {this.props.title !=="BB" && element.phoneNumber!== undefined && element.phoneNumber!== null && element.phoneNumber.trim() !=='' && ReqHelper.dateBetweenDates(Math.floor(new Date().getTime() / 1000), element.from, element.to) &&
                            <div className="carer-details-info">
                                <div className="carer-descriptions-header">{translateKey("phone")}:</div>
                                <PhoneEmailContactComponent text={element.phoneNumber} type={ContactTypes.PHONE}></PhoneEmailContactComponent>
                            </div>
                        }
                        {element.about.trim().length>0 &&
                            <div className="carer-details-info about-me">
                                <div className="carer-descriptions-header">{translateKey("about_me")}:</div>
                                <div>{element.about}</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {return {title: state.auth.title}};
const mapDispatchToProps = dispatch => ({
    translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
    getPhoto: (id, v) => dispatch(getCarerPhoto(id, v))
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarerTableDetails));