export const MultiplierQuality = [
    {
        key: 'dashboard_high',
        value: '717700000',
        color: '#0056a4'
    },
    {
        key: 'dashboard_average',
        value: '717700001',
        color: '#64b445'
    },
    {
        key: 'dashboard_low',
        value: '717700002',
        color: '#bbd854'
    }
]