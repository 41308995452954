import { ENVIRONMENT } from "../../../_constance";

const Values = {
    TEST: [
        { value: 'B14CD29F-10B5-E911-80DA-00155D00F809', labelKey: 'bb_pflegedienst_data_p_14' },
        { value: 'CD38E69D-89A7-E811-80D8-00155D00F809', labelKey: 'recommendation' },
        { value: 'F089ECB3-10B5-E911-80DA-00155D00F809', labelKey: 'form-text-anzeige' },
        { value: '1739E69D-89A7-E811-80D8-00155D00F809', labelKey: 'form-text-krankenhaus' },
        { value: '8538E69D-89A7-E811-80D8-00155D00F809', labelKey: 'form-text-arzt' },
    ],
    PROD: [
        { value: '05707F1C-A1A7-E811-80CA-00155D13F31A', labelKey: 'bb_pflegedienst_data_p_14' },
        { value: '436F7F1C-A1A7-E811-80CA-00155D13F31A', labelKey: 'recommendation' },
        { value: '92B83C0F-11B5-E911-80D1-00155D2D0E3C', labelKey: 'form-text-anzeige' },
        { value: 'B36F7F1C-A1A7-E811-80CA-00155D13F31A', labelKey: 'form-text-krankenhaus' },
        { value: 'F76E7F1C-A1A7-E811-80CA-00155D13F31A', labelKey: 'form-text-arzt' },
    ]
};

// Get options for SelectComponent / ListComponent
export const getOptions = (translateKey) => {
    if (Values[ENVIRONMENT]) {
        return Values[ENVIRONMENT].map(({ value, labelKey }) => ({
            value,
            label: translateKey(labelKey)
        }));
    }
    return [];
};

// Get flattened list of possible values
export const getValues = () => Values[ENVIRONMENT] ? Values[ENVIRONMENT].map(({ value }) => value) : [];
