import { REFRESH_PENDING, REFRESH_FINISHED, NEED_SAVE, DOES_NOT_NEED_SAVE } from './action-types';

export const setRefreshCall = () => {
  return {
      type: REFRESH_PENDING,
  };
};

export const finishRefreshCall = () => {
  return {
      type: REFRESH_FINISHED,
  };
};

export const needsSaveData = () => {
  return {
      type: NEED_SAVE,
  };
};

export const doesNotNeedsSave = () => {
  return {
      type: DOES_NOT_NEED_SAVE,
  };
};