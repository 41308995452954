import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
//import Validate from "../../_helpers/validation";
import { baseData } from '../../../_constance/base_data';
import ListComponent from "../../form-bb-components/list-component/list-component";
import produce from "immer";


class Section6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-6'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });

        this.setState(nextState);
    };

    render() {
        const { disabled } = this.props;
        const { section } = this.state;
        const isOrientationTemporal = section.getFieldByKey('is_orientation_temporal');
        const isOrientationLocational = section.getFieldByKey('is_orientation_locational');
        const isOrientationPersonal = section.getFieldByKey('is_orientation_personal');

        return (
                <div className="form-section-main">
                    <div className="form-section-version">
                        {this.props.translateKey("version")}: {baseData.bbFormVersion}
                    </div>
                    <div className="form-section-main-number">
                        <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                    </div>
                    <div className="form-section-subsection-number">
                        {this.props.subsectionNumber}. {this.props.subsectionName}
                    </div>
                    <form className="section-form-content form_serializer">
                        <fieldset disabled={disabled}>
<ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.props.showValidationErrors}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            transKey="form-text-zeitlich"
                            key="is_orientation_temporal"
                            keyName="is_orientation_temporal"
                            value={isOrientationTemporal.value}
                            validationErrors={isOrientationTemporal.validationErrors}
                            valuesList={[
                                { value: '717700000', label: this.props.translateKey("form-text-uneingeschrankt")},
                                { value: '717700001', label: this.props.translateKey("form-text-teilweise")},
                                { value: '717700002', label: this.props.translateKey("form-text-eingeschrankt")}
                            ]}
                        />

                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.props.showValidationErrors}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            transKey="form-text-ortlich"
                            key="is_orientation_locational"
                            keyName="is_orientation_locational"
                            value={isOrientationLocational.value}
                            validationErrors={isOrientationLocational.validationErrors}
                            valuesList={[
                                { value: '717700000', label: this.props.translateKey("form-text-uneingeschrankt")},
                                { value: '717700001', label: this.props.translateKey("form-text-teilweise")},
                                { value: '717700002', label: this.props.translateKey("form-text-eingeschrankt")}
                            ]}
                        />

                        <ListComponent
                            setTypeChangeToParent={this.setTypeChange}
                            showValidation={this.props.showValidationErrors}
                            className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                            transKey="form-text-personlich"
                            key="is_orientation_personal"
                            keyName="is_orientation_personal"
                            value={isOrientationPersonal.value}
                            validationErrors={isOrientationPersonal.validationErrors}
                            valuesList={[
                                { value: '717700000', label: this.props.translateKey("form-text-uneingeschrankt")},
                                { value: '717700001', label: this.props.translateKey("form-text-teilweise")},
                                { value: '717700002', label: this.props.translateKey("form-text-eingeschrankt")}
                            ]}
                        />
                        </fieldset>
                    </form>
</div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section6));