import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as Entity from '../BBForm/values/entity';
import { baseData } from "../../_constance";

const flattenFields = fieldsList => fieldsList.reduce((previous, field) => Object.assign(previous, {
    [field.key]: field.value,
}), {});
const flattenFieldswithSkiped = fieldsList => fieldsList.filter(element => {
    if (element.value!==null && element.value.toString().trim()!=='') {return true;}return false;
}).reduce((previous, field) => Object.assign(previous, {
    [field.key]: field.value,
}), {});
const flattenFieldsArray = fieldsList => fieldsList.map(field => field.key);
const flattenFieldRejected = fieldsList => fieldsList.filter(element => {
    if (element.value!==null && element.value.toString().trim()!=='') {
        if(element.key === "is_inquiry_rejection_reason" || element.key === 'is_inquiry_rejection_other_reason' || element.key === 'is_contract_status' || element.key === 'is_inquiry_rejection_date'){
            return true;
        }else{
            return false
        }
    }
    return false;
}).reduce((previous, field) => Object.assign(previous, {
    [field.key]: field.value,
}), {});
export default class OfferEnquiryForm extends Form {
    constructor() {
        super({
            sections: [
                new FormSection({
                    key: 'contract-section',
                    fields: [
                        new FormField({
                            key: 'is_start_date',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                        }),
                        new FormField({
                            key: 'is_end_date',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isPermanentContract = form.getFieldByKey('is_permanent_contract');
                                return isPermanentContract.value !== '717700000';
                            },
                            // valueMapper: form => {
                            //     const isPermanentContract = form.getFieldByKey('is_permanent_contract');
                            //     if (isPermanentContract.value) {
                            //         return '2100-01-31';
                            //     }
                            // }
                        }),
                        new FormField({
                            key: 'is_permanent_contract',
                            entity: Entity.CONTRACT,
                            isFalseable: true,
                            isRequired: true,
                            defaultValue: false,
                        }),
                        new FormField({
                            key: 'is_product_id',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                        }),
                        new FormField({
                            key: 'is_rate',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                        }),
                        new FormField({
                            key: 'is_inquiry_rejection_date',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_inquiry_rejection_reason',
                            entity: Entity.CONTRACT,
                            // isRequired: true,
                        }),
                        new FormField({
                            key: 'is_inquiry_rejection_other_reason',
                            entity: Entity.CONTRACT,
                            isRequired: form => {
                                const isInquiryRejestionReason = form.getFieldByKey('is_inquiry_rejection_reason');
                                return isInquiryRejestionReason.value === '717700008';
                            },
                        }),
                        new FormField({
                            key: 'is_holiday_break_value',
                            entity: Entity.CONTRACT,
                            defaultValue: '100',
                        }),
                        new FormField({
                            key: 'is_customer_status_de',
                            entity: Entity.CONTACT,
                            defaultValue: '717700010',
                            valueMapper: () => '717700010',
                        }),
                        new FormField({
                            key: 'is_destination_country',
                            entity: Entity.CONTRACT,
                            defaultValue: '717700000',
                            valueMapper: () => '717700000',
                        }),
                        new FormField({
                            key: 'is_address_to_verify',
                            entity: Entity.CONTRACT,
                            defaultValue: '717700000',
                            valueMapper: () => '717700000',
                        }),
                        new FormField({
                            key: 'is_currencyid',
                            entity: Entity.CONTRACT,
                            defaultValue: baseData.currencyID,
                            valueMapper: () => baseData.currencyID,
                        }),
                        new FormField({
                            key: 'is_inquiry_receipt_date',
                            entity: Entity.CONTRACT,
                            valueMapper: () => Math.floor(Date.now()/1000),
                        }),
                        new FormField({
                            key: 'is_contract_status',
                            entity: Entity.CONTRACT,
                            valueMapper: (form) => {
                                const isInquiryRejectionReason = form.getFieldByKey('is_inquiry_rejection_reason');
                                if(isInquiryRejectionReason.value!==null && isInquiryRejectionReason.value!==''){
                                    return '717700004'
                                }else{
                                    return '717700003'
                                }
                            }
                        }),
                        new FormField({
                            key: 'is_technical_source_inquiry_quote',
                            entity: Entity.CONTRACT,
                            defaultValue: '717700002',
                        }),
                        new FormField({
                            key: 'is_driving_licence',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_second_ooo',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_transfer',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_carer_with_diploma',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                        new FormField({
                            key: 'is_additional_foreign_language',
                            entity: Entity.CONTRACT,
                            //defaultValue: '717700001',
                        }),
                    ],
                }),
                new FormSection({
                    key: 'contract-hidden',
                    fields: [
                        new FormField({
                            key: 'is_cooperation_condition_id',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_contact_patientID',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_primary_patientid',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_identical_patient_data',
                            entity: Entity.CONTRACT,
                            isRequired: true,
                            defaultValue: '717700000',
                        }),
                        new FormField({
                            key: 'is_first_name',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_last_name',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_street',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_house_number',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_appartment_number',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_zip_postal_code',
                            entity: Entity.CONTRACT,
                        }),
                        new FormField({
                            key: 'is_city',
                            entity: Entity.CONTRACT,
                        }),
                    ],
                }),
            ]
        });
    }

    get payload() {
        const patientFields = this.getFieldsByEntity(Entity.PATIENT);
        const contractFields = this.getFieldsByEntity(Entity.CONTRACT);
        return {
            patient: flattenFields(patientFields),
            contract: flattenFields(contractFields),
        };
    }

    getRetiveDataALl(lkId, offerId){
        let retrieveData = []
        if (lkId !== '0') {
            const contactFields = this.getFieldsByEntity(Entity.CONTACT);
            retrieveData.push({
                id: lkId,
                entity: Entity.CONTACT,
                fields: flattenFieldsArray(contactFields),
            });
        }
        if (offerId !== '0') {
            const contactFields = this.getFieldsByEntity(Entity.CONTRACT);
            retrieveData.push({
                id: offerId,
                entity: Entity.CONTRACT,
                fields: flattenFieldsArray(contactFields),
            });
        }
        return retrieveData;
    }

    getPayload(lkId, offerId) {
        const contractFields = this.getFieldsByEntity(Entity.CONTRACT);
        const contactFields = this.getFieldsByEntity(Entity.CONTACT);
        return [
                {
                    "Id": lkId,
                    "Entity": Entity.CONTACT,
                    "Fields": flattenFieldswithSkiped(contactFields)
                },
                {
                    "Id": offerId,
                    "Entity": Entity.CONTRACT,
                    "Fields": flattenFieldswithSkiped(contractFields)
                }
        ]
    }

    getRejectFields(lkId, offerId) {
        const contractFields = this.getFieldsByEntity(Entity.CONTRACT);
        const contactFields = this.getFieldsByEntity(Entity.CONTACT);
        return [
                {
                    "Id": lkId,
                    "Entity": Entity.CONTACT,
                    "Fields": flattenFieldswithSkiped(contactFields)
                },
                {
                    "Id": offerId,
                    "Entity": Entity.CONTRACT,
                    "Fields": flattenFieldRejected(contractFields)
                }
        ]

    }
}