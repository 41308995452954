import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../../_redux/actions/index";
import { IconsMap } from "../../../../_helpers/index";

class ClientPatientDataContactComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            element: this.props.element,
        };
    }

    onOpenShow(){
        this.setState(prevState => {
            return {
                ...prevState,
                isOpen: prevState.isOpen? false: true
                }
            }
        );
    }

    render() {
        const { translateKey, element } = this.props;
        let patientData;
        if(this.state.isOpen){
            patientData = (
                <div className="lead-details-client-info">
                    <div className="col-sm-12 col-lg-5">
                        {element.lastName!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_name")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.lastName}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.firstName!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("first_name")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.firstName}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.relationshipId!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("relationship")}</label>
                                <div className="col-sm-8">
                                    <span className="lead-input-wrapper">
                                        {element.relationshipId}
                                    </span>
                                </div>
                            </div>
                        }
                        {element.phone!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">
                                    {translateKey("phone")}
                                    <a className="mail-icon-in-details" href={"tel:"+element.phone} target="_top">
                                        <img src={IconsMap.svg.phone} alt="Email" className="user-name-icon"/>
                                        {translateKey("phone")}
                                    </a>
                                </label>
                                <div className="col-sm-8">
                                    {element.phone}
                                </div>
                            </div>
                        }
                        {element.cellPhone!==null &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">
                                    {translateKey("mobile_phone")}
                                    <a className="mail-icon-in-details" href={"tel:"+element.cellPhone} target="_top">
                                        <img src={IconsMap.svg.phone} alt="Email" className="user-name-icon"/>
                                    </a>
                                </label>
                                <div className="col-sm-8">
                                    {element.cellPhone}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className="lead-client-row">
                <div className="lead-details-header">
                    <div className="filter-modal-option-text">{translateKey("contact_person_details")}</div>
                    <div className="filter-modal-option-text cursor-pointer" onClick={()=> this.onOpenShow()}>
                        {this.state.isOpen?translateKey("show_less"):translateKey("show_more")}
                    </div>
                </div>
                {patientData}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(ClientPatientDataContactComponent));