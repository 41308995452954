import SortingElement from "./sortingElement";
const SortingDataList = [
    new SortingElement(
        "DESC",
        'multiplier',
        true,
        "text",
        'multiplier',
        'multipliers_multiplier',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'company',
        false,
        "text",
        'company',
        'multipliers_company',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'location',
        false,
        "text",
        'location',
        'multipliers_location',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'telephone',
        false,
        "text",
        'phone',
        'multipliers_telephone',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'email',
        false,
        "text",
        'email',
        'multipliers_email',
        true,
        false,
        null
    ),
    

    new SortingElement(
        "ASC",
        'profession',
        false,
        "text",
        'profession',
        'multipliers_profession',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'phone',
        false,
        "text",
        'home_phone',
        'multipliers_phone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'cellPhone',
        false,
        "text",
        'mobile_phone',
        'multipliers_cellPhone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'businessPhone',
        false,
        "text",
        'business_phone',
        'multipliers_businessPhone',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'phoneOtherRemarks',
        false,
        "text",
        'phone_other_remarks',
        'multipliers_phoneOtherRemarks',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'address.city',
        false,
        "text",
        'city',
        'multipliers_city',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'address.postalCode',
        false,
        "text",
        'postal_code',
        'multipliers_postal_code',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'address.street',
        false,
        "text",
        'street',
        'multipliers_street',
        false,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'careAmbasador',
        false,
        "boolean",
        'care_ambassador',
        'multipliers_care_ambassador',
        true,
        false,
        null
    ),
    new SortingElement(
        "ASC",
        'jobtitle',
        false,
        "text",
        'care_ambassador_job_title',
        'multipliers_jobtitle',
        true,
        false,
        null
    ),
];
export default class SortingMultipliers {
    sortingColumns = [];

    constructor() {
        this.sortingColumns = SortingDataList;
    }

    getColumnByKey(key) {
        return this.sortingColumns.find(column => column.keyName === key);
    }

    getColumnByColumn(columnName) {
        return this.sortingColumns.find(column => column.column === columnName);
    }
    changeColumnByColumn(columnName) {
        this.sortingColumns.forEach(column => {
            if(column.column === columnName){
                if(column.active===true){
                    column.type==='ASC'? column.type="DESC" : column.type='ASC';
                }else{
                    column.type='ASC';
                    column.active = true;
                }
            }else{
                column.type='ASC';
                column.active = false;
            }
        });
    }

    setColumnEnabled (enabled, key){
        for(let i=0; i<this.sortingColumns.length; i++){
            if(this.sortingColumns[i].keyName===key){
                this.sortingColumns[i].enabled = enabled;
                break
            }
        }
    }

    isColumnActive(key){
        return this.sortingColumns.find(column => column.keyName === key && column.acitve === true);
    }

    isColumnVisibleByKey(key, selectedLang) {
        return this.sortingColumns.find(column => {
            if(column.keyName === key && column.blocked === false && column.enabled === true){
                if(column.languageRestrictions!==null){
                    if(selectedLang === column.languageRestrictions){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return true;
                }
            }else{
                return false;
            }
        });
    }
}