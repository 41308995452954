import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { setLeadsData, pendingLeadsData, removeLeadsData, translateKey, authSetActionTimestamp, removeLeadStatusData, getLeadStatusData, getPatientLead} from "../../_redux/actions/index";
import { TableProperties, NavBar, SideBar, LoaderTable, LoaderModal, ConfirmModal, AlertModalInfo} from "../../_components/index";
import { IconsMap, shouldRefresh } from "../../_helpers/index";
import { TableEnums } from "../../_constance";
import { Section0, Section1, Section2, Section3, Section4, Section5, Section6, Section7, Section8, Section9, Section10, Section11, 
  Section12, Section13, Section14, Section15, Section16, Section17, Section18, Section19, Section20} from "../../_components/form-bb-sections/index";
import ContractComponent from "../../_components/form-bb-components/contract-component/ContractComponent";
import OfferEnquiryComponent from "../../_components/form-bb-components/offer-enquiry-component/OfferEnquiryComponent";
import axiosAuth from "../../_services/config/axios-auth";
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { getUserProducts, getRegionsFP } from "../../_redux/actions/bb-form-services";
import BBFormService from '../../_services/bbform';
import * as Entity from '../../_forms/BBForm/values/entity';
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';
import { Alert } from "../../_constance/classes/index";
import BBForm from '../../_forms/BBForm/BBForm';
const notEditableLeadStatuses = ['717700010'];
let finalPageNumber = 20;
class MainPatientWrapper extends React.Component {
  _isMounted = false;
  myTimout;
  patientTimeout;
  constructor(props) {
    super(props);
    this.state = {
      editedForm: false,
      editedContract: false,
      editedQuatation: false,
      showContract: false,
      showOfferEnquiry: false,
      currentSection: 0,
      loader: null,
      modalLoader: true,
      savingData: false,
      errorsMessage: null,
      errorsType: "danger",
      errorComponent: Alert,
      LKId: 0,
      patientId: 0,
      products: [],
      regionsFP: [],
      formbbStatus: "0",
      type: FormTypeToShow.FORM,
      confirmConsents: false,
      confirmNewEnquiry: false,
      toPage: null,
      isConsentsEditable: true,
      isImportant: false,
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ],
      showValidationErrors: false,
      form: new BBForm(),
      rerender: Date.now(),
      newOne: false,
      leadPatients: [],
      wholeResponse: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { match: { params } } = this.props;
    let type = params.type;
    if(type!==FormTypeToShow.CONTRACT && type!==FormTypeToShow.ENQUIRY){
      type=FormTypeToShow.FORM
    }
    if (this._isMounted) {
      this.setState({LKId: params.id,patientId: params.patientId, type: type})
    }
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    this.myTimeout = setTimeout(() => {
      this.getInitialDataTime();
      this.getDataBasic();
    }, 200);
  }

  componentDidUpdate() {
    let isSaved = this.state.editedForm || this.state.editedContract || this.state.editedQuatation;
    this._promptUnsavedChange(isSaved)
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
    clearTimeout(this.patientTimeout);
  };

  _promptUnsavedChange(isUnsaved = false) {
    window.onbeforeunload = isUnsaved;
  }

  changePage = async number => {
      const { form, currentSection, confirmConsents, formbbStatus } = this.state;
      let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(currentSection===finalPageNumber){
        if(!isFormDisabled){
          isFormDisabled = this.state.isConsentsEditable;
        }
      }
      if(!isFormDisabled){
        if(currentSection===finalPageNumber && confirmConsents===false){
          if(this.state.isConsentsEditable){
            let sectionConsents = form.getSectionByOrder(currentSection);
            let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
            if(gotConsentsSoSetFalse.length>0){
              if (this._isMounted) {
                this.setState({
                  confirmConsents: true,
                  toPage: number,
                  isImportant: false
                })
              }
            }else{
              if (this._isMounted) {
                this.setState({
                  confirmConsents: false,
                  currentSection: number,
                  isImportant: false
                })
              }
              window.scrollTo(0, 0);
            }
          }else{
            if (this._isMounted) {
              this.setState({
                confirmConsents: false,
                currentSection: number,
                isImportant: false
              })
            }
            window.scrollTo(0, 0);
          }
        }else{
          const section = form.getSectionByOrder(currentSection);
          if (section.isValid) {
              await this.savePatient();
          }
          if (currentSection > number) {
              if (this._isMounted) {
                this.setState({
                    currentSection: number,
                    savingData: false,
                    showValidationErrors: true,
                    confirmConsents:false,
                    isImportant: false
                });
              }
              window.scrollTo(0, 0);
          } else {
              const { form } = this.state;
              let readyToGo = true;
              for (let i = 0; i < number; i++) {
                  let tempSection = form.getSectionByOrder(i);
                  if (!tempSection.isValid) {
                      readyToGo = false;
                      if (this._isMounted) {
                        this.setState({
                            showValidationErrors: false,
                            currentSection: i,
                            confirmConsents: false,
                            isImportant: false
                        });
                      }
                      window.scrollTo(0, 0);
                      break;
                  }
              }
              if (readyToGo) {
                  if (this._isMounted) {
                    this.setState({
                        currentSection: number,
                        confirmConsents: false,
                        isImportant: false
                    });
                  }
                  window.scrollTo(0, 0);
              }
          }
      }
    }else{
      if (this._isMounted) {
        this.setState({
          currentSection: number,
          savingData: false,
          showValidationErrors: true,
          confirmConsents:false,
          isImportant: false
        });
      }
      window.scrollTo(0, 0);
    }
  };

  previousPageClick = async () => {
      const { currentSection, confirmConsents, formbbStatus, form} = this.state;
      let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(currentSection===finalPageNumber){
        if(!isFormDisabled){
          isFormDisabled = !this.state.isConsentsEditable;
        }
      }
      if(!isFormDisabled){
        if(currentSection===finalPageNumber && confirmConsents===false){
          let sectionConsents = form.getSectionByOrder(currentSection);
          let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
          if(gotConsentsSoSetFalse.length>0){
            if (this._isMounted) {
              this.setState({
                confirmConsents: true,
                toPage: parseInt(this.state.currentSection) - 1,
                isImportant: false
              })
            }
          }else{
            if (this._isMounted) {
              this.setState({
                confirmConsents: false,
                currentSection: parseInt(this.state.currentSection) - 1,
                isImportant: false
              })
            }
            window.scrollTo(0, 0);
          }
        }else{
          const [isSaved, LKId, patientId] = await this.savePatient();
          if (isSaved) {
            const { match: { params } } = this.props;
            if(params.patientId==="0" || params.patientId===0){
              this.props.getPatientLeadData(this.props.appLang);
              const { history } = this.props;
              history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientId}`);
            }
          }
          if (this._isMounted) {
            this.setState({
              currentSection: this.giveMeSpecialToGoSection(),
              LKId: LKId,
              patientId: patientId,
              isImportant: false
            });
          }
          window.scrollTo(0, 0);
        }
      }else{
        if (this._isMounted) {
          this.setState({
            currentSection: this.giveMeSpecialToGoSection(),
            isImportant: false
          });
        }
        window.scrollTo(0, 0);
      }
  };

  giveMeSpecialToGoSection(){
    const { currentSection, form } = this.state;
    let toPageNumber = parseInt(currentSection) - 1;
    if(toPageNumber === Sections.PATIENT){
      const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
      if(isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8){
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }else{
          toPageNumber =  Sections.CONTACT_PERSON
        }
      }
    }else{
      if(toPageNumber === Sections.CONTACT_PERSON){
        const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
        if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
          toPageNumber = Sections.CLIENT
        }
      }
    }
    return toPageNumber;
  }

  clearAlertComponent = (withTimeOut=false) =>{
    let timeOutTime = 0;
    if(withTimeOut){
      timeOutTime = 2000;
    }
    setTimeout(() => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              errorComponent: {
                ...prevState.errorComponent,
                show: false,
                type: "danger",
                message: null
              }
          }
        })
      }
    }, timeOutTime);
  }

  showAlertMessage = (type, message) =>{
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            errorComponent: {
              ...prevState.errorComponent,
              show: true,
              type: type,
              message: message
            }
        }
      })
    }
    this.clearAlertComponent(true);
  }

  nextPageClick = async () => {
    const { form, currentSection, confirmConsents, formbbStatus } = this.state;
    let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if(currentSection===finalPageNumber){
      if(!isFormDisabled){
        isFormDisabled = !this.state.isConsentsEditable;
      }
    }
    if(!isFormDisabled){
      if(currentSection===finalPageNumber && confirmConsents===false){
        if (this._isMounted) {
          this.setState({
            confirmConsents: true,
            toPage: null
          })
        }
      }else{
        const section = form.getSectionByOrder(currentSection);
        const [isSaved, LKId, patientId] = await this.savePatient();
        if (isSaved) {
          const { match: { params } } = this.props;
          if(params.patientId==="0" || params.patientId===0){
            this.props.getPatientLeadData(this.props.appLang);
            const { history } = this.props;
            history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientId}`);
          }
          if (this._isMounted) {
            this.setState({
              currentSection: section.nextSection,
              LKId,
              patientId,
            });
          }
          window.scrollTo(0, 0);
        }
      }
    }else{
      if(currentSection===finalPageNumber){
        if (this._isMounted) {
          this.setState({
            confirmConsents:false,
            isConsentsEditable: false
          });
        }
      }else{
        if (this._isMounted) {
          const section = form.getSectionByOrder(currentSection);
          this.setState({currentSection: section.nextSection});
        }
        window.scrollTo(0, 0);
      }

    }
  };

  saveConsents = async () => {
    const [isSaved] = await this.savePatient();
    if (isSaved) {
      const { toPage } = this.state;
      if(toPage!=null){
        if (this._isMounted) {
          this.setState({
            confirmConsents:false,
            currentSection: toPage,
            toPage: null
          });
        }
        window.scrollTo(0, 0);
      }else{
        if (this._isMounted) {
          this.setState({
            confirmConsents:false,
            isConsentsEditable: false
          });
        }
      }
    }else{
      if (this._isMounted) {
        this.setState({confirmConsents:false});
      }
    }
  };

  savePatient = async () => {
      const { form, currentSection, formbbStatus, isImportant } = this.state;
      const { match: { params } } = this.props;
      let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(currentSection===finalPageNumber){
        if(!isFormDisabled){
          isFormDisabled = !this.state.isConsentsEditable;
        }
      }
      if(!isFormDisabled){
        if (this._isMounted) {
          this.setState({ savingData: true });
        }
        const section = form.getSectionByOrder(currentSection);
        let allImportantFieldsFilled = true;
        if(isImportant===false && !section.allImportantsFilled){
          allImportantFieldsFilled = false;
        }
        if (section.isValid && allImportantFieldsFilled) {
            try {
                const { match: { params } } = this.props;
                const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, params.patientId));
                let lkid = 0;
                let patientid = 0;
                const { data } = response;
                data.forEach(element => {
                  if (element.entity === Entity.PATIENT) {
                      patientid = element.id;
                  } else {
                      if (element.entity === Entity.CONTACT) {
                          lkid = element.id;
                      }
                  }
                });
                if (this._isMounted) {
                  this.setState({
                      savingData: false,
                      showValidationErrors: false,
                      isImportant: false,
                  });
                }
                if(currentSection.toString()==='0'){
                  const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
                  const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
                  if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                    /// pass fields from 1 to second section
                    form.getFieldByKey('is_contact_person_firstname').value = form.getFieldByKey('firstname').value;
                    form.getFieldByKey('is_contact_person_lastname').value = form.getFieldByKey('lastname').value;
                    form.getFieldByKey('is_contact_person_mobile_phone').value = form.getFieldByKey('is_mobile_phone').value;
                    form.getFieldByKey('is_relationship_with_patient_op').value = isDegreeRelationShipWithPatientCarer.value;
                    this.simpleSaveWithoutErrors(Sections.CONTACT_PERSON);
                  }
                  if(isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8){
                    /// pass fields from 1 to third section
                    const firstNameValue = form.getFieldByKey('firstname').value;
                    const lastNameValue = form.getFieldByKey('lastname').value;
                    form.getFieldByKey('is_patient_first_name').value = firstNameValue;
                    form.getFieldByKey('is_patient_last_name').value = lastNameValue;
                    form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue +" "+ lastNameValue;
                    form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
                    form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
                    form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
                    form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
                    form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
                    form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
                    this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
                  }
                }
                return [true, lkid, patientid];
            } catch (e) {
                console.log(e);
                if (this._isMounted) {
                  this.setState({
                      savingData: false,
                      showValidationErrors: false,
                      isImportant: false
                  });
                }
                return [false];
            }
        } else {
            if (this._isMounted) {
              this.setState({
                  savingData: false,
                  showValidationErrors: true,
                  isImportant: true,
              });
            }
            return [false];
        }
      }else{
        if (this._isMounted) {
          this.setState({
            savingData: false,
            showValidationErrors: false,
            isImportant: false
          });
        }
        return [true, params.id, params.patientId]
      }
  };

  simpleSaveWithoutErrors = async (sectionNumber, patientid) => {
    const { match: { params } } = this.props;
    const { form, currentSection, formbbStatus } = this.state;
    let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if(currentSection===finalPageNumber){
      if(!isFormDisabled){
        isFormDisabled = this.state.isConsentsEditable;
      }
    }
    if(!isFormDisabled){
      const section = form.getSectionByOrder(sectionNumber);
      if (section.isValid) {
        let newPatient = params.patientId;
        if(patientid!==0 || patientid!=="0"){
          newPatient = patientid;
        }
        await BBFormService.savePatient(form.getPayloadForSection(section.key, params.id, newPatient));
        this.props.getPatientLeadData(this.props.appLang);
      }
    }
  };

  getMeInitLeadPatientsList(){
    const { match: { params }, patientLeadData } = this.props;
    let pushedElements = [];
    let leadPatientsList = patientLeadData.filter(element => {
        if(element.leadId === params.id){
            if(pushedElements.indexOf(element.id) > -1){
                return false;
            }else{
                pushedElements.push(element.id);
                return element
            }
        }else{
          return false;
        }
    })
    if (this._isMounted) {
      this.setState({leadPatients: leadPatientsList})
    }
    return pushedElements;
  }

  gotBasicDataPrepareWhichPageToShow(){
    switch(this.state.type){
      case FormTypeToShow.ENQUIRY:  this.prepareMeEnquiryOrContract(FormTypeToShow.ENQUIRY);
                                    break;
      case FormTypeToShow.CONTRACT: this.prepareMeEnquiryOrContract(FormTypeToShow.CONTRACT);
                                    break;
      default:  this.prepareMeForm();
    }
  }

  prepareMeEnquiryOrContract(type){
    const { translateKey, match: { params } } = this.props;
    const { form, formbbStatus } = this.state;
    const leadPatients = this.getMeInitLeadPatientsList();
    if(params.patientId===0 || params.patientId==='0'){
      // CREATE NEW ENQUIRY
      if(notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1){
        //PROCESSING OFFER, DONT SHOW OFFER
        alert(translateKey("form-text-processing-offer"));
        if(leadPatients.length>0){
          if (this._isMounted) {
            this.setState({
              showValidationErrors: false,
              loader: false,
              modalLoader: false,
              savingData: false,
              showContract: false,
              showOfferEnquiry: false
            })
          }
          BBFormService.retrievePatient(form.getRetiveDataALl(params.id, leadPatients)).then(response => {
            const { data } = response;
            let consentsEditable = true;
            data.forEach(entity => form.setFields(entity.fields));
            let sectionConsents = form.getSectionByOrder(finalPageNumber);
            let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
            if(gotConsentsSoSetFalse.length>0){consentsEditable=false}
            if (this._isMounted) {
              this.setState({
                wholeResponse: data,
                isConsentsEditable: consentsEditable
              });
            }
          }).then(()=>{
            if (this._isMounted) {
              this.setState({rerender: Date.now()});
            }
          })
        }else{
          alert(translateKey("form-error-no-patient"));
          if (this._isMounted) {
            this.setState({
              showValidationErrors: false,
              loader: false,
              modalLoader: false,
              savingData: false,
              showContract: false,
              showOfferEnquiry: false
            })
          }
          BBFormService.retrievePatient(form.getRetiveDataALl(params.id, [])).then(response => {
            const { data } = response;
            let consentsEditable = true;
            data.forEach(entity => form.setFields(entity.fields));
            let sectionConsents = form.getSectionByOrder(finalPageNumber);
            let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
            if(gotConsentsSoSetFalse.length>0){consentsEditable=false}
            if (this._isMounted) {
              this.setState({isConsentsEditable: consentsEditable});
            }
          }).then(()=>{
            if (this._isMounted) {
              this.setState({rerender: Date.now()});
            }
          })
        }
      }else{
        if(leadPatients.length>0){
          if (this._isMounted) {
            this.setState({
              showValidationErrors: false,
              loader: false,
              modalLoader: false,
              savingData: false,
              showContract: false,
              showOfferEnquiry: false
            })
          }
          BBFormService.retrievePatient(form.getRetiveDataALl(params.id, leadPatients)).then(response => {
            const { data } = response;
            let consentsEditable = true;
            data.forEach(entity => form.setFields(entity.fields));
            let sectionConsents = form.getSectionByOrder(finalPageNumber);
            let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
            if(gotConsentsSoSetFalse.length>0){consentsEditable=false}
            if (this._isMounted) {
              this.setState({
                wholeResponse: data,
                isConsentsEditable: consentsEditable
              });
            }
            /*let readyToGo = true;
            for(let i=0; i<form.sections.length; i++){
              let section = form.getSectionByOrder(i);
              if(!section.isValid){
                readyToGo = true;
                if (this._isMounted) {
                  this.setState({
                    showValidationErrors: true,
                    currentSection: i,
                    savingData: false,
                    modalLoader: false,
                    showContract: false,
                    showOfferEnquiry: false
                  })
                }
                break;
              }
            }*/
            //if(readyToGo){
              if(type === FormTypeToShow.ENQUIRY){
                this.showMeQuatation(true);
              }else{
                this.showMeContract(true);
              }
            // }else{
            //   if(type === FormTypeToShow.ENQUIRY){
            //     alert(translateKey("form-text-basic-reqired-message"));
            //     this.showMeQuatation(false);
            //   }else{
            //     alert(translateKey("form-text-basic-reqired-message"));
            //     this.showMeContract(false);
            //   }
            // }
          })
        }else{
          alert(translateKey("form-error-no-patient"));
          if (this._isMounted) {
            this.setState({
              showValidationErrors: false,
              loader: false,
              modalLoader: false,
              savingData: false,
              showContract: false,
              showOfferEnquiry: false
            })
          }
        }
      }
    }else{
      // EXITS
      if(leadPatients.length>0){
        if (this._isMounted) {
          this.setState({
            showValidationErrors: false,
            loader: false,
            modalLoader: false,
            savingData: false,
            showContract: false,
            showOfferEnquiry: false
          })
        }
        BBFormService.retrievePatient(form.getRetiveDataALl(params.id, leadPatients)).then(response => {
          const { data } = response;
          let consentsEditable = true;
          data.forEach(entity => form.setFields(entity.fields));
          let sectionConsents = form.getSectionByOrder(finalPageNumber);
          let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
          if(gotConsentsSoSetFalse.length>0){consentsEditable=false}
          if (this._isMounted) {
            this.setState({
              wholeResponse: data,
              isConsentsEditable: consentsEditable
            });
          }
        }).then(()=>{
          if (this._isMounted) {
            this.setState({rerender: Date.now()});
          }
        })
        if(type === FormTypeToShow.ENQUIRY){
          this.showMeQuatation(false);
        }else{
          this.showMeContract(false);
        }
      }else{
        alert(translateKey("form-error-no-patient"));
        if (this._isMounted) {
          this.setState({
            showValidationErrors: false,
            loader: false,
            modalLoader: false,
            savingData: false,
            showContract: false,
            showOfferEnquiry: false
          })
        }
        BBFormService.retrievePatient(form.getRetiveDataALl(params.id, [])).then(response => {
          const { data } = response;
          let consentsEditable = true;
          data.forEach(entity => form.setFields(entity.fields));
          let sectionConsents = form.getSectionByOrder(finalPageNumber);
          let gotConsentsSoSetFalse = sectionConsents.fields.filter(field => field.value !== null);
          if(gotConsentsSoSetFalse.length>0){consentsEditable=false}
          if (this._isMounted) {
            this.setState({isConsentsEditable: consentsEditable});
          }
        }).then(()=>{
          if (this._isMounted) {
            this.setState({rerender: Date.now()});
          }
        })
      }
    }
  }

  prepareMeForm(){
    const { match: { params } } = this.props;
    const { form } = this.state;
    let patientIdList = [];
    if(params.patientId!==0 && params.patientId!=="0"){
      patientIdList.push(params.patientId);
    }
    BBFormService.retrievePatient(form.getRetiveDataALl(params.id, patientIdList)).then(response => {
      const { data } = response;
      let consentsEditable = true;
      data.forEach(entity => form.setFields(entity.fields));
      let sectionConsents = form.getSectionByOrder(finalPageNumber);
      let test = sectionConsents.fields.filter(field => field.value !== null);
      if(test.length>0){consentsEditable=false}
      if (this._isMounted) {
        this.setState({isConsentsEditable: consentsEditable});
      }
      let readyToGo = true;
      if(params.patientId==="0" || params.patientId===0){
        if (this._isMounted) {
          this.setState({
            showValidationErrors: false,
            currentSection: 0,
            savingData: false,
            modalLoader: false,
            showContract: false,
            showOfferEnquiry: false
          })
        }
        window.scrollTo(0, 0);
      }else{
        for(let i=0; i<form.sections.length; i++){
          let section = form.getSectionByOrder(i);
          if(!section.isValid){
            readyToGo = false;    
            if (this._isMounted) {                    
              this.setState({
                showValidationErrors: i!==0?true:false,
                currentSection: i,
                savingData: false,
                modalLoader: false,
                showContract: false,
                showOfferEnquiry: false
              })
            }
            window.scrollTo(0, 0);
            break;
          }
        }
        if(readyToGo){
          if (this._isMounted) {
            this.setState({
              showValidationErrors: true,
              currentSection: finalPageNumber,
              savingData: false,
              modalLoader: false,
              showContract: false,
              showOfferEnquiry: false
            })
          }
          window.scrollTo(0, 0);
        }
      }
    }).then(()=>{
      if (this._isMounted) {
        this.setState({rerender: Date.now()});
      }
    })
  }

  getInitialDataTime(){
    if(this.props.leadsStatus.length>0){
      clearTimeout(this.myTimeout);
      this.getLeadsDataByTimestamp();
    }else{
      this.props.getStatusLead(this.props.appLang);
      this.myTimeout = setTimeout(() => {
        this.getInitialDataTime()
      }, 500);
    }
  }

  getLeadsList = () => {
    this.props.pendingRequestLeads();
    axiosAuth.get("leads/list/0/" + this.props.appLang)
      .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
      .then(json => { return json.data })
      .then(res => {
        const { match: { params } } = this.props;
        for(let i=0;i<res.length; i++){
          if(res[i].leadId===params.id){
            let name='---';
            if(res[i].client.lastName!==null){
              name = res[i].client.lastName;
              if(res[i].client.firstName!==null){
                name += ' '+res[i].client.firstName;
              }
            }else{
              if(res[i].client.firstName!==null){
                name = res[i].client.firstName;
              }
            }
            if (this._isMounted) {
              this.setState({
                LKId: params.id,
                breadCrumbs:[...this.state.breadCrumbs, {
                  link: "leadDetails/"+params.id,
                  key: "",
                  name: name
                },
                {
                  link: "",
                  key: "",
                  name: "BB"
                }],
                formbbStatus: res[i].status
              })
            }
            this.gotBasicDataPrepareWhichPageToShow();
            break;
          }
        }
      })
      .catch((error) => {
        this.props.clearDataLeads();
        this.getBreadCrumb();
      });
  }

  getLeadsDataByTimestamp(){
    const lastUpdate = this.props.leadsTimestamp;
    const actualTime = new Date().getTime();
    if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
      this.getLeadsList();
    } else {
      if(this.props.leadsData.length>0){
        this.getBreadCrumb();
      }else{
        this.getLeadsList();
      }
    }
  }

  getBreadCrumb() {
    const { match: { params } } = this.props;
    for(let i=0;i<this.props.leadsData.length; i++){
      if(this.props.leadsData[i].id===params.id){
        let name='---';
        if(this.props.leadsData[i].client.lastName!==null){
          name = this.props.leadsData[i].client.lastName;
          if(this.props.leadsData[i].client.firstName!==null){
            name += ' '+this.props.leadsData[i].client.firstName;
          }
        }else{
          if(this.props.leadsData[i].client.firstName!==null){
            name = this.props.leadsData[i].client.firstName;
          }
        }
        if (this._isMounted) {
          this.setState({
            LKId: params.id,
            breadCrumbs:[...this.state.breadCrumbs, {
              link: "leadDetails/"+params.id,
              key: "",
              name: name
            },
            {
              link: "",
              key: "",
              name: "BB"
            }],
            formbbStatus: this.props.leadsData[i].status,
          })
        }
        this.gotBasicDataPrepareWhichPageToShow();
        break;
      }
    }
  }

  getDataBasic(isFormDisabled){
    const { translateKey } = this.props;
    const { form, rerender, showValidationErrors, isImportant } = this.state;
    let content = (
      <LoaderTable />
    );

    if(!this.state.loader){
      switch (this.state.currentSection) {
        case 0:
          content = (
            <div className="section-class-container">
              <Section0
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_client_data_p_0")}
                subsectionNumber="1"
                sectionNumber="1"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section0>
            </div>
          );
          break;
        case 1:
          content = (
            <div className="section-class-container">
              <Section1
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_contact_data_p_1")}
                subsectionNumber="1.1"
                sectionNumber="1"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section1>
            </div>
          );
          break;
        case 2:
          content = (
            <div className="section-class-container">
              <Section2
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_lead_data_p_2")}
                subsectionNumber="1.2"
                sectionNumber="1"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section2>
            </div>
          );
          break;
        case 3:
          content = (
            <div className="section-class-container">
              <Section3
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegegrad_data_p_3")}
                subsectionNumber="2"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section3>
            </div>
          );
          break;
        case 4:
          content = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section4
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_ills_data_p_4")}
                subsectionNumber="2.1"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section4>
            </div>
          );
          break;
        case 5:
          content = (
            <div className="section-class-container">
              <Section5
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_comnunication_data_p_5")}
                subsectionNumber="2.2"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section5>
            </div>
          );
          break;
        case 6:
          content = (
            <div className="section-class-container">
              <Section6
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_orientation_data_p_6")}
                subsectionNumber="2.3"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section6>
            </div>
          );
          break;
        case 7:
          content = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section7
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_characteristic_data_p_7")}
                subsectionNumber="2.4"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section7>
            </div>
          );
          break;
        case 8:
          content = (
            <div className="section-class-container">
              <Section8
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_movement_data_p_8")}
                subsectionNumber="2.5"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section8>
            </div>
          );
          break;
        case 9:
          content = (
            <div className="section-class-container">
              <Section9
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_defecation_data_p_9")}
                subsectionNumber="2.6"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section9>
            </div>
          );
          break;
        case 10:
          content = (
            <div className="section-class-container">
              <Section10
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_hugiene_data_p_10")}
                subsectionNumber="2.7"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section10>
            </div>
          );
          break;
        case 11:
          content = (
            <div className="section-class-container">
              <Section11
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_foods_data_p_11")}
                subsectionNumber="2.8"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section11>
            </div>
          );
          break;
        case 12:
          content = (
            <div className="section-class-container">
              <Section12
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_clothes_data_p_12")}
                subsectionNumber="2.9"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section12>
            </div>
          );
          break;
        case 13:
          content = (
            <div className="section-class-container" onKeyPress={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section13
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_sleep_data_p_13")}
                subsectionNumber="2.10"
                sectionNumber="2"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section13>
            </div>
          );
          break;
        case 14:
          content = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section14
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegedienst_data_p_14")}
                subsectionNumber="3"
                sectionNumber="3"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section14>
            </div>
          );
          break;
        case 15:
          content = (
            <div className="section-class-container">
              <Section15
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_therapy_data_p_15")}
                subsectionNumber="4"
                sectionNumber="4"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section15>
            </div>
          );
          break;
        case 16:
          content = (
            <div className="section-class-container" onKeyPress={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section16
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_expectations_data_p_16")}
                subsectionNumber="5"
                sectionNumber="5"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section16>
            </div>
          );
          break;
        case 17:
          content = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section17
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_actions_data_p_17")}
                subsectionNumber="5.1"
                sectionNumber="5"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section17>
            </div>
          );
          break;
        case 18:
          content = (
            <div className="section-class-container">
              <Section18
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_place_data_p_18")}
                subsectionNumber="5.2"
                sectionNumber="5"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section18>
            </div>
          );
          break;
        case 19:
          content = (
            <div className="section-class-container">
              <Section19
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-text-notiz")}
                subsectionNumber="5.3"
                sectionNumber="5"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section19>
            </div>
          );
          break;
        case 20:
          content = (
            <div className="section-class-container">
              <Section20
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("consents")}
                subsectionNumber="6"
                sectionNumber="6"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled || !this.state.isConsentsEditable}
              ></Section20>
            </div>
          );
          break;
        default:
          content = (
            <div className="section-class-container">
              <Section0
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_client_data_p_0")}
                subsectionNumber="1"
                sectionNumber="1"
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
              ></Section0>
            </div>
          );
      }
    }
    return content;
  }

  showMeQuatation =(isNewOne = true) => {
    const { match: { params }, history } = this.props;
    const { formbbStatus, form } = this.state;
    if(isNewOne){history.push(`${baseData.subFolders}bb-form-lead/${params.id}/0/${FormTypeToShow.ENQUIRY}`);}
    let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    const leadPatients = this.getMeInitLeadPatientsList();
    if(leadPatients.length>0){
      BBFormService.retrievePatient(form.getRetiveDataALl(params.id, leadPatients)).then(response => {
        const { data } = response;
        if (this._isMounted) {
          this.setState({wholeResponse: data});
        }
      }).then(()=>{
        if (this._isMounted) {
          this.setState({rerender: Date.now()});
        }
      })
    }
    if(params.patientId!==0 || params.patientId!=='0'){
      if (this._isMounted) {
        this.setState({modalLoader: true});
      }
      this.props.getProducts().then((data)=>{
        if (this._isMounted) {
          if(data.length>1){
            data.sort((a,b)=>{
              const nameA = a.ProductName;
              const nameB = b.ProductName;
              if (nameA === null || nameA === undefined ) {return -1;}
              else if (nameB === null || nameB === undefined ) {return 1;}
              else if (nameA === nameB) {return 0;}
              if (nameA.localeCompare(nameB)===-1){ return -1; }
              else{ return 1; }
            })
          }
          this.setState({
            modalLoader: false,
            products: data,
          });
        }
      })
      .catch((error)=>{
        console.log(error);
      })
      this.props.getRegions().then((data)=>{
        if (this._isMounted) {
          this.setState({
            modalLoader: false,
            regionsFP: data
          });
        }
      })
      .catch((error)=>{
        console.log(error);
      })
      if (this._isMounted) {
        this.setState({
          showContract: false,
          showOfferEnquiry: true,
          newOne: isNewOne
        })
      }
    }else{
      if(this.state.showOfferEnquiry === false && this.state.showContract===false && isFormDisabled){
        if (this._isMounted) {
          this.setState({
            modalLoader: false,
            savingData: false,
            confirmNewEnquiry: true
          })
        }
      }else{
        if (this._isMounted) {
          this.setState({modalLoader: true});
        }
        this.props.getProducts().then((data)=>{
          if (this._isMounted) {
            if(data.length>1){
              data.sort((a,b)=>{
                const nameA = a.ProductName;
                const nameB = b.ProductName;
                if (nameA === null || nameA === undefined ) {return -1;}
                else if (nameB === null || nameB === undefined ) {return 1;}
                else if (nameA === nameB) {return 0;}
                if (nameA.localeCompare(nameB)===-1){ return -1; }
                else{ return 1; }
              })
            }
            this.setState({
              modalLoader: false,
              products: data,
            });
          }
        })
        .catch((error)=>{
          console.log(error);
        })
        this.props.getRegions().then((data)=>{
          if (this._isMounted) {
            this.setState({
              modalLoader: false,
              regionsFP: data
            });
          }
        })
        .catch((error)=>{
          console.log(error);
        })
        if (this._isMounted) {
          this.setState({
            showContract: false,
            showOfferEnquiry: true,
            newOne: isNewOne
          })
        }
      }
    }
  }

  showMeContract = (isNewOne = true) => {
    const { match: { params }, history } = this.props;
    if(isNewOne){history.push(`${baseData.subFolders}bb-form-lead/${params.id}/0/${FormTypeToShow.CONTRACT}`);}
    const { formbbStatus, form} = this.state;
    let isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if(this.state.showOfferEnquiry === false && this.state.showContract===false && isFormDisabled){
      if (this._isMounted) {
        this.setState({
          modalLoader: false,
          savingData: false,
          confirmNewEnquiry: true
        })
      }
    }else{
      const leadPatients = this.getMeInitLeadPatientsList();
      if(leadPatients.length>0){
        BBFormService.retrievePatient(form.getRetiveDataALl(params.id, leadPatients)).then(response => {
          const { data } = response;
          if (this._isMounted) {
            this.setState({wholeResponse: data});
          }
        }).then(()=>{
          if (this._isMounted) {
            this.setState({rerender: Date.now()});
          }
        })
      }
      if (this._isMounted) {
        this.setState({modalLoader: true})
      }
      this.props.getProducts().then((data)=>{
        if (this._isMounted) {
          if(data.length>1){
            data.sort((a,b)=>{
              const nameA = a.ProductName;
              const nameB = b.ProductName;
              if (nameA === null || nameA === undefined ) {return -1;}
              else if (nameB === null || nameB === undefined ) {return 1;}
              else if (nameA === nameB) {return 0;}
              if (nameA.localeCompare(nameB)===-1){ return -1; }
              else{ return 1; }
            })
          }
          this.setState({
            modalLoader: false,
            products: data
          });
        }
      })
      .catch((error)=>{
        console.log(error);
      })
      this.props.getRegions().then((data)=>{
        if (this._isMounted) {
          this.setState({
            modalLoader: false,
            regionsFP: data
          });
        }
      })
      .catch((error)=>{
        console.log(error);
      })
      if (this._isMounted) {
        this.setState({
          showContract: true,
          showOfferEnquiry: false,
          newOne: isNewOne
        })
      }
    }
  }

  rejectModalConsents = () =>{
    if (this._isMounted) {
      this.setState({
        confirmConsents: false,
        savingData: false,
        toPage: 0
      })
    }
  }

  redirectToLeadOffer = () =>{
    const { match: { params }, history } = this.props;
    if (this._isMounted) {
      this.setState({confirmNewEnquiry: false})
    }
    history.push(`${baseData.subFolders}leadDetails/${params.id}`);
  }

  closeNewEnquiryModal = () =>{
    if (this._isMounted) {
      this.setState({confirmNewEnquiry: false})
    }
  }

  clearConsents = () =>{
    const { form, currentSection, toPage } = this.state;
    const section = form.getSectionByOrder(currentSection);
    section.clearFields();
    if (this._isMounted) {
      this.setState({ 
        rerender: Date.now(),
        confirmConsents: false
      });
    }
    if(toPage!==null){
      this.changePage(toPage)
    }
  }

  shouldIShowAddButtons(){
    let show = true;
    const { form, isConsentsEditable}  = this.state;
    const { match: { params } } = this.props;
    let type = params.type;
    let readyToGo = true;
    if(type !== FormTypeToShow.ENQUIRY && type !== FormTypeToShow.CONTRACT){
      for (let i = 0; i < form.sections.length; i++) {
        let tempSection = form.getSectionByOrder(i);
        if (!tempSection.isValid) {
            readyToGo = false;
            break;
        }
      }
      if (!readyToGo) {
        show = false;
      }else{
        if(isConsentsEditable===true){
          show = false;
        }
      }
    }
    /*if(notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1){
      show = false; 
    }*/
    return show;
  }

  preapreMeButtonsTable(){
    let buttonsArray = [TableEnums.CREATECONTRACT];
    const { match: { params } } = this.props;
    if(params.patientId!==0 && params.patientId!=="0"){
      const { formbbStatus } = this.state;
      let isButtonReadyToShow = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(!isButtonReadyToShow){
        //buttonsArray.push(TableEnums.CREATEQUATATION);
      }
    }else{
      if(params.type !== FormTypeToShow.ENQUIRY){
        const { formbbStatus } = this.state;
        let isButtonReadyToShow = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
        if(!isButtonReadyToShow){
          //buttonsArray.push(TableEnums.CREATEQUATATION);
        }
      }
    }
    return buttonsArray;
  }

  setBBstatus = (status) =>{
    if (this._isMounted) {
      this.setState({formbbStatus: status})
    }
  }

  showSaveModal = () =>{
    if (this._isMounted) {
      this.setState({savingData: !this.state.savingData})
    }
  }

  hideSaveModal = () =>{
    if (this._isMounted) {
      this.setState({savingData: false})
    }
  }

  shouldWeRerenderBasedOnImportant(){
    if (this._isMounted) {
      this.setState({ rerender: Date.now()});
    }
  }

  render() {
    const { form, showContract, showOfferEnquiry, currentSection, formbbStatus, confirmConsents, confirmNewEnquiry, rerender, errorComponent, isImportant} = this.state;
    const { translateKey } = this.props;
    const isFormDisabled = notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    const showAddButtons = this.shouldIShowAddButtons();
    const section = form.getSectionByOrder(currentSection);
    return (
      <div className="mainContentWrapper" onClick={() => {this.props.tookAction()}}>
        <NavBar breadCrumbs={this.state.breadCrumbs} />
        <TableProperties showMeQuatation={this.showMeQuatation} showMeContract={this.showMeContract} options={showAddButtons?this.preapreMeButtonsTable(): []} filterData={[]}/>
        <div className="mainContent">
          <SideBar rerender={rerender} showSectionValidation = {true} changeSection={(number)=>this.changePage(number)} form={showContract===true || showOfferEnquiry===true?'':form} activeSectionNumber={currentSection}/>
          <LoaderModal show={this.state.savingData || this.state.modalLoader} text={this.state.savingData?translateKey("saving_with_dots"):''}/>
          <div className="listDataWrapper">
            {showContract === true &&
              <ContractComponent wholeResponse={this.state.wholeResponse} showMessage={this.showAlertMessage} hideSaveModal={this.hideSaveModal} showSaveModal={this.showSaveModal} leadPatients={this.state.leadPatients} showButtons = {true} setNewLeadStatus = {this.setBBstatus} bbStatus={this.state.formbbStatus} newOne={this.state.newOne} products={this.state.products} regionsFP={this.state.regionsFP} disabled={false} bbform={form}/>
            }
            {showOfferEnquiry === true &&
              <OfferEnquiryComponent wholeResponse={this.state.wholeResponse} showMessage={this.showAlertMessage} hideSaveModal={this.hideSaveModal} showSaveModal={this.showSaveModal} showButtons = {true} setNewLeadStatus = {this.setBBstatus} bbStatus={this.state.formbbStatus} newOne={this.state.newOne} type={this.state.type} convertToContrat={this.showMeContract} products={this.state.products} regionsFP={this.state.regionsFP} disabled={false} bbform={form} />
            }
            {showContract === false && showOfferEnquiry === false &&
              <React.Fragment>
                <ConfirmModal key="confirm-modal-new-enquiry" rejectMe={this.closeNewEnquiryModal} acceptMe={this.redirectToLeadOffer} show={confirmNewEnquiry} titleMessage="" mainMessage={translateKey("form-text-processing-offer")} messageYes={translateKey("form-text-go-to-lead-offer")} messageNo={translateKey("cancel")}/>
                <ConfirmModal otherMe={this.clearConsents} rejectMe={this.rejectModalConsents} acceptMe={this.saveConsents} show={confirmConsents} titleMessage="" mainMessage={translateKey("form-text-confirm-consents-save")} messageYes={translateKey("save")} messageNo={translateKey("cancel")} otherMessage={translateKey("clear")}/>
                {this.getDataBasic(isFormDisabled)}
                <div rerender={rerender} className={"btn-toolbar-fixed"+(currentSection===0 || currentSection===finalPageNumber ?' btn-toolbar-fixed-onlyone':'')}>
                  {isImportant===true && !section.allImportantsFilled && 
                    <div className="previous-next-wrapper form_importants_fields_class">
                      {translateKey("form_important_fields")}
                    </div>
                  }
                  <div className="previous-next-wrapper">
                    {currentSection!==0 &&
                      <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.previousPageClick}>{translateKey("previous")}</button>
                    }
                    {currentSection!==finalPageNumber &&
                      <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.nextPageClick}>{translateKey("next")}</button>
                    }
                    { currentSection===finalPageNumber && this.state.isConsentsEditable &&
                      <button type="button" className="btn btn-primary cursor-pointer next-previous-class" onClick={this.nextPageClick}>{translateKey("save")}</button>
                    }
                  </div>
                </div>
              </React.Fragment>
            }
            <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
            <div className="basic-bottom-section-line" style={{backgroundImage: "url(" + IconsMap.svg.pasek_big + ")"}}></div>
          </div>
        </div>
        <Prompt
            when={this.state.editedContract || this.state.editedQuatation || this.state.editedForm}
            message={translateKey("unsaved_data_lost")}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsData: state.database.leads,
    leadsStatus: state.enums.leadStatus,
    leadsStatusStatus: state.enums.leadStatusStatus,
    patientLeadData: state.database.patientLead
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setLeadsData: (data) => dispatch(setLeadsData(data)),
  getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
  pendingRequestLeads: () => dispatch(pendingLeadsData()),
  clearDataLeads: () => dispatch(removeLeadsData()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPatientWrapper));