
import {AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT, AUTH_START, REFRESHING_TOKEN, REFRESHED_TOKEN, UPDATE_ACTION_TIMESTAMP, CLOSE_POPUP } from "../actions/action-types"

const initialState = {
  appLogin: null,
  appToken: null,
  appTokenTime: null,
  rsaToken: null,
  actionTimestamp: null,
  error: null,
  loading: false,
  isRefreshingToken: false,
  userId: null,
  CooperationId: null,
  title: null,
  isPopupOpen: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        appLogin: action.login,
        appToken: action.appToken,
        appTokenTime: action.appTokenTime,
        rsaToken: action.rsaToken,
        userId: action.userId,
        error: null,
        loading: false,
        isRefreshingToken: false,
        CooperationId: action.cooperationId,
        title: action.title,
      };
    case AUTH_START:
      return {
        ...state,
        loading: true,
        error: null,
        isRefreshingToken: false
      };
    case AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        isRefreshingToken: false
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        appToken: null,
        appLogin: null,
        appTokenTime: null,
        rsaToken: null,
        isRefreshingToken: false,
        userId: null,
        CooperationId: null,
        title: null,
        isPopupOpen: true,
      }
    case REFRESHING_TOKEN:
      return {
        ...state,
        isRefreshingToken: true
      }
    case REFRESHED_TOKEN:
      return {
        ...state,
        appToken: action.appToken,
        error: null,
        isRefreshingToken: false
      }
    case UPDATE_ACTION_TIMESTAMP:
      return {
        ...state,
        actionTimestamp: action.actionTimestamp
      }
    case CLOSE_POPUP:
      return {
        ...state,
        isPopupOpen: false,
      }
    default:
      return state;
  }
};

export default reducer;