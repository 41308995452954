import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { baseData } from '../../../_constance/base_data';
import  ListClothesComponent  from '../../form-bb-components/list-clothes-component/list-clothes-component';
import produce from "immer";


class Section12 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-12'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showValidationErrors !== this.props.showValidationErrors) {
            this.setState({
                showValidation: this.props.showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    render() {
        const { disabled } = this.props;
        const { section } = this.state;
        return (
            <div className="form-section-main">
                <div className="form-section-version">
                    {this.props.translateKey("version")}: {baseData.bbFormVersion}
                </div>
                <div className="form-section-main-number">
                    <span>{this.props.translateKey("mainFormName")}</span><span className="form-section-main-number-number">S.{this.props.sectionNumber}</span>
                </div>
                <div className="form-section-subsection-number">
                    {this.props.subsectionNumber}. {this.props.subsectionName}
                </div>
                <form className="section-form-content form_serializer">
                    <fieldset disabled={disabled}>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-ankleiden-oberteil" key="is_dressing_upper_part_of_body" keyName="is_dressing_upper_part_of_body" value={section.getFieldByKey('is_dressing_upper_part_of_body').value} validationErrors={section.getFieldByKey('is_dressing_upper_part_of_body').validationErrors}/>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-ankleiden-unterhose" key="is_dressing_underwear" keyName="is_dressing_underwear" value={section.getFieldByKey('is_dressing_underwear').value} validationErrors={section.getFieldByKey('is_dressing_underwear').validationErrors}/>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-ankleiden-hose" key="is_dressing_pants_skirt" keyName="is_dressing_pants_skirt" value={section.getFieldByKey('is_dressing_pants_skirt').value} validationErrors={section.getFieldByKey('is_dressing_pants_skirt').validationErrors}/>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-ankleiden-schuhe" key="is_dressing_shoes" keyName="is_dressing_shoes" value={section.getFieldByKey('is_dressing_shoes').value} validationErrors={section.getFieldByKey('is_dressing_shoes').validationErrors}/>

                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-auskleiden-oberteil" key="is_undress_upper_part_of_body" keyName="is_undress_upper_part_of_body" value={section.getFieldByKey('is_undress_upper_part_of_body').value} validationErrors={section.getFieldByKey('is_undress_upper_part_of_body').validationErrors}/>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-auskleiden-unterhose" key="is_undress_underwear" keyName="is_undress_underwear" value={section.getFieldByKey('is_undress_underwear').value} validationErrors={section.getFieldByKey('is_undress_underwear').validationErrors}/>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-auskleiden-hose" key="is_undress_pants_skirt" keyName="is_undress_pants_skirt" value={section.getFieldByKey('is_undress_pants_skirt').value} validationErrors={section.getFieldByKey('is_undress_pants_skirt').validationErrors}/>
                        <ListClothesComponent showValidation={this.props.showValidationErrors} setTypeChangeToParent={this.setTypeChange} className="col-lg-6" transKey="form-text-auskleiden-schuhe" key="is_undress_shoes" keyName="is_undress_shoes" value={section.getFieldByKey('is_undress_shoes').value} validationErrors={section.getFieldByKey('is_undress_shoes').validationErrors}/>
                    </fieldset>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {return {}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section12));
